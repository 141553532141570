// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Function = require("../Data.Function/index.js");

var Biapply = function Biapply(Bifunctor0, biapply) {
  this.Bifunctor0 = Bifunctor0;
  this.biapply = biapply;
};

var biapply = function biapply(dict) {
  return dict.biapply;
};

var biapplyFirst = function biapplyFirst(dictBiapply) {
  return function (a) {
    return function (b) {
      return biapply(dictBiapply)(Control_Category.identity(Control_Category.categoryFn)(Data_Bifunctor.bimap(dictBiapply.Bifunctor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn))))(a))(b);
    };
  };
};

var biapplySecond = function biapplySecond(dictBiapply) {
  return function (a) {
    return function (b) {
      return biapply(dictBiapply)(Control_Category.identity(Control_Category.categoryFn)(Data_Bifunctor.bimap(dictBiapply.Bifunctor0())(Data_Function["const"])(Data_Function["const"]))(a))(b);
    };
  };
};

var bilift2 = function bilift2(dictBiapply) {
  return function (f) {
    return function (g) {
      return function (a) {
        return function (b) {
          return biapply(dictBiapply)(Control_Category.identity(Control_Category.categoryFn)(Data_Bifunctor.bimap(dictBiapply.Bifunctor0())(f)(g))(a))(b);
        };
      };
    };
  };
};

var bilift3 = function bilift3(dictBiapply) {
  return function (f) {
    return function (g) {
      return function (a) {
        return function (b) {
          return function (c) {
            return biapply(dictBiapply)(biapply(dictBiapply)(Control_Category.identity(Control_Category.categoryFn)(Data_Bifunctor.bimap(dictBiapply.Bifunctor0())(f)(g))(a))(b))(c);
          };
        };
      };
    };
  };
};

module.exports = {
  biapply: biapply,
  Biapply: Biapply,
  biapplyFirst: biapplyFirst,
  biapplySecond: biapplySecond,
  bilift2: bilift2,
  bilift3: bilift3
};