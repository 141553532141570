// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");

var Data_Semigroup_First = require("../Data.Semigroup.First/index.js");

var Data_Semigroup_Last = require("../Data.Semigroup.Last/index.js");

var Prelude = require("../Prelude/index.js");

var Newtype = function Newtype(unwrap, wrap) {
  this.unwrap = unwrap;
  this.wrap = wrap;
};

var wrap = function wrap(dict) {
  return dict.wrap;
};

var unwrap = function unwrap(dict) {
  return dict.unwrap;
};

var underF2 = function underF2(dictFunctor) {
  return function (dictFunctor1) {
    return function (dictNewtype) {
      return function (dictNewtype1) {
        return function (v) {
          return function (f) {
            return function ($66) {
              return function ($67) {
                return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(Data_Function.on(f)(Data_Functor.map(dictFunctor)(wrap(dictNewtype)))($66)($67));
              };
            };
          };
        };
      };
    };
  };
};

var underF = function underF(dictFunctor) {
  return function (dictFunctor1) {
    return function (dictNewtype) {
      return function (dictNewtype1) {
        return function (v) {
          return function (f) {
            return function ($68) {
              return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(wrap(dictNewtype))($68)));
            };
          };
        };
      };
    };
  };
};

var under2 = function under2(dictNewtype) {
  return function (dictNewtype1) {
    return function (v) {
      return function (f) {
        return function ($69) {
          return function ($70) {
            return unwrap(dictNewtype1)(Data_Function.on(f)(wrap(dictNewtype))($69)($70));
          };
        };
      };
    };
  };
};

var under = function under(dictNewtype) {
  return function (dictNewtype1) {
    return function (v) {
      return function (f) {
        return function ($71) {
          return unwrap(dictNewtype1)(f(wrap(dictNewtype)($71)));
        };
      };
    };
  };
};

var un = function un(dictNewtype) {
  return function (v) {
    return unwrap(dictNewtype);
  };
};

var traverse = function traverse(dictFunctor) {
  return function (dictNewtype) {
    return function (v) {
      return function (f) {
        return function ($72) {
          return Data_Functor.map(dictFunctor)(wrap(dictNewtype))(f(unwrap(dictNewtype)($72)));
        };
      };
    };
  };
};

var overF2 = function overF2(dictFunctor) {
  return function (dictFunctor1) {
    return function (dictNewtype) {
      return function (dictNewtype1) {
        return function (v) {
          return function (f) {
            return function ($73) {
              return function ($74) {
                return Data_Functor.map(dictFunctor1)(wrap(dictNewtype1))(Data_Function.on(f)(Data_Functor.map(dictFunctor)(unwrap(dictNewtype)))($73)($74));
              };
            };
          };
        };
      };
    };
  };
};

var overF = function overF(dictFunctor) {
  return function (dictFunctor1) {
    return function (dictNewtype) {
      return function (dictNewtype1) {
        return function (v) {
          return function (f) {
            return function ($75) {
              return Data_Functor.map(dictFunctor1)(wrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(unwrap(dictNewtype))($75)));
            };
          };
        };
      };
    };
  };
};

var over2 = function over2(dictNewtype) {
  return function (dictNewtype1) {
    return function (v) {
      return function (f) {
        return function ($76) {
          return function ($77) {
            return wrap(dictNewtype1)(Data_Function.on(f)(unwrap(dictNewtype))($76)($77));
          };
        };
      };
    };
  };
};

var over = function over(dictNewtype) {
  return function (dictNewtype1) {
    return function (v) {
      return function (f) {
        return function ($78) {
          return wrap(dictNewtype1)(f(unwrap(dictNewtype)($78)));
        };
      };
    };
  };
};

var op = function op(dictNewtype) {
  return un(dictNewtype);
};

var newtypeMultiplicative = new Newtype(function (v) {
  return v;
}, Data_Monoid_Multiplicative.Multiplicative);
var newtypeLast = new Newtype(function (v) {
  return v;
}, Data_Semigroup_Last.Last);
var newtypeFirst = new Newtype(function (v) {
  return v;
}, Data_Semigroup_First.First);
var newtypeEndo = new Newtype(function (v) {
  return v;
}, Data_Monoid_Endo.Endo);
var newtypeDual = new Newtype(function (v) {
  return v;
}, Data_Monoid_Dual.Dual);
var newtypeDisj = new Newtype(function (v) {
  return v;
}, Data_Monoid_Disj.Disj);
var newtypeConj = new Newtype(function (v) {
  return v;
}, Data_Monoid_Conj.Conj);
var newtypeAdditive = new Newtype(function (v) {
  return v;
}, Data_Monoid_Additive.Additive);

var collect = function collect(dictFunctor) {
  return function (dictNewtype) {
    return function (v) {
      return function (f) {
        return function ($79) {
          return wrap(dictNewtype)(f(Data_Functor.map(dictFunctor)(unwrap(dictNewtype))($79)));
        };
      };
    };
  };
};

var alaF = function alaF(dictFunctor) {
  return function (dictFunctor1) {
    return function (dictNewtype) {
      return function (dictNewtype1) {
        return function (v) {
          return function (f) {
            return function ($80) {
              return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(wrap(dictNewtype))($80)));
            };
          };
        };
      };
    };
  };
};

var ala = function ala(dictFunctor) {
  return function (dictNewtype) {
    return function (dictNewtype1) {
      return function (v) {
        return function (f) {
          return Data_Functor.map(dictFunctor)(unwrap(dictNewtype))(f(wrap(dictNewtype1)));
        };
      };
    };
  };
};

module.exports = {
  unwrap: unwrap,
  wrap: wrap,
  Newtype: Newtype,
  un: un,
  op: op,
  ala: ala,
  alaF: alaF,
  over: over,
  overF: overF,
  under: under,
  underF: underF,
  over2: over2,
  overF2: overF2,
  under2: under2,
  underF2: underF2,
  traverse: traverse,
  collect: collect,
  newtypeAdditive: newtypeAdditive,
  newtypeMultiplicative: newtypeMultiplicative,
  newtypeConj: newtypeConj,
  newtypeDisj: newtypeDisj,
  newtypeDual: newtypeDual,
  newtypeEndo: newtypeEndo,
  newtypeFirst: newtypeFirst,
  newtypeLast: newtypeLast
};