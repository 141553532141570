// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_DOM_HTMLCollection = require("../Web.DOM.HTMLCollection/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NodeList = require("../Web.DOM.NodeList/index.js");

var QuerySelector = function QuerySelector(x) {
  return x;
};

var querySelector = function querySelector(qs) {
  return function ($3) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_querySelector"](qs)($3));
  };
};

var ordQuerySelector = Data_Ord.ordString;
var newtypeQuerySelector = new Data_Newtype.Newtype(function (n) {
  return n;
}, QuerySelector);

var lastElementChild = function lastElementChild($4) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_lastElementChild"]($4));
};

var firstElementChild = function firstElementChild($5) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_firstElementChild"]($5));
};

var eqQuerySelector = Data_Eq.eqString;
module.exports = {
  firstElementChild: firstElementChild,
  lastElementChild: lastElementChild,
  QuerySelector: QuerySelector,
  querySelector: querySelector,
  eqQuerySelector: eqQuerySelector,
  ordQuerySelector: ordQuerySelector,
  newtypeQuerySelector: newtypeQuerySelector,
  children: $foreign.children,
  childElementCount: $foreign.childElementCount,
  querySelectorAll: $foreign.querySelectorAll
};