// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var React_Basic = require("../React.Basic/index.js");

var breadcrumbItem = function breadcrumbItem(children) {
  return React_Basic.element($foreign["_breadcrumbItem"])({
    children: children
  });
};

var breadcrumb = function breadcrumb(dictUnion) {
  return React_Basic.element($foreign["_breadcrumb"]);
};

var breadcrumb_ = function breadcrumb_(children) {
  return breadcrumb()({
    children: children
  });
};

module.exports = {
  breadcrumb: breadcrumb,
  breadcrumb_: breadcrumb_,
  breadcrumbItem: breadcrumbItem
};