// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Auth = require("../Optimus.Client.Auth/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var render = function render(v) {
  return Data_Monoid.mempty(React_Basic.monoidJSX);
};

var didMount = function didMount(self) {
  return function __do() {
    var v = Optimus_Client_Auth.requireAuth();

    if (v instanceof Data_Maybe.Just) {
      return self.props.history.replace(new Optimus_Client_Route.Route(v.value0, Optimus_Client_Route.SiloIndex.value))();
    }

    ;
    return self.props.history.replace(Optimus_Client_Route.Login.value)();
  };
};

var component = React_Basic.createComponent("Index");
var indexPage = React_Basic.make()(component)({
  initialState: Data_Unit.unit,
  didMount: didMount,
  render: render
});
module.exports = {
  component: component,
  indexPage: indexPage,
  didMount: didMount,
  render: render
};