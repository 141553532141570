// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Input = require("../Optimus.Client.Antd.Input/index.js");

var Optimus_Client_Antd_Message = require("../Optimus.Client.Antd.Message/index.js");

var Optimus_Client_ApiClient_Users = require("../Optimus.Client.ApiClient.Users/index.js");

var Optimus_Client_Clipboard = require("../Optimus.Client.Clipboard/index.js");

var Optimus_Client_Component_Content = require("../Optimus.Client.Component.Content/index.js");

var Optimus_Client_Components_Card = require("../Optimus.Client.Components.Card/index.js");

var Optimus_Client_Components_Table = require("../Optimus.Client.Components.Table/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Optimus_Shared_Users = require("../Optimus.Shared.Users/index.js");

var Optimus_Utilities_Default = require("../Optimus.Utilities.Default/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Loading = function () {
  function Loading() {}

  ;
  Loading.value = new Loading();
  return Loading;
}();

var Success = function () {
  function Success(value0) {
    this.value0 = value0;
  }

  ;

  Success.create = function (value0) {
    return new Success(value0);
  };

  return Success;
}();

var Failed = function () {
  function Failed() {}

  ;
  Failed.value = new Failed();
  return Failed;
}();

var Init = function () {
  function Init() {}

  ;
  Init.value = new Init();
  return Init;
}();

var component = React_Basic.createComponent("SearchUsersPage");

var searchPage = function searchPage(props) {
  var onSubmitSearch = function onSubmitSearch(self) {
    return function (query) {
      return self.props.history.push(Optimus_Client_Route.Route.create(props.siloId)(Optimus_Client_Route.SiloRoute.create(Optimus_Client_Route.Users.create(new Optimus_Client_Route.UsersSearch({
        query: new Data_Maybe.Just(query)
      })))));
    };
  };

  var render = function render(self) {
    return Optimus_Client_Component_Content.content({
      title: "User Search",
      children: [Optimus_Client_Components_Card.card([Optimus_Client_Antd_Grid.row_([React_Basic.keyed(Data_Maybe.fromMaybe(Optimus_Utilities_Default["default"](Optimus_Utilities_Default.defaultString))(self.props.query))(Optimus_Client_Antd_Input.inputSearch()()({
        allowClear: true,
        defaultValue: Data_Maybe.fromMaybe(Optimus_Utilities_Default["default"](Optimus_Utilities_Default.defaultString))(self.props.query),
        enterButton: true,
        onSearch: Effect_Uncurried.mkEffectFn1(onSubmitSearch(self))
      }))]), React_Basic_DOM_Generated.br()({}), Optimus_Client_Antd_Grid.row_([function () {
        if (self.state instanceof Failed) {
          return React_Basic_DOM.text("FAILED");
        }

        ;
        return React_Basic.fragment([Optimus_Client_Components_Table.table({
          onRowClick: function onRowClick(v) {
            return self.props.history.push(Optimus_Client_Route.Route.create(props.siloId)(Optimus_Client_Route.SiloRoute.create(Optimus_Client_Route.Users.create(new Optimus_Client_Route.UsersOverview(v.zapperId)))));
          },
          loading: function () {
            if (self.state instanceof Loading) {
              return true;
            }

            ;
            return false;
          }()
        })([Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowDateTiem)("Date Registered")(function (v) {
          return v.dateRegistered;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowBoolean)("Active")(function (v) {
          return v.active;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowString)("Mobile Number")(function (v) {
          return v.mobile;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("Email")(function (v) {
          return v.email;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("First Name")(function (v) {
          return v.firstName;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("Last Name")(function (v) {
          return v.lastName;
        }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowArray(Optimus_Client_InfoShow.infoShowString))("Tags")(function (v) {
          return v.tags;
        }), Optimus_Client_Components_Table.cspec("Actions")(function (v) {
          return React_Basic_DOM_Generated.a()({
            onClick: React_Basic_DOM_Events.capture_(function __do() {
              Optimus_Client_Clipboard.copy(v.zapperId)();
              return Optimus_Client_Antd_Message.message.success("Successfully copied Zapper ID: " + v.zapperId)();
            }),
            children: [React_Basic_DOM.text("Copy Zapper ID")]
          });
        })])(function () {
          if (self.state instanceof Success) {
            return self.state.value0;
          }

          ;
          return [];
        }())]);
      }()])])]
    });
  };

  var handleUserSearch = function handleUserSearch(v) {
    if (v.query instanceof Data_Maybe.Just) {
      return function __do() {
        v.setState(function (s) {
          return Loading.value;
        })();
        return Effect_Aff.runAff_(function (v1) {
          if (v1 instanceof Data_Either.Right) {
            return v.setState(function (s) {
              return new Success(v1.value0);
            });
          }

          ;
          return v.setState(function (s) {
            return Failed.value;
          });
        })(Optimus_Client_ApiClient_Users.getUserSearch(v.siloId)(v.query.value0))();
      };
    }

    ;
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
  };

  var didUpdate = function didUpdate(self) {
    return function (v) {
      var $27 = !Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.prevProps.query)(self.props.query);

      if ($27) {
        return handleUserSearch({
          query: self.props.query,
          setState: self.setState,
          siloId: self.props.siloId
        });
      }

      ;
      return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
  };

  var didMount = function didMount(self) {
    return handleUserSearch({
      query: self.props.query,
      setState: self.setState,
      siloId: self.props.siloId
    });
  };

  return React_Basic.make()(component)({
    initialState: Init.value,
    didMount: didMount,
    didUpdate: didUpdate,
    render: render
  })(props);
};

module.exports = {
  searchPage: searchPage
};