// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_DOM_Node = require("../Web.DOM.Node/index.js");

var Web_DOM_NodeList = require("../Web.DOM.NodeList/index.js");

var MutationRecordAttributes = function () {
  function MutationRecordAttributes() {}

  ;
  MutationRecordAttributes.value = new MutationRecordAttributes();
  return MutationRecordAttributes;
}();

var MutationRecordCharacterData = function () {
  function MutationRecordCharacterData() {}

  ;
  MutationRecordCharacterData.value = new MutationRecordCharacterData();
  return MutationRecordCharacterData;
}();

var MutationRecordChildList = function () {
  function MutationRecordChildList() {}

  ;
  MutationRecordChildList.value = new MutationRecordChildList();
  return MutationRecordChildList;
}();

var type_ = function type_(dictPartial) {
  var stringToType = function stringToType(v) {
    if (v === "attributes") {
      return MutationRecordAttributes.value;
    }

    ;

    if (v === "characterData") {
      return MutationRecordCharacterData.value;
    }

    ;

    if (v === "childList") {
      return MutationRecordChildList.value;
    }

    ;
    throw new Error("Failed pattern match at Web.DOM.MutationRecord (line 36, column 18 - line 39, column 43): " + [v.constructor.name]);
  };

  return function ($6) {
    return Data_Functor.map(Effect.functorEffect)(stringToType)($foreign.typeString($6));
  };
};

var previousSibling = function previousSibling($7) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_previousSibling"]($7));
};

var oldValue = function oldValue($8) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_oldValue"]($8));
};

var nextSibling = function nextSibling($9) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_nextSibling"]($9));
};

var attributeNamespace = function attributeNamespace($10) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_attributeNamespace"]($10));
};

var attributeName = function attributeName($11) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_attributeName"]($11));
};

module.exports = {
  MutationRecordAttributes: MutationRecordAttributes,
  MutationRecordCharacterData: MutationRecordCharacterData,
  MutationRecordChildList: MutationRecordChildList,
  type_: type_,
  nextSibling: nextSibling,
  previousSibling: previousSibling,
  attributeName: attributeName,
  attributeNamespace: attributeNamespace,
  oldValue: oldValue,
  typeString: $foreign.typeString,
  target: $foreign.target,
  addedNodes: $foreign.addedNodes,
  removedNodes: $foreign.removedNodes
};