// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Date = require("../Data.Date/index.js");

var Data_Date_Component = require("../Data.Date.Component/index.js");

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_DateTime_Instant = require("../Data.DateTime.Instant/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Time = require("../Data.Time/index.js");

var Data_Time_Component = require("../Data.Time.Component/index.js");

var Data_Time_Duration = require("../Data.Time.Duration/index.js");

var Effect = require("../Effect/index.js");

var Foreign = require("../Foreign/index.js");

var Prelude = require("../Prelude/index.js");

var toUTCString = function toUTCString(dt) {
  return $foreign.dateMethod("toUTCString", dt);
};

var toTimeString = function toTimeString(dt) {
  return $foreign.dateMethod("toTimeString", dt);
};

var toString = function toString(dt) {
  return $foreign.dateMethod("toString", dt);
};

var toInstant = Control_Bind.composeKleisliFlipped(Data_Maybe.bindMaybe)(function ($4) {
  return Data_DateTime_Instant.instant(Data_Time_Duration.Milliseconds($4));
})($foreign.toInstantImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));

var toISOString = function toISOString(dt) {
  return $foreign.dateMethodEff("toISOString", dt);
};

var toDateTime = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_DateTime_Instant.toDateTime))(toInstant);

var toDateString = function toDateString(dt) {
  return $foreign.dateMethod("toDateString", dt);
};

var toDate = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_DateTime.date))(toDateTime);
var readDate = Foreign.unsafeReadTagged("Date");

var getUTCSeconds = function getUTCSeconds(dt) {
  return $foreign.dateMethod("getUTCSeconds", dt);
};

var getUTCMonth = function getUTCMonth(dt) {
  return $foreign.dateMethod("getUTCMonth", dt);
};

var getUTCMinutes = function getUTCMinutes(dt) {
  return $foreign.dateMethod("getUTCMinutes", dt);
};

var getUTCMilliseconds = function getUTCMilliseconds(dt) {
  return $foreign.dateMethod("getUTCMilliseconds", dt);
};

var getUTCHours = function getUTCHours(dt) {
  return $foreign.dateMethod("getUTCHours", dt);
};

var getUTCFullYear = function getUTCFullYear(dt) {
  return $foreign.dateMethod("getUTCFullYear", dt);
};

var getUTCDay = function getUTCDay(dt) {
  return $foreign.dateMethod("getUTCDay", dt);
};

var getUTCDate = function getUTCDate(dt) {
  return $foreign.dateMethod("getUTCDate", dt);
};

var getTimezoneOffset = function getTimezoneOffset(dt) {
  return $foreign.dateMethodEff("getTimezoneOffset", dt);
};

var getTime = function getTime(dt) {
  return $foreign.dateMethod("getTime", dt);
};

var showJSDate = new Data_Show.Show(function (a) {
  return "(fromTime " + (Data_Show.show(Data_Show.showNumber)(getTime(a)) + ")");
});

var getSeconds = function getSeconds(dt) {
  return $foreign.dateMethodEff("getSeconds", dt);
};

var getMonth = function getMonth(dt) {
  return $foreign.dateMethodEff("getMonth", dt);
};

var getMinutes = function getMinutes(dt) {
  return $foreign.dateMethodEff("getMinutes", dt);
};

var getMilliseconds = function getMilliseconds(dt) {
  return $foreign.dateMethodEff("getMilliseconds", dt);
};

var getHours = function getHours(dt) {
  return $foreign.dateMethodEff("getHours", dt);
};

var getFullYear = function getFullYear(dt) {
  return $foreign.dateMethodEff("getFullYear", dt);
};

var getDay = function getDay(dt) {
  return $foreign.dateMethodEff("getDay", dt);
};

var getDate = function getDate(dt) {
  return $foreign.dateMethodEff("getDate", dt);
};

var fromDateTime = function fromDateTime(v) {
  return $foreign.jsdate({
    year: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0))),
    month: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(v.value0)) - 1 | 0),
    day: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(v.value0))),
    hour: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1))),
    minute: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(v.value1))),
    second: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(v.value1))),
    millisecond: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(v.value1)))
  });
};

var eqJSDate = new Data_Eq.Eq(function (a) {
  return function (b) {
    return getTime(a) === getTime(b);
  };
});
var ordJSDate = new Data_Ord.Ord(function () {
  return eqJSDate;
}, function (a) {
  return function (b) {
    return Data_Ord.compare(Data_Ord.ordNumber)(getTime(a))(getTime(b));
  };
});
module.exports = {
  readDate: readDate,
  fromDateTime: fromDateTime,
  toDateTime: toDateTime,
  toDate: toDate,
  toInstant: toInstant,
  getTime: getTime,
  getUTCDate: getUTCDate,
  getUTCDay: getUTCDay,
  getUTCFullYear: getUTCFullYear,
  getUTCHours: getUTCHours,
  getUTCMilliseconds: getUTCMilliseconds,
  getUTCMinutes: getUTCMinutes,
  getUTCMonth: getUTCMonth,
  getUTCSeconds: getUTCSeconds,
  getDate: getDate,
  getDay: getDay,
  getFullYear: getFullYear,
  getHours: getHours,
  getMilliseconds: getMilliseconds,
  getMinutes: getMinutes,
  getMonth: getMonth,
  getSeconds: getSeconds,
  getTimezoneOffset: getTimezoneOffset,
  toDateString: toDateString,
  toISOString: toISOString,
  toString: toString,
  toTimeString: toTimeString,
  toUTCString: toUTCString,
  eqJSDate: eqJSDate,
  ordJSDate: ordJSDate,
  showJSDate: showJSDate,
  isValid: $foreign.isValid,
  fromInstant: $foreign.fromInstant,
  jsdate: $foreign.jsdate,
  jsdateLocal: $foreign.jsdateLocal,
  now: $foreign.now,
  parse: $foreign.parse,
  fromTime: $foreign.fromTime
};