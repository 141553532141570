// Generated by purs version 0.12.3
"use strict";

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Card = require("../Optimus.Client.Antd.Card/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var React_Basic = require("../React.Basic/index.js");

var card = function card(children) {
  return Optimus_Client_Antd_Grid.col()({
    className: "p-3",
    children: [Optimus_Client_Antd_Card.card()({
      bordered: false,
      children: children
    })]
  });
};

module.exports = {
  card: card
};