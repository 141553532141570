// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var First = function First(x) {
  return x;
};

var showFirst = function showFirst(dictShow) {
  return new Data_Show.Show(function (v) {
    return "First (" + (Data_Show.show(Data_Maybe.showMaybe(dictShow))(v) + ")");
  });
};

var semigroupFirst = new Data_Semigroup.Semigroup(function (v) {
  return function (v1) {
    if (v instanceof Data_Maybe.Just) {
      return v;
    }

    ;
    return v1;
  };
});

var ordFirst = function ordFirst(dictOrd) {
  return Data_Maybe.ordMaybe(dictOrd);
};

var ord1First = Data_Maybe.ord1Maybe;
var newtypeFirst = new Data_Newtype.Newtype(function (n) {
  return n;
}, First);
var monoidFirst = new Data_Monoid.Monoid(function () {
  return semigroupFirst;
}, Data_Maybe.Nothing.value);
var monadFirst = Data_Maybe.monadMaybe;
var invariantFirst = Data_Maybe.invariantMaybe;
var functorFirst = Data_Maybe.functorMaybe;
var extendFirst = Data_Maybe.extendMaybe;

var eqFirst = function eqFirst(dictEq) {
  return Data_Maybe.eqMaybe(dictEq);
};

var eq1First = Data_Maybe.eq1Maybe;

var boundedFirst = function boundedFirst(dictBounded) {
  return Data_Maybe.boundedMaybe(dictBounded);
};

var bindFirst = Data_Maybe.bindMaybe;
var applyFirst = Data_Maybe.applyMaybe;
var applicativeFirst = Data_Maybe.applicativeMaybe;
var altFirst = new Control_Alt.Alt(function () {
  return functorFirst;
}, Data_Semigroup.append(semigroupFirst));
var plusFirst = new Control_Plus.Plus(function () {
  return altFirst;
}, Data_Monoid.mempty(monoidFirst));
var alternativeFirst = new Control_Alternative.Alternative(function () {
  return applicativeFirst;
}, function () {
  return plusFirst;
});
var monadZeroFirst = new Control_MonadZero.MonadZero(function () {
  return alternativeFirst;
}, function () {
  return monadFirst;
});
module.exports = {
  First: First,
  newtypeFirst: newtypeFirst,
  eqFirst: eqFirst,
  eq1First: eq1First,
  ordFirst: ordFirst,
  ord1First: ord1First,
  boundedFirst: boundedFirst,
  functorFirst: functorFirst,
  invariantFirst: invariantFirst,
  applyFirst: applyFirst,
  applicativeFirst: applicativeFirst,
  bindFirst: bindFirst,
  monadFirst: monadFirst,
  extendFirst: extendFirst,
  showFirst: showFirst,
  semigroupFirst: semigroupFirst,
  monoidFirst: monoidFirst,
  altFirst: altFirst,
  plusFirst: plusFirst,
  alternativeFirst: alternativeFirst,
  monadZeroFirst: monadZeroFirst
};