// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM_ChildNode = require("../Web.DOM.ChildNode/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("DocumentType");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("DocumentType");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("DocumentType");
module.exports = {
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromEventTarget: fromEventTarget,
  toNode: toNode,
  toChildNode: toChildNode,
  toEventTarget: toEventTarget,
  name: $foreign.name,
  publicId: $foreign.publicId,
  systemId: $foreign.systemId
};