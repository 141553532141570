// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_DOM_DOMTokenList = require("../Web.DOM.DOMTokenList/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLAnchorElement");
module.exports = {
  fromHTMLElement: fromHTMLElement,
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toHTMLElement: toHTMLElement,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  target: $foreign.target,
  setTarget: $foreign.setTarget,
  download: $foreign.download,
  setDownload: $foreign.setDownload,
  rel: $foreign.rel,
  setRel: $foreign.setRel,
  rev: $foreign.rev,
  setRev: $foreign.setRev,
  relList: $foreign.relList,
  hreflang: $foreign.hreflang,
  setHreflang: $foreign.setHreflang,
  type_: $foreign.type_,
  setType: $foreign.setType,
  text: $foreign.text,
  setText: $foreign.setText
};