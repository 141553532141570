var auth0 = require('auth0-js');

exports.mkWebAuth = function (webConfig) {
  return function () {
    return new auth0.WebAuth(webConfig);
  };
};

exports._authorize = function (webAuth) {
  return function () {
    return webAuth.authorize();
  };
};

exports._parseHash = function (left) {
  return function (right) {
    return function (callback) {
      return function (webAuth) {
        return function () {
          webAuth.parseHash(function (err, res) {
            if (err) {
              return callback(left(err))();
            }

            return callback(right(res))();
          });
        };
      };
    };
  };
};

exports._logout = function (webAuth) {
  return function (logoutConfig) {
    return function () {
      return webAuth.logout(logoutConfig);
    };
  };
};