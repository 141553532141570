// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Maybe_First = require("../Data.Maybe.First/index.js");

var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Foldable = function Foldable(foldMap, foldl, foldr) {
  this.foldMap = foldMap;
  this.foldl = foldl;
  this.foldr = foldr;
};

var foldr = function foldr(dict) {
  return dict.foldr;
};

var indexr = function indexr(dictFoldable) {
  return function (idx) {
    var go = function go(a) {
      return function (cursor) {
        if (cursor.elem instanceof Data_Maybe.Just) {
          return cursor;
        }

        ;
        var $106 = cursor.pos === idx;

        if ($106) {
          return {
            elem: new Data_Maybe.Just(a),
            pos: cursor.pos
          };
        }

        ;
        return {
          pos: cursor.pos + 1 | 0,
          elem: cursor.elem
        };
      };
    };

    return function ($193) {
      return function (v) {
        return v.elem;
      }(foldr(dictFoldable)(go)({
        elem: Data_Maybe.Nothing.value,
        pos: 0
      })($193));
    };
  };
};

var $$null = function $$null(dictFoldable) {
  return foldr(dictFoldable)(function (v) {
    return function (v1) {
      return false;
    };
  })(true);
};

var oneOf = function oneOf(dictFoldable) {
  return function (dictPlus) {
    return foldr(dictFoldable)(Control_Alt.alt(dictPlus.Alt0()))(Control_Plus.empty(dictPlus));
  };
};

var oneOfMap = function oneOfMap(dictFoldable) {
  return function (dictPlus) {
    return function (f) {
      return foldr(dictFoldable)(function ($194) {
        return Control_Alt.alt(dictPlus.Alt0())(f($194));
      })(Control_Plus.empty(dictPlus));
    };
  };
};

var traverse_ = function traverse_(dictApplicative) {
  return function (dictFoldable) {
    return function (f) {
      return foldr(dictFoldable)(function ($195) {
        return Control_Apply.applySecond(dictApplicative.Apply0())(f($195));
      })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
    };
  };
};

var for_ = function for_(dictApplicative) {
  return function (dictFoldable) {
    return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
  };
};

var sequence_ = function sequence_(dictApplicative) {
  return function (dictFoldable) {
    return traverse_(dictApplicative)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var foldl = function foldl(dict) {
  return dict.foldl;
};

var indexl = function indexl(dictFoldable) {
  return function (idx) {
    var go = function go(cursor) {
      return function (a) {
        if (cursor.elem instanceof Data_Maybe.Just) {
          return cursor;
        }

        ;
        var $109 = cursor.pos === idx;

        if ($109) {
          return {
            elem: new Data_Maybe.Just(a),
            pos: cursor.pos
          };
        }

        ;
        return {
          pos: cursor.pos + 1 | 0,
          elem: cursor.elem
        };
      };
    };

    return function ($196) {
      return function (v) {
        return v.elem;
      }(foldl(dictFoldable)(go)({
        elem: Data_Maybe.Nothing.value,
        pos: 0
      })($196));
    };
  };
};

var intercalate = function intercalate(dictFoldable) {
  return function (dictMonoid) {
    return function (sep) {
      return function (xs) {
        var go = function go(v) {
          return function (x) {
            if (v.init) {
              return {
                init: false,
                acc: x
              };
            }

            ;
            return {
              init: false,
              acc: Data_Semigroup.append(dictMonoid.Semigroup0())(v.acc)(Data_Semigroup.append(dictMonoid.Semigroup0())(sep)(x))
            };
          };
        };

        return foldl(dictFoldable)(go)({
          init: true,
          acc: Data_Monoid.mempty(dictMonoid)
        })(xs).acc;
      };
    };
  };
};

var length = function length(dictFoldable) {
  return function (dictSemiring) {
    return foldl(dictFoldable)(function (c) {
      return function (v) {
        return Data_Semiring.add(dictSemiring)(Data_Semiring.one(dictSemiring))(c);
      };
    })(Data_Semiring.zero(dictSemiring));
  };
};

var maximumBy = function maximumBy(dictFoldable) {
  return function (cmp) {
    var max$prime = function max$prime(v) {
      return function (v1) {
        if (v instanceof Data_Maybe.Nothing) {
          return new Data_Maybe.Just(v1);
        }

        ;

        if (v instanceof Data_Maybe.Just) {
          return new Data_Maybe.Just(function () {
            var $116 = Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1))(Data_Ordering.GT.value);

            if ($116) {
              return v.value0;
            }

            ;
            return v1;
          }());
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 389, column 3 - line 389, column 27): " + [v.constructor.name, v1.constructor.name]);
      };
    };

    return foldl(dictFoldable)(max$prime)(Data_Maybe.Nothing.value);
  };
};

var maximum = function maximum(dictOrd) {
  return function (dictFoldable) {
    return maximumBy(dictFoldable)(Data_Ord.compare(dictOrd));
  };
};

var minimumBy = function minimumBy(dictFoldable) {
  return function (cmp) {
    var min$prime = function min$prime(v) {
      return function (v1) {
        if (v instanceof Data_Maybe.Nothing) {
          return new Data_Maybe.Just(v1);
        }

        ;

        if (v instanceof Data_Maybe.Just) {
          return new Data_Maybe.Just(function () {
            var $120 = Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1))(Data_Ordering.LT.value);

            if ($120) {
              return v.value0;
            }

            ;
            return v1;
          }());
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 402, column 3 - line 402, column 27): " + [v.constructor.name, v1.constructor.name]);
      };
    };

    return foldl(dictFoldable)(min$prime)(Data_Maybe.Nothing.value);
  };
};

var minimum = function minimum(dictOrd) {
  return function (dictFoldable) {
    return minimumBy(dictFoldable)(Data_Ord.compare(dictOrd));
  };
};

var product = function product(dictFoldable) {
  return function (dictSemiring) {
    return foldl(dictFoldable)(Data_Semiring.mul(dictSemiring))(Data_Semiring.one(dictSemiring));
  };
};

var sum = function sum(dictFoldable) {
  return function (dictSemiring) {
    return foldl(dictFoldable)(Data_Semiring.add(dictSemiring))(Data_Semiring.zero(dictSemiring));
  };
};

var foldableMultiplicative = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});
var foldableMaybe = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      if (v instanceof Data_Maybe.Nothing) {
        return Data_Monoid.mempty(dictMonoid);
      }

      ;

      if (v instanceof Data_Maybe.Just) {
        return f(v.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [f.constructor.name, v.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Data_Maybe.Nothing) {
        return z;
      }

      ;

      if (v1 instanceof Data_Maybe.Just) {
        return v(z)(v1.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Data_Maybe.Nothing) {
        return z;
      }

      ;

      if (v1 instanceof Data_Maybe.Just) {
        return v(v1.value0)(z);
      }

      ;
      throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
});
var foldableDual = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});
var foldableDisj = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});
var foldableConj = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});
var foldableAdditive = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});

var foldMapDefaultR = function foldMapDefaultR(dictFoldable) {
  return function (dictMonoid) {
    return function (f) {
      return foldr(dictFoldable)(function (x) {
        return function (acc) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldableArray = new Foldable(function (dictMonoid) {
  return foldMapDefaultR(foldableArray)(dictMonoid);
}, $foreign.foldlArray, $foreign.foldrArray);

var foldMapDefaultL = function foldMapDefaultL(dictFoldable) {
  return function (dictMonoid) {
    return function (f) {
      return foldl(dictFoldable)(function (acc) {
        return function (x) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f(x));
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldMap = function foldMap(dict) {
  return dict.foldMap;
};

var foldableFirst = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return foldMap(foldableMaybe)(dictMonoid)(f)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return foldl(foldableMaybe)(f)(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return foldr(foldableMaybe)(f)(z)(v);
    };
  };
});
var foldableLast = new Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return foldMap(foldableMaybe)(dictMonoid)(f)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return foldl(foldableMaybe)(f)(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return foldr(foldableMaybe)(f)(z)(v);
    };
  };
});

var foldlDefault = function foldlDefault(dictFoldable) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(foldMap(dictFoldable)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))(function ($197) {
          return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo(Data_Function.flip(c)($197)));
        })(xs)))(u);
      };
    };
  };
};

var foldrDefault = function foldrDefault(dictFoldable) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMap(dictFoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function ($198) {
          return Data_Monoid_Endo.Endo(c($198));
        })(xs))(u);
      };
    };
  };
};

var surroundMap = function surroundMap(dictFoldable) {
  return function (dictSemigroup) {
    return function (d) {
      return function (t) {
        return function (f) {
          var joined = function joined(a) {
            return function (m) {
              return Data_Semigroup.append(dictSemigroup)(d)(Data_Semigroup.append(dictSemigroup)(t(a))(m));
            };
          };

          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMap(dictFoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(joined)(f))(d);
        };
      };
    };
  };
};

var surround = function surround(dictFoldable) {
  return function (dictSemigroup) {
    return function (d) {
      return surroundMap(dictFoldable)(dictSemigroup)(d)(Control_Category.identity(Control_Category.categoryFn));
    };
  };
};

var foldM = function foldM(dictFoldable) {
  return function (dictMonad) {
    return function (f) {
      return function (a0) {
        return foldl(dictFoldable)(function (ma) {
          return function (b) {
            return Control_Bind.bind(dictMonad.Bind1())(ma)(Data_Function.flip(f)(b));
          };
        })(Control_Applicative.pure(dictMonad.Applicative0())(a0));
      };
    };
  };
};

var fold = function fold(dictFoldable) {
  return function (dictMonoid) {
    return foldMap(dictFoldable)(dictMonoid)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var findMap = function findMap(dictFoldable) {
  return function (p) {
    var go = function go(v) {
      return function (v1) {
        if (v instanceof Data_Maybe.Nothing) {
          return p(v1);
        }

        ;
        return v;
      };
    };

    return foldl(dictFoldable)(go)(Data_Maybe.Nothing.value);
  };
};

var find = function find(dictFoldable) {
  return function (p) {
    var go = function go(v) {
      return function (v1) {
        if (v instanceof Data_Maybe.Nothing && p(v1)) {
          return new Data_Maybe.Just(v1);
        }

        ;
        return v;
      };
    };

    return foldl(dictFoldable)(go)(Data_Maybe.Nothing.value);
  };
};

var any = function any(dictFoldable) {
  return function (dictHeytingAlgebra) {
    return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeDisj)(Data_Newtype.newtypeDisj)(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
  };
};

var elem = function elem(dictFoldable) {
  return function (dictEq) {
    return function ($199) {
      return any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(dictEq)($199));
    };
  };
};

var notElem = function notElem(dictFoldable) {
  return function (dictEq) {
    return function (x) {
      return function ($200) {
        return !elem(dictFoldable)(dictEq)(x)($200);
      };
    };
  };
};

var or = function or(dictFoldable) {
  return function (dictHeytingAlgebra) {
    return any(dictFoldable)(dictHeytingAlgebra)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var all = function all(dictFoldable) {
  return function (dictHeytingAlgebra) {
    return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeConj)(Data_Newtype.newtypeConj)(Data_Monoid_Conj.Conj)(foldMap(dictFoldable)(Data_Monoid_Conj.monoidConj(dictHeytingAlgebra)));
  };
};

var and = function and(dictFoldable) {
  return function (dictHeytingAlgebra) {
    return all(dictFoldable)(dictHeytingAlgebra)(Control_Category.identity(Control_Category.categoryFn));
  };
};

module.exports = {
  Foldable: Foldable,
  foldr: foldr,
  foldl: foldl,
  foldMap: foldMap,
  foldrDefault: foldrDefault,
  foldlDefault: foldlDefault,
  foldMapDefaultL: foldMapDefaultL,
  foldMapDefaultR: foldMapDefaultR,
  fold: fold,
  foldM: foldM,
  traverse_: traverse_,
  for_: for_,
  sequence_: sequence_,
  oneOf: oneOf,
  oneOfMap: oneOfMap,
  intercalate: intercalate,
  surroundMap: surroundMap,
  surround: surround,
  and: and,
  or: or,
  all: all,
  any: any,
  sum: sum,
  product: product,
  elem: elem,
  notElem: notElem,
  indexl: indexl,
  indexr: indexr,
  find: find,
  findMap: findMap,
  maximum: maximum,
  maximumBy: maximumBy,
  minimum: minimum,
  minimumBy: minimumBy,
  "null": $$null,
  length: length,
  foldableArray: foldableArray,
  foldableMaybe: foldableMaybe,
  foldableFirst: foldableFirst,
  foldableLast: foldableLast,
  foldableAdditive: foldableAdditive,
  foldableDual: foldableDual,
  foldableDisj: foldableDisj,
  foldableConj: foldableConj,
  foldableMultiplicative: foldableMultiplicative
};