// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_ST = require("../Control.Monad.ST/index.js");

var Control_Monad_ST_Internal = require("../Control.Monad.ST.Internal/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var unsafeThaw = function unsafeThaw($11) {
  return Control_Applicative.pure(Control_Monad_ST_Internal.applicativeST)($11);
};

var unsafeFreeze = function unsafeFreeze($12) {
  return Control_Applicative.pure(Control_Monad_ST_Internal.applicativeST)($12);
};

var thaw = $foreign.copyImpl;

var withArray = function withArray(f) {
  return function (xs) {
    return function __do() {
      var v = thaw(xs)();
      var v1 = f(v)();
      return unsafeFreeze(v)();
    };
  };
};

var sortBy = function sortBy(comp) {
  var comp$prime = function comp$prime(x) {
    return function (y) {
      var v = comp(x)(y);

      if (v instanceof Data_Ordering.GT) {
        return 1;
      }

      ;

      if (v instanceof Data_Ordering.EQ) {
        return 0;
      }

      ;

      if (v instanceof Data_Ordering.LT) {
        return -1 | 0;
      }

      ;
      throw new Error("Failed pattern match at Data.Array.ST (line 94, column 15 - line 99, column 1): " + [v.constructor.name]);
    };
  };

  return $foreign.sortByImpl(comp$prime);
};

var sortWith = function sortWith(dictOrd) {
  return function (f) {
    return sortBy(Data_Ord.comparing(dictOrd)(f));
  };
};

var sort = function sort(dictOrd) {
  return sortBy(Data_Ord.compare(dictOrd));
};

var run = function run(st) {
  return Control_Bind.bind(Control_Monad_ST_Internal.bindST)(st)(unsafeFreeze)();
};

var push = function push(a) {
  return $foreign.pushAll([a]);
};

var peek = $foreign.peekImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

var modify = function modify(i) {
  return function (f) {
    return function (xs) {
      return function __do() {
        var v = peek(i)(xs)();

        if (v instanceof Data_Maybe.Just) {
          return $foreign.poke(i)(f(v.value0))(xs)();
        }

        ;

        if (v instanceof Data_Maybe.Nothing) {
          return false;
        }

        ;
        throw new Error("Failed pattern match at Data.Array.ST (line 156, column 3 - line 158, column 26): " + [v.constructor.name]);
      };
    };
  };
};

var freeze = $foreign.copyImpl;
module.exports = {
  run: run,
  withArray: withArray,
  peek: peek,
  push: push,
  modify: modify,
  sort: sort,
  sortBy: sortBy,
  sortWith: sortWith,
  freeze: freeze,
  thaw: thaw,
  unsafeFreeze: unsafeFreeze,
  unsafeThaw: unsafeThaw,
  empty: $foreign.empty,
  poke: $foreign.poke,
  pushAll: $foreign.pushAll,
  splice: $foreign.splice,
  toAssocArray: $foreign.toAssocArray
};