// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var item = function item(index) {
  return function ($0) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_item"](index)($0));
  };
};

module.exports = {
  item: item,
  add: $foreign.add,
  contains: $foreign.contains,
  remove: $foreign.remove,
  toggle: $foreign.toggle,
  toggleForce: $foreign.toggleForce
};