// Generated by purs version 0.12.3
"use strict";

var Data_Tuple = require("../Data.Tuple/index.js");

var emptyRouteState = {
  segments: [],
  params: [],
  hash: ""
};
module.exports = {
  emptyRouteState: emptyRouteState
};