// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Promise = require("../Control.Promise/index.js");

var Data_ArrayBuffer_Types = require("../Data.ArrayBuffer.Types/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Foreign = require("../Foreign/index.js");

var Foreign_Object = require("../Foreign.Object/index.js");

var Milkis_Impl = require("../Milkis.Impl/index.js");

var Prelude = require("../Prelude/index.js");

var Type_Row = require("../Type.Row/index.js");

var Type_Row_Homogeneous = require("../Type.Row.Homogeneous/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var URL = function URL(x) {
  return x;
};

var text = function text(res) {
  return Control_Promise.toAffE($foreign.textImpl(res));
};

var statusCode = function statusCode(response) {
  return response.status;
};

var showURL = Data_Show.showString;
var sameOriginCredentials = "same-origin";
var putMethod = "PUT";
var postMethod = "POST";
var omitCredentials = "omit";
var newtypeURL = new Data_Newtype.Newtype(function (n) {
  return n;
}, URL);

var json = function json(res) {
  return Control_Promise.toAffE($foreign.jsonImpl(res));
};

var includeCredentials = "include";
var headMethod = "HEAD";
var getMethod = "GET";

var fromRecord = function fromRecord(dictHomogeneous) {
  return $foreign.fromRecordImpl;
};

var makeHeaders = function makeHeaders(dictHomogeneous) {
  return fromRecord(dictHomogeneous);
};

var fetch = function fetch(impl) {
  return function (dictUnion) {
    return function (url) {
      return function (opts) {
        return Control_Promise.toAffE($foreign["_fetch"](impl)(url)(opts));
      };
    };
  };
};

var deleteMethod = "DELETE";
var defaultFetchOptions = {
  method: getMethod
};

var arrayBuffer = function arrayBuffer(res) {
  return Control_Promise.toAffE($foreign.arrayBufferImpl(res));
};

module.exports = {
  URL: URL,
  defaultFetchOptions: defaultFetchOptions,
  getMethod: getMethod,
  postMethod: postMethod,
  putMethod: putMethod,
  deleteMethod: deleteMethod,
  headMethod: headMethod,
  omitCredentials: omitCredentials,
  sameOriginCredentials: sameOriginCredentials,
  includeCredentials: includeCredentials,
  fetch: fetch,
  json: json,
  text: text,
  arrayBuffer: arrayBuffer,
  makeHeaders: makeHeaders,
  statusCode: statusCode,
  newtypeURL: newtypeURL,
  showURL: showURL
};