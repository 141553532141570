// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_Client = require("../ZRPC.Client/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var SetEmailErrorInvalidEmail = function () {
  function SetEmailErrorInvalidEmail() {}

  ;
  SetEmailErrorInvalidEmail.value = new SetEmailErrorInvalidEmail();
  return SetEmailErrorInvalidEmail;
}();

var SetEmailErrorExistingEmail = function () {
  function SetEmailErrorExistingEmail() {}

  ;
  SetEmailErrorExistingEmail.value = new SetEmailErrorExistingEmail();
  return SetEmailErrorExistingEmail;
}();

var SetEmailErrorUnableToSetEmail = function () {
  function SetEmailErrorUnableToSetEmail() {}

  ;
  SetEmailErrorUnableToSetEmail.value = new SetEmailErrorUnableToSetEmail();
  return SetEmailErrorUnableToSetEmail;
}();

var ResendEmailVerificationErrorExpiredVerification = function () {
  function ResendEmailVerificationErrorExpiredVerification() {}

  ;
  ResendEmailVerificationErrorExpiredVerification.value = new ResendEmailVerificationErrorExpiredVerification();
  return ResendEmailVerificationErrorExpiredVerification;
}();

var ResendEmailVerificationErrorExceededResendCount = function () {
  function ResendEmailVerificationErrorExceededResendCount() {}

  ;
  ResendEmailVerificationErrorExceededResendCount.value = new ResendEmailVerificationErrorExceededResendCount();
  return ResendEmailVerificationErrorExceededResendCount;
}();

var ResendEmailVerificationErrorEmailVerificationNotFound = function () {
  function ResendEmailVerificationErrorEmailVerificationNotFound() {}

  ;
  ResendEmailVerificationErrorEmailVerificationNotFound.value = new ResendEmailVerificationErrorEmailVerificationNotFound();
  return ResendEmailVerificationErrorEmailVerificationNotFound;
}();

var GetZapperErrorProfileNotFound = function () {
  function GetZapperErrorProfileNotFound() {}

  ;
  GetZapperErrorProfileNotFound.value = new GetZapperErrorProfileNotFound();
  return GetZapperErrorProfileNotFound;
}();

var GenderMale = function () {
  function GenderMale() {}

  ;
  GenderMale.value = new GenderMale();
  return GenderMale;
}();

var GenderFemale = function () {
  function GenderFemale() {}

  ;
  GenderFemale.value = new GenderFemale();
  return GenderFemale;
}();

var EmailTypeUnverified = function () {
  function EmailTypeUnverified(value0) {
    this.value0 = value0;
  }

  ;

  EmailTypeUnverified.create = function (value0) {
    return new EmailTypeUnverified(value0);
  };

  return EmailTypeUnverified;
}();

var EmailTypeVerified = function () {
  function EmailTypeVerified(value0) {
    this.value0 = value0;
  }

  ;

  EmailTypeVerified.create = function (value0) {
    return new EmailTypeVerified(value0);
  };

  return EmailTypeVerified;
}();

var EmailConfirmed = function () {
  function EmailConfirmed(value0) {
    this.value0 = value0;
  }

  ;

  EmailConfirmed.create = function (value0) {
    return new EmailConfirmed(value0);
  };

  return EmailConfirmed;
}();

var EmailUnconfirmed = function () {
  function EmailUnconfirmed(value0) {
    this.value0 = value0;
  }

  ;

  EmailUnconfirmed.create = function (value0) {
    return new EmailUnconfirmed(value0);
  };

  return EmailUnconfirmed;
}();

var AccountStatusActive = function () {
  function AccountStatusActive() {}

  ;
  AccountStatusActive.value = new AccountStatusActive();
  return AccountStatusActive;
}();

var AccountStatusSuspended = function () {
  function AccountStatusSuspended() {}

  ;
  AccountStatusSuspended.value = new AccountStatusSuspended();
  return AccountStatusSuspended;
}();

var AccountStatusInactive = function () {
  function AccountStatusInactive() {}

  ;
  AccountStatusInactive.value = new AccountStatusInactive();
  return AccountStatusInactive;
}();

var rpcReq = function rpcReq(dictZRPCWrite) {
  return function (dictZRPCRead) {
    return ZRPC_Client.mkRPCAppReqBuilder(dictZRPCWrite)(dictZRPCRead)("zapper");
  };
};

var getTagInfoFromCode = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOpt";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkInfo";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ownerId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))("getTagInfoFromCode");
var genericSetEmailError = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof SetEmailErrorInvalidEmail) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof SetEmailErrorExistingEmail) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof SetEmailErrorUnableToSetEmail) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 261, column 8 - line 261, column 64): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return SetEmailErrorInvalidEmail.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return SetEmailErrorExistingEmail.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return SetEmailErrorUnableToSetEmail.value;
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 261, column 8 - line 261, column 64): " + [x.constructor.name]);
});
var showSetEmailError = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericSetEmailError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorInvalidEmail";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorExistingEmail";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorUnableToSetEmail";
  })))))(x);
});
var genericResendEmailVerificationError = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof ResendEmailVerificationErrorExpiredVerification) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof ResendEmailVerificationErrorExceededResendCount) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof ResendEmailVerificationErrorEmailVerificationNotFound) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 206, column 8 - line 206, column 94): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return ResendEmailVerificationErrorExpiredVerification.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return ResendEmailVerificationErrorExceededResendCount.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return ResendEmailVerificationErrorEmailVerificationNotFound.value;
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 206, column 8 - line 206, column 94): " + [x.constructor.name]);
});
var showResendEmailVerificationError = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericResendEmailVerificationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExpiredVerification";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExceededResendCount";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorEmailVerificationNotFound";
  })))))(x);
});
var genericGetZapperError = new Data_Generic_Rep.Generic(function (x) {
  return Data_Generic_Rep.NoArguments.value;
}, function (x) {
  return GetZapperErrorProfileNotFound.value;
});
var showGetZapperError = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericGetZapperError)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GetZapperErrorProfileNotFound";
  })))(x);
});
var genericGender = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof GenderMale) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof GenderFemale) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 118, column 8 - line 118, column 50): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return GenderMale.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return GenderFemale.value;
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 118, column 8 - line 118, column 50): " + [x.constructor.name]);
});
var showGender = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericGender)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GenderMale";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GenderFemale";
  }))))(x);
});
var genericEmailType = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof EmailTypeUnverified) {
    return new Data_Generic_Rep.Inl(x.value0);
  }

  ;

  if (x instanceof EmailTypeVerified) {
    return new Data_Generic_Rep.Inr(x.value0);
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 163, column 8 - line 163, column 56): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return new EmailTypeUnverified(x.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return new EmailTypeVerified(x.value0);
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 163, column 8 - line 163, column 56): " + [x.constructor.name]);
});
var showEmailType = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericEmailType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "sendConfirmation";
  }))(Data_Show.showRecordFieldsNil)(Data_Show.showBoolean))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeUnverified";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeVerified";
  }))))(x);
});
var genericEmail = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof EmailConfirmed) {
    return new Data_Generic_Rep.Inl(x.value0);
  }

  ;

  if (x instanceof EmailUnconfirmed) {
    return new Data_Generic_Rep.Inr(x.value0);
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 94, column 8 - line 94, column 48): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return new EmailConfirmed(x.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return new EmailUnconfirmed(x.value0);
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 94, column 8 - line 94, column 48): " + [x.constructor.name]);
});
var showEmail = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericEmail)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "EmailConfirmed";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "EmailUnconfirmed";
  }))))(x);
});
var genericAccountStatus = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof AccountStatusActive) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof AccountStatusSuspended) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof AccountStatusInactive) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 42, column 8 - line 42, column 64): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return AccountStatusActive.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return AccountStatusSuspended.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return AccountStatusInactive.value;
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Clients.Zapper (line 42, column 8 - line 42, column 64): " + [x.constructor.name]);
});
var showAccountStatus = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericAccountStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusActive";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusSuspended";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusInactive";
  })))))(x);
});
var eqSetEmailError = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof SetEmailErrorInvalidEmail && y instanceof SetEmailErrorInvalidEmail) {
      return true;
    }

    ;

    if (x instanceof SetEmailErrorExistingEmail && y instanceof SetEmailErrorExistingEmail) {
      return true;
    }

    ;

    if (x instanceof SetEmailErrorUnableToSetEmail && y instanceof SetEmailErrorUnableToSetEmail) {
      return true;
    }

    ;
    return false;
  };
});
var eqResendEmailVerificationError = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof ResendEmailVerificationErrorExpiredVerification && y instanceof ResendEmailVerificationErrorExpiredVerification) {
      return true;
    }

    ;

    if (x instanceof ResendEmailVerificationErrorExceededResendCount && y instanceof ResendEmailVerificationErrorExceededResendCount) {
      return true;
    }

    ;

    if (x instanceof ResendEmailVerificationErrorEmailVerificationNotFound && y instanceof ResendEmailVerificationErrorEmailVerificationNotFound) {
      return true;
    }

    ;
    return false;
  };
});
var eqGetZapperError = new Data_Eq.Eq(function (x) {
  return function (y) {
    return true;
  };
});
var eqGender = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof GenderMale && y instanceof GenderMale) {
      return true;
    }

    ;

    if (x instanceof GenderFemale && y instanceof GenderFemale) {
      return true;
    }

    ;
    return false;
  };
});
var eqEmailType = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof EmailTypeUnverified && y instanceof EmailTypeUnverified) {
      return x.value0.email === y.value0.email && x.value0.sendConfirmation === y.value0.sendConfirmation;
    }

    ;

    if (x instanceof EmailTypeVerified && y instanceof EmailTypeVerified) {
      return x.value0.email === y.value0.email;
    }

    ;
    return false;
  };
});
var eqEmail = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof EmailConfirmed && y instanceof EmailConfirmed) {
      return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.value)(y.value0.value);
    }

    ;

    if (x instanceof EmailUnconfirmed && y instanceof EmailUnconfirmed) {
      return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.value)(y.value0.value);
    }

    ;
    return false;
  };
});
var eqAccountStatus = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof AccountStatusActive && y instanceof AccountStatusActive) {
      return true;
    }

    ;

    if (x instanceof AccountStatusSuspended && y instanceof AccountStatusSuspended) {
      return true;
    }

    ;

    if (x instanceof AccountStatusInactive && y instanceof AccountStatusInactive) {
      return true;
    }

    ;
    return false;
  };
});
var consPrefixSetEmailError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "SetEmailError";
});
var zrpcReadSetEmailError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixSetEmailError)(genericSetEmailError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorInvalidEmail";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorExistingEmail";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorUnableToSetEmail";
  })))))(x);
});
var zrpcWriteSetEmailError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixSetEmailError)(genericSetEmailError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorInvalidEmail";
  })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorExistingEmail";
  })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SetEmailErrorUnableToSetEmail";
  })))))(x);
});
var consPrefixResendEmailVerificationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "ResendEmailVerificationError";
});
var zrpcReadResendEmailVerificationError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixResendEmailVerificationError)(genericResendEmailVerificationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExpiredVerification";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExceededResendCount";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorEmailVerificationNotFound";
  })))))(x);
});
var resendEmailVerification = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadResendEmailVerificationError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("resendEmailVerification");
var zrpcWriteResendEmailVerificationError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixResendEmailVerificationError)(genericResendEmailVerificationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExpiredVerification";
  })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorExceededResendCount";
  })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationErrorEmailVerificationNotFound";
  })))))(x);
});
var consPrefixGetZapperError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "GetZapperError";
});
var zrpcReadGetZapperError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixGetZapperError)(genericGetZapperError)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GetZapperErrorProfileNotFound";
  })))(x);
});
var zrpcWriteGetZapperError = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGetZapperError)(genericGetZapperError)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GetZapperErrorProfileNotFound";
  })))(x);
});
var consPrefixGender = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "Gender";
});
var zrpcReadGender = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixGender)(genericGender)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GenderMale";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GenderFemale";
  }))))(x);
});
var zrpcWriteGender = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGender)(genericGender)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GenderMale";
  })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GenderFemale";
  }))))(x);
});
var consPrefixEmailType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "EmailType";
});
var zrpcReadEmailType = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixEmailType)(genericEmailType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeUnverified";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "sendConfirmation";
  }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeVerified";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))(x);
});
var zrpcWriteEmailType = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixEmailType)(genericEmailType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeUnverified";
  }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "sendConfirmation";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailTypeVerified";
  }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(x);
});
var setEmail = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteEmailType))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadSetEmailError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))("setEmail");
var consPrefixEmail = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "Email";
});
var zrpcReadEmail = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixEmail)(genericEmail)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailConfirmed";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailUnconfirmed";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))(x);
});
var zrpcWriteEmail = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixEmail)(genericEmail)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailConfirmed";
  }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "EmailUnconfirmed";
  }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(x);
});
var consPrefixAccountStatus = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "AccountStatus";
});
var zrpcReadAccountStatus = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
  return ZRPC_ZRPCRead.genericZRPCRead(consPrefixAccountStatus)(genericAccountStatus)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusActive";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusSuspended";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusInactive";
  })))))(x);
});
var getZapper = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadGetZapperError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()()))))("getZapper");
var getZapperByEmail = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "includeInactive";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "siloId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "inactiveUsers";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "user";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("getZapperByEmail");
var getZapperByMobile = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "includeInactive";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "siloId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "inactiveUsers";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "user";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("getZapperByMobile");
var getZapperByTag = rpcReq(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "includeInactive";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "siloId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "inactiveUsers";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "user";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "accountStatus";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadAccountStatus))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "avatar";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "birthday";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDate))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "branchId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "email";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadEmail))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "fingerprint";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadLong))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "firstName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "gender";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadGender))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "ipAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "lastName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "location";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "city";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "country";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "region";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "refTag";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "referredBy";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "streetAddress";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tags";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "barcode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "dateActivated";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "holdingId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "id";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "imageUrl";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isSuspended";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "isVerified";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "linkedAt";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "magstripe";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "tagOwner";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "uuid";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userKey";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "userName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "zipCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))("getZapperByTag");
var zrpcWriteAccountStatus = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
  return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixAccountStatus)(genericAccountStatus)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusActive";
  })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusSuspended";
  })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AccountStatusInactive";
  })))))(x);
});
module.exports = {
  AccountStatusActive: AccountStatusActive,
  AccountStatusSuspended: AccountStatusSuspended,
  AccountStatusInactive: AccountStatusInactive,
  EmailConfirmed: EmailConfirmed,
  EmailUnconfirmed: EmailUnconfirmed,
  GenderMale: GenderMale,
  GenderFemale: GenderFemale,
  EmailTypeUnverified: EmailTypeUnverified,
  EmailTypeVerified: EmailTypeVerified,
  GetZapperErrorProfileNotFound: GetZapperErrorProfileNotFound,
  ResendEmailVerificationErrorExpiredVerification: ResendEmailVerificationErrorExpiredVerification,
  ResendEmailVerificationErrorExceededResendCount: ResendEmailVerificationErrorExceededResendCount,
  ResendEmailVerificationErrorEmailVerificationNotFound: ResendEmailVerificationErrorEmailVerificationNotFound,
  SetEmailErrorInvalidEmail: SetEmailErrorInvalidEmail,
  SetEmailErrorExistingEmail: SetEmailErrorExistingEmail,
  SetEmailErrorUnableToSetEmail: SetEmailErrorUnableToSetEmail,
  getZapper: getZapper,
  getZapperByMobile: getZapperByMobile,
  getZapperByTag: getZapperByTag,
  getZapperByEmail: getZapperByEmail,
  getTagInfoFromCode: getTagInfoFromCode,
  resendEmailVerification: resendEmailVerification,
  setEmail: setEmail,
  eqAccountStatus: eqAccountStatus,
  genericAccountStatus: genericAccountStatus,
  consPrefixAccountStatus: consPrefixAccountStatus,
  showAccountStatus: showAccountStatus,
  zrpcReadAccountStatus: zrpcReadAccountStatus,
  zrpcWriteAccountStatus: zrpcWriteAccountStatus,
  eqEmail: eqEmail,
  genericEmail: genericEmail,
  consPrefixEmail: consPrefixEmail,
  showEmail: showEmail,
  zrpcReadEmail: zrpcReadEmail,
  zrpcWriteEmail: zrpcWriteEmail,
  eqGender: eqGender,
  genericGender: genericGender,
  consPrefixGender: consPrefixGender,
  showGender: showGender,
  zrpcReadGender: zrpcReadGender,
  zrpcWriteGender: zrpcWriteGender,
  eqEmailType: eqEmailType,
  genericEmailType: genericEmailType,
  consPrefixEmailType: consPrefixEmailType,
  showEmailType: showEmailType,
  zrpcReadEmailType: zrpcReadEmailType,
  zrpcWriteEmailType: zrpcWriteEmailType,
  eqGetZapperError: eqGetZapperError,
  genericGetZapperError: genericGetZapperError,
  consPrefixGetZapperError: consPrefixGetZapperError,
  showGetZapperError: showGetZapperError,
  zrpcReadGetZapperError: zrpcReadGetZapperError,
  zrpcWriteGetZapperError: zrpcWriteGetZapperError,
  eqResendEmailVerificationError: eqResendEmailVerificationError,
  genericResendEmailVerificationError: genericResendEmailVerificationError,
  consPrefixResendEmailVerificationError: consPrefixResendEmailVerificationError,
  showResendEmailVerificationError: showResendEmailVerificationError,
  zrpcReadResendEmailVerificationError: zrpcReadResendEmailVerificationError,
  zrpcWriteResendEmailVerificationError: zrpcWriteResendEmailVerificationError,
  eqSetEmailError: eqSetEmailError,
  genericSetEmailError: genericSetEmailError,
  consPrefixSetEmailError: consPrefixSetEmailError,
  showSetEmailError: showSetEmailError,
  zrpcReadSetEmailError: zrpcReadSetEmailError,
  zrpcWriteSetEmailError: zrpcWriteSetEmailError
};