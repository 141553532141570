// Generated by purs version 0.12.3
"use strict";

var Semigroupoid = function Semigroupoid(compose) {
  this.compose = compose;
};

var semigroupoidFn = new Semigroupoid(function (f) {
  return function (g) {
    return function (x) {
      return f(g(x));
    };
  };
});

var compose = function compose(dict) {
  return dict.compose;
};

var composeFlipped = function composeFlipped(dictSemigroupoid) {
  return function (f) {
    return function (g) {
      return compose(dictSemigroupoid)(g)(f);
    };
  };
};

module.exports = {
  compose: compose,
  Semigroupoid: Semigroupoid,
  composeFlipped: composeFlipped,
  semigroupoidFn: semigroupoidFn
};