// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_List = require("../Data.List/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Prelude = require("../Prelude/index.js");

var Free = function Free(x) {
  return x;
};

var showFree = function showFree(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Free " + (Data_Show.show(Data_List_Types.showList(Data_List_Types.showList(dictShow)))(v) + ")");
  });
};

var semiringFree = new Data_Semiring.Semiring(function (v) {
  return function (v1) {
    return Data_Semigroup.append(Data_List_Types.semigroupList)(v)(v1);
  };
}, function (v) {
  return function (v1) {
    return Control_Bind.bind(Data_List_Types.bindList)(v)(function (v2) {
      return Control_Bind.bind(Data_List_Types.bindList)(v1)(function (v3) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Semigroup.append(Data_List_Types.semigroupList)(v2)(v3));
      });
    });
  };
}, Data_List.singleton(Data_List_Types.Nil.value), Data_List_Types.Nil.value);
var newtypeFree = new Data_Newtype.Newtype(function (n) {
  return n;
}, Free);

var liftFree = function liftFree(dictSemiring) {
  return function (f) {
    return function (v) {
      return Data_Foldable.sum(Data_List_Types.foldableList)(dictSemiring)(Data_Functor.map(Data_List_Types.functorList)(function ($60) {
        return Data_Foldable.product(Data_List_Types.foldableList)(dictSemiring)(Data_Functor.map(Data_List_Types.functorList)(f)($60));
      })(v));
    };
  };
};

var functorFree = new Data_Functor.Functor(function (fn) {
  return function (v) {
    return Free(Data_Functor.map(Data_List_Types.functorList)(Data_Functor.map(Data_List_Types.functorList)(fn))(v));
  };
});

var free = function free(a) {
  return Data_List.singleton(Data_List.singleton(a));
};

var lowerFree = function lowerFree(dictSemiring) {
  return function (f) {
    return function (a) {
      return f(free(a));
    };
  };
};

var foldableFree = new Data_Foldable.Foldable(function (dictMonoid) {
  return function (fn) {
    return function (v) {
      return Data_Foldable.fold(Data_List_Types.foldableList)(dictMonoid)(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_List_Types.monoidList)(Data_Functor.map(Data_List_Types.functorList)(fn))(v));
    };
  };
}, function (fn) {
  return function (accum) {
    return function (v) {
      return Data_Foldable.foldl(Data_List_Types.foldableList)(Data_Foldable.foldl(Data_List_Types.foldableList)(fn))(accum)(v);
    };
  };
}, function (fn) {
  return function (accum) {
    return function (v) {
      return Data_Foldable.foldr(Data_List_Types.foldableList)(Data_Function.flip(Data_Foldable.foldr(Data_List_Types.foldableList)(fn)))(accum)(v);
    };
  };
});
var traversableFree = new Data_Traversable.Traversable(function () {
  return foldableFree;
}, function () {
  return functorFree;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Free)(Data_Traversable.sequence(Data_List_Types.traversableList)(dictApplicative)(Data_Functor.map(Data_List_Types.functorList)(Data_Traversable.sequence(Data_List_Types.traversableList)(dictApplicative))(v)));
  };
}, function (dictApplicative) {
  return function (fn) {
    return function (freeA) {
      return Data_Traversable.sequence(traversableFree)(dictApplicative)(Data_Functor.map(functorFree)(fn)(freeA));
    };
  };
});

var eqFree = function eqFree(dictEq) {
  return new Data_Eq.Eq(function (v) {
    return function (v1) {
      return Data_Eq.eq(Data_List_Types.eqList(Data_List_Types.eqList(dictEq)))(v)(v1);
    };
  });
};

var ordFree = function ordFree(dictOrd) {
  return new Data_Ord.Ord(function () {
    return eqFree(dictOrd.Eq0());
  }, function (v) {
    return function (v1) {
      return Data_Ord.compare(Data_List_Types.ordList(Data_List_Types.ordList(dictOrd)))(v)(v1);
    };
  });
};

var applyFree = new Control_Apply.Apply(function () {
  return functorFree;
}, function (v) {
  return function (v1) {
    return Free(Control_Bind.bind(Data_List_Types.bindList)(v)(function (v2) {
      return Control_Bind.bind(Data_List_Types.bindList)(v1)(function (v3) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Control_Apply.apply(Data_List_Types.applyList)(v2)(v3));
      });
    }));
  };
});
var applicativeFree = new Control_Applicative.Applicative(function () {
  return applyFree;
}, free);
module.exports = {
  Free: Free,
  free: free,
  liftFree: liftFree,
  lowerFree: lowerFree,
  newtypeFree: newtypeFree,
  showFree: showFree,
  eqFree: eqFree,
  ordFree: ordFree,
  semiringFree: semiringFree,
  functorFree: functorFree,
  applyFree: applyFree,
  applicativeFree: applicativeFree,
  foldableFree: foldableFree,
  traversableFree: traversableFree
};