// Generated by purs version 0.12.3
"use strict";

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var section = function section(header) {
  return function (body) {
    return React_Basic_DOM_Generated.div_([Optimus_Client_Antd_Typography.title()({
      level: 3,
      ellipsis: true,
      children: [header]
    }), body]);
  };
};

module.exports = {
  section: section
};