// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Digit = require("../Data.Digit/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_List = require("../Data.List/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable = require("../Data.Unfoldable/index.js");

var Effect_Exception_Unsafe = require("../Effect.Exception.Unsafe/index.js");

var Global = require("../Global/index.js");

var Partial_Unsafe = require("../Partial.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary/index.js");

var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");

var Plus = function () {
  function Plus() {}

  ;
  Plus.value = new Plus();
  return Plus;
}();

var Minus = function () {
  function Minus() {}

  ;
  Minus.value = new Minus();
  return Minus;
}();

var Float = function () {
  function Float() {}

  ;
  Float.value = new Float();
  return Float;
}();

var Integral = function () {
  function Integral() {}

  ;
  Integral.value = new Integral();
  return Integral;
}();

var Scientific = function () {
  function Scientific() {}

  ;
  Scientific.value = new Scientific();
  return Scientific;
}();

var HugeNum = function HugeNum(x) {
  return x;
};

var zeroHugeNum = {
  digits: new Data_List_Types.Cons(Data_Digit["_zero"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
  decimal: 1,
  sign: Plus.value
};
var unsafeRemoveFrontZeroes = Data_List.dropWhile(function (v) {
  return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
});

var truncate = function truncate(n) {
  return function (v) {
    var integral = Data_List.take(v.decimal)(v.digits);
    var fractional = Data_List.drop(v.decimal)(v.digits);
    var newFractional = Data_List.take(n)(fractional);
    var z = {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(integral)(newFractional),
      decimal: v.decimal,
      sign: v.sign
    };
    return z;
  };
};

var trivialFraction = function trivialFraction(v) {
  var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
  var meaty = Data_List.dropWhile(function (v1) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
  })(decimals);
  return Data_List["null"](meaty);
};

var toString = function toString(v) {
  var sign = function () {
    if (v.sign instanceof Plus) {
      return Data_Monoid.mempty(Data_List_Types.monoidList);
    }

    ;

    if (v.sign instanceof Minus) {
      return Control_Applicative.pure(Data_List_Types.applicativeList)("-");
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 182, column 14 - line 184, column 49): " + [v.sign.constructor.name]);
  }();

  var charray = Data_Functor.map(Data_List_Types.functorList)(Data_Digit.toChar)(v.digits);
  var numray = Data_Maybe.fromJust()(Data_List.insertAt(v.decimal)(".")(charray));
  return Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(Data_Semigroup.append(Data_List_Types.semigroupList)(sign)(numray));
};

var toNumber = function toNumber($215) {
  return Global.readFloat(toString($215));
};

var timesSign = function timesSign(v) {
  return function (v1) {
    if (v instanceof Plus && v1 instanceof Plus) {
      return Plus.value;
    }

    ;

    if (v instanceof Minus && v1 instanceof Minus) {
      return Plus.value;
    }

    ;
    return Minus.value;
  };
};

var takeMeatyParts = function takeMeatyParts(arr) {
  return Data_List.reverse(Data_List.dropWhile(function (v) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
  })(Data_List.reverse(arr)));
};

var timesPowOfTen = function timesPowOfTen(n) {
  return function (v) {
    var newDecimal = v.decimal + n | 0;

    var newZeroesLength = function () {
      var $99 = newDecimal >= Data_List.length(takeMeatyParts(v.digits));

      if ($99) {
        return newDecimal - Data_List.length(takeMeatyParts(v.digits)) | 0;
      }

      ;
      return 0;
    }();

    var newZeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(newZeroesLength)(Data_Digit["_zero"]);
    var z = {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(v.digits)(newZeroes),
      sign: v.sign,
      decimal: newDecimal
    };
    return z;
  };
};

var smallEnough = function smallEnough(v) {
  if (v.decimal === 1 && Data_List.length(v.digits) === 2) {
    return true;
  }

  ;
  return false;
};

var showHugeNum = new Data_Show.Show(function ($216) {
  return "HugeNum " + toString($216);
});

var rec = function rec(v) {
  return v;
};

var parseScientific = function parseScientific(n) {
  var split = Data_List.span(function (v) {
    return v !== "e";
  })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
  var signSplit = Data_Maybe.fromJust()(Data_List.uncons(Data_List.drop(1)(split.rest)));

  var sign = function () {
    var $104 = n < 0;

    if ($104) {
      return Minus.value;
    }

    ;
    return Plus.value;
  }();

  var exponent = Data_Int.round(Global.readFloat(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(signSplit.tail)));

  var expSign = function () {
    if (signSplit.head === "+") {
      return Plus.value;
    }

    ;
    return Minus.value;
  }();

  var base = Data_List.filter(function (v) {
    return v !== ".";
  })(split.init);
  var z = {
    exponent: exponent,
    expSign: expSign,
    base: base,
    sign: sign
  };
  return z;
};

var parsePlusPlus = function parsePlusPlus(exp) {
  return function (base) {
    var zeroCardinality = (exp - Data_List.length(base) | 0) + 2 | 0;
    var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroCardinality)(Data_Digit["_zero"]);
    var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(base))(zeroes);
    var decimal = 1 + exp | 0;
    var r = {
      digits: digits,
      decimal: decimal,
      sign: Plus.value
    };
    return r;
  };
};

var parsePlusMinus = function parsePlusMinus(exp) {
  return function (base) {
    var r = parsePlusPlus(exp)(base);
    return {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(r.digits)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
      decimal: r.decimal,
      sign: Minus.value
    };
  };
};

var parseNumber = function parseNumber(n) {
  if (Data_String_CodeUnits.contains("e")(Data_Show.show(Data_Show.showNumber)(n))) {
    return Scientific.value;
  }

  ;

  if (Data_String_CodeUnits.contains(".")(Data_Show.show(Data_Show.showNumber)(n))) {
    return Float.value;
  }

  ;

  if (Data_Boolean.otherwise) {
    return Integral.value;
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 212, column 1 - line 212, column 37): " + [n.constructor.name]);
};

var parseMinusPlus = function parseMinusPlus(exp) {
  return function (base) {
    var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]);
    var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(Data_List.mapMaybe(Data_Digit.fromChar)(base));
    var r = {
      digits: digits,
      decimal: 1,
      sign: Plus.value
    };
    return r;
  };
};

var parseMinusMinus = function parseMinusMinus(exp) {
  return function (base) {
    var r = parseMinusPlus(exp)(base);
    return {
      digits: r.digits,
      decimal: r.decimal,
      sign: Minus.value
    };
  };
};

var scientificToHugeNum = function scientificToHugeNum(n) {
  var parsed = parseScientific(n);

  var r = function () {
    if (parsed.expSign instanceof Plus) {
      if (parsed.sign instanceof Plus) {
        return parsePlusPlus(parsed.exponent)(parsed.base);
      }

      ;
      return parsePlusMinus(parsed.exponent)(parsed.base);
    }

    ;

    if (parsed.sign instanceof Plus) {
      return parseMinusPlus(parsed.exponent)(parsed.base);
    }

    ;
    return parseMinusMinus(parsed.exponent)(parsed.base);
  }();

  return r;
};

var oneHugeNum = {
  digits: new Data_List_Types.Cons(Data_Digit["_one"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
  decimal: 1,
  sign: Plus.value
};

var numOfIntegral = function numOfIntegral(v) {
  return v.decimal;
};

var numOfFractional = function numOfFractional(v) {
  return Data_List.length(v.digits) - v.decimal | 0;
};

var neg = function neg(v) {
  if (v.sign instanceof Minus) {
    return {
      digits: v.digits,
      decimal: v.decimal,
      sign: Plus.value
    };
  }

  ;
  return {
    digits: v.digits,
    decimal: v.decimal,
    sign: Minus.value
  };
};

var meatyDecimals = function meatyDecimals(v) {
  var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
  var meaty = Data_List.dropWhile(function (v1) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
  })(decimals);
  return Data_List.length(meaty);
};

var makeHugeInteger$prime = function makeHugeInteger$prime(v) {
  var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(unsafeRemoveFrontZeroes(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]));
  var decimal = Data_List.length(digits) - 1 | 0;
  var z = {
    digits: digits,
    decimal: decimal,
    sign: v.sign
  };
  return z;
};

var lessPrecise = function lessPrecise(v) {
  return function (v1) {
    return Data_List.length(Data_List.drop(v.decimal)(v.digits)) < Data_List.length(Data_List.drop(v1.decimal)(v1.digits));
  };
};

var isZero = function isZero(v) {
  return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
  })(v.digits);
};

var isPositive = function isPositive(v) {
  if (v.sign instanceof Plus) {
    return true;
  }

  ;
  return false;
};

var isNegative = function isNegative(v) {
  if (v.sign instanceof Minus) {
    return true;
  }

  ;
  return false;
};

var isHugeInteger = function isHugeInteger(v) {
  return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
  })(Data_List.drop(v.decimal)(v.digits));
};

var makeHugeInteger = function makeHugeInteger(r) {
  var $124 = isHugeInteger(r);

  if ($124) {
    return r;
  }

  ;
  return makeHugeInteger$prime(r);
};

var integralToHugeNum = function integralToHugeNum(n) {
  var sign = function () {
    var $125 = n < 0;

    if ($125) {
      return Minus.value;
    }

    ;
    return Plus.value;
  }();

  var integral = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)));
  var fractional = Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);

  var decimal = function () {
    if (sign instanceof Minus) {
      return Data_List.length(integral) - 1 | 0;
    }

    ;
    return Data_List.length(integral);
  }();

  return {
    digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(integral))(fractional),
    decimal: decimal,
    sign: sign
  };
};

var integerPart = function integerPart(v) {
  return {
    digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
    decimal: v.decimal,
    sign: v.sign
  };
};

var googol = {
  digits: new Data_List_Types.Cons(Data_Digit["_one"], Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(101)(Data_Digit["_zero"])),
  decimal: 101,
  sign: Plus.value
};
var genericSign = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Plus) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Minus) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 56, column 8 - line 56, column 46): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Plus.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return Minus.value;
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 56, column 8 - line 56, column 46): " + [x.constructor.name]);
});
var genericHugeNum = new Data_Generic_Rep.Generic(function (x) {
  return x;
}, function (x) {
  return x;
});

var fromString = function fromString(s) {
  var charlist = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(s));
  return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.head(charlist))(function (v) {
    var signDigits = function () {
      if (v === "-") {
        return {
          sign: Minus.value,
          digits: Data_List.drop(1)(charlist)
        };
      }

      ;
      return {
        sign: Plus.value,
        digits: charlist
      };
    }();

    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.elemIndex(Data_Eq.eqChar)(".")(signDigits.digits))(function (v1) {
      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.deleteAt(v1)(signDigits.digits))(function (v2) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_List_Types.traversableList)(Data_Maybe.applicativeMaybe)(Data_Functor.map(Data_List_Types.functorList)(Data_Digit.fromChar)(v2)))(function (v3) {
          return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
            sign: signDigits.sign,
            decimal: v1,
            digits: v3
          });
        });
      });
    });
  });
};

var fractionalPart = function fractionalPart(v) {
  return {
    digits: new Data_List_Types.Cons(Data_Digit["_zero"], Data_List.drop(v.decimal)(v.digits)),
    decimal: 1,
    sign: v.sign
  };
};

var floatToHugeNum = function floatToHugeNum(n) {
  var sign = function () {
    var $140 = n < 0;

    if ($140) {
      return Minus.value;
    }

    ;
    return Plus.value;
  }();

  var pos = n >= 0;

  var split = function () {
    if (pos) {
      return Data_List.span(function (v) {
        return v !== ".";
      })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
    }

    ;
    return Data_List.span(function (v) {
      return v !== ".";
    })(Data_List.drop(1)(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)))));
  }();

  var small = Data_List.drop(1)(split.rest);
  var decimal = Data_List.length(split.init);
  var digits = Data_List.mapMaybe(Data_Digit.fromChar)(Data_Semigroup.append(Data_List_Types.semigroupList)(split.init)(small));
  var r = {
    digits: digits,
    decimal: decimal,
    sign: sign
  };
  return r;
};

var fromNumber = function fromNumber(n) {
  var v = parseNumber(n);

  if (v instanceof Float) {
    return floatToHugeNum(n);
  }

  ;

  if (v instanceof Scientific) {
    return scientificToHugeNum(n);
  }

  ;

  if (v instanceof Integral) {
    return integralToHugeNum(n);
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 291, column 16 - line 294, column 52): " + [v.constructor.name]);
};

var eqSign = new Data_Eq.Eq(function (v) {
  return function (v1) {
    if (v instanceof Plus && v1 instanceof Plus) {
      return true;
    }

    ;

    if (v instanceof Minus && v1 instanceof Minus) {
      return true;
    }

    ;
    return false;
  };
});
var ordSign = new Data_Ord.Ord(function () {
  return eqSign;
}, function (v) {
  return function (v1) {
    if (v instanceof Plus && v1 instanceof Minus) {
      return Data_Ordering.GT.value;
    }

    ;

    if (v instanceof Minus && v1 instanceof Plus) {
      return Data_Ordering.LT.value;
    }

    ;
    return Data_Ordering.EQ.value;
  };
});

var strictlyEqual = function strictlyEqual(v) {
  return function (v1) {
    return v.decimal === v1.decimal && Data_Eq.eq(Data_List_Types.eqList(Data_Digit.eqDigit))(v.digits)(v1.digits) && Data_Eq.eq(eqSign)(v.sign)(v1.sign);
  };
};

var dropZeroes = function () {
  var dropIntegralZeroes = function dropIntegralZeroes(v) {
    var integralPart = Data_List.take(v.decimal)(v.digits);
    var zeroes = Data_List.takeWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(integralPart);

    var digits = function () {
      var $150 = Data_List.length(integralPart) === Data_List.length(zeroes);

      if ($150) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
      }

      ;
      return Data_List.drop(Data_List.length(zeroes))(integralPart);
    }();

    var decimal = Data_List.length(digits);
    var z = {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(digits)(Data_List.drop(v.decimal)(v.digits)),
      decimal: decimal,
      sign: v.sign
    };
    return z;
  };

  var dropFractionalZeroes = function dropFractionalZeroes(v) {
    var fractionalDigits = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
    var meatyFraction = Data_List.dropWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(fractionalDigits);
    var digits = Data_List.reverse(meatyFraction);
    var z = {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(function () {
        var $152 = Data_List["null"](digits);

        if ($152) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return digits;
      }()),
      decimal: v.decimal,
      sign: v.sign
    };
    return z;
  };

  return function ($217) {
    return dropIntegralZeroes(dropFractionalZeroes($217));
  };
}();

var eqHugeNum = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (isZero(x) && isZero(y)) {
      return true;
    }

    ;

    if (Data_Boolean.otherwise) {
      return strictlyEqual(dropZeroes(x))(dropZeroes(y));
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 83, column 1 - line 83, column 33): " + [x.constructor.name, y.constructor.name]);
  };
});

var digitwiseSubtract = function digitwiseSubtract(v) {
  return function (v1) {
    var tint = Data_Digit.toInt(v1.value0) - Data_Digit.toInt(v.value1) | 0;
    var bint = Data_Digit.toInt(v1.value1);
    var diff$prime = tint - bint | 0;
    var diff = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
      var $157 = diff$prime < 0;

      if ($157) {
        return diff$prime + 10 | 0;
      }

      ;
      return diff$prime;
    }()));

    var spill = function () {
      var $158 = diff$prime < 0;

      if ($158) {
        return Data_Digit["_one"];
      }

      ;
      return Data_Digit["_zero"];
    }();

    return new Data_Tuple.Tuple(new Data_List_Types.Cons(diff, v.value0), spill);
  };
};

var digitwiseAdd = function digitwiseAdd(v) {
  return function (v1) {
    var tint = Data_Digit.toInt(v1.value0) + Data_Digit.toInt(v.value1) | 0;
    var bint = Data_Digit.toInt(v1.value1);
    var summ$prime = tint + bint | 0;

    var spill = function () {
      var $165 = summ$prime > 9;

      if ($165) {
        return Data_Digit["_one"];
      }

      ;
      return Data_Digit["_zero"];
    }();

    var summ = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
      var $166 = summ$prime > 9;

      if ($166) {
        return summ$prime - 10 | 0;
      }

      ;
      return summ$prime;
    }()));
    return new Data_Tuple.Tuple(new Data_List_Types.Cons(summ, v.value0), spill);
  };
};

var equivalize = function () {
  var integralize = function integralize(v) {
    if (Data_List.length(Data_List.take(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.take(v.snd.decimal)(v.snd.digits))) {
      return {
        fst: v.fst,
        snd: v.snd
      };
    }

    ;

    if (Data_Boolean.otherwise) {
      var lesser = rec(Data_Ord.min(ordHugeNum)(v.fst)(v.snd));
      var greater = rec(Data_Ord.max(ordHugeNum)(v.fst)(v.snd));
      var zeroesLength = Data_List.length(Data_List.take(greater.decimal)(greater.digits)) - Data_List.length(Data_List.take(lesser.decimal)(lesser.digits)) | 0;
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroesLength)(Data_Digit["_zero"]);
      var lesser$prime = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(lesser.digits),
        decimal: greater.decimal,
        sign: lesser.sign
      };
      var z = {
        fst: lesser$prime,
        snd: greater
      };
      return z;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 158, column 3 - line 166, column 57): " + [v.constructor.name]);
  };

  var fractionalize = function fractionalize(v) {
    if (Data_List.length(Data_List.drop(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.drop(v.snd.decimal)(v.snd.digits))) {
      return {
        fst: v.fst,
        snd: v.snd
      };
    }

    ;

    if (Data_Boolean.otherwise) {
      var test = lessPrecise(v.fst)(v.snd);

      var lesser = function () {
        if (test) {
          return v.fst;
        }

        ;
        return v.snd;
      }();

      var greater = function () {
        if (test) {
          return v.snd;
        }

        ;
        return v.fst;
      }();

      var lesserDecimal = Data_List.length(Data_List.drop(greater.decimal)(greater.digits)) - Data_List.length(Data_List.drop(lesser.decimal)(lesser.digits)) | 0;
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(lesserDecimal)(Data_Digit["_zero"]);
      var lesser$prime = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(lesser.digits)(zeroes),
        decimal: lesser.decimal,
        sign: lesser.sign
      };
      var z = {
        fst: lesser$prime,
        snd: greater
      };
      return z;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 148, column 3 - line 157, column 57): " + [v.constructor.name]);
  };

  return function ($218) {
    return integralize(fractionalize($218));
  };
}();

var compareHugeNum = function compareHugeNum(v) {
  return function (v1) {
    if (Data_Ord.lessThan(ordSign)(v.sign)(v1.sign)) {
      return Data_Ordering.LT.value;
    }

    ;

    if (Data_Ord.greaterThan(ordSign)(v.sign)(v1.sign)) {
      return Data_Ordering.GT.value;
    }

    ;

    if (v.decimal > v1.decimal) {
      return Data_Ordering.GT.value;
    }

    ;

    if (v.decimal < v1.decimal) {
      return Data_Ordering.LT.value;
    }

    ;

    if (Data_Eq.eq(eqHugeNum)(v)(v1)) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (Data_Boolean.otherwise) {
      var r = equivalize({
        fst: v,
        snd: v1
      });
      var s = rec(r.fst);
      var m = rec(r.snd);

      var x$prime = function () {
        var $181 = Data_Eq.eq(eqHugeNum)(v)(r.fst);

        if ($181) {
          return s;
        }

        ;
        return m;
      }();

      var y$prime = function () {
        var $182 = Data_Eq.eq(eqHugeNum)(v1)(r.fst);

        if ($182) {
          return s;
        }

        ;
        return m;
      }();

      var z = function () {
        var v2 = Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.take(v.decimal)(v.digits))(Data_List.take(v.decimal)(v1.digits));

        if (v2 instanceof Data_Ordering.EQ) {
          return Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.drop(v.decimal)(x$prime.digits))(Data_List.drop(v.decimal)(y$prime.digits));
        }

        ;
        return v2;
      }();

      return z;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 109, column 1 - line 109, column 49): " + [v.constructor.name, v1.constructor.name]);
  };
};

var ordHugeNum = new Data_Ord.Ord(function () {
  return eqHugeNum;
}, compareHugeNum);

var arrayToHugeNum = function arrayToHugeNum(xs) {
  return {
    sign: Plus.value,
    digits: Data_Semigroup.append(Data_List_Types.semigroupList)(xs)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
    decimal: Data_List.length(xs)
  };
};

var arbHugeNum = new Test_QuickCheck_Arbitrary.Arbitrary(Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Int.toNumber)(Test_QuickCheck_Gen.chooseInt(0)(1000)))(function (v) {
  return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Int.toNumber)(Test_QuickCheck_Gen.chooseInt(0)(10)))(function (v1) {
    return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(fromNumber(v + v1 / 10.0));
  });
}));

var adjustDigitsForDecimal = function adjustDigitsForDecimal(decimal) {
  return function (digits) {
    var go = function go($copy_n) {
      return function ($copy_ds) {
        var $tco_var_n = $copy_n;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(n, ds) {
          if (n <= 0) {
            $tco_done = true;
            return ds;
          }

          ;

          if (Data_Boolean.otherwise) {
            $tco_var_n = n - 1 | 0;
            $copy_ds = new Data_List_Types.Cons(Data_Digit["_zero"], ds);
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.HugeNum (line 388, column 3 - line 390, column 42): " + [n.constructor.name, ds.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_n, $copy_ds);
        }

        ;
        return $tco_result;
      };
    };

    return go((decimal - Data_List.length(digits) | 0) + 1 | 0)(digits);
  };
};

var adjustDecimalForTriviality = function adjustDecimalForTriviality(h1) {
  return function (h2) {
    return function (v) {
      var digitsLength = Data_List.length(v.digits) - 1 | 0;
      var digits$prime = Data_List.take(digitsLength)(v.digits);
      var decimalMod = meatyDecimals(h1) + meatyDecimals(h2) | 0;
      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_Unfoldable.replicate(Data_List_Types.unfoldableList)((decimalMod - digitsLength | 0) + 1 | 0)(Data_Digit["_zero"]))(digits$prime);
      var decimal = Data_List.length(Data_List.drop(decimalMod)(Data_List.reverse(digits)));
      var r = {
        digits: digits,
        decimal: decimal,
        sign: Plus.value
      };
      return dropZeroes(r);
    };
  };
};

var adjustDecimalForFrontZeroes = function adjustDecimalForFrontZeroes(xs) {
  return function (oldDec) {
    var newDigits$prime = unsafeRemoveFrontZeroes(Data_List.take(oldDec)(xs));

    var newDigits = function () {
      var $191 = Data_List["null"](newDigits$prime);

      if ($191) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
      }

      ;
      return newDigits$prime;
    }();

    return Data_List.length(newDigits);
  };
};

var addPlusPlus = function addPlusPlus(x) {
  return function (y) {
    var eqv = equivalize({
      fst: x,
      snd: y
    });
    var r1 = rec(eqv.fst);
    var r2 = rec(eqv.snd);
    var r = Data_List.zip(Data_List.reverse(r1.digits))(Data_List.reverse(r2.digits));
    var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseAdd)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
    var spill = Data_Tuple.snd(digits$prime$prime);
    var digits$prime = Data_Tuple.fst(digits$prime$prime);
    var digits = unsafeRemoveFrontZeroes(new Data_List_Types.Cons(spill, digits$prime));
    var decimal = adjustDecimalForFrontZeroes(new Data_List_Types.Cons(spill, digits$prime))(r1.decimal + 1 | 0);
    var z = {
      digits: adjustDigitsForDecimal(decimal)(digits),
      decimal: decimal,
      sign: Plus.value
    };
    return dropZeroes(z);
  };
};

var addPlusMinus = function addPlusMinus(x) {
  return function (y) {
    var eqv = equivalize({
      fst: x,
      snd: y
    });
    var r1 = rec(Data_Ord.min(ordHugeNum)(eqv.fst)(eqv.snd));
    var r2 = rec(Data_Ord.max(ordHugeNum)(eqv.fst)(eqv.snd));
    var r = Data_List.zip(Data_List.reverse(r2.digits))(Data_List.reverse(r1.digits));
    var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseSubtract)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
    var fractionalDigits = Data_List.drop(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
    var integralDigits$prime$prime = Data_List.take(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
    var integralDigits$prime = unsafeRemoveFrontZeroes(integralDigits$prime$prime);

    var integralDigits = function () {
      var $192 = Data_List["null"](integralDigits$prime);

      if ($192) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
      }

      ;
      return integralDigits$prime;
    }();

    var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(integralDigits)(fractionalDigits);
    var decimal = adjustDecimalForFrontZeroes(Data_Tuple.fst(digits$prime$prime))(r1.decimal);
    var z = {
      digits: digits,
      decimal: decimal,
      sign: Plus.value
    };
    return z;
  };
};

var addMinusMinus = function addMinusMinus(x) {
  return function (y) {
    var z = rec(addPlusPlus(x)(y));
    return {
      digits: z.digits,
      decimal: z.decimal,
      sign: Minus.value
    };
  };
};

var abs = function abs(v) {
  return {
    digits: v.digits,
    decimal: v.decimal,
    sign: Plus.value
  };
};

var getPowForKRep = function getPowForKRep(x) {
  return function (y) {
    return function (v) {
      return v - 1 | 0;
    }(function (v) {
      return v.decimal;
    }(rec(Data_Ord.min(ordHugeNum)(abs(x))(abs(y)))));
  };
};

var plus = function plus(x) {
  return function (y) {
    if (isZero(x)) {
      return y;
    }

    ;

    if (isZero(y)) {
      return x;
    }

    ;

    if (isPositive(x) && isPositive(y)) {
      return addPlusPlus(x)(y);
    }

    ;

    if (isNegative(x) && isNegative(y)) {
      return addMinusMinus(x)(y);
    }

    ;

    if (Data_Boolean.otherwise) {
      var lesserMag = Data_Ord.min(ordHugeNum)(abs(x))(abs(y));
      var lesser = Data_Ord.min(ordHugeNum)(x)(y);
      var greaterMag = Data_Ord.max(ordHugeNum)(abs(x))(abs(y));
      var greater = Data_Ord.max(ordHugeNum)(x)(y);

      var z = function () {
        var $196 = Data_Eq.eq(eqHugeNum)(greater)(greaterMag);

        if ($196) {
          return addPlusMinus(greater)(lesser);
        }

        ;
        return neg(addPlusMinus(greaterMag)(lesserMag));
      }();

      return z;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 440, column 1 - line 440, column 38): " + [x.constructor.name, y.constructor.name]);
  };
};

var fromKRep = function fromKRep(k) {
  var bm = {
    sign: Plus.value,
    digits: new Data_List_Types.Cons(Data_Digit["_one"], Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(k.exp + 1 | 0)(Data_Digit["_zero"])),
    decimal: k.exp + 1 | 0
  };
  var prod = Data_Semigroup.append(Data_List_Types.semigroupList)(k.coeff)(Data_List.drop(1)(bm.digits));
  var leftSummand = {
    digits: prod,
    sign: Plus.value,
    decimal: (bm.decimal + Data_List.length(k.coeff) | 0) - 1 | 0
  };
  var z = plus(leftSummand)(k["const"]);
  return z;
};

var scale = function () {
  var go = function go($copy_x) {
    return function ($copy_v) {
      return function ($copy_v1) {
        var $tco_var_x = $copy_x;
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(x, v, v1) {
          if (v === 0) {
            $tco_done = true;
            return x;
          }

          ;
          $tco_var_x = plus(x)(v1);
          $tco_var_v = v - 1 | 0;
          $copy_v1 = v1;
          return;
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_x, $tco_var_v, $copy_v1);
        }

        ;
        return $tco_result;
      };
    };
  };

  return go(zeroHugeNum);
}();

var multSmallNum = function multSmallNum(v) {
  return function (r2) {
    var v1 = Data_List.uncons(v.digits);

    if (v1 instanceof Data_Maybe.Just) {
      var $203 = Data_Eq.eq(Data_Digit.eqDigit)(v1.value0.head)(Data_Digit["_zero"]);

      if ($203) {
        return zeroHugeNum;
      }

      ;
      return scale(Data_Digit.toInt(v1.value0.head))(r2);
    }

    ;
    return Effect_Exception_Unsafe.unsafeThrow("Error: The impossible happened");
  };
};

var subHugeNum = function subHugeNum(x) {
  return function (y) {
    return plus(x)(neg(y));
  };
};

var toKRep = function toKRep(exp) {
  return function (v) {
    var coeff = Data_List.take(v.decimal - exp | 0)(v.digits);
    var bm = new Data_List_Types.Cons(Data_Digit["_one"], Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]));
    var prod = Data_Semigroup.append(Data_List_Types.semigroupList)(coeff)(Data_List.drop(1)(bm));
    var leftSummand = arrayToHugeNum(prod);
    var k = plus(v)(neg(leftSummand));
    var z = {
      exp: exp,
      coeff: coeff,
      "const": k
    };
    return z;
  };
};

var times = function times(r1) {
  return function (r2) {
    if (Data_Eq.eq(eqSign)(timesSign(function (v) {
      return v.sign;
    }(rec(r1)))(function (v) {
      return v.sign;
    }(rec(r2))))(Minus.value)) {
      return neg(times(abs(r1))(abs(r2)));
    }

    ;

    if (!trivialFraction(r1) || !trivialFraction(r2)) {
      return adjustDecimalForTriviality(r1)(r2)(times(makeHugeInteger(r1))(makeHugeInteger(r2)));
    }

    ;

    if (smallEnough(r1)) {
      return multSmallNum(r1)(r2);
    }

    ;

    if (smallEnough(r2)) {
      return multSmallNum(r2)(r1);
    }

    ;

    if (Data_Boolean.otherwise) {
      var exp = getPowForKRep(r1)(r2);
      var k1 = toKRep(exp)(r1);
      var k2 = toKRep(exp)(r2);
      var z0 = times(k1["const"])(k2["const"]);
      var z2 = times(arrayToHugeNum(k1.coeff))(arrayToHugeNum(k2.coeff));

      var z1 = function () {
        var rightFactor = plus(arrayToHugeNum(k2.coeff))(k2["const"]);
        var leftFactor = plus(arrayToHugeNum(k1.coeff))(k1["const"]);
        var prod = times(leftFactor)(rightFactor);
        return plus(plus(prod)(neg(z2)))(neg(z0));
      }();

      var z1Bm = timesPowOfTen(exp)(z1);
      var z2B2m = timesPowOfTen(2 * exp | 0)(z2);
      var z = plus(plus(z2B2m)(z1Bm))(z0);
      return z;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 512, column 1 - line 512, column 39): " + [r1.constructor.name, r2.constructor.name]);
  };
};

var semiringHugeNum = new Data_Semiring.Semiring(plus, times, oneHugeNum, zeroHugeNum);

var ceil = function ceil(h) {
  if (isNegative(h)) {
    return integerPart(h);
  }

  ;

  if (isZero(h)) {
    return Data_Semiring.zero(semiringHugeNum);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Semiring.add(semiringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 324, column 1 - line 324, column 27): " + [h.constructor.name]);
};

var pow = function pow(r) {
  return function (v) {
    if (v === 0) {
      return Data_Semiring.one(semiringHugeNum);
    }

    ;

    if (v === 1) {
      return r;
    }

    ;
    var c = Data_Semiring.mul(semiringHugeNum)(r)(r);
    var ans = pow(c)(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v)(2));
    var $212 = Data_Int.odd(v);

    if ($212) {
      return Data_Semiring.mul(semiringHugeNum)(r)(ans);
    }

    ;
    return ans;
  };
};

var ringHugeNum = new Data_Ring.Ring(function () {
  return semiringHugeNum;
}, function (r1) {
  return function (r2) {
    return Data_Semiring.add(semiringHugeNum)(r1)(neg(r2));
  };
});

var floor = function floor(h) {
  if (isPositive(h)) {
    return integerPart(h);
  }

  ;

  if (isZero(h)) {
    return Data_Semiring.zero(semiringHugeNum);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Ring.sub(ringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 318, column 1 - line 318, column 28): " + [h.constructor.name]);
};

var round = function round(h) {
  if (Data_Ord.lessThan(ordHugeNum)(abs(Data_Ring.sub(ringHugeNum)(h)(floor(h))))(abs(Data_Ring.sub(ringHugeNum)(ceil(h))(h)))) {
    return floor(h);
  }

  ;

  if (Data_Boolean.otherwise) {
    return ceil(h);
  }

  ;
  throw new Error("Failed pattern match at Data.HugeNum (line 330, column 1 - line 330, column 28): " + [h.constructor.name]);
};

module.exports = {
  fromString: fromString,
  fromNumber: fromNumber,
  toNumber: toNumber,
  toString: toString,
  integerPart: integerPart,
  fractionalPart: fractionalPart,
  numOfIntegral: numOfIntegral,
  numOfFractional: numOfFractional,
  abs: abs,
  neg: neg,
  isNegative: isNegative,
  isPositive: isPositive,
  isZero: isZero,
  floor: floor,
  ceil: ceil,
  round: round,
  googol: googol,
  pow: pow,
  truncate: truncate,
  arbHugeNum: arbHugeNum,
  showHugeNum: showHugeNum,
  eqHugeNum: eqHugeNum,
  ordHugeNum: ordHugeNum,
  semiringHugeNum: semiringHugeNum,
  ringHugeNum: ringHugeNum
};