// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Ord_Unsafe = require("../Data.Ord.Unsafe/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Data_Void = require("../Data.Void/index.js");

var Record_Unsafe = require("../Record.Unsafe/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var Ord = function Ord(Eq0, compare) {
  this.Eq0 = Eq0;
  this.compare = compare;
};

var Ord1 = function Ord1(Eq10, compare1) {
  this.Eq10 = Eq10;
  this.compare1 = compare1;
};

var OrdRecord = function OrdRecord(EqRecord0, compareRecord) {
  this.EqRecord0 = EqRecord0;
  this.compareRecord = compareRecord;
};

var ordVoid = new Ord(function () {
  return Data_Eq.eqVoid;
}, function (v) {
  return function (v1) {
    return Data_Ordering.EQ.value;
  };
});
var ordUnit = new Ord(function () {
  return Data_Eq.eqUnit;
}, function (v) {
  return function (v1) {
    return Data_Ordering.EQ.value;
  };
});
var ordString = new Ord(function () {
  return Data_Eq.eqString;
}, Data_Ord_Unsafe.unsafeCompare);
var ordRecordNil = new OrdRecord(function () {
  return Data_Eq.eqRowNil;
}, function (v) {
  return function (v1) {
    return function (v2) {
      return Data_Ordering.EQ.value;
    };
  };
});
var ordOrdering = new Ord(function () {
  return Data_Ordering.eqOrdering;
}, function (v) {
  return function (v1) {
    if (v instanceof Data_Ordering.LT && v1 instanceof Data_Ordering.LT) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.EQ) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (v instanceof Data_Ordering.GT && v1 instanceof Data_Ordering.GT) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (v instanceof Data_Ordering.LT) {
      return Data_Ordering.LT.value;
    }

    ;

    if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.LT) {
      return Data_Ordering.GT.value;
    }

    ;

    if (v instanceof Data_Ordering.EQ && v1 instanceof Data_Ordering.GT) {
      return Data_Ordering.LT.value;
    }

    ;

    if (v instanceof Data_Ordering.GT) {
      return Data_Ordering.GT.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Ord (line 73, column 1 - line 73, column 37): " + [v.constructor.name, v1.constructor.name]);
  };
});
var ordNumber = new Ord(function () {
  return Data_Eq.eqNumber;
}, Data_Ord_Unsafe.unsafeCompare);
var ordInt = new Ord(function () {
  return Data_Eq.eqInt;
}, Data_Ord_Unsafe.unsafeCompare);
var ordChar = new Ord(function () {
  return Data_Eq.eqChar;
}, Data_Ord_Unsafe.unsafeCompare);
var ordBoolean = new Ord(function () {
  return Data_Eq.eqBoolean;
}, Data_Ord_Unsafe.unsafeCompare);

var compareRecord = function compareRecord(dict) {
  return dict.compareRecord;
};

var ordRecord = function ordRecord(dictRowToList) {
  return function (dictOrdRecord) {
    return new Ord(function () {
      return Data_Eq.eqRec(dictRowToList)(dictOrdRecord.EqRecord0());
    }, compareRecord(dictOrdRecord)(Type_Data_RowList.RLProxy.value));
  };
};

var compare1 = function compare1(dict) {
  return dict.compare1;
};

var compare = function compare(dict) {
  return dict.compare;
};

var comparing = function comparing(dictOrd) {
  return function (f) {
    return function (x) {
      return function (y) {
        return compare(dictOrd)(f(x))(f(y));
      };
    };
  };
};

var greaterThan = function greaterThan(dictOrd) {
  return function (a1) {
    return function (a2) {
      var v = compare(dictOrd)(a1)(a2);

      if (v instanceof Data_Ordering.GT) {
        return true;
      }

      ;
      return false;
    };
  };
};

var greaterThanOrEq = function greaterThanOrEq(dictOrd) {
  return function (a1) {
    return function (a2) {
      var v = compare(dictOrd)(a1)(a2);

      if (v instanceof Data_Ordering.LT) {
        return false;
      }

      ;
      return true;
    };
  };
};

var signum = function signum(dictOrd) {
  return function (dictRing) {
    return function (x) {
      var $43 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));

      if ($43) {
        return Data_Semiring.one(dictRing.Semiring0());
      }

      ;
      return Data_Ring.negate(dictRing)(Data_Semiring.one(dictRing.Semiring0()));
    };
  };
};

var lessThan = function lessThan(dictOrd) {
  return function (a1) {
    return function (a2) {
      var v = compare(dictOrd)(a1)(a2);

      if (v instanceof Data_Ordering.LT) {
        return true;
      }

      ;
      return false;
    };
  };
};

var lessThanOrEq = function lessThanOrEq(dictOrd) {
  return function (a1) {
    return function (a2) {
      var v = compare(dictOrd)(a1)(a2);

      if (v instanceof Data_Ordering.GT) {
        return false;
      }

      ;
      return true;
    };
  };
};

var max = function max(dictOrd) {
  return function (x) {
    return function (y) {
      var v = compare(dictOrd)(x)(y);

      if (v instanceof Data_Ordering.LT) {
        return y;
      }

      ;

      if (v instanceof Data_Ordering.EQ) {
        return x;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return x;
      }

      ;
      throw new Error("Failed pattern match at Data.Ord (line 128, column 3 - line 131, column 12): " + [v.constructor.name]);
    };
  };
};

var min = function min(dictOrd) {
  return function (x) {
    return function (y) {
      var v = compare(dictOrd)(x)(y);

      if (v instanceof Data_Ordering.LT) {
        return x;
      }

      ;

      if (v instanceof Data_Ordering.EQ) {
        return x;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return y;
      }

      ;
      throw new Error("Failed pattern match at Data.Ord (line 119, column 3 - line 122, column 12): " + [v.constructor.name]);
    };
  };
};

var ordArray = function ordArray(dictOrd) {
  return new Ord(function () {
    return Data_Eq.eqArray(dictOrd.Eq0());
  }, function () {
    var toDelta = function toDelta(x) {
      return function (y) {
        var v = compare(dictOrd)(x)(y);

        if (v instanceof Data_Ordering.EQ) {
          return 0;
        }

        ;

        if (v instanceof Data_Ordering.LT) {
          return 1;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return -1 | 0;
        }

        ;
        throw new Error("Failed pattern match at Data.Ord (line 66, column 7 - line 71, column 1): " + [v.constructor.name]);
      };
    };

    return function (xs) {
      return function (ys) {
        return compare(ordInt)(0)($foreign.ordArrayImpl(toDelta)(xs)(ys));
      };
    };
  }());
};

var ord1Array = new Ord1(function () {
  return Data_Eq.eq1Array;
}, function (dictOrd) {
  return compare(ordArray(dictOrd));
});

var ordRecordCons = function ordRecordCons(dictOrdRecord) {
  return function (dictCons) {
    return function (dictIsSymbol) {
      return function (dictOrd) {
        return new OrdRecord(function () {
          return Data_Eq.eqRowCons(dictOrdRecord.EqRecord0())(dictCons)(dictIsSymbol)(dictOrd.Eq0());
        }, function (v) {
          return function (ra) {
            return function (rb) {
              var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
              var left = compare(dictOrd)(Record_Unsafe.unsafeGet(key)(ra))(Record_Unsafe.unsafeGet(key)(rb));
              var $49 = Data_Eq.notEq(Data_Ordering.eqOrdering)(left)(Data_Ordering.EQ.value);

              if ($49) {
                return left;
              }

              ;
              return compareRecord(dictOrdRecord)(Type_Data_RowList.RLProxy.value)(ra)(rb);
            };
          };
        });
      };
    };
  };
};

var clamp = function clamp(dictOrd) {
  return function (low) {
    return function (hi) {
      return function (x) {
        return min(dictOrd)(hi)(max(dictOrd)(low)(x));
      };
    };
  };
};

var between = function between(dictOrd) {
  return function (low) {
    return function (hi) {
      return function (x) {
        if (lessThan(dictOrd)(x)(low)) {
          return false;
        }

        ;

        if (greaterThan(dictOrd)(x)(hi)) {
          return false;
        }

        ;
        return true;
      };
    };
  };
};

var abs = function abs(dictOrd) {
  return function (dictRing) {
    return function (x) {
      var $53 = greaterThanOrEq(dictOrd)(x)(Data_Semiring.zero(dictRing.Semiring0()));

      if ($53) {
        return x;
      }

      ;
      return Data_Ring.negate(dictRing)(x);
    };
  };
};

module.exports = {
  Ord: Ord,
  compare: compare,
  Ord1: Ord1,
  compare1: compare1,
  lessThan: lessThan,
  lessThanOrEq: lessThanOrEq,
  greaterThan: greaterThan,
  greaterThanOrEq: greaterThanOrEq,
  comparing: comparing,
  min: min,
  max: max,
  clamp: clamp,
  between: between,
  abs: abs,
  signum: signum,
  OrdRecord: OrdRecord,
  compareRecord: compareRecord,
  ordBoolean: ordBoolean,
  ordInt: ordInt,
  ordNumber: ordNumber,
  ordString: ordString,
  ordChar: ordChar,
  ordUnit: ordUnit,
  ordVoid: ordVoid,
  ordArray: ordArray,
  ordOrdering: ordOrdering,
  ord1Array: ord1Array,
  ordRecordNil: ordRecordNil,
  ordRecordCons: ordRecordCons,
  ordRecord: ordRecord
};