// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_List = require("../Data.List/index.js");

var Data_Map = require("../Data.Map/index.js");

var Data_Map_Internal = require("../Data.Map.Internal/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Prelude = require("../Prelude/index.js");

var Path = function () {
  function Path(value0) {
    this.value0 = value0;
  }

  ;

  Path.create = function (value0) {
    return new Path(value0);
  };

  return Path;
}();

var Query = function () {
  function Query(value0) {
    this.value0 = value0;
  }

  ;

  Query.create = function (value0) {
    return new Query(value0);
  };

  return Query;
}();

var eqRoutePart = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof Path && y instanceof Path) {
      return x.value0 === y.value0;
    }

    ;

    if (x instanceof Query && y instanceof Query) {
      return Data_Eq.eq(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqString))(x.value0)(y.value0);
    }

    ;
    return false;
  };
});
var ordRoutePart = new Data_Ord.Ord(function () {
  return eqRoutePart;
}, function (x) {
  return function (y) {
    if (x instanceof Path && y instanceof Path) {
      return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
    }

    ;

    if (x instanceof Path) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Path) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Query && y instanceof Query) {
      return Data_Ord.compare(Data_Map_Internal.ordMap(Data_Ord.ordString)(Data_Ord.ordString))(x.value0)(y.value0);
    }

    ;
    throw new Error("Failed pattern match at Routing.Types (line 11, column 8 - line 11, column 46): " + [x.constructor.name, y.constructor.name]);
  };
});
module.exports = {
  Path: Path,
  Query: Query,
  eqRoutePart: eqRoutePart,
  ordRoutePart: ordRoutePart
};