// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

module.exports = {
  language: $foreign.language,
  platform: $foreign.platform,
  userAgent: $foreign.userAgent
};