// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Second = function Second(x) {
  return x;
};

var Minute = function Minute(x) {
  return x;
};

var Millisecond = function Millisecond(x) {
  return x;
};

var Hour = function Hour(x) {
  return x;
};

var showSecond = new Data_Show.Show(function (v) {
  return "(Second " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var showMinute = new Data_Show.Show(function (v) {
  return "(Minute " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var showMillisecond = new Data_Show.Show(function (v) {
  return "(Millisecond " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var showHour = new Data_Show.Show(function (v) {
  return "(Hour " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var ordSecond = Data_Ord.ordInt;
var ordMinute = Data_Ord.ordInt;
var ordMillisecond = Data_Ord.ordInt;
var ordHour = Data_Ord.ordInt;
var eqSecond = Data_Eq.eqInt;
var eqMinute = Data_Eq.eqInt;
var eqMillisecond = Data_Eq.eqInt;
var eqHour = Data_Eq.eqInt;
var boundedSecond = new Data_Bounded.Bounded(function () {
  return ordSecond;
}, 0, 59);
var boundedMinute = new Data_Bounded.Bounded(function () {
  return ordMinute;
}, 0, 59);
var boundedMillisecond = new Data_Bounded.Bounded(function () {
  return ordMillisecond;
}, 0, 999);
var boundedHour = new Data_Bounded.Bounded(function () {
  return ordHour;
}, 0, 23);
var boundedEnumSecond = new Data_Enum.BoundedEnum(function () {
  return boundedSecond;
}, function () {
  return enumSecond;
}, 60, function (v) {
  return v;
}, function (n) {
  if (n >= 0 && n <= 59) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Time.Component (line 90, column 1 - line 90, column 49): " + [n.constructor.name]);
});
var enumSecond = new Data_Enum.Enum(function () {
  return ordSecond;
}, function ($28) {
  return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumSecond)($28)));
}, function ($29) {
  return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumSecond)($29)));
});
var boundedEnumMinute = new Data_Enum.BoundedEnum(function () {
  return boundedMinute;
}, function () {
  return enumMinute;
}, 60, function (v) {
  return v;
}, function (n) {
  if (n >= 0 && n <= 59) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Time.Component (line 61, column 1 - line 61, column 49): " + [n.constructor.name]);
});
var enumMinute = new Data_Enum.Enum(function () {
  return ordMinute;
}, function ($30) {
  return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMinute)($30)));
}, function ($31) {
  return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMinute)($31)));
});
var boundedEnumMillisecond = new Data_Enum.BoundedEnum(function () {
  return boundedMillisecond;
}, function () {
  return enumMillisecond;
}, 1000, function (v) {
  return v;
}, function (n) {
  if (n >= 0 && n <= 999) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Time.Component (line 120, column 1 - line 120, column 59): " + [n.constructor.name]);
});
var enumMillisecond = new Data_Enum.Enum(function () {
  return ordMillisecond;
}, function ($32) {
  return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMillisecond)($32)));
}, function ($33) {
  return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMillisecond)($33)));
});
var boundedEnumHour = new Data_Enum.BoundedEnum(function () {
  return boundedHour;
}, function () {
  return enumHour;
}, 24, function (v) {
  return v;
}, function (n) {
  if (n >= 0 && n <= 23) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Time.Component (line 32, column 1 - line 32, column 45): " + [n.constructor.name]);
});
var enumHour = new Data_Enum.Enum(function () {
  return ordHour;
}, function ($34) {
  return Data_Enum.toEnum(boundedEnumHour)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumHour)($34)));
}, function ($35) {
  return Data_Enum.toEnum(boundedEnumHour)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumHour)($35)));
});
module.exports = {
  eqHour: eqHour,
  ordHour: ordHour,
  boundedHour: boundedHour,
  enumHour: enumHour,
  boundedEnumHour: boundedEnumHour,
  showHour: showHour,
  eqMinute: eqMinute,
  ordMinute: ordMinute,
  boundedMinute: boundedMinute,
  enumMinute: enumMinute,
  boundedEnumMinute: boundedEnumMinute,
  showMinute: showMinute,
  eqSecond: eqSecond,
  ordSecond: ordSecond,
  boundedSecond: boundedSecond,
  enumSecond: enumSecond,
  boundedEnumSecond: boundedEnumSecond,
  showSecond: showSecond,
  eqMillisecond: eqMillisecond,
  ordMillisecond: ordMillisecond,
  boundedMillisecond: boundedMillisecond,
  enumMillisecond: enumMillisecond,
  boundedEnumMillisecond: boundedEnumMillisecond,
  showMillisecond: showMillisecond
};