// Generated by purs version 0.12.3
"use strict";

var Type_Prelude = require("../Type.Prelude/index.js");

var ConsPrefix = function ConsPrefix(consPrefix) {
  this.consPrefix = consPrefix;
};

var consPrefix = function consPrefix(dict) {
  return dict.consPrefix;
};

module.exports = {
  ConsPrefix: ConsPrefix,
  consPrefix: consPrefix
};