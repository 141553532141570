// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Alert = require("../Optimus.Client.Antd.Alert/index.js");

var Optimus_Client_Antd_Button = require("../Optimus.Client.Antd.Button/index.js");

var Optimus_Client_Antd_Card = require("../Optimus.Client.Antd.Card/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Icon = require("../Optimus.Client.Antd.Icon/index.js");

var Optimus_Client_Antd_Input = require("../Optimus.Client.Antd.Input/index.js");

var Optimus_Client_Antd_Notification = require("../Optimus.Client.Antd.Notification/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var Optimus_Client_ApiClient_Users = require("../Optimus.Client.ApiClient.Users/index.js");

var Optimus_Shared_Users = require("../Optimus.Shared.Users/index.js");

var Optimus_Utilities_Default = require("../Optimus.Utilities.Default/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var Record = require("../Record/index.js");

var ZRPC_Clients_Zapper = require("../ZRPC.Clients.Zapper/index.js");

var OtherErr = function () {
  function OtherErr() {}

  ;
  OtherErr.value = new OtherErr();
  return OtherErr;
}();

var Z = function () {
  function Z(value0) {
    this.value0 = value0;
  }

  ;

  Z.create = function (value0) {
    return new Z(value0);
  };

  return Z;
}();

var FSInit = function () {
  function FSInit() {}

  ;
  FSInit.value = new FSInit();
  return FSInit;
}();

var FSLoading = function () {
  function FSLoading() {}

  ;
  FSLoading.value = new FSLoading();
  return FSLoading;
}();

var FSSuccess = function () {
  function FSSuccess() {}

  ;
  FSSuccess.value = new FSSuccess();
  return FSSuccess;
}();

var FSFail = function () {
  function FSFail(value0) {
    this.value0 = value0;
  }

  ;

  FSFail.create = function (value0) {
    return new FSFail(value0);
  };

  return FSFail;
}();

var DSNone = function () {
  function DSNone() {}

  ;
  DSNone.value = new DSNone();
  return DSNone;
}();

var DSLoading = function () {
  function DSLoading() {}

  ;
  DSLoading.value = new DSLoading();
  return DSLoading;
}();

var DSSuccess = function () {
  function DSSuccess(value0) {
    this.value0 = value0;
  }

  ;

  DSSuccess.create = function (value0) {
    return new DSSuccess(value0);
  };

  return DSSuccess;
}();

var component = React_Basic.createComponent("ChangeUserEmail");

var changeEmailPage = function () {
  var onSubmit = function onSubmit(self) {
    var handleRes = function handleRes(v) {
      if (v instanceof Data_Either.Right && v.value0 instanceof Data_Either.Right) {
        if (v.value0.value0 instanceof Data_Either.Left) {
          return self.setState(function (v1) {
            return {
              formState: FSFail.create(new Z(v.value0.value0.value0)),
              newEmail: v1.newEmail,
              dataState: v1.dataState
            };
          });
        }

        ;

        if (v.value0.value0 instanceof Data_Either.Right) {
          return self.setState(function (v1) {
            return {
              formState: FSSuccess.value,
              newEmail: "",
              dataState: new DSSuccess(v.value0.value0.value0)
            };
          });
        }

        ;
        throw new Error("Failed pattern match at Optimus.Client.Pages.Users.ChangeEmail (line 76, column 39 - line 78, column 111): " + [v.value0.value0.constructor.name]);
      }

      ;
      return self.setState(function (v1) {
        return {
          formState: new FSFail(OtherErr.value),
          newEmail: v1.newEmail,
          dataState: v1.dataState
        };
      });
    };

    return React_Basic_DOM_Events.capture_(function __do() {
      self.setState(function (v) {
        return {
          formState: FSLoading.value,
          newEmail: v.newEmail,
          dataState: v.dataState
        };
      })();
      return Effect_Aff.runAff_(handleRes)(Optimus_Client_ApiClient_Users.postChangeEmail(self.props.siloId)(self.props.zapperId)({
        newEmail: self.state.newEmail
      }))();
    });
  };

  var onFetchEmail = function onFetchEmail(self) {
    return function __do() {
      self.setState(function (v) {
        var $19 = {};

        for (var $20 in v) {
          if ({}.hasOwnProperty.call(v, $20)) {
            $19[$20] = v[$20];
          }

          ;
        }

        ;
        $19.dataState = DSLoading.value;
        return $19;
      })();
      return Effect_Aff.runAff_(function (v) {
        if (v instanceof Data_Either.Right) {
          return self.setState(function (v1) {
            var $23 = {};

            for (var $24 in v1) {
              if ({}.hasOwnProperty.call(v1, $24)) {
                $23[$24] = v1[$24];
              }

              ;
            }

            ;
            $23.dataState = new DSSuccess(v.value0);
            return $23;
          });
        }

        ;

        if (v instanceof Data_Either.Left) {
          return function __do() {
            Optimus_Client_Antd_Notification.notification.error()({
              message: React_Basic_DOM.text("Zapper Email"),
              description: React_Basic_DOM.text("Failed to load current email")
            })();
            return self.setState(function (v1) {
              var $27 = {};

              for (var $28 in v1) {
                if ({}.hasOwnProperty.call(v1, $28)) {
                  $27[$28] = v1[$28];
                }

                ;
              }

              ;
              $27.dataState = DSNone.value;
              return $27;
            })();
          };
        }

        ;
        throw new Error("Failed pattern match at Optimus.Client.Pages.Users.ChangeEmail (line 52, column 16 - line 57, column 51): " + [v.constructor.name]);
      })(Optimus_Client_ApiClient_Users.getChangeEmail(self.props.siloId)(self.props.zapperId))();
    };
  };

  var onEmailChange = function onEmailChange(self) {
    return React_Basic_Events.handler(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
      return "targetValue";
    }))()()()()(React_Basic_Events.mergeNil))({
      targetValue: React_Basic_DOM_Events.targetValue
    }))(function (v) {
      return self.setState(function (v1) {
        return {
          newEmail: Data_Maybe.fromMaybe(Optimus_Utilities_Default["default"](Optimus_Utilities_Default.defaultString))(v.targetValue),
          dataState: v1.dataState,
          formState: v1.formState
        };
      });
    });
  };

  var render = function render(self) {
    var pair = function pair(key) {
      return function (value) {
        return Optimus_Client_Antd_Grid.row()({
          type: "flex",
          className: "justify-center items-center mb-2",
          children: [Optimus_Client_Antd_Grid.col()({
            xs: 24,
            sm: 6,
            className: "ant-form-item-label",
            children: [React_Basic_DOM_Generated.label_([Optimus_Client_Antd_Typography.text()({
              strong: true,
              children: [React_Basic_DOM.text(key)]
            })])]
          }), Optimus_Client_Antd_Grid.col()({
            xs: 24,
            sm: 18,
            children: [value]
          })]
        });
      };
    };

    var getEmail = function getEmail(v) {
      if (v instanceof DSSuccess) {
        return Data_Maybe.fromMaybe(Optimus_Utilities_Default["default"](Optimus_Utilities_Default.defaultString))(Control_Bind.bind(Data_Maybe.bindMaybe)(v.value0.email)(function (v1) {
          if (v1 instanceof ZRPC_Clients_Zapper.EmailConfirmed) {
            return v1.value0.value;
          }

          ;

          if (v1 instanceof ZRPC_Clients_Zapper.EmailUnconfirmed) {
            return v1.value0.value;
          }

          ;
          throw new Error("Failed pattern match at Optimus.Client.Pages.Users.ChangeEmail (line 141, column 17 - line 143, column 63): " + [v1.constructor.name]);
        }));
      }

      ;
      return "-";
    };

    var alertDisplay = function alertDisplay(formS) {
      var commonProps = {
        showIcon: true,
        className: "mb-2"
      };

      if (formS instanceof FSLoading) {
        return Data_Monoid.mempty(React_Basic.monoidJSX);
      }

      ;

      if (formS instanceof FSInit) {
        return Data_Monoid.mempty(React_Basic.monoidJSX);
      }

      ;

      if (formS instanceof FSSuccess) {
        return Optimus_Client_Antd_Alert.alert()(Record.merge()()(commonProps)({
          type: "success",
          message: React_Basic_DOM.text("Change Email Success")
        }));
      }

      ;

      if (formS instanceof FSFail) {
        return Optimus_Client_Antd_Alert.alert()(Record.merge()()(commonProps)({
          type: "error",
          description: React_Basic_DOM.text(function () {
            if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorExistingEmail) {
              return "Email provided already taken.";
            }

            ;

            if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorInvalidEmail) {
              return "Email provided is invalid.";
            }

            ;

            if (formS.value0 instanceof Z && formS.value0.value0 instanceof ZRPC_Clients_Zapper.SetEmailErrorUnableToSetEmail) {
              return "Unable to set new email.";
            }

            ;

            if (formS.value0 instanceof OtherErr) {
              return "Unknown Error has occured.";
            }

            ;
            throw new Error("Failed pattern match at Optimus.Client.Pages.Users.ChangeEmail (line 162, column 43 - line 167, column 19): " + [formS.value0.constructor.name]);
          }()),
          message: React_Basic_DOM.text("Change Email Failure")
        }));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.Pages.Users.ChangeEmail (line 149, column 13 - line 168, column 20): " + [formS.constructor.name]);
    };

    return Optimus_Client_Antd_Grid.row()({
      type: "flex",
      justify: "center",
      children: [Optimus_Client_Antd_Card.card()({
        title: "Change Email",
        className: "w-100 mt-3 mw-md",
        children: Data_Array.singleton(function () {
          if (self.state.dataState instanceof DSLoading) {
            return React_Basic_DOM_Generated.div()({
              className: "flex justify-center",
              children: Data_Array.singleton(Optimus_Client_Antd_Icon.icon()({
                type: "loading",
                className: "fs-2"
              }))
            });
          }

          ;
          return React_Basic_DOM_Generated.form()({
            onSubmit: onSubmit(self),
            children: [alertDisplay(self.state.formState), pair("Current Email")(Optimus_Client_Antd_Typography.text()({
              className: "pl-1",
              children: [React_Basic_DOM.text(getEmail(self.state.dataState))]
            })), pair("New Email")(Optimus_Client_Antd_Input.input()({
              value: self.state.newEmail,
              onChange: onEmailChange(self)
            })), Optimus_Client_Antd_Grid.row()({
              className: "justify-end mt-4",
              type: "flex",
              children: Data_Array.singleton(Optimus_Client_Antd_Button.button()({
                htmlType: "submit",
                type: "primary",
                loading: function () {
                  if (self.state.formState instanceof FSLoading) {
                    return true;
                  }

                  ;
                  return false;
                }(),
                children: [React_Basic_DOM.text("Submit")]
              }))
            })]
          });
        }())
      })]
    });
  };

  var initialState = {
    newEmail: "",
    formState: FSInit.value,
    dataState: DSNone.value
  };

  var didMount = function didMount(self) {
    return onFetchEmail(self);
  };

  return React_Basic.make()(component)({
    initialState: initialState,
    render: render,
    didMount: didMount
  });
}();

module.exports = {
  changeEmailPage: changeEmailPage
};