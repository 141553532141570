// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var Record = require("../Record/index.js");

var Type_Row = require("../Type.Row/index.js");

var EventFn = function EventFn(x) {
  return x;
};

var Merge = function Merge(mergeImpl) {
  this.mergeImpl = mergeImpl;
};

var unsafeEventFn = EventFn;
var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
var mergeNil = new Merge(function (v) {
  return function (v1) {
    return function (v2) {
      return {};
    };
  };
});

var mergeImpl = function mergeImpl(dict) {
  return dict.mergeImpl;
};

var mergeCons = function mergeCons(dictIsSymbol) {
  return function (dictCons) {
    return function (dictCons1) {
      return function (dictLacks) {
        return function (dictLacks1) {
          return function (dictMerge) {
            return new Merge(function (v) {
              return function (fns) {
                return function (a) {
                  var v1 = mergeImpl(dictMerge)(Type_Row.RLProxy.value)(Record["delete"](dictIsSymbol)(dictLacks)(dictCons)(Data_Symbol.SProxy.value)(fns));
                  var v2 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(fns);
                  return Record.insert(dictIsSymbol)(dictLacks1)(dictCons1)(Data_Symbol.SProxy.value)(v2(a))(v1(a));
                };
              };
            });
          };
        };
      };
    };
  };
};

var merge = function merge(dictRowToList) {
  return function (dictMerge) {
    return mergeImpl(dictMerge)(Type_Row.RLProxy.value);
  };
};

var handler_ = function handler_($21) {
  return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($21));
};

var handler = function handler(v) {
  return function (cb) {
    return function ($22) {
      return cb(v($22))();
    };
  };
};

var categoryBuilder = Control_Category.categoryFn;
var syntheticEvent = Control_Category.identity(categoryBuilder);
module.exports = {
  unsafeEventFn: unsafeEventFn,
  handler: handler,
  handler_: handler_,
  syntheticEvent: syntheticEvent,
  merge: merge,
  Merge: Merge,
  mergeImpl: mergeImpl,
  semigroupoidBuilder: semigroupoidBuilder,
  categoryBuilder: categoryBuilder,
  mergeNil: mergeNil,
  mergeCons: mergeCons
};