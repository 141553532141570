// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var select = function select(dictUnion) {
  return React_Basic.element($foreign["_select"]);
};

var select_ = function select_(children) {
  return select()({
    children: children
  });
};

var option = function option(dictUnion) {
  return React_Basic.element($foreign["_option"]);
};

var option_ = function option_(children) {
  return option()({
    children: children
  });
};

module.exports = {
  select: select,
  select_: select_,
  option: option,
  option_: option_
};