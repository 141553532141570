// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM_Comment = require("../Web.DOM.Comment/index.js");

var Web_DOM_DocumentFragment = require("../Web.DOM.DocumentFragment/index.js");

var Web_DOM_DocumentType = require("../Web.DOM.DocumentType/index.js");

var Web_DOM_Element = require("../Web.DOM.Element/index.js");

var Web_DOM_HTMLCollection = require("../Web.DOM.HTMLCollection/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");

var Web_DOM_ParentNode = require("../Web.DOM.ParentNode/index.js");

var Web_DOM_ProcessingInstruction = require("../Web.DOM.ProcessingInstruction/index.js");

var Web_DOM_Text = require("../Web.DOM.Text/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonElementParentNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;

var getElementsByTagNameNS = function getElementsByTagNameNS($0) {
  return $foreign["_getElementsByTagNameNS"](Data_Nullable.toNullable($0));
};

var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("Document");
var fromNonElementParentNode = Web_Internal_FFI.unsafeReadProtoTagged("Document");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("Document");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Document");

var documentElement = function documentElement($1) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_documentElement"]($1));
};

var doctype = function doctype($2) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_doctype"]($2));
};

var createElementNS = function createElementNS($3) {
  return $foreign["_createElementNS"](Data_Nullable.toNullable($3));
};

module.exports = {
  fromNode: fromNode,
  fromParentNode: fromParentNode,
  fromNonElementParentNode: fromNonElementParentNode,
  fromEventTarget: fromEventTarget,
  toNode: toNode,
  toParentNode: toParentNode,
  toNonElementParentNode: toNonElementParentNode,
  toEventTarget: toEventTarget,
  doctype: doctype,
  documentElement: documentElement,
  getElementsByTagNameNS: getElementsByTagNameNS,
  createElementNS: createElementNS,
  url: $foreign.url,
  documentURI: $foreign.documentURI,
  origin: $foreign.origin,
  compatMode: $foreign.compatMode,
  characterSet: $foreign.characterSet,
  contentType: $foreign.contentType,
  getElementsByTagName: $foreign.getElementsByTagName,
  getElementsByClassName: $foreign.getElementsByClassName,
  createElement: $foreign.createElement,
  createDocumentFragment: $foreign.createDocumentFragment,
  createTextNode: $foreign.createTextNode,
  createComment: $foreign.createComment,
  createProcessingInstruction: $foreign.createProcessingInstruction,
  importNode: $foreign.importNode,
  adoptNode: $foreign.adoptNode
};