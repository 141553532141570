// Generated by purs version 0.12.3
"use strict";

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var grid = function grid(v) {
  var wrapCol = function wrapCol(item) {
    return Optimus_Client_Antd_Grid.col()({
      xs: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.xs),
      sm: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.sm),
      md: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.md),
      lg: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.lg),
      xl: Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(24)(v.rowItemCount.xl),
      className: "mb-" + Data_Show.show(Data_Show.showInt)(v.gutter),
      children: [item]
    });
  };

  return Optimus_Client_Antd_Grid.row()({
    className: v.className,
    gutter: v.gutter * 4 | 0,
    children: Data_Functor.mapFlipped(Data_Functor.functorArray)(v.items)(wrapCol)
  });
};

module.exports = {
  grid: grid
};