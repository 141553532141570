// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var renderText = function renderText(dictInfoShow) {
  return function ($1) {
    return React_Basic_DOM.text(Optimus_Client_InfoShow.showInfo(dictInfoShow)($1));
  };
};

var renderPair = function renderPair(p) {
  return Optimus_Client_Antd_Grid.row()({
    type: "flex",
    justify: "center",
    children: [Optimus_Client_Antd_Grid.col()({
      xs: 24,
      sm: 16,
      md: 12,
      lg: 8,
      children: [Optimus_Client_Antd_Grid.row()({
        gutter: 16,
        className: "mb-1",
        children: [Optimus_Client_Antd_Grid.col()({
          span: 8,
          children: [Optimus_Client_Antd_Typography.text()({
            strong: true,
            children: [React_Basic_DOM.text(p.key)]
          })]
        }), Optimus_Client_Antd_Grid.col()({
          className: "break-word",
          span: 16,
          children: [p.value]
        })]
      })]
    })]
  });
};

var ip = function ip(key) {
  return function (value) {
    return {
      key: key,
      value: value
    };
  };
};

var infoPanel = function infoPanel(ips) {
  return React_Basic_DOM_Generated.div_(Data_Functor.map(Data_Functor.functorArray)(renderPair)(ips));
};

module.exports = {
  infoPanel: infoPanel,
  ip: ip,
  renderText: renderText
};