// Generated by purs version 0.12.3
"use strict";

var Control_Biapply = require("../Control.Biapply/index.js");

var Biapplicative = function Biapplicative(Biapply0, bipure) {
  this.Biapply0 = Biapply0;
  this.bipure = bipure;
};

var bipure = function bipure(dict) {
  return dict.bipure;
};

module.exports = {
  bipure: bipure,
  Biapplicative: Biapplicative
};