// Generated by purs version 0.12.3
"use strict";

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_NonEmpty = require("../Data.NonEmpty/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable = require("../Data.Unfoldable/index.js");

var Data_Unfoldable1 = require("../Data.Unfoldable1/index.js");

var Partial_Unsafe = require("../Partial.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var unsafeFromArrayF = Unsafe_Coerce.unsafeCoerce;
var unsafeFromArray = Unsafe_Coerce.unsafeCoerce;
var toArray = Unsafe_Coerce.unsafeCoerce;

var unionBy$prime = function unionBy$prime(eq) {
  return function (xs) {
    return function ($39) {
      return unsafeFromArray(Data_Array.unionBy(eq)(toArray(xs))($39));
    };
  };
};

var union$prime = function union$prime(dictEq) {
  return unionBy$prime(Data_Eq.eq(dictEq));
};

var unionBy = function unionBy(eq) {
  return function (xs) {
    return function ($40) {
      return unionBy$prime(eq)(xs)(toArray($40));
    };
  };
};

var union = function union(dictEq) {
  return unionBy(Data_Eq.eq(dictEq));
};

var unzip = function unzip($41) {
  return Data_Bifunctor.bimap(Data_Tuple.bifunctorTuple)(unsafeFromArray)(unsafeFromArray)(Data_Array.unzip(toArray($41)));
};

var updateAt = function updateAt(i) {
  return function (x) {
    return function ($42) {
      return unsafeFromArrayF(Data_Array.updateAt(i)(x)(toArray($42)));
    };
  };
};

var zip = function zip(xs) {
  return function (ys) {
    return unsafeFromArray(Data_Array.zip(toArray(xs))(toArray(ys)));
  };
};

var zipWith = function zipWith(f) {
  return function (xs) {
    return function (ys) {
      return unsafeFromArray(Data_Array.zipWith(f)(toArray(xs))(toArray(ys)));
    };
  };
};

var zipWithA = function zipWithA(dictApplicative) {
  return function (f) {
    return function (xs) {
      return function (ys) {
        return unsafeFromArrayF(Data_Array.zipWithA(dictApplicative)(f)(toArray(xs))(toArray(ys)));
      };
    };
  };
};

var some = function some(dictAlternative) {
  return function (dictLazy) {
    return function ($43) {
      return unsafeFromArrayF(Data_Array.some(dictAlternative)(dictLazy)($43));
    };
  };
};

var snoc$prime = function snoc$prime(xs) {
  return function (x) {
    return unsafeFromArray(Data_Array.snoc(xs)(x));
  };
};

var snoc = function snoc(xs) {
  return function (x) {
    return unsafeFromArray(Data_Array.snoc(toArray(xs))(x));
  };
};

var singleton = function singleton($44) {
  return unsafeFromArray(Data_Array.singleton($44));
};

var replicate = function replicate(i) {
  return function (x) {
    return unsafeFromArray(Data_Array.replicate(Data_Ord.max(Data_Ord.ordInt)(1)(i))(x));
  };
};

var range = function range(x) {
  return function (y) {
    return unsafeFromArray(Data_Array.range(x)(y));
  };
};

var modifyAt = function modifyAt(i) {
  return function (f) {
    return function ($45) {
      return unsafeFromArrayF(Data_Array.modifyAt(i)(f)(toArray($45)));
    };
  };
};

var intersectBy$prime = function intersectBy$prime(eq) {
  return function (xs) {
    return Data_Array.intersectBy(eq)(toArray(xs));
  };
};

var intersectBy = function intersectBy(eq) {
  return function (xs) {
    return function ($46) {
      return intersectBy$prime(eq)(xs)(toArray($46));
    };
  };
};

var intersect$prime = function intersect$prime(dictEq) {
  return intersectBy$prime(Data_Eq.eq(dictEq));
};

var intersect = function intersect(dictEq) {
  return intersectBy(Data_Eq.eq(dictEq));
};

var insertAt = function insertAt(i) {
  return function (x) {
    return function ($47) {
      return unsafeFromArrayF(Data_Array.insertAt(i)(x)(toArray($47)));
    };
  };
};

var fromFoldable1 = function fromFoldable1(dictFoldable1) {
  return function ($48) {
    return unsafeFromArray(Data_Array.fromFoldable(dictFoldable1.Foldable0())($48));
  };
};

var fromArray = function fromArray(xs) {
  if (Data_Array.length(xs) > 0) {
    return new Data_Maybe.Just(unsafeFromArray(xs));
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Array.NonEmpty (line 134, column 1 - line 134, column 58): " + [xs.constructor.name]);
};

var fromFoldable = function fromFoldable(dictFoldable) {
  return function ($49) {
    return fromArray(Data_Array.fromFoldable(dictFoldable)($49));
  };
};

var difference$prime = function difference$prime(dictEq) {
  return function (xs) {
    return Data_Array.difference(dictEq)(toArray(xs));
  };
};

var cons$prime = function cons$prime(x) {
  return function (xs) {
    return unsafeFromArray(Data_Array.cons(x)(xs));
  };
};

var fromNonEmpty = function fromNonEmpty(v) {
  return cons$prime(v.value0)(v.value1);
};

var concatMap = Data_Function.flip(Control_Bind.bind(Data_Array_NonEmpty_Internal.bindNonEmptyArray));

var concat = function concat($50) {
  return unsafeFromArray(Data_Array.concat(toArray(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(toArray)($50))));
};

var appendArray = function appendArray(xs) {
  return function (ys) {
    return unsafeFromArray(Data_Semigroup.append(Data_Semigroup.semigroupArray)(toArray(xs))(ys));
  };
};

var alterAt = function alterAt(i) {
  return function (f) {
    return function ($51) {
      return Data_Array.alterAt(i)(f)(toArray($51));
    };
  };
};

var adaptMaybe = function adaptMaybe(f) {
  return function ($52) {
    return Data_Maybe.fromJust()(f(toArray($52)));
  };
};

var head = adaptMaybe(Data_Array.head);
var init = adaptMaybe(Data_Array.init);
var last = adaptMaybe(Data_Array.last);
var tail = adaptMaybe(Data_Array.tail);
var uncons = adaptMaybe(Data_Array.uncons);

var toNonEmpty = function toNonEmpty($53) {
  return function (v) {
    return new Data_NonEmpty.NonEmpty(v.head, v.tail);
  }(uncons($53));
};

var unsnoc = adaptMaybe(Data_Array.unsnoc);

var adaptAny = function adaptAny(f) {
  return function ($54) {
    return f(toArray($54));
  };
};

var catMaybes = adaptAny(Data_Array.catMaybes);

var $$delete = function $$delete(dictEq) {
  return function (x) {
    return adaptAny(Data_Array["delete"](dictEq)(x));
  };
};

var deleteAt = function deleteAt(i) {
  return adaptAny(Data_Array.deleteAt(i));
};

var deleteBy = function deleteBy(f) {
  return function (x) {
    return adaptAny(Data_Array.deleteBy(f)(x));
  };
};

var difference = function difference(dictEq) {
  return function (xs) {
    return adaptAny(difference$prime(dictEq)(xs));
  };
};

var drop = function drop(i) {
  return adaptAny(Data_Array.drop(i));
};

var dropEnd = function dropEnd(i) {
  return adaptAny(Data_Array.dropEnd(i));
};

var dropWhile = function dropWhile(f) {
  return adaptAny(Data_Array.dropWhile(f));
};

var elemIndex = function elemIndex(dictEq) {
  return function (x) {
    return adaptAny(Data_Array.elemIndex(dictEq)(x));
  };
};

var elemLastIndex = function elemLastIndex(dictEq) {
  return function (x) {
    return adaptAny(Data_Array.elemLastIndex(dictEq)(x));
  };
};

var filter = function filter(f) {
  return adaptAny(Data_Array.filter(f));
};

var filterA = function filterA(dictApplicative) {
  return function (f) {
    return adaptAny(Data_Array.filterA(dictApplicative)(f));
  };
};

var findIndex = function findIndex(x) {
  return adaptAny(Data_Array.findIndex(x));
};

var findLastIndex = function findLastIndex(x) {
  return adaptAny(Data_Array.findLastIndex(x));
};

var foldM = function foldM(dictMonad) {
  return function (f) {
    return function (acc) {
      return adaptAny(Data_Array.foldM(dictMonad)(f)(acc));
    };
  };
};

var foldRecM = function foldRecM(dictMonadRec) {
  return function (f) {
    return function (acc) {
      return adaptAny(Data_Array.foldRecM(dictMonadRec)(f)(acc));
    };
  };
};

var index = adaptAny(Data_Array.index);
var length = adaptAny(Data_Array.length);

var mapMaybe = function mapMaybe(f) {
  return adaptAny(Data_Array.mapMaybe(f));
};

var partition = function partition(f) {
  return adaptAny(Data_Array.partition(f));
};

var slice = function slice(start) {
  return function (end) {
    return adaptAny(Data_Array.slice(start)(end));
  };
};

var span = function span(f) {
  return adaptAny(Data_Array.span(f));
};

var take = function take(i) {
  return adaptAny(Data_Array.take(i));
};

var takeEnd = function takeEnd(i) {
  return adaptAny(Data_Array.takeEnd(i));
};

var takeWhile = function takeWhile(f) {
  return adaptAny(Data_Array.takeWhile(f));
};

var toUnfoldable = function toUnfoldable(dictUnfoldable) {
  return adaptAny(Data_Array.toUnfoldable(dictUnfoldable));
};

var unsafeAdapt = function unsafeAdapt(f) {
  return function ($55) {
    return unsafeFromArray(adaptAny(f)($55));
  };
};

var cons = function cons(x) {
  return unsafeAdapt(Data_Array.cons(x));
};

var insert = function insert(dictOrd) {
  return function (x) {
    return unsafeAdapt(Data_Array.insert(dictOrd)(x));
  };
};

var insertBy = function insertBy(f) {
  return function (x) {
    return unsafeAdapt(Data_Array.insertBy(f)(x));
  };
};

var modifyAtIndices = function modifyAtIndices(dictFoldable) {
  return function (is) {
    return function (f) {
      return unsafeAdapt(Data_Array.modifyAtIndices(dictFoldable)(is)(f));
    };
  };
};

var nub = function nub(dictOrd) {
  return unsafeAdapt(Data_Array.nub(dictOrd));
};

var nubBy = function nubBy(f) {
  return unsafeAdapt(Data_Array.nubBy(f));
};

var nubByEq = function nubByEq(f) {
  return unsafeAdapt(Data_Array.nubByEq(f));
};

var nubEq = function nubEq(dictEq) {
  return unsafeAdapt(Data_Array.nubEq(dictEq));
};

var reverse = unsafeAdapt(Data_Array.reverse);

var sort = function sort(dictOrd) {
  return unsafeAdapt(Data_Array.sort(dictOrd));
};

var sortBy = function sortBy(f) {
  return unsafeAdapt(Data_Array.sortBy(f));
};

var sortWith = function sortWith(dictOrd) {
  return function (f) {
    return unsafeAdapt(Data_Array.sortWith(dictOrd)(f));
  };
};

var updateAtIndices = function updateAtIndices(dictFoldable) {
  return function (pairs) {
    return unsafeAdapt(Data_Array.updateAtIndices(dictFoldable)(pairs));
  };
};

var unsafeIndex = function unsafeIndex(dictPartial) {
  return adaptAny(Data_Array.unsafeIndex(dictPartial));
};

var toUnfoldable1 = function toUnfoldable1(dictUnfoldable1) {
  return function (xs) {
    var len = length(xs);

    var f = function f(i) {
      return Data_Tuple.Tuple.create(unsafeIndex()(xs)(i))(function () {
        var $38 = i < (len - 1 | 0);

        if ($38) {
          return new Data_Maybe.Just(i + 1 | 0);
        }

        ;
        return Data_Maybe.Nothing.value;
      }());
    };

    return Data_Unfoldable1.unfoldr1(dictUnfoldable1)(f)(0);
  };
};

module.exports = {
  fromArray: fromArray,
  fromNonEmpty: fromNonEmpty,
  toArray: toArray,
  toNonEmpty: toNonEmpty,
  fromFoldable: fromFoldable,
  fromFoldable1: fromFoldable1,
  toUnfoldable: toUnfoldable,
  toUnfoldable1: toUnfoldable1,
  singleton: singleton,
  range: range,
  replicate: replicate,
  some: some,
  length: length,
  cons: cons,
  "cons'": cons$prime,
  snoc: snoc,
  "snoc'": snoc$prime,
  appendArray: appendArray,
  insert: insert,
  insertBy: insertBy,
  head: head,
  last: last,
  tail: tail,
  init: init,
  uncons: uncons,
  unsnoc: unsnoc,
  index: index,
  elemIndex: elemIndex,
  elemLastIndex: elemLastIndex,
  findIndex: findIndex,
  findLastIndex: findLastIndex,
  insertAt: insertAt,
  deleteAt: deleteAt,
  updateAt: updateAt,
  updateAtIndices: updateAtIndices,
  modifyAt: modifyAt,
  modifyAtIndices: modifyAtIndices,
  alterAt: alterAt,
  reverse: reverse,
  concat: concat,
  concatMap: concatMap,
  filter: filter,
  partition: partition,
  filterA: filterA,
  mapMaybe: mapMaybe,
  catMaybes: catMaybes,
  sort: sort,
  sortBy: sortBy,
  sortWith: sortWith,
  slice: slice,
  take: take,
  takeEnd: takeEnd,
  takeWhile: takeWhile,
  drop: drop,
  dropEnd: dropEnd,
  dropWhile: dropWhile,
  span: span,
  nub: nub,
  nubBy: nubBy,
  nubEq: nubEq,
  nubByEq: nubByEq,
  union: union,
  "union'": union$prime,
  unionBy: unionBy,
  "unionBy'": unionBy$prime,
  "delete": $$delete,
  deleteBy: deleteBy,
  difference: difference,
  "difference'": difference$prime,
  intersect: intersect,
  "intersect'": intersect$prime,
  intersectBy: intersectBy,
  "intersectBy'": intersectBy$prime,
  zipWith: zipWith,
  zipWithA: zipWithA,
  zip: zip,
  unzip: unzip,
  foldM: foldM,
  foldRecM: foldRecM,
  unsafeIndex: unsafeIndex
};