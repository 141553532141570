// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Routing_Duplex = require("../Routing.Duplex/index.js");

var Routing_Duplex_Generic = require("../Routing.Duplex.Generic/index.js");

var GSep = function GSep(gsep) {
  this.gsep = gsep;
};

var GParams = function GParams(gparams) {
  this.gparams = gparams;
};

var gsepStringString = new GSep(function (a) {
  return function (b) {
    return Routing_Duplex.prefix(a)(Routing_Duplex.prefix(b)(Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex)(Data_Unit.unit)));
  };
});

var gsepStringRoute = function gsepStringRoute(dictGRouteDuplexCtr) {
  return new GSep(function (a) {
    return function ($5) {
      return Routing_Duplex.prefix(a)(Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr)($5));
    };
  });
};

var gsepRouteString = function gsepRouteString(dictGRouteDuplexCtr) {
  return new GSep(function ($6) {
    return Routing_Duplex.suffix(Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr)($6));
  });
};

var gsepProduct = function gsepProduct(dictGRouteDuplexCtr) {
  return new GSep(Routing_Duplex_Generic.product(dictGRouteDuplexCtr));
};

var gsep = function gsep(dict) {
  return dict.gsep;
};

var gparamsString = function gparamsString(dictRouteDuplexParams) {
  return new GParams(function (a) {
    return function ($7) {
      return Routing_Duplex.prefix(a)(Routing_Duplex.params(dictRouteDuplexParams)($7));
    };
  });
};

var gparamsRoute = function gparamsRoute(dictRouteDuplexParams) {
  return new GParams(function (a) {
    return function ($8) {
      return Routing_Duplex_Generic.product(Routing_Duplex_Generic.gRouteAll)(a)(Routing_Duplex.params(dictRouteDuplexParams)($8));
    };
  });
};

var gparams = function gparams(dict) {
  return dict.gparams;
};

module.exports = {
  gparams: gparams,
  gsep: gsep,
  GSep: GSep,
  GParams: GParams,
  gsepStringString: gsepStringString,
  gsepStringRoute: gsepStringRoute,
  gsepRouteString: gsepRouteString,
  gsepProduct: gsepProduct,
  gparamsString: gparamsString,
  gparamsRoute: gparamsRoute
};