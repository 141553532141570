var message = require('antd').message;

exports.message = {
  success: function success(m) {
    return function () {
      message.success(m);
    };
  },
  warning: function warning(m) {
    return function () {
      message.warning(m);
    };
  },
  error: function error(m) {
    return function () {
      message.error(m);
    };
  }
};