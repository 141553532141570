// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var React_Basic = require("../React.Basic/index.js");

var skeleton = function skeleton(dictUnion) {
  return React_Basic.element($foreign["_skeleton"]);
};

module.exports = {
  skeleton: skeleton
};