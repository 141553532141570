// Generated by purs version 0.12.3
"use strict";

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Icon = require("../Optimus.Client.Antd.Icon/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var render = function render(v) {
  return React_Basic_DOM_Generated.div()({
    className: "flex justify-center",
    children: [Optimus_Client_Antd_Icon.icon()({
      className: "absolute top-50 fs-2",
      type: "loading"
    })]
  });
};

var component = React_Basic.createComponent("Loading");
var loadingPage = React_Basic.makeStateless(component)(render)({});
module.exports = {
  component: component,
  loadingPage: loadingPage,
  render: render
};