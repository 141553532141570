// Generated by purs version 0.12.3
"use strict";

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var content = function content(v) {
  return React_Basic_DOM_Generated.div()({
    className: "mw-xl mx-auto",
    children: [Optimus_Client_Antd_Typography.title()({
      className: "px-3 pt-2",
      level: 2,
      children: [React_Basic_DOM.text(v.title)]
    }), React_Basic_DOM_Generated.div()({
      children: v.children
    })]
  });
};

module.exports = {
  content: content
};