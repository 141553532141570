// Generated by purs version 0.12.3
"use strict";

var Type_Data_Boolean = require("../Type.Data.Boolean/index.js");

var Type_Data_Ordering = require("../Type.Data.Ordering/index.js");

var Type_Data_Symbol = require("../Type.Data.Symbol/index.js");

var Type_Equality = require("../Type.Equality/index.js");

var Type_Proxy = require("../Type.Proxy/index.js");

var Type_Row = require("../Type.Row/index.js");

module.exports = {};