// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Prelude = require("../Prelude/index.js");

var Record_Unsafe = require("../Record.Unsafe/index.js");

var Record_Unsafe_Union = require("../Record.Unsafe.Union/index.js");

var Type_Row = require("../Type.Row/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var EqualFields = function EqualFields(equalFields) {
  this.equalFields = equalFields;
};

var union = function union(dictUnion) {
  return function (l) {
    return function (r) {
      return Record_Unsafe_Union.unsafeUnionFn(l, r);
    };
  };
};

var set = function set(dictIsSymbol) {
  return function (dictCons) {
    return function (dictCons1) {
      return function (l) {
        return function (b) {
          return function (r) {
            return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(b)(r);
          };
        };
      };
    };
  };
};

var nub = function nub(dictNub) {
  return Unsafe_Coerce.unsafeCoerce;
};

var merge = function merge(dictUnion) {
  return function (dictNub) {
    return function (l) {
      return function (r) {
        return Record_Unsafe_Union.unsafeUnionFn(l, r);
      };
    };
  };
};

var insert = function insert(dictIsSymbol) {
  return function (dictLacks) {
    return function (dictCons) {
      return function (l) {
        return function (a) {
          return function (r) {
            return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
          };
        };
      };
    };
  };
};

var get = function get(dictIsSymbol) {
  return function (dictCons) {
    return function (l) {
      return function (r) {
        return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
      };
    };
  };
};

var modify = function modify(dictIsSymbol) {
  return function (dictCons) {
    return function (dictCons1) {
      return function (l) {
        return function (f) {
          return function (r) {
            return set(dictIsSymbol)(dictCons)(dictCons1)(l)(f(get(dictIsSymbol)(dictCons)(l)(r)))(r);
          };
        };
      };
    };
  };
};

var equalFieldsNil = new EqualFields(function (v) {
  return function (v1) {
    return function (v2) {
      return true;
    };
  };
});

var equalFields = function equalFields(dict) {
  return dict.equalFields;
};

var equalFieldsCons = function equalFieldsCons(dictIsSymbol) {
  return function (dictEq) {
    return function (dictCons) {
      return function (dictEqualFields) {
        return new EqualFields(function (v) {
          return function (a) {
            return function (b) {
              var get$prime = get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value);
              var equalRest = equalFields(dictEqualFields)(Type_Row.RLProxy.value);
              return Data_Eq.eq(dictEq)(get$prime(a))(get$prime(b)) && equalRest(a)(b);
            };
          };
        });
      };
    };
  };
};

var equal = function equal(dictRowToList) {
  return function (dictEqualFields) {
    return function (a) {
      return function (b) {
        return equalFields(dictEqualFields)(Type_Row.RLProxy.value)(a)(b);
      };
    };
  };
};

var disjointUnion = function disjointUnion(dictUnion) {
  return function (dictNub) {
    return function (l) {
      return function (r) {
        return Record_Unsafe_Union.unsafeUnionFn(l, r);
      };
    };
  };
};

var $$delete = function $$delete(dictIsSymbol) {
  return function (dictLacks) {
    return function (dictCons) {
      return function (l) {
        return function (r) {
          return Record_Unsafe.unsafeDelete(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
        };
      };
    };
  };
};

var rename = function rename(dictIsSymbol) {
  return function (dictIsSymbol1) {
    return function (dictCons) {
      return function (dictLacks) {
        return function (dictCons1) {
          return function (dictLacks1) {
            return function (prev) {
              return function (next) {
                return function (record) {
                  return insert(dictIsSymbol1)(dictLacks1)(dictCons1)(next)(get(dictIsSymbol)(dictCons)(prev)(record))($$delete(dictIsSymbol)(dictLacks)(dictCons)(prev)(record));
                };
              };
            };
          };
        };
      };
    };
  };
};

module.exports = {
  get: get,
  set: set,
  modify: modify,
  insert: insert,
  "delete": $$delete,
  rename: rename,
  equal: equal,
  merge: merge,
  union: union,
  disjointUnion: disjointUnion,
  nub: nub,
  EqualFields: EqualFields,
  equalFields: equalFields,
  equalFieldsCons: equalFieldsCons,
  equalFieldsNil: equalFieldsNil
};