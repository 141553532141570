// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Global_Unsafe = require("../Global.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var Routing_Match = require("../Routing.Match/index.js");

var Routing_Parser = require("../Routing.Parser/index.js");

var matchWith = function matchWith(decoder) {
  return function (matcher) {
    return function ($0) {
      return Routing_Match.runMatch(matcher)(Routing_Parser.parse(decoder)($0));
    };
  };
};

var match = matchWith(Global_Unsafe.unsafeDecodeURIComponent);
module.exports = {
  match: match,
  matchWith: matchWith
};