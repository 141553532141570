// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var title = function title(dictUnion) {
  return React_Basic.element($foreign["_title"]);
};

var title_ = function title_(children) {
  return title()({
    children: children
  });
};

var text = function text(dictUnion) {
  return React_Basic.element($foreign["_text"]);
};

var text_ = function text_(children) {
  return text()({
    children: children
  });
};

var paragraph = function paragraph(dictUnion) {
  return React_Basic.element($foreign["_paragraph"]);
};

var paragraph_ = function paragraph_(children) {
  return paragraph()({
    children: children
  });
};

module.exports = {
  title: title,
  title_: title_,
  paragraph: paragraph,
  paragraph_: paragraph_,
  text: text,
  text_: text_
};