// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Bifoldable = require("../Data.Bifoldable/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Bitraversable = require("../Data.Bitraversable/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Left = function () {
  function Left(value0) {
    this.value0 = value0;
  }

  ;

  Left.create = function (value0) {
    return new Left(value0);
  };

  return Left;
}();

var Right = function () {
  function Right(value0) {
    this.value0 = value0;
  }

  ;

  Right.create = function (value0) {
    return new Right(value0);
  };

  return Right;
}();

var showEither = function showEither(dictShow) {
  return function (dictShow1) {
    return new Data_Show.Show(function (v) {
      if (v instanceof Left) {
        return "(Left " + (Data_Show.show(dictShow)(v.value0) + ")");
      }

      ;

      if (v instanceof Right) {
        return "(Right " + (Data_Show.show(dictShow1)(v.value0) + ")");
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 163, column 1 - line 163, column 61): " + [v.constructor.name]);
    });
  };
};

var note$prime = function note$prime(f) {
  return Data_Maybe["maybe'"](function ($198) {
    return Left.create(f($198));
  })(Right.create);
};

var note = function note(a) {
  return Data_Maybe.maybe(new Left(a))(Right.create);
};

var functorEither = new Data_Functor.Functor(function (f) {
  return function (m) {
    if (m instanceof Left) {
      return new Left(m.value0);
    }

    ;

    if (m instanceof Right) {
      return new Right(f(m.value0));
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 38, column 8 - line 38, column 52): " + [m.constructor.name]);
  };
});
var functorWithIndexEither = new Data_FunctorWithIndex.FunctorWithIndex(function () {
  return functorEither;
}, function (f) {
  return Data_Functor.map(functorEither)(f(Data_Unit.unit));
});
var invariantEither = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorEither));

var fromRight = function fromRight(dictPartial) {
  return function (v) {
    if (v instanceof Right) {
      return v.value0;
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 261, column 1 - line 261, column 52): " + [v.constructor.name]);
  };
};

var fromLeft = function fromLeft(dictPartial) {
  return function (v) {
    if (v instanceof Left) {
      return v.value0;
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 256, column 1 - line 256, column 51): " + [v.constructor.name]);
  };
};

var foldableEither = new Data_Foldable.Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      if (v instanceof Left) {
        return Data_Monoid.mempty(dictMonoid);
      }

      ;

      if (v instanceof Right) {
        return f(v.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [f.constructor.name, v.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Left) {
        return z;
      }

      ;

      if (v1 instanceof Right) {
        return v(z)(v1.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Left) {
        return z;
      }

      ;

      if (v1 instanceof Right) {
        return v(v1.value0)(z);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 187, column 1 - line 187, column 47): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
});
var foldableWithIndexEither = new Data_FoldableWithIndex.FoldableWithIndex(function () {
  return foldableEither;
}, function (dictMonoid) {
  return function (f) {
    return function (v) {
      if (v instanceof Left) {
        return Data_Monoid.mempty(dictMonoid);
      }

      ;

      if (v instanceof Right) {
        return f(Data_Unit.unit)(v.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 195, column 1 - line 195, column 70): " + [f.constructor.name, v.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Left) {
        return z;
      }

      ;

      if (v1 instanceof Right) {
        return v(Data_Unit.unit)(z)(v1.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 195, column 1 - line 195, column 70): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
}, function (v) {
  return function (z) {
    return function (v1) {
      if (v1 instanceof Left) {
        return z;
      }

      ;

      if (v1 instanceof Right) {
        return v(Data_Unit.unit)(v1.value0)(z);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 195, column 1 - line 195, column 70): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
    };
  };
});
var traversableEither = new Data_Traversable.Traversable(function () {
  return foldableEither;
}, function () {
  return functorEither;
}, function (dictApplicative) {
  return function (v) {
    if (v instanceof Left) {
      return Control_Applicative.pure(dictApplicative)(new Left(v.value0));
    }

    ;

    if (v instanceof Right) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Right.create)(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 211, column 1 - line 211, column 53): " + [v.constructor.name]);
  };
}, function (dictApplicative) {
  return function (v) {
    return function (v1) {
      if (v1 instanceof Left) {
        return Control_Applicative.pure(dictApplicative)(new Left(v1.value0));
      }

      ;

      if (v1 instanceof Right) {
        return Data_Functor.map(dictApplicative.Apply0().Functor0())(Right.create)(v(v1.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 211, column 1 - line 211, column 53): " + [v.constructor.name, v1.constructor.name]);
    };
  };
});
var traversableWithIndexEither = new Data_TraversableWithIndex.TraversableWithIndex(function () {
  return foldableWithIndexEither;
}, function () {
  return functorWithIndexEither;
}, function () {
  return traversableEither;
}, function (dictApplicative) {
  return function (v) {
    return function (v1) {
      if (v1 instanceof Left) {
        return Control_Applicative.pure(dictApplicative)(new Left(v1.value0));
      }

      ;

      if (v1 instanceof Right) {
        return Data_Functor.map(dictApplicative.Apply0().Functor0())(Right.create)(v(Data_Unit.unit)(v1.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 217, column 1 - line 217, column 76): " + [v.constructor.name, v1.constructor.name]);
    };
  };
});
var extendEither = new Control_Extend.Extend(function () {
  return functorEither;
}, function (v) {
  return function (v1) {
    if (v1 instanceof Left) {
      return new Left(v1.value0);
    }

    ;
    return new Right(v(v1));
  };
});

var eqEither = function eqEither(dictEq) {
  return function (dictEq1) {
    return new Data_Eq.Eq(function (x) {
      return function (y) {
        if (x instanceof Left && y instanceof Left) {
          return Data_Eq.eq(dictEq)(x.value0)(y.value0);
        }

        ;

        if (x instanceof Right && y instanceof Right) {
          return Data_Eq.eq(dictEq1)(x.value0)(y.value0);
        }

        ;
        return false;
      };
    });
  };
};

var ordEither = function ordEither(dictOrd) {
  return function (dictOrd1) {
    return new Data_Ord.Ord(function () {
      return eqEither(dictOrd.Eq0())(dictOrd1.Eq0());
    }, function (x) {
      return function (y) {
        if (x instanceof Left && y instanceof Left) {
          return Data_Ord.compare(dictOrd)(x.value0)(y.value0);
        }

        ;

        if (x instanceof Left) {
          return Data_Ordering.LT.value;
        }

        ;

        if (y instanceof Left) {
          return Data_Ordering.GT.value;
        }

        ;

        if (x instanceof Right && y instanceof Right) {
          return Data_Ord.compare(dictOrd1)(x.value0)(y.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 179, column 8 - line 179, column 64): " + [x.constructor.name, y.constructor.name]);
      };
    });
  };
};

var eq1Either = function eq1Either(dictEq) {
  return new Data_Eq.Eq1(function (dictEq1) {
    return Data_Eq.eq(eqEither(dictEq)(dictEq1));
  });
};

var ord1Either = function ord1Either(dictOrd) {
  return new Data_Ord.Ord1(function () {
    return eq1Either(dictOrd.Eq0());
  }, function (dictOrd1) {
    return Data_Ord.compare(ordEither(dictOrd)(dictOrd1));
  });
};

var either = function either(v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Left) {
        return v(v2.value0);
      }

      ;

      if (v2 instanceof Right) {
        return v1(v2.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 238, column 1 - line 238, column 64): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
    };
  };
};

var hush = either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
var isLeft = either(Data_Function["const"](true))(Data_Function["const"](false));
var isRight = either(Data_Function["const"](false))(Data_Function["const"](true));

var choose = function choose(dictAlt) {
  return function (a) {
    return function (b) {
      return Control_Alt.alt(dictAlt)(Data_Functor.map(dictAlt.Functor0())(Left.create)(a))(Data_Functor.map(dictAlt.Functor0())(Right.create)(b));
    };
  };
};

var boundedEither = function boundedEither(dictBounded) {
  return function (dictBounded1) {
    return new Data_Bounded.Bounded(function () {
      return ordEither(dictBounded.Ord0())(dictBounded1.Ord0());
    }, new Left(Data_Bounded.bottom(dictBounded)), new Right(Data_Bounded.top(dictBounded1)));
  };
};

var bifunctorEither = new Data_Bifunctor.Bifunctor(function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Left) {
        return new Left(v(v2.value0));
      }

      ;

      if (v2 instanceof Right) {
        return new Right(v1(v2.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 46, column 1 - line 46, column 45): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
    };
  };
});
var bifoldableEither = new Data_Bifoldable.Bifoldable(function (dictMonoid) {
  return function (v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return v(v2.value0);
        }

        ;

        if (v2 instanceof Right) {
          return v1(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 203, column 1 - line 203, column 47): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };
}, function (v) {
  return function (v1) {
    return function (z) {
      return function (v2) {
        if (v2 instanceof Left) {
          return v(z)(v2.value0);
        }

        ;

        if (v2 instanceof Right) {
          return v1(z)(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 203, column 1 - line 203, column 47): " + [v.constructor.name, v1.constructor.name, z.constructor.name, v2.constructor.name]);
      };
    };
  };
}, function (v) {
  return function (v1) {
    return function (z) {
      return function (v2) {
        if (v2 instanceof Left) {
          return v(v2.value0)(z);
        }

        ;

        if (v2 instanceof Right) {
          return v1(v2.value0)(z);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 203, column 1 - line 203, column 47): " + [v.constructor.name, v1.constructor.name, z.constructor.name, v2.constructor.name]);
      };
    };
  };
});
var bitraversableEither = new Data_Bitraversable.Bitraversable(function () {
  return bifoldableEither;
}, function () {
  return bifunctorEither;
}, function (dictApplicative) {
  return function (v) {
    if (v instanceof Left) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Left.create)(v.value0);
    }

    ;

    if (v instanceof Right) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Right.create)(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 221, column 1 - line 221, column 53): " + [v.constructor.name]);
  };
}, function (dictApplicative) {
  return function (v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Left.create)(v(v2.value0));
        }

        ;

        if (v2 instanceof Right) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Right.create)(v1(v2.value0));
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 221, column 1 - line 221, column 53): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };
});
var applyEither = new Control_Apply.Apply(function () {
  return functorEither;
}, function (v) {
  return function (v1) {
    if (v instanceof Left) {
      return new Left(v.value0);
    }

    ;

    if (v instanceof Right) {
      return Data_Functor.map(functorEither)(v.value0)(v1);
    }

    ;
    throw new Error("Failed pattern match at Data.Either (line 82, column 1 - line 82, column 41): " + [v.constructor.name, v1.constructor.name]);
  };
});
var bindEither = new Control_Bind.Bind(function () {
  return applyEither;
}, either(function (e) {
  return function (v) {
    return new Left(e);
  };
})(function (a) {
  return function (f) {
    return f(a);
  };
}));

var semigroupEither = function semigroupEither(dictSemigroup) {
  return new Data_Semigroup.Semigroup(function (x) {
    return function (y) {
      return Control_Apply.apply(applyEither)(Data_Functor.map(functorEither)(Data_Semigroup.append(dictSemigroup))(x))(y);
    };
  });
};

var applicativeEither = new Control_Applicative.Applicative(function () {
  return applyEither;
}, Right.create);
var monadEither = new Control_Monad.Monad(function () {
  return applicativeEither;
}, function () {
  return bindEither;
});
var altEither = new Control_Alt.Alt(function () {
  return functorEither;
}, function (v) {
  return function (v1) {
    if (v instanceof Left) {
      return v1;
    }

    ;
    return v;
  };
});
module.exports = {
  Left: Left,
  Right: Right,
  either: either,
  choose: choose,
  isLeft: isLeft,
  isRight: isRight,
  fromLeft: fromLeft,
  fromRight: fromRight,
  note: note,
  "note'": note$prime,
  hush: hush,
  functorEither: functorEither,
  functorWithIndexEither: functorWithIndexEither,
  invariantEither: invariantEither,
  bifunctorEither: bifunctorEither,
  applyEither: applyEither,
  applicativeEither: applicativeEither,
  altEither: altEither,
  bindEither: bindEither,
  monadEither: monadEither,
  extendEither: extendEither,
  showEither: showEither,
  eqEither: eqEither,
  eq1Either: eq1Either,
  ordEither: ordEither,
  ord1Either: ord1Either,
  boundedEither: boundedEither,
  foldableEither: foldableEither,
  foldableWithIndexEither: foldableWithIndexEither,
  bifoldableEither: bifoldableEither,
  traversableEither: traversableEither,
  traversableWithIndexEither: traversableWithIndexEither,
  bitraversableEither: bitraversableEither,
  semigroupEither: semigroupEither
};