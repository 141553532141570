// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");

var Control_Monad_State = require("../Control.Monad.State/index.js");

var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");

var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");

var Control_Monad_Trans_Class = require("../Control.Monad.Trans.Class/index.js");

var Control_MonadPlus = require("../Control.MonadPlus/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Prelude = require("../Prelude/index.js");

var Text_Parsing_Parser_Pos = require("../Text.Parsing.Parser.Pos/index.js");

var ParseState = function () {
  function ParseState(value0, value1, value2) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value2;
  }

  ;

  ParseState.create = function (value0) {
    return function (value1) {
      return function (value2) {
        return new ParseState(value0, value1, value2);
      };
    };
  };

  return ParseState;
}();

var ParseError = function () {
  function ParseError(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  ParseError.create = function (value0) {
    return function (value1) {
      return new ParseError(value0, value1);
    };
  };

  return ParseError;
}();

var ParserT = function ParserT(x) {
  return x;
};

var showParseError = new Data_Show.Show(function (v) {
  return "(ParseError " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Text_Parsing_Parser_Pos.showPosition)(v.value1) + ")")));
});

var parseErrorPosition = function parseErrorPosition(v) {
  return v.value1;
};

var parseErrorMessage = function parseErrorMessage(v) {
  return v.value0;
};

var newtypeParserT = new Data_Newtype.Newtype(function (n) {
  return n;
}, ParserT);

var runParserT = function runParserT(dictMonad) {
  return function (s) {
    return function (p) {
      var initialState = new ParseState(s, Text_Parsing_Parser_Pos.initialPos, false);
      return Control_Monad_State_Trans.evalStateT(dictMonad.Bind1().Apply0().Functor0())(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p)))(initialState);
    };
  };
};

var runParser = function runParser(s) {
  return function ($90) {
    return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(runParserT(Data_Identity.monadIdentity)(s)($90));
  };
};

var monadTransParserT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
  return function ($91) {
    return ParserT(Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Control_Monad_State_Trans.monadStateT(dictMonad))(Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)(dictMonad)($91)));
  };
});

var monadThrowParserT = function monadThrowParserT(dictMonad) {
  return Control_Monad_Except_Trans.monadThrowExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var monadStateParserT = function monadStateParserT(dictMonad) {
  return Control_Monad_Except_Trans.monadStateExceptT(Control_Monad_State_Trans.monadStateStateT(dictMonad));
};

var position = function position(dictMonad) {
  return Control_Monad_State_Class.gets(monadStateParserT(dictMonad))(function (v) {
    return v.value1;
  });
};

var monadRecParserT = function monadRecParserT(dictMonadRec) {
  return Control_Monad_Except_Trans.monadRecExceptT(Control_Monad_State_Trans.monadRecStateT(dictMonadRec));
};

var monadParserT = function monadParserT(dictMonad) {
  return Control_Monad_Except_Trans.monadExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var monadErrorParserT = function monadErrorParserT(dictMonad) {
  return Control_Monad_Except_Trans.monadErrorExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var mapParserT = function mapParserT($92) {
  return Data_Newtype.over(newtypeParserT)(newtypeParserT)(ParserT)(Control_Monad_Except_Trans.mapExceptT(Control_Monad_State_Trans.mapStateT($92)));
};

var lazyParserT = new Control_Lazy.Lazy(function (f) {
  return Control_Lazy.defer(Control_Monad_State_Trans.lazyStateT)(function ($93) {
    return Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(f($93)));
  });
});
var hoistParserT = mapParserT;

var functorParserT = function functorParserT(dictFunctor) {
  return Control_Monad_Except_Trans.functorExceptT(Control_Monad_State_Trans.functorStateT(dictFunctor));
};

var failWithPosition = function failWithPosition(dictMonad) {
  return function (message) {
    return function (pos) {
      return Control_Monad_Error_Class.throwError(monadThrowParserT(dictMonad))(new ParseError(message, pos));
    };
  };
};

var eqParseError = new Data_Eq.Eq(function (x) {
  return function (y) {
    return x.value0 === y.value0 && Data_Eq.eq(Text_Parsing_Parser_Pos.eqPosition)(x.value1)(y.value1);
  };
});
var ordParseError = new Data_Ord.Ord(function () {
  return eqParseError;
}, function (x) {
  return function (y) {
    var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);

    if (v instanceof Data_Ordering.LT) {
      return Data_Ordering.LT.value;
    }

    ;

    if (v instanceof Data_Ordering.GT) {
      return Data_Ordering.GT.value;
    }

    ;
    return Data_Ord.compare(Text_Parsing_Parser_Pos.ordPosition)(x.value1)(y.value1);
  };
});

var consume = function consume(dictMonad) {
  return Control_Monad_State_Class.modify_(monadStateParserT(dictMonad))(function (v) {
    return new ParseState(v.value0, v.value1, true);
  });
};

var bindParserT = function bindParserT(dictMonad) {
  return Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var fail = function fail(dictMonad) {
  return function (message) {
    return Control_Bind.bindFlipped(bindParserT(dictMonad))(failWithPosition(dictMonad)(message))(position(dictMonad));
  };
};

var applyParserT = function applyParserT(dictMonad) {
  return Control_Monad_Except_Trans.applyExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var semigroupParserT = function semigroupParserT(dictMonad) {
  return function (dictSemigroup) {
    return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyParserT(dictMonad))(Data_Semigroup.append(dictSemigroup)));
  };
};

var applicativeParserT = function applicativeParserT(dictMonad) {
  return Control_Monad_Except_Trans.applicativeExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
};

var monoidParserT = function monoidParserT(dictMonad) {
  return function (dictMonoid) {
    return new Data_Monoid.Monoid(function () {
      return semigroupParserT(dictMonad)(dictMonoid.Semigroup0());
    }, Control_Applicative.pure(applicativeParserT(dictMonad))(Data_Monoid.mempty(dictMonoid)));
  };
};

var altParserT = function altParserT(dictMonad) {
  return new Control_Alt.Alt(function () {
    return functorParserT(dictMonad.Bind1().Apply0().Functor0());
  }, function (p1) {
    return function (p2) {
      return ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
        return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p1)))(new ParseState(v.value0, v.value1, false)))(function (v1) {
          if (v1.value0 instanceof Data_Either.Left && !v1.value1.value2) {
            return Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p2)))(v);
          }

          ;
          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
        });
      })));
    };
  });
};

var plusParserT = function plusParserT(dictMonad) {
  return new Control_Plus.Plus(function () {
    return altParserT(dictMonad);
  }, fail(dictMonad)("No alternative"));
};

var alternativeParserT = function alternativeParserT(dictMonad) {
  return new Control_Alternative.Alternative(function () {
    return applicativeParserT(dictMonad);
  }, function () {
    return plusParserT(dictMonad);
  });
};

var monadZeroParserT = function monadZeroParserT(dictMonad) {
  return new Control_MonadZero.MonadZero(function () {
    return alternativeParserT(dictMonad);
  }, function () {
    return monadParserT(dictMonad);
  });
};

var monadPlusParserT = function monadPlusParserT(dictMonad) {
  return new Control_MonadPlus.MonadPlus(function () {
    return monadZeroParserT(dictMonad);
  });
};

module.exports = {
  ParseError: ParseError,
  parseErrorMessage: parseErrorMessage,
  parseErrorPosition: parseErrorPosition,
  ParseState: ParseState,
  ParserT: ParserT,
  runParser: runParser,
  runParserT: runParserT,
  hoistParserT: hoistParserT,
  mapParserT: mapParserT,
  consume: consume,
  position: position,
  fail: fail,
  failWithPosition: failWithPosition,
  showParseError: showParseError,
  eqParseError: eqParseError,
  ordParseError: ordParseError,
  newtypeParserT: newtypeParserT,
  lazyParserT: lazyParserT,
  semigroupParserT: semigroupParserT,
  monoidParserT: monoidParserT,
  functorParserT: functorParserT,
  applyParserT: applyParserT,
  applicativeParserT: applicativeParserT,
  bindParserT: bindParserT,
  monadParserT: monadParserT,
  monadRecParserT: monadRecParserT,
  monadStateParserT: monadStateParserT,
  monadThrowParserT: monadThrowParserT,
  monadErrorParserT: monadErrorParserT,
  altParserT: altParserT,
  plusParserT: plusParserT,
  alternativeParserT: alternativeParserT,
  monadZeroParserT: monadZeroParserT,
  monadPlusParserT: monadPlusParserT,
  monadTransParserT: monadTransParserT
};