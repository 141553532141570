// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_DOM_HTMLCollection = require("../Web.DOM.HTMLCollection/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");

var Web_HTML_HTMLTableCaptionElement = require("../Web.HTML.HTMLTableCaptionElement/index.js");

var Web_HTML_HTMLTableSectionElement = require("../Web.HTML.HTMLTableSectionElement/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;

var tHead = function tHead($0) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_tHead"]($0));
};

var tFoot = function tFoot($1) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_tFoot"]($1));
};

var setTHead = function setTHead($2) {
  return $foreign["_setTHead"](Data_Nullable.toNullable($2));
};

var setTFoot = function setTFoot($3) {
  return $foreign["_setTFoot"](Data_Nullable.toNullable($3));
};

var setCaption = function setCaption($4) {
  return $foreign["_setCaption"](Data_Nullable.toNullable($4));
};

var insertRow = $foreign["insertRow'"](-1 | 0);
var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLTableElement");

var caption = function caption($5) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_caption"]($5));
};

module.exports = {
  fromHTMLElement: fromHTMLElement,
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toHTMLElement: toHTMLElement,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  caption: caption,
  setCaption: setCaption,
  tHead: tHead,
  setTHead: setTHead,
  tFoot: tFoot,
  setTFoot: setTFoot,
  insertRow: insertRow,
  createCaption: $foreign.createCaption,
  deleteCaption: $foreign.deleteCaption,
  createTHead: $foreign.createTHead,
  deleteTHead: $foreign.deleteTHead,
  createTFoot: $foreign.createTFoot,
  deleteTFoot: $foreign.deleteTFoot,
  tBodies: $foreign.tBodies,
  createTBody: $foreign.createTBody,
  rows: $foreign.rows,
  "insertRow'": $foreign["insertRow'"],
  deleteRow: $foreign.deleteRow,
  border: $foreign.border,
  setBorder: $foreign.setBorder
};