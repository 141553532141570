// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Additive = function Additive(x) {
  return x;
};

var showAdditive = function showAdditive(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Additive " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semigroupAdditive = function semigroupAdditive(dictSemiring) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Data_Semiring.add(dictSemiring)(v)(v1);
    };
  });
};

var ordAdditive = function ordAdditive(dictOrd) {
  return dictOrd;
};

var monoidAdditive = function monoidAdditive(dictSemiring) {
  return new Data_Monoid.Monoid(function () {
    return semigroupAdditive(dictSemiring);
  }, Data_Semiring.zero(dictSemiring));
};

var functorAdditive = new Data_Functor.Functor(function (f) {
  return function (m) {
    return f(m);
  };
});

var eqAdditive = function eqAdditive(dictEq) {
  return dictEq;
};

var eq1Additive = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqAdditive(dictEq));
});
var ord1Additive = new Data_Ord.Ord1(function () {
  return eq1Additive;
}, function (dictOrd) {
  return Data_Ord.compare(ordAdditive(dictOrd));
});

var boundedAdditive = function boundedAdditive(dictBounded) {
  return dictBounded;
};

var applyAdditive = new Control_Apply.Apply(function () {
  return functorAdditive;
}, function (v) {
  return function (v1) {
    return v(v1);
  };
});
var bindAdditive = new Control_Bind.Bind(function () {
  return applyAdditive;
}, function (v) {
  return function (f) {
    return f(v);
  };
});
var applicativeAdditive = new Control_Applicative.Applicative(function () {
  return applyAdditive;
}, Additive);
var monadAdditive = new Control_Monad.Monad(function () {
  return applicativeAdditive;
}, function () {
  return bindAdditive;
});
module.exports = {
  Additive: Additive,
  eqAdditive: eqAdditive,
  eq1Additive: eq1Additive,
  ordAdditive: ordAdditive,
  ord1Additive: ord1Additive,
  boundedAdditive: boundedAdditive,
  showAdditive: showAdditive,
  functorAdditive: functorAdditive,
  applyAdditive: applyAdditive,
  applicativeAdditive: applicativeAdditive,
  bindAdditive: bindAdditive,
  monadAdditive: monadAdditive,
  semigroupAdditive: semigroupAdditive,
  monoidAdditive: monoidAdditive
};