// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Bifunctor = function Bifunctor(bimap) {
  this.bimap = bimap;
};

var bimap = function bimap(dict) {
  return dict.bimap;
};

var lmap = function lmap(dictBifunctor) {
  return function (f) {
    return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var rmap = function rmap(dictBifunctor) {
  return bimap(dictBifunctor)(Control_Category.identity(Control_Category.categoryFn));
};

module.exports = {
  bimap: bimap,
  Bifunctor: Bifunctor,
  lmap: lmap,
  rmap: rmap
};