// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_JSDate = require("../Data.JSDate/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");

var Web_HTML_HTMLMediaElement_CanPlayType = require("../Web.HTML.HTMLMediaElement.CanPlayType/index.js");

var Web_HTML_HTMLMediaElement_NetworkState = require("../Web.HTML.HTMLMediaElement.NetworkState/index.js");

var Web_HTML_HTMLMediaElement_ReadyState = require("../Web.HTML.HTMLMediaElement.ReadyState/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;

var readyState = function readyState(el) {
  return Data_Functor.map(Effect.functorEffect)(function ($0) {
    return Data_Maybe.fromMaybe(Web_HTML_HTMLMediaElement_ReadyState.HaveNothing.value)(Data_Enum.toEnum(Web_HTML_HTMLMediaElement_ReadyState.boundedEnumReadyState)($0));
  })(function () {
    return $foreign["_readyState"](el);
  });
};

var networkState = function networkState(el) {
  return Data_Functor.map(Effect.functorEffect)(function ($1) {
    return Data_Maybe.fromMaybe(Web_HTML_HTMLMediaElement_NetworkState.Empty.value)(Data_Enum.toEnum(Web_HTML_HTMLMediaElement_NetworkState.boundedEnumNetworkState)($1));
  })(function () {
    return $foreign["_networkState"](el);
  });
};

var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLMediaElement");

var canPlayType = function canPlayType(ty) {
  return function (el) {
    return Data_Functor.map(Effect.functorEffect)(function ($2) {
      return Data_Maybe.fromMaybe(Web_HTML_HTMLMediaElement_CanPlayType.Unspecified.value)(Web_HTML_HTMLMediaElement_CanPlayType.parse($2));
    })(function () {
      return $foreign["_canPlayType"](ty, el);
    });
  };
};

module.exports = {
  fromHTMLElement: fromHTMLElement,
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toHTMLElement: toHTMLElement,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  networkState: networkState,
  canPlayType: canPlayType,
  readyState: readyState,
  src: $foreign.src,
  setSrc: $foreign.setSrc,
  currentSrc: $foreign.currentSrc,
  crossOrigin: $foreign.crossOrigin,
  setCrossOrigin: $foreign.setCrossOrigin,
  preload: $foreign.preload,
  setPreload: $foreign.setPreload,
  load: $foreign.load,
  seeking: $foreign.seeking,
  currentTime: $foreign.currentTime,
  setCurrentTime: $foreign.setCurrentTime,
  duration: $foreign.duration,
  getStartDate: $foreign.getStartDate,
  paused: $foreign.paused,
  defaultPlaybackRate: $foreign.defaultPlaybackRate,
  setDefaultPlaybackRate: $foreign.setDefaultPlaybackRate,
  playbackRate: $foreign.playbackRate,
  setPlaybackRate: $foreign.setPlaybackRate,
  ended: $foreign.ended,
  autoplay: $foreign.autoplay,
  setAutoplay: $foreign.setAutoplay,
  loop: $foreign.loop,
  setLoop: $foreign.setLoop,
  play: $foreign.play,
  pause: $foreign.pause,
  mediaGroup: $foreign.mediaGroup,
  setMediaGroup: $foreign.setMediaGroup,
  controls: $foreign.controls,
  setControls: $foreign.setControls,
  volume: $foreign.volume,
  setVolume: $foreign.setVolume,
  muted: $foreign.muted,
  setMuted: $foreign.setMuted,
  defaultMuted: $foreign.defaultMuted,
  setDefaultMuted: $foreign.setDefaultMuted
};