// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_BooleanAlgebra = require("../Data.BooleanAlgebra/index.js");

var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var EuclideanRing = function EuclideanRing(CommutativeRing0, degree, div, mod) {
  this.CommutativeRing0 = CommutativeRing0;
  this.degree = degree;
  this.div = div;
  this.mod = mod;
};

var mod = function mod(dict) {
  return dict.mod;
};

var gcd = function gcd($copy_dictEq) {
  return function ($copy_dictEuclideanRing) {
    return function ($copy_a) {
      return function ($copy_b) {
        var $tco_var_dictEq = $copy_dictEq;
        var $tco_var_dictEuclideanRing = $copy_dictEuclideanRing;
        var $tco_var_a = $copy_a;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(dictEq, dictEuclideanRing, a, b) {
          var $7 = Data_Eq.eq(dictEq)(b)(Data_Semiring.zero(dictEuclideanRing.CommutativeRing0().Ring0().Semiring0()));

          if ($7) {
            $tco_done = true;
            return a;
          }

          ;
          $tco_var_dictEq = dictEq;
          $tco_var_dictEuclideanRing = dictEuclideanRing;
          $tco_var_a = b;
          $copy_b = mod(dictEuclideanRing)(a)(b);
          return;
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_dictEq, $tco_var_dictEuclideanRing, $tco_var_a, $copy_b);
        }

        ;
        return $tco_result;
      };
    };
  };
};

var euclideanRingNumber = new EuclideanRing(function () {
  return Data_CommutativeRing.commutativeRingNumber;
}, function (v) {
  return 1;
}, $foreign.numDiv, function (v) {
  return function (v1) {
    return 0.0;
  };
});
var euclideanRingInt = new EuclideanRing(function () {
  return Data_CommutativeRing.commutativeRingInt;
}, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);

var div = function div(dict) {
  return dict.div;
};

var lcm = function lcm(dictEq) {
  return function (dictEuclideanRing) {
    return function (a) {
      return function (b) {
        var $8 = Data_Eq.eq(dictEq)(a)(Data_Semiring.zero(dictEuclideanRing.CommutativeRing0().Ring0().Semiring0())) || Data_Eq.eq(dictEq)(b)(Data_Semiring.zero(dictEuclideanRing.CommutativeRing0().Ring0().Semiring0()));

        if ($8) {
          return Data_Semiring.zero(dictEuclideanRing.CommutativeRing0().Ring0().Semiring0());
        }

        ;
        return div(dictEuclideanRing)(Data_Semiring.mul(dictEuclideanRing.CommutativeRing0().Ring0().Semiring0())(a)(b))(gcd(dictEq)(dictEuclideanRing)(a)(b));
      };
    };
  };
};

var degree = function degree(dict) {
  return dict.degree;
};

module.exports = {
  EuclideanRing: EuclideanRing,
  degree: degree,
  div: div,
  mod: mod,
  gcd: gcd,
  lcm: lcm,
  euclideanRingInt: euclideanRingInt,
  euclideanRingNumber: euclideanRingNumber
};