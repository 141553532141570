// Generated by purs version 0.12.3
"use strict";

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var wbr = function wbr(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("wbr"));
};

var video = function video(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("video"));
};

var video_ = function video_(children) {
  return video()({
    children: children
  });
};

var $$var = function $$var(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("var"));
};

var var_ = function var_(children) {
  return $$var()({
    children: children
  });
};

var ul = function ul(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ul"));
};

var ul_ = function ul_(children) {
  return ul()({
    children: children
  });
};

var u = function u(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("u"));
};

var u_ = function u_(children) {
  return u()({
    children: children
  });
};

var track = function track(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("track"));
};

var tr = function tr(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tr"));
};

var tr_ = function tr_(children) {
  return tr()({
    children: children
  });
};

var title = function title(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("title"));
};

var title_ = function title_(children) {
  return title()({
    children: children
  });
};

var time = function time(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("time"));
};

var time_ = function time_(children) {
  return time()({
    children: children
  });
};

var thead = function thead(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("thead"));
};

var thead_ = function thead_(children) {
  return thead()({
    children: children
  });
};

var th = function th(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("th"));
};

var th_ = function th_(children) {
  return th()({
    children: children
  });
};

var tfoot = function tfoot(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tfoot"));
};

var tfoot_ = function tfoot_(children) {
  return tfoot()({
    children: children
  });
};

var textarea = function textarea(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("textarea"));
};

var textarea_ = function textarea_(children) {
  return textarea()({
    children: children
  });
};

var template = function template(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("template"));
};

var template_ = function template_(children) {
  return template()({
    children: children
  });
};

var td = function td(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("td"));
};

var td_ = function td_(children) {
  return td()({
    children: children
  });
};

var tbody = function tbody(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("tbody"));
};

var tbody_ = function tbody_(children) {
  return tbody()({
    children: children
  });
};

var table = function table(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("table"));
};

var table_ = function table_(children) {
  return table()({
    children: children
  });
};

var svg = function svg(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("svg"));
};

var svg_ = function svg_(children) {
  return svg()({
    children: children
  });
};

var sup = function sup(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sup"));
};

var sup_ = function sup_(children) {
  return sup()({
    children: children
  });
};

var summary = function summary(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("summary"));
};

var summary_ = function summary_(children) {
  return summary()({
    children: children
  });
};

var sub = function sub(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("sub"));
};

var sub_ = function sub_(children) {
  return sub()({
    children: children
  });
};

var style = function style(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("style"));
};

var style_ = function style_(children) {
  return style()({
    children: children
  });
};

var strong = function strong(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("strong"));
};

var strong_ = function strong_(children) {
  return strong()({
    children: children
  });
};

var span = function span(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("span"));
};

var span_ = function span_(children) {
  return span()({
    children: children
  });
};

var source = function source(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("source"));
};

var small = function small(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("small"));
};

var small_ = function small_(children) {
  return small()({
    children: children
  });
};

var slot = function slot(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("slot"));
};

var slot_ = function slot_(children) {
  return slot()({
    children: children
  });
};

var select = function select(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("select"));
};

var select_ = function select_(children) {
  return select()({
    children: children
  });
};

var section = function section(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("section"));
};

var section_ = function section_(children) {
  return section()({
    children: children
  });
};

var script = function script(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("script"));
};

var script_ = function script_(children) {
  return script()({
    children: children
  });
};

var samp = function samp(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("samp"));
};

var samp_ = function samp_(children) {
  return samp()({
    children: children
  });
};

var s = function s(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("s"));
};

var s_ = function s_(children) {
  return s()({
    children: children
  });
};

var ruby = function ruby(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ruby"));
};

var ruby_ = function ruby_(children) {
  return ruby()({
    children: children
  });
};

var rtc = function rtc(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rtc"));
};

var rtc_ = function rtc_(children) {
  return rtc()({
    children: children
  });
};

var rt = function rt(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rt"));
};

var rt_ = function rt_(children) {
  return rt()({
    children: children
  });
};

var rp = function rp(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rp"));
};

var rp_ = function rp_(children) {
  return rp()({
    children: children
  });
};

var rb = function rb(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("rb"));
};

var rb_ = function rb_(children) {
  return rb()({
    children: children
  });
};

var q = function q(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("q"));
};

var q_ = function q_(children) {
  return q()({
    children: children
  });
};

var progress = function progress(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("progress"));
};

var progress_ = function progress_(children) {
  return progress()({
    children: children
  });
};

var pre = function pre(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("pre"));
};

var pre_ = function pre_(children) {
  return pre()({
    children: children
  });
};

var picture = function picture(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("picture"));
};

var picture_ = function picture_(children) {
  return picture()({
    children: children
  });
};

var param = function param(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("param"));
};

var p = function p(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("p"));
};

var p_ = function p_(children) {
  return p()({
    children: children
  });
};

var output = function output(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("output"));
};

var output_ = function output_(children) {
  return output()({
    children: children
  });
};

var option = function option(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("option"));
};

var option_ = function option_(children) {
  return option()({
    children: children
  });
};

var optgroup = function optgroup(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("optgroup"));
};

var optgroup_ = function optgroup_(children) {
  return optgroup()({
    children: children
  });
};

var ol = function ol(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ol"));
};

var ol_ = function ol_(children) {
  return ol()({
    children: children
  });
};

var object = function object(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("object"));
};

var object_ = function object_(children) {
  return object()({
    children: children
  });
};

var noscript = function noscript(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("noscript"));
};

var noscript_ = function noscript_(children) {
  return noscript()({
    children: children
  });
};

var nav = function nav(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("nav"));
};

var nav_ = function nav_(children) {
  return nav()({
    children: children
  });
};

var meter = function meter(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meter"));
};

var meter_ = function meter_(children) {
  return meter()({
    children: children
  });
};

var meta = function meta(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("meta"));
};

var menuitem = function menuitem(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menuitem"));
};

var menuitem_ = function menuitem_(children) {
  return menuitem()({
    children: children
  });
};

var menu = function menu(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("menu"));
};

var menu_ = function menu_(children) {
  return menu()({
    children: children
  });
};

var math = function math(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("math"));
};

var math_ = function math_(children) {
  return math()({
    children: children
  });
};

var mark = function mark(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("mark"));
};

var mark_ = function mark_(children) {
  return mark()({
    children: children
  });
};

var map = function map(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("map"));
};

var map_ = function map_(children) {
  return map()({
    children: children
  });
};

var main = function main(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("main"));
};

var main_ = function main_(children) {
  return main()({
    children: children
  });
};

var link = function link(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("link"));
};

var li = function li(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("li"));
};

var li_ = function li_(children) {
  return li()({
    children: children
  });
};

var legend = function legend(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("legend"));
};

var legend_ = function legend_(children) {
  return legend()({
    children: children
  });
};

var label = function label(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("label"));
};

var label_ = function label_(children) {
  return label()({
    children: children
  });
};

var keygen = function keygen(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("keygen"));
};

var keygen_ = function keygen_(children) {
  return keygen()({
    children: children
  });
};

var kbd = function kbd(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("kbd"));
};

var kbd_ = function kbd_(children) {
  return kbd()({
    children: children
  });
};

var ins = function ins(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("ins"));
};

var ins_ = function ins_(children) {
  return ins()({
    children: children
  });
};

var input = function input(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("input"));
};

var img = function img(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("img"));
};

var iframe = function iframe(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("iframe"));
};

var iframe_ = function iframe_(children) {
  return iframe()({
    children: children
  });
};

var i = function i(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("i"));
};

var i_ = function i_(children) {
  return i()({
    children: children
  });
};

var html = function html(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("html"));
};

var html_ = function html_(children) {
  return html()({
    children: children
  });
};

var hr = function hr(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hr"));
};

var hgroup = function hgroup(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("hgroup"));
};

var hgroup_ = function hgroup_(children) {
  return hgroup()({
    children: children
  });
};

var header = function header(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("header"));
};

var header_ = function header_(children) {
  return header()({
    children: children
  });
};

var head = function head(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("head"));
};

var head_ = function head_(children) {
  return head()({
    children: children
  });
};

var h6 = function h6(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h6"));
};

var h6_ = function h6_(children) {
  return h6()({
    children: children
  });
};

var h5 = function h5(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h5"));
};

var h5_ = function h5_(children) {
  return h5()({
    children: children
  });
};

var h4 = function h4(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h4"));
};

var h4_ = function h4_(children) {
  return h4()({
    children: children
  });
};

var h3 = function h3(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h3"));
};

var h3_ = function h3_(children) {
  return h3()({
    children: children
  });
};

var h2 = function h2(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h2"));
};

var h2_ = function h2_(children) {
  return h2()({
    children: children
  });
};

var h1 = function h1(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("h1"));
};

var h1_ = function h1_(children) {
  return h1()({
    children: children
  });
};

var form = function form(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("form"));
};

var form_ = function form_(children) {
  return form()({
    children: children
  });
};

var footer = function footer(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("footer"));
};

var footer_ = function footer_(children) {
  return footer()({
    children: children
  });
};

var figure = function figure(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figure"));
};

var figure_ = function figure_(children) {
  return figure()({
    children: children
  });
};

var figcaption = function figcaption(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("figcaption"));
};

var figcaption_ = function figcaption_(children) {
  return figcaption()({
    children: children
  });
};

var fieldset = function fieldset(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("fieldset"));
};

var fieldset_ = function fieldset_(children) {
  return fieldset()({
    children: children
  });
};

var embed = function embed(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("embed"));
};

var em = function em(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("em"));
};

var em_ = function em_(children) {
  return em()({
    children: children
  });
};

var dt = function dt(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dt"));
};

var dt_ = function dt_(children) {
  return dt()({
    children: children
  });
};

var dl = function dl(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dl"));
};

var dl_ = function dl_(children) {
  return dl()({
    children: children
  });
};

var div = function div(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
};

var div_ = function div_(children) {
  return div()({
    children: children
  });
};

var dialog = function dialog(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dialog"));
};

var dialog_ = function dialog_(children) {
  return dialog()({
    children: children
  });
};

var dfn = function dfn(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dfn"));
};

var dfn_ = function dfn_(children) {
  return dfn()({
    children: children
  });
};

var details = function details(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("details"));
};

var details_ = function details_(children) {
  return details()({
    children: children
  });
};

var del = function del(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("del"));
};

var del_ = function del_(children) {
  return del()({
    children: children
  });
};

var dd = function dd(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("dd"));
};

var dd_ = function dd_(children) {
  return dd()({
    children: children
  });
};

var datalist = function datalist(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("datalist"));
};

var datalist_ = function datalist_(children) {
  return datalist()({
    children: children
  });
};

var data$prime = function data$prime(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("data"));
};

var data_ = function data_(children) {
  return data$prime()({
    children: children
  });
};

var colgroup = function colgroup(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("colgroup"));
};

var colgroup_ = function colgroup_(children) {
  return colgroup()({
    children: children
  });
};

var col = function col(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("col"));
};

var code = function code(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("code"));
};

var code_ = function code_(children) {
  return code()({
    children: children
  });
};

var cite = function cite(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("cite"));
};

var cite_ = function cite_(children) {
  return cite()({
    children: children
  });
};

var caption = function caption(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("caption"));
};

var caption_ = function caption_(children) {
  return caption()({
    children: children
  });
};

var canvas = function canvas(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("canvas"));
};

var canvas_ = function canvas_(children) {
  return canvas()({
    children: children
  });
};

var button = function button(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("button"));
};

var button_ = function button_(children) {
  return button()({
    children: children
  });
};

var br = function br(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("br"));
};

var body = function body(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("body"));
};

var body_ = function body_(children) {
  return body()({
    children: children
  });
};

var blockquote = function blockquote(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("blockquote"));
};

var blockquote_ = function blockquote_(children) {
  return blockquote()({
    children: children
  });
};

var bdo = function bdo(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdo"));
};

var bdo_ = function bdo_(children) {
  return bdo()({
    children: children
  });
};

var bdi = function bdi(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("bdi"));
};

var bdi_ = function bdi_(children) {
  return bdi()({
    children: children
  });
};

var base = function base(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("base"));
};

var b = function b(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("b"));
};

var b_ = function b_(children) {
  return b()({
    children: children
  });
};

var audio = function audio(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("audio"));
};

var audio_ = function audio_(children) {
  return audio()({
    children: children
  });
};

var aside = function aside(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("aside"));
};

var aside_ = function aside_(children) {
  return aside()({
    children: children
  });
};

var article = function article(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("article"));
};

var article_ = function article_(children) {
  return article()({
    children: children
  });
};

var area = function area(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("area"));
};

var address = function address(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("address"));
};

var address_ = function address_(children) {
  return address()({
    children: children
  });
};

var abbr = function abbr(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("abbr"));
};

var abbr_ = function abbr_(children) {
  return abbr()({
    children: children
  });
};

var a = function a(dictUnion) {
  return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("a"));
};

var a_ = function a_(children) {
  return a()({
    children: children
  });
};

module.exports = {
  a: a,
  a_: a_,
  abbr: abbr,
  abbr_: abbr_,
  address: address,
  address_: address_,
  area: area,
  article: article,
  article_: article_,
  aside: aside,
  aside_: aside_,
  audio: audio,
  audio_: audio_,
  b: b,
  b_: b_,
  base: base,
  bdi: bdi,
  bdi_: bdi_,
  bdo: bdo,
  bdo_: bdo_,
  blockquote: blockquote,
  blockquote_: blockquote_,
  body: body,
  body_: body_,
  br: br,
  button: button,
  button_: button_,
  canvas: canvas,
  canvas_: canvas_,
  caption: caption,
  caption_: caption_,
  cite: cite,
  cite_: cite_,
  code: code,
  code_: code_,
  col: col,
  colgroup: colgroup,
  colgroup_: colgroup_,
  "data'": data$prime,
  data_: data_,
  datalist: datalist,
  datalist_: datalist_,
  dd: dd,
  dd_: dd_,
  del: del,
  del_: del_,
  details: details,
  details_: details_,
  dfn: dfn,
  dfn_: dfn_,
  dialog: dialog,
  dialog_: dialog_,
  div: div,
  div_: div_,
  dl: dl,
  dl_: dl_,
  dt: dt,
  dt_: dt_,
  em: em,
  em_: em_,
  embed: embed,
  fieldset: fieldset,
  fieldset_: fieldset_,
  figcaption: figcaption,
  figcaption_: figcaption_,
  figure: figure,
  figure_: figure_,
  footer: footer,
  footer_: footer_,
  form: form,
  form_: form_,
  h1: h1,
  h1_: h1_,
  h2: h2,
  h2_: h2_,
  h3: h3,
  h3_: h3_,
  h4: h4,
  h4_: h4_,
  h5: h5,
  h5_: h5_,
  h6: h6,
  h6_: h6_,
  head: head,
  head_: head_,
  header: header,
  header_: header_,
  hgroup: hgroup,
  hgroup_: hgroup_,
  hr: hr,
  html: html,
  html_: html_,
  i: i,
  i_: i_,
  iframe: iframe,
  iframe_: iframe_,
  img: img,
  input: input,
  ins: ins,
  ins_: ins_,
  kbd: kbd,
  kbd_: kbd_,
  keygen: keygen,
  keygen_: keygen_,
  label: label,
  label_: label_,
  legend: legend,
  legend_: legend_,
  li: li,
  li_: li_,
  link: link,
  main: main,
  main_: main_,
  map: map,
  map_: map_,
  mark: mark,
  mark_: mark_,
  math: math,
  math_: math_,
  menu: menu,
  menu_: menu_,
  menuitem: menuitem,
  menuitem_: menuitem_,
  meta: meta,
  meter: meter,
  meter_: meter_,
  nav: nav,
  nav_: nav_,
  noscript: noscript,
  noscript_: noscript_,
  object: object,
  object_: object_,
  ol: ol,
  ol_: ol_,
  optgroup: optgroup,
  optgroup_: optgroup_,
  option: option,
  option_: option_,
  output: output,
  output_: output_,
  p: p,
  p_: p_,
  param: param,
  picture: picture,
  picture_: picture_,
  pre: pre,
  pre_: pre_,
  progress: progress,
  progress_: progress_,
  q: q,
  q_: q_,
  rb: rb,
  rb_: rb_,
  rp: rp,
  rp_: rp_,
  rt: rt,
  rt_: rt_,
  rtc: rtc,
  rtc_: rtc_,
  ruby: ruby,
  ruby_: ruby_,
  s: s,
  s_: s_,
  samp: samp,
  samp_: samp_,
  script: script,
  script_: script_,
  section: section,
  section_: section_,
  select: select,
  select_: select_,
  slot: slot,
  slot_: slot_,
  small: small,
  small_: small_,
  source: source,
  span: span,
  span_: span_,
  strong: strong,
  strong_: strong_,
  style: style,
  style_: style_,
  sub: sub,
  sub_: sub_,
  summary: summary,
  summary_: summary_,
  sup: sup,
  sup_: sup_,
  svg: svg,
  svg_: svg_,
  table: table,
  table_: table_,
  tbody: tbody,
  tbody_: tbody_,
  td: td,
  td_: td_,
  template: template,
  template_: template_,
  textarea: textarea,
  textarea_: textarea_,
  tfoot: tfoot,
  tfoot_: tfoot_,
  th: th,
  th_: th_,
  thead: thead,
  thead_: thead_,
  time: time,
  time_: time_,
  title: title,
  title_: title_,
  tr: tr,
  tr_: tr_,
  track: track,
  u: u,
  u_: u_,
  ul: ul,
  ul_: ul_,
  "var": $$var,
  var_: var_,
  video: video,
  video_: video_,
  wbr: wbr
};