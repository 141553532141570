// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var key = function key(i) {
  return function ($0) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_key"](i)($0));
  };
};

var getItem = function getItem(s) {
  return function ($1) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getItem"](s)($1));
  };
};

module.exports = {
  key: key,
  getItem: getItem,
  length: $foreign.length,
  setItem: $foreign.setItem,
  removeItem: $foreign.removeItem,
  clear: $foreign.clear
};