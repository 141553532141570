// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Monad = function Monad(Applicative0, Bind1) {
  this.Applicative0 = Applicative0;
  this.Bind1 = Bind1;
};

var whenM = function whenM(dictMonad) {
  return function (mb) {
    return function (m) {
      return Control_Bind.bind(dictMonad.Bind1())(mb)(function (v) {
        return Control_Applicative.when(dictMonad.Applicative0())(v)(m);
      });
    };
  };
};

var unlessM = function unlessM(dictMonad) {
  return function (mb) {
    return function (m) {
      return Control_Bind.bind(dictMonad.Bind1())(mb)(function (v) {
        return Control_Applicative.unless(dictMonad.Applicative0())(v)(m);
      });
    };
  };
};

var monadFn = new Monad(function () {
  return Control_Applicative.applicativeFn;
}, function () {
  return Control_Bind.bindFn;
});
var monadArray = new Monad(function () {
  return Control_Applicative.applicativeArray;
}, function () {
  return Control_Bind.bindArray;
});

var liftM1 = function liftM1(dictMonad) {
  return function (f) {
    return function (a) {
      return Control_Bind.bind(dictMonad.Bind1())(a)(function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(f(v));
      });
    };
  };
};

var ap = function ap(dictMonad) {
  return function (f) {
    return function (a) {
      return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
        return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
          return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
        });
      });
    };
  };
};

module.exports = {
  Monad: Monad,
  liftM1: liftM1,
  ap: ap,
  whenM: whenM,
  unlessM: unlessM,
  monadFn: monadFn,
  monadArray: monadArray
};