// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var previousElementSibling = function previousElementSibling($0) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_previousElementSibling"]($0));
};

var nextElementSibling = function nextElementSibling($1) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_nextElementSibling"]($1));
};

module.exports = {
  previousElementSibling: previousElementSibling,
  nextElementSibling: nextElementSibling
};