// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_String_Unsafe = require("../Data.String.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var uncons = function uncons(v) {
  if (v === "") {
    return Data_Maybe.Nothing.value;
  }

  ;
  return new Data_Maybe.Just({
    head: Data_String_Unsafe.charAt(0)(v),
    tail: $foreign.drop(1)(v)
  });
};

var toChar = $foreign["_toChar"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

var takeWhile = function takeWhile(p) {
  return function (s) {
    return $foreign.take($foreign.countPrefix(p)(s))(s);
  };
};

var takeRight = function takeRight(i) {
  return function (s) {
    return $foreign.drop($foreign.length(s) - i | 0)(s);
  };
};

var slice = function slice(b) {
  return function (e) {
    return function (s) {
      var l = $foreign.length(s);

      var norm = function norm(x) {
        if (x < 0) {
          return l + x | 0;
        }

        ;

        if (Data_Boolean.otherwise) {
          return x;
        }

        ;
        throw new Error("Failed pattern match at Data.String.CodeUnits (line 314, column 5 - line 315, column 27): " + [x.constructor.name]);
      };

      var e$prime = norm(e);
      var b$prime = norm(b);
      var $7 = b$prime < 0 || b$prime >= l || e$prime < 0 || e$prime > l || b$prime > e$prime;

      if ($7) {
        return Data_Maybe.Nothing.value;
      }

      ;
      return new Data_Maybe.Just($foreign["_slice"](b)(e)(s));
    };
  };
};

var lastIndexOf$prime = $foreign["_lastIndexOf'"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var lastIndexOf = $foreign["_lastIndexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

var stripSuffix = function stripSuffix(v) {
  return function (str) {
    var v1 = lastIndexOf(v)(str);

    if (v1 instanceof Data_Maybe.Just && v1.value0 === ($foreign.length(str) - $foreign.length(v) | 0)) {
      return Data_Maybe.Just.create($foreign.take(v1.value0)(str));
    }

    ;
    return Data_Maybe.Nothing.value;
  };
};

var indexOf$prime = $foreign["_indexOf'"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

var stripPrefix = function stripPrefix(v) {
  return function (str) {
    var v1 = indexOf(v)(str);

    if (v1 instanceof Data_Maybe.Just && v1.value0 === 0) {
      return Data_Maybe.Just.create($foreign.drop($foreign.length(v))(str));
    }

    ;
    return Data_Maybe.Nothing.value;
  };
};

var dropWhile = function dropWhile(p) {
  return function (s) {
    return $foreign.drop($foreign.countPrefix(p)(s))(s);
  };
};

var dropRight = function dropRight(i) {
  return function (s) {
    return $foreign.take($foreign.length(s) - i | 0)(s);
  };
};

var contains = function contains(pat) {
  return function ($16) {
    return Data_Maybe.isJust(indexOf(pat)($16));
  };
};

var charAt = $foreign["_charAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
module.exports = {
  stripPrefix: stripPrefix,
  stripSuffix: stripSuffix,
  contains: contains,
  charAt: charAt,
  toChar: toChar,
  uncons: uncons,
  indexOf: indexOf,
  "indexOf'": indexOf$prime,
  lastIndexOf: lastIndexOf,
  "lastIndexOf'": lastIndexOf$prime,
  takeRight: takeRight,
  takeWhile: takeWhile,
  dropRight: dropRight,
  dropWhile: dropWhile,
  slice: slice,
  singleton: $foreign.singleton,
  fromCharArray: $foreign.fromCharArray,
  toCharArray: $foreign.toCharArray,
  length: $foreign.length,
  countPrefix: $foreign.countPrefix,
  take: $foreign.take,
  drop: $foreign.drop,
  splitAt: $foreign.splitAt
};