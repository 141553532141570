// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_List = require("../Data.List/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Map = require("../Data.Map/index.js");

var Data_Map_Internal = require("../Data.Map.Internal/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Semiring_Free = require("../Data.Semiring.Free/index.js");

var Data_String_NonEmpty = require("../Data.String.NonEmpty/index.js");

var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Data_Validation_Semiring = require("../Data.Validation.Semiring/index.js");

var Global = require("../Global/index.js");

var Prelude = require("../Prelude/index.js");

var Routing_Match_Error = require("../Routing.Match.Error/index.js");

var Routing_Types = require("../Routing.Types/index.js");

var Match = function Match(x) {
  return x;
};

var str = function str(route) {
  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, route.value0.value0));
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedString.value));
};

var runMatch = function runMatch(v) {
  return function (route) {
    var foldErrors = function foldErrors(errs) {
      return Data_Either.Left.create(Data_Foldable.foldl(Data_List_Types.foldableList)(function (b) {
        return function (a) {
          return a + ("\x0a" + b);
        };
      })("")(Control_Bind.bind(Data_List_Types.bindList)(Data_Functor.map(Data_List_Types.functorList)(Data_List.reverse)(Data_Newtype.unwrap(Data_Semiring_Free.newtypeFree)(errs)))(function (v1) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Foldable.foldl(Data_List_Types.foldableList)(function (b) {
          return function (a) {
            return a + (";" + b);
          };
        })("")(Data_Functor.map(Data_List_Types.functorList)(Routing_Match_Error.showMatchError)(v1)));
      })));
    };

    return Data_Validation_Semiring.unV(foldErrors)(function ($90) {
      return Data_Either.Right.create(Data_Tuple.snd($90));
    })(v(route));
  };
};

var params = function params(route) {
  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Query) {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, route.value0.value0));
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedQuery.value));
};

var param = function param(key) {
  return function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Query) {
      var v = Data_Map_Internal.lookup(Data_Ord.ordString)(key)(route.value0.value0);

      if (v instanceof Data_Maybe.Nothing) {
        return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.KeyNotFound(key)));
      }

      ;

      if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(new Data_List_Types.Cons(Routing_Types.Query.create(Data_Map_Internal["delete"](Data_Ord.ordString)(key)(route.value0.value0)), route.value1), v.value0));
      }

      ;
      throw new Error("Failed pattern match at Routing.Match (line 121, column 7 - line 125, column 67): " + [v.constructor.name]);
    }

    ;
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedQuery.value));
  };
};

var optionalMatch = function optionalMatch(v) {
  return function (route) {
    return Data_Validation_Semiring.unV(Data_Function["const"](Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route, Data_Maybe.Nothing.value))))(function ($91) {
      return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(Data_Functor.map(Data_Tuple.functorTuple)(Data_Maybe.Just.create)($91));
    })(v(route));
  };
};

var num = function num(route) {
  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
    var res = Global.readFloat(route.value0.value0);
    var $38 = Global["isNaN"](res);

    if ($38) {
      return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedNumber.value));
    }

    ;
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, res));
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedNumber.value));
};

var newtypeMatch = new Data_Newtype.Newtype(function (v) {
  return v;
}, Match);
var matchFunctor = new Data_Functor.Functor(function (fn) {
  return function (v) {
    return Match(function (r) {
      return Data_Validation_Semiring.unV(Data_Validation_Semiring.invalid)(function (v1) {
        return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(v1.value0, fn(v1.value1)));
      })(v(r));
    });
  };
});
var matchApply = new Control_Apply.Apply(function () {
  return matchFunctor;
}, function (v) {
  return function (v1) {
    var processFnRes = function processFnRes(v2) {
      return Data_Validation_Semiring.unV(Data_Validation_Semiring.invalid)(function (v3) {
        return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(v3.value0, v2.value1(v3.value1)));
      })(v1(v2.value0));
    };

    var processFnErr = function processFnErr(r) {
      return function (err) {
        return Data_Validation_Semiring.invalid(Data_Semiring.mul(Data_Semiring_Free.semiringFree)(err)(Data_Validation_Semiring.unV(Control_Category.identity(Control_Category.categoryFn))(Data_Function["const"](Data_Semiring.one(Data_Semiring_Free.semiringFree)))(v1(r))));
      };
    };

    return Match(function (r) {
      return Data_Validation_Semiring.unV(processFnErr(r))(processFnRes)(v(r));
    });
  };
});
var matchApplicative = new Control_Applicative.Applicative(function () {
  return matchApply;
}, function (a) {
  return function (r) {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(r, a));
  };
});
var matchAlt = new Control_Alt.Alt(function () {
  return matchFunctor;
}, function (v) {
  return function (v1) {
    return Match(function (r) {
      return Control_Alt.alt(Data_Validation_Semiring.altV(Data_Semiring_Free.semiringFree))(v(r))(v1(r));
    });
  };
});
var matchPlus = new Control_Plus.Plus(function () {
  return matchAlt;
}, Match(Data_Function["const"](Data_Validation_Semiring.invalid(Data_Semiring.one(Data_Semiring_Free.semiringFree)))));
var matchAlternative = new Control_Alternative.Alternative(function () {
  return matchApplicative;
}, function () {
  return matchPlus;
});

var lit = function lit(input) {
  return function (route) {
    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path && route.value0.value0 === input) {
      return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, Data_Unit.unit));
    }

    ;

    if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
      return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.UnexpectedPath(input)));
    }

    ;
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedPathPart.value));
  };
};

var root = lit("");

var list = function list(v) {
  var go = function go(accum) {
    return function (r) {
      return Data_Validation_Semiring.unV(Data_Function["const"](Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(r, Data_List.reverse(accum)))))(function (v1) {
        return go(new Data_List_Types.Cons(v1.value1, accum))(v1.value0);
      })(v(r));
    };
  };

  return Match(go(Data_List_Types.Nil.value));
};

var $$int = function $$int(route) {
  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path) {
    var v = Data_Int.fromString(route.value0.value0);

    if (v instanceof Data_Maybe.Nothing) {
      return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedInt.value));
    }

    ;

    if (v instanceof Data_Maybe.Just) {
      return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, v.value0));
    }

    ;
    throw new Error("Failed pattern match at Routing.Match (line 88, column 29 - line 90, column 38): " + [v.constructor.name]);
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedInt.value));
};

var fail = function fail(msg) {
  return function (v) {
    return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.Fail(msg)));
  };
};

var end = function end(route) {
  if (route instanceof Data_List_Types.Nil) {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_Unit.unit));
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedEnd.value));
};

var eitherMatch = function eitherMatch(v) {
  var runEither = function runEither(v1) {
    if (v1.value1 instanceof Data_Either.Left) {
      return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(new Routing_Match_Error.Fail("Nested check failed")));
    }

    ;

    if (v1.value1 instanceof Data_Either.Right) {
      return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(v1.value0, v1.value1.value0));
    }

    ;
    throw new Error("Failed pattern match at Routing.Match (line 202, column 5 - line 204, column 39): " + [v1.value1.constructor.name]);
  };

  return Match(function (r) {
    return Data_Validation_Semiring.unV(Data_Validation_Semiring.invalid)(runEither)(v(r));
  });
};

var nonempty = eitherMatch(Data_Functor.map(matchFunctor)(function ($92) {
  return Data_Maybe.maybe(new Data_Either.Left("Empty string"))(Data_Either.Right.create)(Data_String_NonEmpty_Internal.fromString($92));
})(str));

var bool = function bool(route) {
  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path && route.value0.value0 === "true") {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, true));
  }

  ;

  if (route instanceof Data_List_Types.Cons && route.value0 instanceof Routing_Types.Path && route.value0.value0 === "false") {
    return Control_Applicative.pure(Data_Validation_Semiring.applicativeV(Data_Semiring_Free.semiringFree))(new Data_Tuple.Tuple(route.value1, false));
  }

  ;
  return Data_Validation_Semiring.invalid(Data_Semiring_Free.free(Routing_Match_Error.ExpectedBoolean.value));
};

module.exports = {
  Match: Match,
  root: root,
  lit: lit,
  num: num,
  "int": $$int,
  bool: bool,
  str: str,
  param: param,
  params: params,
  end: end,
  fail: fail,
  nonempty: nonempty,
  list: list,
  runMatch: runMatch,
  eitherMatch: eitherMatch,
  optionalMatch: optionalMatch,
  newtypeMatch: newtypeMatch,
  matchFunctor: matchFunctor,
  matchAlt: matchAlt,
  matchPlus: matchPlus,
  matchAlternative: matchAlternative,
  matchApply: matchApply,
  matchApplicative: matchApplicative
};