// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_State = require("../Control.Monad.State/index.js");

var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");

var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Prelude = require("../Prelude/index.js");

var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");

var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");

var Text_Parsing_Parser_Pos = require("../Text.Parsing.Parser.Pos/index.js");

var StringLike = function StringLike(drop, indexOf, $$null, uncons) {
  this.drop = drop;
  this.indexOf = indexOf;
  this["null"] = $$null;
  this.uncons = uncons;
};

var uncons = function uncons(dict) {
  return dict.uncons;
};

var stringLikeString = new StringLike(Data_String_CodePoints.drop, Data_String_CodePoints.indexOf, Data_String_Common["null"], Data_String_CodeUnits.uncons);

var $$null = function $$null(dict) {
  return dict["null"];
};

var indexOf = function indexOf(dict) {
  return dict.indexOf;
};

var eof = function eof(dictStringLike) {
  return function (dictMonad) {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
      return v.value0;
    }))(function (v) {
      return Control_Applicative.unless(Text_Parsing_Parser.applicativeParserT(dictMonad))($$null(dictStringLike)(v))(Text_Parsing_Parser.fail(dictMonad)("Expected EOF"));
    });
  };
};

var drop = function drop(dict) {
  return dict.drop;
};

var string = function string(dictStringLike) {
  return function (dictMonad) {
    return function (str) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
        return v.value0;
      }))(function (v) {
        var v1 = indexOf(dictStringLike)(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(str))(v);

        if (v1 instanceof Data_Maybe.Just && v1.value0 === 0) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
            return new Text_Parsing_Parser.ParseState(drop(dictStringLike)(Data_String_CodePoints.length(str))(v), Text_Parsing_Parser_Pos.updatePosString(v2.value1)(str), true);
          }))(function () {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(str);
          });
        }

        ;
        return Text_Parsing_Parser.fail(dictMonad)("Expected " + Data_Show.show(Data_Show.showString)(str));
      });
    };
  };
};

var anyChar = function anyChar(dictStringLike) {
  return function (dictMonad) {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
      return v.value0;
    }))(function (v) {
      var v1 = uncons(dictStringLike)(v);

      if (v1 instanceof Data_Maybe.Nothing) {
        return Text_Parsing_Parser.fail(dictMonad)("Unexpected EOF");
      }

      ;

      if (v1 instanceof Data_Maybe.Just) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
          return new Text_Parsing_Parser.ParseState(v1.value0.tail, Text_Parsing_Parser_Pos.updatePosString(v2.value1)(Data_String_CodeUnits.singleton(v1.value0.head)), true);
        }))(function () {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1.value0.head);
        });
      }

      ;
      throw new Error("Failed pattern match at Text.Parsing.Parser.String (line 56, column 3 - line 63, column 16): " + [v1.constructor.name]);
    });
  };
};

var satisfy = function satisfy(dictStringLike) {
  return function (dictMonad) {
    return function (f) {
      return Text_Parsing_Parser_Combinators.tryRethrow(dictMonad)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(anyChar(dictStringLike)(dictMonad))(function (v) {
        var $61 = f(v);

        if ($61) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v);
        }

        ;
        return Text_Parsing_Parser.fail(dictMonad)("Character '" + (Data_String_CodeUnits.singleton(v) + "' did not satisfy predicate"));
      }));
    };
  };
};

var $$char = function $$char(dictStringLike) {
  return function (dictMonad) {
    return function (c) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(function (v) {
        return v === c;
      }))(Data_Show.show(Data_Show.showChar)(c));
    };
  };
};

var noneOf = function noneOf(dictStringLike) {
  return function (dictMonad) {
    return function (ss) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.notElem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("none of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
    };
  };
};

var oneOf = function oneOf(dictStringLike) {
  return function (dictMonad) {
    return function (ss) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("one of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
    };
  };
};

var whiteSpace = function whiteSpace(dictStringLike) {
  return function (dictMonad) {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(satisfy(dictStringLike)(dictMonad)(function (c) {
      return c === "\x0a" || c === "\x0d" || c === " " || c === "\x09";
    })))(function (v) {
      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.fromCharArray(v));
    });
  };
};

var skipSpaces = function skipSpaces(dictStringLike) {
  return function (dictMonad) {
    return Data_Functor["void"](Text_Parsing_Parser.functorParserT(dictMonad.Bind1().Apply0().Functor0()))(whiteSpace(dictStringLike)(dictMonad));
  };
};

module.exports = {
  drop: drop,
  indexOf: indexOf,
  "null": $$null,
  uncons: uncons,
  StringLike: StringLike,
  eof: eof,
  string: string,
  anyChar: anyChar,
  satisfy: satisfy,
  "char": $$char,
  whiteSpace: whiteSpace,
  skipSpaces: skipSpaces,
  oneOf: oneOf,
  noneOf: noneOf,
  stringLikeString: stringLikeString
};