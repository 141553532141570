// Generated by purs version 0.12.3
"use strict";

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var DivisionRing = function DivisionRing(Ring0, recip) {
  this.Ring0 = Ring0;
  this.recip = recip;
};

var recip = function recip(dict) {
  return dict.recip;
};

var rightDiv = function rightDiv(dictDivisionRing) {
  return function (a) {
    return function (b) {
      return Data_Semiring.mul(dictDivisionRing.Ring0().Semiring0())(a)(recip(dictDivisionRing)(b));
    };
  };
};

var leftDiv = function leftDiv(dictDivisionRing) {
  return function (a) {
    return function (b) {
      return Data_Semiring.mul(dictDivisionRing.Ring0().Semiring0())(recip(dictDivisionRing)(b))(a);
    };
  };
};

var divisionringNumber = new DivisionRing(function () {
  return Data_Ring.ringNumber;
}, function (x) {
  return 1.0 / x;
});
module.exports = {
  DivisionRing: DivisionRing,
  recip: recip,
  leftDiv: leftDiv,
  rightDiv: rightDiv,
  divisionringNumber: divisionringNumber
};