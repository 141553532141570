// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_Event_Event = require("../Web.Event.Event/index.js");

var Web_Event_Internal_Types = require("../Web.Event.Internal.Types/index.js");

module.exports = {
  eventListener: $foreign.eventListener,
  addEventListener: $foreign.addEventListener,
  removeEventListener: $foreign.removeEventListener,
  dispatchEvent: $foreign.dispatchEvent
};