// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var sider = function sider(dictUnion) {
  return React_Basic.element($foreign["_sider"]);
};

var sider_ = function sider_(children) {
  return sider()({
    children: children
  });
};

var layout = function layout(dictUnion) {
  return React_Basic.element($foreign["_layout"]);
};

var layout_ = function layout_(children) {
  return layout()({
    children: children
  });
};

var header = function header(dictUnion) {
  return React_Basic.element($foreign["_header"]);
};

var header_ = function header_(children) {
  return header()({
    children: children
  });
};

var footer = function footer(dictUnion) {
  return React_Basic.element($foreign["_footer"]);
};

var footer_ = function footer_(children) {
  return footer()({
    children: children
  });
};

var content = function content(dictUnion) {
  return React_Basic.element($foreign["_content"]);
};

var content_ = function content_(children) {
  return content()({
    children: children
  });
};

module.exports = {
  layout: layout,
  layout_: layout_,
  header: header,
  header_: header_,
  sider: sider,
  sider_: sider_,
  content: content,
  content_: content_,
  footer: footer,
  footer_: footer_
};