// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_DOM_NodeList = require("../Web.DOM.NodeList/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");

var Web_HTML_HTMLFormElement = require("../Web.HTML.HTMLFormElement/index.js");

var Web_HTML_ValidityState = require("../Web.HTML.ValidityState/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLButtonElement");

var form = function form($0) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_form"]($0));
};

module.exports = {
  fromHTMLElement: fromHTMLElement,
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toHTMLElement: toHTMLElement,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  form: form,
  autofocus: $foreign.autofocus,
  setAutofocus: $foreign.setAutofocus,
  disabled: $foreign.disabled,
  setDisabled: $foreign.setDisabled,
  formAction: $foreign.formAction,
  setFormAction: $foreign.setFormAction,
  formEnctype: $foreign.formEnctype,
  setFormEnctype: $foreign.setFormEnctype,
  formMethod: $foreign.formMethod,
  setFormMethod: $foreign.setFormMethod,
  formNoValidate: $foreign.formNoValidate,
  setFormNoValidate: $foreign.setFormNoValidate,
  formTarget: $foreign.formTarget,
  setFormTarget: $foreign.setFormTarget,
  name: $foreign.name,
  setName: $foreign.setName,
  type_: $foreign.type_,
  setType: $foreign.setType,
  value: $foreign.value,
  setValue: $foreign.setValue,
  willValidate: $foreign.willValidate,
  validity: $foreign.validity,
  validationMessage: $foreign.validationMessage,
  checkValidity: $foreign.checkValidity,
  setCustomValidity: $foreign.setCustomValidity,
  labels: $foreign.labels
};