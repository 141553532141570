// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Prelude = require("../Prelude/index.js");

var Distributive = function Distributive(Functor0, collect, distribute) {
  this.Functor0 = Functor0;
  this.collect = collect;
  this.distribute = distribute;
};

var distributiveIdentity = new Distributive(function () {
  return Data_Identity.functorIdentity;
}, function (dictFunctor) {
  return function (f) {
    return function ($11) {
      return Data_Identity.Identity(Data_Functor.map(dictFunctor)(function ($12) {
        return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(f($12));
      })($11));
    };
  };
}, function (dictFunctor) {
  return function ($13) {
    return Data_Identity.Identity(Data_Functor.map(dictFunctor)(Data_Newtype.unwrap(Data_Identity.newtypeIdentity))($13));
  };
});

var distribute = function distribute(dict) {
  return dict.distribute;
};

var distributiveFunction = new Distributive(function () {
  return Data_Functor.functorFn;
}, function (dictFunctor) {
  return function (f) {
    return function ($14) {
      return distribute(distributiveFunction)(dictFunctor)(Data_Functor.map(dictFunctor)(f)($14));
    };
  };
}, function (dictFunctor) {
  return function (a) {
    return function (e) {
      return Data_Functor.map(dictFunctor)(function (v) {
        return v(e);
      })(a);
    };
  };
});

var cotraverse = function cotraverse(dictDistributive) {
  return function (dictFunctor) {
    return function (f) {
      return function ($15) {
        return Data_Functor.map(dictDistributive.Functor0())(f)(distribute(dictDistributive)(dictFunctor)($15));
      };
    };
  };
};

var collectDefault = function collectDefault(dictDistributive) {
  return function (dictFunctor) {
    return function (f) {
      return function ($16) {
        return distribute(dictDistributive)(dictFunctor)(Data_Functor.map(dictFunctor)(f)($16));
      };
    };
  };
};

var collect = function collect(dict) {
  return dict.collect;
};

var distributeDefault = function distributeDefault(dictDistributive) {
  return function (dictFunctor) {
    return collect(dictDistributive)(dictFunctor)(Control_Category.identity(Control_Category.categoryFn));
  };
};

module.exports = {
  collect: collect,
  distribute: distribute,
  Distributive: Distributive,
  distributeDefault: distributeDefault,
  collectDefault: collectDefault,
  cotraverse: cotraverse,
  distributiveIdentity: distributiveIdentity,
  distributiveFunction: distributiveFunction
};