// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var namedItem = function namedItem(id) {
  return function ($0) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_namedItem"](id)($0));
  };
};

var item = function item(i) {
  return function ($1) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_item"](i)($1));
  };
};

module.exports = {
  item: item,
  namedItem: namedItem,
  length: $foreign.length,
  toArray: $foreign.toArray
};