// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Biapplicative = require("../Control.Biapplicative/index.js");

var Control_Biapply = require("../Control.Biapply/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Clown = function Clown(x) {
  return x;
};

var showClown = function showClown(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Clown " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var ordClown = function ordClown(dictOrd) {
  return dictOrd;
};

var newtypeClown = new Data_Newtype.Newtype(function (n) {
  return n;
}, Clown);
var functorClown = new Data_Functor.Functor(function (v) {
  return function (v1) {
    return v1;
  };
});

var eqClown = function eqClown(dictEq) {
  return dictEq;
};

var bifunctorClown = function bifunctorClown(dictFunctor) {
  return new Data_Bifunctor.Bifunctor(function (f) {
    return function (v) {
      return function (v1) {
        return Data_Functor.map(dictFunctor)(f)(v1);
      };
    };
  });
};

var biapplyClown = function biapplyClown(dictApply) {
  return new Control_Biapply.Biapply(function () {
    return bifunctorClown(dictApply.Functor0());
  }, function (v) {
    return function (v1) {
      return Control_Apply.apply(dictApply)(v)(v1);
    };
  });
};

var biapplicativeClown = function biapplicativeClown(dictApplicative) {
  return new Control_Biapplicative.Biapplicative(function () {
    return biapplyClown(dictApplicative.Apply0());
  }, function (a) {
    return function (v) {
      return Control_Applicative.pure(dictApplicative)(a);
    };
  });
};

module.exports = {
  Clown: Clown,
  newtypeClown: newtypeClown,
  eqClown: eqClown,
  ordClown: ordClown,
  showClown: showClown,
  functorClown: functorClown,
  bifunctorClown: bifunctorClown,
  biapplyClown: biapplyClown,
  biapplicativeClown: biapplicativeClown
};