// Generated by purs version 0.12.3
"use strict";

var Data_Maybe = require("../Data.Maybe/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM_CharacterData = require("../Web.DOM.CharacterData/index.js");

var Web_DOM_ChildNode = require("../Web.DOM.ChildNode/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NonDocumentTypeChildNode = require("../Web.DOM.NonDocumentTypeChildNode/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var toCharacterData = Unsafe_Coerce.unsafeCoerce;
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("Comment");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("Comment");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Comment");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("Comment");
var fromCharacterData = Web_Internal_FFI.unsafeReadProtoTagged("Comment");
module.exports = {
  fromCharacterData: fromCharacterData,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromEventTarget: fromEventTarget,
  toCharacterData: toCharacterData,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toEventTarget: toEventTarget
};