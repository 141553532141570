// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var randomRange = function randomRange(min) {
  return function (max) {
    return function __do() {
      var v = $foreign.random();
      return v * (max - min) + min;
    };
  };
};

var randomInt = function randomInt(low) {
  return function (high) {
    return function __do() {
      var v = $foreign.random();
      var asNumber = (Data_Int.toNumber(high) - Data_Int.toNumber(low) + 1) * v + Data_Int.toNumber(low);
      return Data_Int.floor(asNumber);
    };
  };
};

var randomBool = Data_Functor.map(Effect.functorEffect)(function (v) {
  return v < 0.5;
})($foreign.random);
module.exports = {
  randomInt: randomInt,
  randomRange: randomRange,
  randomBool: randomBool,
  random: $foreign.random
};