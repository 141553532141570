// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Table = require("../Optimus.Client.Antd.Table/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var table = function table(v) {
  return function (cols) {
    return function (dataSource) {
      var mkColumn = function mkColumn(col) {
        return {
          title: col.title,
          key: col.title,
          render: function render(v1, r) {
            return col.showValue(r);
          }
        };
      };

      var columns = Data_Functor.mapFlipped(Data_Functor.functorArray)(cols)(mkColumn);
      return Optimus_Client_Antd_Table.table()({
        dataSource: dataSource,
        columns: columns,
        pagination: false,
        loading: v.loading,
        rowKey: function rowKey(v1, i) {
          return Data_Show.show(Data_Show.showInt)(i);
        },
        onRow: function onRow(v1, i) {
          return {
            onClick: React_Basic_Events.handler_(v.onRowClick(v1))
          };
        }
      });
    };
  };
};

var scspec = function scspec(dictInfoShow) {
  return function (title) {
    return function (getter) {
      return {
        title: title,
        showValue: function showValue($9) {
          return React_Basic_DOM.text(Optimus_Client_InfoShow.showInfo(dictInfoShow)(getter($9)));
        }
      };
    };
  };
};

var cspec = function cspec(title) {
  return function (toJSX) {
    return {
      title: title,
      showValue: toJSX
    };
  };
};

module.exports = {
  table: table,
  cspec: cspec,
  scspec: scspec
};