// Generated by purs version 0.12.3
"use strict";

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Date = require("../Data.Date/index.js");

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Formatter_DateTime = require("../Data.Formatter.DateTime/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Time = require("../Data.Time/index.js");

var Partial_Unsafe = require("../Partial.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var dateFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("YYYY MMM DD"));

var formatDate = function formatDate(d) {
  return Data_Formatter_DateTime.format(dateFormatter)(new Data_DateTime.DateTime(d, Data_Bounded.bottom(Data_Time.boundedTime)));
};

module.exports = {
  formatDate: formatDate
};