// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var subMenu = function subMenu(dictUnion) {
  return React_Basic.element($foreign["_subMenu"]);
};

var subMenu_ = function subMenu_(children) {
  return subMenu()({
    children: children
  });
};

var menuItemGroup = function menuItemGroup(dictUnion) {
  return React_Basic.element($foreign["_menuItemGroup"]);
};

var menuItemGroup_ = function menuItemGroup_(children) {
  return menuItemGroup()({
    children: children
  });
};

var menuItem = function menuItem(dictUnion) {
  return React_Basic.element($foreign["_menuItem"]);
};

var menuItem_ = function menuItem_(children) {
  return menuItem()({
    children: children
  });
};

var menuDivider = React_Basic.element($foreign["_menuDivider"])({});

var menu = function menu(dictUnion) {
  return React_Basic.element($foreign["_menu"]);
};

var menu_ = function menu_(children) {
  return menu()({
    children: children
  });
};

module.exports = {
  menu: menu,
  menu_: menu_,
  subMenu: subMenu,
  subMenu_: subMenu_,
  menuItemGroup: menuItemGroup,
  menuItemGroup_: menuItemGroup_,
  menuItem: menuItem,
  menuItem_: menuItem_,
  menuDivider: menuDivider
};