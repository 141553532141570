// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Partial = require("../Partial/index.js");

var unsafePartialBecause = function unsafePartialBecause(v) {
  return function (x) {
    return $foreign.unsafePartial(function (dictPartial) {
      return x(dictPartial);
    });
  };
};

var unsafeCrashWith = function unsafeCrashWith(msg) {
  return $foreign.unsafePartial(function (dictPartial) {
    return Partial.crashWith(dictPartial)(msg);
  });
};

module.exports = {
  unsafePartialBecause: unsafePartialBecause,
  unsafeCrashWith: unsafeCrashWith,
  unsafePartial: $foreign.unsafePartial
};