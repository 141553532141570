// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect = require("../Effect/index.js");

var Effect_Console = require("../Effect.Console/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Optimus_Client_App = require("../Optimus.Client.App/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var Routing_PushState = require("../Routing.PushState/index.js");

var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var main = function __do() {
  var v = Web_HTML.window();
  var v1 = Web_HTML_Window.document(v)();
  var v2 = Web_DOM_NonElementParentNode.getElementById("root")(Web_HTML_HTMLDocument.toNonElementParentNode(v1))();
  var v3 = Routing_PushState.makeInterface();

  (function () {
    if (v2 instanceof Data_Maybe.Nothing) {
      return Effect_Exception["throw"]("Container element not found")();
    }

    ;

    if (v2 instanceof Data_Maybe.Just) {
      return React_Basic_DOM.render(Optimus_Client_App.app({
        history: Optimus_Client_Route.navToHistory(v3)
      }))(v2.value0)();
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.Main (line 24, column 3 - line 26, column 59): " + [v2.constructor.name]);
  })();

  return Effect_Console.log("Optimus Started!")();
};

module.exports = {
  main: main
};