// Generated by purs version 0.12.3
"use strict";

var Data_Function = require("../Data.Function/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Record = require("../Record/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var ZRPC_Types_Millis = require("../ZRPC.Types.Millis/index.js");

var ShowDefaultVal = function ShowDefaultVal(showDefaultVal) {
  this.showDefaultVal = showDefaultVal;
};

var ShowDefaultVals = function ShowDefaultVals(showDefaultVals) {
  this.showDefaultVals = showDefaultVals;
};

var ShowDefaultValsRL = function ShowDefaultValsRL(showDefaultValsRL) {
  this.showDefaultValsRL = showDefaultValsRL;
};

var showDefaultValsRLNil = new ShowDefaultValsRL(function (v) {
  return function (v1) {
    return React_Basic.fragment([]);
  };
});

var showDefaultValsRL = function showDefaultValsRL(dict) {
  return dict.showDefaultValsRL;
};

var showDefaultValsRecord = function showDefaultValsRecord(dictRowToList) {
  return function (dictShowDefaultValsRL) {
    return new ShowDefaultVals(function (r) {
      return showDefaultValsRL(dictShowDefaultValsRL)(Type_Data_RowList.RLProxy.value)(r);
    });
  };
};

var showDefaultVals = function showDefaultVals(dict) {
  return dict.showDefaultVals;
};

var showDefaultVal = function showDefaultVal(dict) {
  return dict.showDefaultVal;
};

var showDefaultValsRLCons = function showDefaultValsRLCons(dictIsSymbol) {
  return function (dictShowDefaultValsRL) {
    return function (dictCons) {
      return function (dictShowDefaultVal) {
        return new ShowDefaultValsRL(function (v) {
          return function (r) {
            var others = showDefaultValsRL(dictShowDefaultValsRL)(Type_Data_RowList.RLProxy.value)(r);
            var value = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);
            return Data_Semigroup.append(React_Basic.semigroupJSX)(React_Basic_DOM_Generated.div_([React_Basic.fragment([React_Basic_DOM.text(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value) + ":")]), showDefaultVal(dictShowDefaultVal)(value)]))(others);
          };
        });
      };
    };
  };
};

var showDefaultRecord = function showDefaultRecord(dictShowDefaultVals) {
  return new ShowDefaultVal(showDefaultVals(dictShowDefaultVals));
};

var genShowDefault = function genShowDefault(dictShow) {
  return function (x) {
    return React_Basic_DOM_Generated.div_([React_Basic_DOM.text(Data_Show.show(dictShow)(x))]);
  };
};

var showDefaultBoolean = new ShowDefaultVal(genShowDefault(Data_Show.showBoolean));
var showDefaultInt = new ShowDefaultVal(genShowDefault(Data_Show.showInt));
var showDefaultRoundingMode = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showRoundingMode));
var showDefaultValAM = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showAuthorizationMode));
var showDefaultValLTVM = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showLinkTagVerificationMode));
var showDefaultValMillis = new ShowDefaultVal(genShowDefault(ZRPC_Types_Millis.showMillis));
var showDefaultValString = new ShowDefaultVal(genShowDefault(Data_Show.showString));
var showDefaultValTransactionAmountComputationMode = new ShowDefaultVal(genShowDefault(ZRPC_Clients_Mercanti.showTransactionAmountComputationMode));
module.exports = {
  ShowDefaultVal: ShowDefaultVal,
  ShowDefaultVals: ShowDefaultVals,
  ShowDefaultValsRL: ShowDefaultValsRL,
  showDefaultVal: showDefaultVal,
  showDefaultVals: showDefaultVals,
  showDefaultValsRL: showDefaultValsRL,
  showDefaultValString: showDefaultValString,
  showDefaultBoolean: showDefaultBoolean,
  showDefaultInt: showDefaultInt,
  showDefaultRecord: showDefaultRecord,
  showDefaultRoundingMode: showDefaultRoundingMode,
  showDefaultValMillis: showDefaultValMillis,
  showDefaultValLTVM: showDefaultValLTVM,
  showDefaultValAM: showDefaultValAM,
  showDefaultValTransactionAmountComputationMode: showDefaultValTransactionAmountComputationMode,
  showDefaultValsRecord: showDefaultValsRecord,
  showDefaultValsRLNil: showDefaultValsRLNil,
  showDefaultValsRLCons: showDefaultValsRLCons
};