// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");

var Foreign = require("../Foreign/index.js");

var Prelude = require("../Prelude/index.js");

var Index = function Index(errorAt, hasOwnProperty, hasProperty, index) {
  this.errorAt = errorAt;
  this.hasOwnProperty = hasOwnProperty;
  this.hasProperty = hasProperty;
  this.index = index;
};

var Indexable = function Indexable(ix) {
  this.ix = ix;
};

var unsafeReadProp = function unsafeReadProp(k) {
  return function (value) {
    return $foreign.unsafeReadPropImpl(Foreign.fail(new Foreign.TypeMismatch("object", Foreign.typeOf(value))), Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)), k, value);
  };
};

var readProp = unsafeReadProp;
var readIndex = unsafeReadProp;

var ix = function ix(dict) {
  return dict.ix;
};

var index = function index(dict) {
  return dict.index;
};

var indexableExceptT = new Indexable(function (dictIndex) {
  return function (f) {
    return function (i) {
      return Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Function.flip(index(dictIndex))(i))(f);
    };
  };
});
var indexableForeign = new Indexable(function (dictIndex) {
  return index(dictIndex);
});

var hasPropertyImpl = function hasPropertyImpl(v) {
  return function (value) {
    if (Foreign.isNull(value)) {
      return false;
    }

    ;

    if (Foreign.isUndefined(value)) {
      return false;
    }

    ;

    if (Foreign.typeOf(value) === "object" || Foreign.typeOf(value) === "function") {
      return $foreign.unsafeHasProperty(v, value);
    }

    ;
    return false;
  };
};

var hasProperty = function hasProperty(dict) {
  return dict.hasProperty;
};

var hasOwnPropertyImpl = function hasOwnPropertyImpl(v) {
  return function (value) {
    if (Foreign.isNull(value)) {
      return false;
    }

    ;

    if (Foreign.isUndefined(value)) {
      return false;
    }

    ;

    if (Foreign.typeOf(value) === "object" || Foreign.typeOf(value) === "function") {
      return $foreign.unsafeHasOwnProperty(v, value);
    }

    ;
    return false;
  };
};

var indexInt = new Index(Foreign.ErrorAtIndex.create, hasOwnPropertyImpl, hasPropertyImpl, Data_Function.flip(readIndex));
var indexString = new Index(Foreign.ErrorAtProperty.create, hasOwnPropertyImpl, hasPropertyImpl, Data_Function.flip(readProp));

var hasOwnProperty = function hasOwnProperty(dict) {
  return dict.hasOwnProperty;
};

var errorAt = function errorAt(dict) {
  return dict.errorAt;
};

module.exports = {
  Index: Index,
  Indexable: Indexable,
  readProp: readProp,
  readIndex: readIndex,
  ix: ix,
  index: index,
  hasProperty: hasProperty,
  hasOwnProperty: hasOwnProperty,
  errorAt: errorAt,
  indexString: indexString,
  indexInt: indexInt,
  indexableForeign: indexableForeign,
  indexableExceptT: indexableExceptT
};