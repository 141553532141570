// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_ApiClient_Users = require("../Optimus.Client.ApiClient.Users/index.js");

var Optimus_Client_AsyncPage = require("../Optimus.Client.AsyncPage/index.js");

var Optimus_Client_Components_Layout = require("../Optimus.Client.Components.Layout/index.js");

var Optimus_Client_Pages_Callback = require("../Optimus.Client.Pages.Callback/index.js");

var Optimus_Client_Pages_Home = require("../Optimus.Client.Pages.Home/index.js");

var Optimus_Client_Pages_Index = require("../Optimus.Client.Pages.Index/index.js");

var Optimus_Client_Pages_Login = require("../Optimus.Client.Pages.Login/index.js");

var Optimus_Client_Pages_Settings = require("../Optimus.Client.Pages.Settings/index.js");

var Optimus_Client_Pages_Users_ChangeEmail = require("../Optimus.Client.Pages.Users.ChangeEmail/index.js");

var Optimus_Client_Pages_Users_Overview = require("../Optimus.Client.Pages.Users.Overview/index.js");

var Optimus_Client_Pages_Users_Search = require("../Optimus.Client.Pages.Users.Search/index.js");

var Optimus_Client_Pages_Users_Tags = require("../Optimus.Client.Pages.Users.Tags/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var render = function render(self) {
  var handleUsersRoute = function handleUsersRoute(u) {
    return function (siloId) {
      if (u instanceof Optimus_Client_Route.UsersSearch) {
        return Optimus_Client_Pages_Users_Search.searchPage({
          query: u.value0.query,
          siloId: siloId,
          history: self.props.history
        });
      }

      ;

      if (u instanceof Optimus_Client_Route.UsersOverview) {
        return React_Basic.keyed("UsersOverview")(Optimus_Client_AsyncPage.asyncPage({
          fetchData: Optimus_Client_ApiClient_Users.getUserById(siloId)(u.value0),
          pageView: Optimus_Client_Pages_Users_Overview.overviewPage,
          history: self.props.history,
          siloId: siloId
        }));
      }

      ;

      if (u instanceof Optimus_Client_Route.UsersChangeEmail) {
        return Optimus_Client_Pages_Users_ChangeEmail.changeEmailPage({
          siloId: siloId,
          zapperId: u.value0
        });
      }

      ;

      if (u instanceof Optimus_Client_Route.UsersTags) {
        return React_Basic.keyed("UserTags")(Optimus_Client_AsyncPage.asyncPage({
          fetchData: Optimus_Client_ApiClient_Users.getUserTags(siloId)(u.value0),
          pageView: Optimus_Client_Pages_Users_Tags.tagsPage,
          history: self.props.history,
          siloId: siloId
        }));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.App (line 77, column 33 - line 93, column 12): " + [u.constructor.name]);
    };
  };

  var handleSettingsRoute = function handleSettingsRoute(s) {
    return function (siloId) {
      if (s instanceof Optimus_Client_Route.HoldingSettings) {
        return Optimus_Client_Pages_Settings.holdingSettingsPage(s.value0)(siloId)(self.props.history);
      }

      ;

      if (s instanceof Optimus_Client_Route.MerchantSettings) {
        return Optimus_Client_Pages_Settings.merchantSettingsPage(s.value0)(siloId)(self.props.history);
      }

      ;

      if (s instanceof Optimus_Client_Route.BranchSettings) {
        return Optimus_Client_Pages_Settings.branchSettingsPage(s.value0)(siloId)(self.props.history);
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.App (line 95, column 36 - line 98, column 84): " + [s.constructor.name]);
    };
  };

  var handleModuleRoute = function handleModuleRoute(m) {
    return function (siloId) {
      if (m instanceof Optimus_Client_Route.Users) {
        return handleUsersRoute(m.value0)(siloId);
      }

      ;

      if (m instanceof Optimus_Client_Route.Settings) {
        return handleSettingsRoute(m.value0)(siloId);
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.App (line 73, column 34 - line 75, column 49): " + [m.constructor.name]);
    };
  };

  var renderContent = function renderContent(r) {
    return function (siloId) {
      if (r instanceof Optimus_Client_Route.SiloIndex) {
        return Optimus_Client_Pages_Home.homePage({});
      }

      ;

      if (r instanceof Optimus_Client_Route.SiloRoute) {
        return handleModuleRoute(r.value0)(siloId);
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.App (line 69, column 30 - line 71, column 48): " + [r.constructor.name]);
    };
  };

  if (self.state.route instanceof Data_Maybe.Nothing) {
    return React_Basic_DOM.text("This page does not exist.");
  }

  ;

  if (self.state.route instanceof Data_Maybe.Just) {
    if (self.state.route.value0 instanceof Optimus_Client_Route.Index) {
      return Optimus_Client_Pages_Index.indexPage({
        history: self.props.history
      });
    }

    ;

    if (self.state.route.value0 instanceof Optimus_Client_Route.Login) {
      return Optimus_Client_Pages_Login.loginPage({});
    }

    ;

    if (self.state.route.value0 instanceof Optimus_Client_Route.Callback) {
      return Optimus_Client_Pages_Callback.callbackPage({
        history: self.props.history
      });
    }

    ;

    if (self.state.route.value0 instanceof Optimus_Client_Route.Route) {
      return Optimus_Client_Components_Layout.layout({
        route: self.state.route.value0,
        history: self.props.history,
        content: renderContent(self.state.route.value0.value1)(self.state.route.value0.value0)
      });
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.App (line 58, column 17 - line 67, column 10): " + [self.state.route.value0.constructor.name]);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.App (line 56, column 15 - line 67, column 10): " + [self.state.route.constructor.name]);
};

var initialState = {
  route: Data_Maybe.Nothing.value
};

var didMount = function didMount(self) {
  var setRoute = function setRoute(v) {
    return function (r) {
      return self.setState(function (v1) {
        return {
          route: new Data_Maybe.Just(r)
        };
      });
    };
  };

  return function __do() {
    var v = self.props.history.listener(setRoute)();
    return Data_Unit.unit;
  };
};

var component = React_Basic.createComponent("App");
var app = React_Basic.make()(component)({
  initialState: initialState,
  didMount: didMount,
  render: render
});
module.exports = {
  app: app
};