// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var unmount = Effect_Uncurried.runEffectFn1($foreign.unmountComponentAtNode_);
var text = Unsafe_Coerce.unsafeCoerce;
var render$prime = Effect_Uncurried.runEffectFn3($foreign.render_);

var render = function render(jsx) {
  return function (node) {
    return render$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
  };
};

var hydrate$prime = Effect_Uncurried.runEffectFn3($foreign.hydrate_);

var hydrate = function hydrate(jsx) {
  return function (node) {
    return hydrate$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
  };
};

var findDOMNode = function findDOMNode(instance_) {
  return Effect_Exception["try"](function __do() {
    var v = $foreign.findDOMNode_(instance_);
    var v1 = Data_Nullable.toMaybe(v);

    if (v1 instanceof Data_Maybe.Nothing) {
      return Effect_Exception["throw"]("Node not found.")();
    }

    ;

    if (v1 instanceof Data_Maybe.Just) {
      return v1.value0;
    }

    ;
    throw new Error("Failed pattern match at React.Basic.DOM (line 99, column 3 - line 101, column 22): " + [v1.constructor.name]);
  });
};

var css = Unsafe_Coerce.unsafeCoerce;
var createPortal = Data_Function_Uncurried.runFn2($foreign.createPortal_);
module.exports = {
  render: render,
  "render'": render$prime,
  hydrate: hydrate,
  "hydrate'": hydrate$prime,
  unmount: unmount,
  findDOMNode: findDOMNode,
  createPortal: createPortal,
  text: text,
  css: css,
  mergeStyles: $foreign.mergeStyles
};