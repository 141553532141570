// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  and: $foreign.and,
  or: $foreign.or,
  xor: $foreign.xor,
  shl: $foreign.shl,
  shr: $foreign.shr,
  zshr: $foreign.zshr,
  complement: $foreign.complement
};