// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var $$try = function $$try(action) {
  return $foreign.catchException(function ($0) {
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Either.Left.create($0));
  })(Data_Functor.map(Effect.functorEffect)(Data_Either.Right.create)(action));
};

var $$throw = function $$throw($1) {
  return $foreign.throwException($foreign.error($1));
};

var stack = $foreign.stackImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var showError = new Data_Show.Show($foreign.showErrorImpl);
module.exports = {
  stack: stack,
  "throw": $$throw,
  "try": $$try,
  showError: showError,
  error: $foreign.error,
  message: $foreign.message,
  name: $foreign.name,
  throwException: $foreign.throwException,
  catchException: $foreign.catchException
};