// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_DOM_Document = require("../Web.DOM.Document/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");

var Web_HTML_HTMLFormElement = require("../Web.HTML.HTMLFormElement/index.js");

var Web_HTML_ValidityState = require("../Web.HTML.ValidityState/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toHTMLElement = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;
var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromHTMLElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromElement = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("HTMLObjectElement");

var form = function form($0) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_form"]($0));
};

var contentDocument = function contentDocument($1) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_contentDocument"]($1));
};

module.exports = {
  fromHTMLElement: fromHTMLElement,
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toHTMLElement: toHTMLElement,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  form: form,
  contentDocument: contentDocument,
  data_: $foreign.data_,
  setData: $foreign.setData,
  type_: $foreign.type_,
  setType: $foreign.setType,
  typeMustMatch: $foreign.typeMustMatch,
  name: $foreign.name,
  setName: $foreign.setName,
  useMap: $foreign.useMap,
  setUseMap: $foreign.setUseMap,
  width: $foreign.width,
  setWidth: $foreign.setWidth,
  height: $foreign.height,
  setHeight: $foreign.setHeight,
  willValidate: $foreign.willValidate,
  validity: $foreign.validity,
  validationMessage: $foreign.validationMessage,
  checkValidity: $foreign.checkValidity,
  setCustomValidity: $foreign.setCustomValidity
};