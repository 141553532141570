// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  nan: $foreign.nan,
  "isNaN": $foreign["isNaN"],
  infinity: $foreign.infinity,
  "isFinite": $foreign["isFinite"],
  readInt: $foreign.readInt,
  readFloat: $foreign.readFloat
};