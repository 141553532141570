// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Optimus_Shared_Bridge = require("../Optimus.Shared.Bridge/index.js");

var Prelude = require("../Prelude/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var SaveSettingsFailed = function () {
  function SaveSettingsFailed() {}

  ;
  SaveSettingsFailed.value = new SaveSettingsFailed();
  return SaveSettingsFailed;
}();

var Holding = function () {
  function Holding() {}

  ;
  Holding.value = new Holding();
  return Holding;
}();

var Merchant = function () {
  function Merchant() {}

  ;
  Merchant.value = new Merchant();
  return Merchant;
}();

var Branch = function () {
  function Branch() {}

  ;
  Branch.value = new Branch();
  return Branch;
}();

var genericSettingsErr = new Data_Generic_Rep.Generic(function (x) {
  return Data_Generic_Rep.NoArguments.value;
}, function (x) {
  return SaveSettingsFailed.value;
});
var showSettingsErr = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericSettingsErr)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SaveSettingsFailed";
  })))(x);
});
var genericEntity = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Holding) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Merchant) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof Branch) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Settings (line 15, column 8 - line 15, column 50): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Holding.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return Merchant.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return Branch.value;
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Settings (line 15, column 8 - line 15, column 50): " + [x.constructor.name]);
});
var showEntity = new Data_Show.Show(function (x) {
  return Data_String_Common.toLower(Data_Generic_Rep_Show.genericShow(genericEntity)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Holding";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Merchant";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Branch";
  })))))(x));
});
var consPrefixSettingsErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "";
});
var zrpcReadSettingsErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixSettingsErr)(genericSettingsErr)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "SaveSettingsFailed";
}))));
var zrpcWriteSettingsErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixSettingsErr)(genericSettingsErr)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "SaveSettingsFailed";
}))));
module.exports = {
  Holding: Holding,
  Merchant: Merchant,
  Branch: Branch,
  SaveSettingsFailed: SaveSettingsFailed,
  genericEntity: genericEntity,
  showEntity: showEntity,
  genericSettingsErr: genericSettingsErr,
  consPrefixSettingsErr: consPrefixSettingsErr,
  zrpcWriteSettingsErr: zrpcWriteSettingsErr,
  zrpcReadSettingsErr: zrpcReadSettingsErr,
  showSettingsErr: showSettingsErr
};