// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect_Class = require("../Effect.Class/index.js");

var Effect_Console = require("../Effect.Console/index.js");

var warnShow = function warnShow(dictMonadEffect) {
  return function (dictShow) {
    return function ($14) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.warnShow(dictShow)($14));
    };
  };
};

var warn = function warn(dictMonadEffect) {
  return function ($15) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.warn($15));
  };
};

var timeEnd = function timeEnd(dictMonadEffect) {
  return function ($16) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.timeEnd($16));
  };
};

var time = function time(dictMonadEffect) {
  return function ($17) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.time($17));
  };
};

var logShow = function logShow(dictMonadEffect) {
  return function (dictShow) {
    return function ($18) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.logShow(dictShow)($18));
    };
  };
};

var log = function log(dictMonadEffect) {
  return function ($19) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.log($19));
  };
};

var infoShow = function infoShow(dictMonadEffect) {
  return function (dictShow) {
    return function ($20) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.infoShow(dictShow)($20));
    };
  };
};

var info = function info(dictMonadEffect) {
  return function ($21) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.info($21));
  };
};

var errorShow = function errorShow(dictMonadEffect) {
  return function (dictShow) {
    return function ($22) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.errorShow(dictShow)($22));
    };
  };
};

var error = function error(dictMonadEffect) {
  return function ($23) {
    return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.error($23));
  };
};

module.exports = {
  log: log,
  logShow: logShow,
  warn: warn,
  warnShow: warnShow,
  error: error,
  errorShow: errorShow,
  info: info,
  infoShow: infoShow,
  time: time,
  timeEnd: timeEnd
};