// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Prelude = require("../Prelude/index.js");

var MonadTell = function MonadTell(Monad0, tell) {
  this.Monad0 = Monad0;
  this.tell = tell;
};

var MonadWriter = function MonadWriter(MonadTell0, listen, pass) {
  this.MonadTell0 = MonadTell0;
  this.listen = listen;
  this.pass = pass;
};

var tell = function tell(dict) {
  return dict.tell;
};

var pass = function pass(dict) {
  return dict.pass;
};

var listen = function listen(dict) {
  return dict.listen;
};

var listens = function listens(dictMonadWriter) {
  return function (f) {
    return function (m) {
      return Control_Bind.bind(dictMonadWriter.MonadTell0().Monad0().Bind1())(listen(dictMonadWriter)(m))(function (v) {
        return Control_Applicative.pure(dictMonadWriter.MonadTell0().Monad0().Applicative0())(new Data_Tuple.Tuple(v.value0, f(v.value1)));
      });
    };
  };
};

var censor = function censor(dictMonadWriter) {
  return function (f) {
    return function (m) {
      return pass(dictMonadWriter)(Control_Bind.bind(dictMonadWriter.MonadTell0().Monad0().Bind1())(m)(function (v) {
        return Control_Applicative.pure(dictMonadWriter.MonadTell0().Monad0().Applicative0())(new Data_Tuple.Tuple(v, f));
      }));
    };
  };
};

module.exports = {
  listen: listen,
  pass: pass,
  tell: tell,
  MonadTell: MonadTell,
  MonadWriter: MonadWriter,
  listens: listens,
  censor: censor
};