// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Prelude = require("../Prelude/index.js");

var $$null = function $$null(s) {
  return s === "";
};

var localeCompare = $foreign["_localeCompare"](Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value);
module.exports = {
  "null": $$null,
  localeCompare: localeCompare,
  replace: $foreign.replace,
  replaceAll: $foreign.replaceAll,
  split: $foreign.split,
  toLower: $foreign.toLower,
  toUpper: $foreign.toUpper,
  trim: $foreign.trim,
  joinWith: $foreign.joinWith
};