// Generated by purs version 0.12.3
"use strict";

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Optimus_Client_Component_Content = require("../Optimus.Client.Component.Content/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var component = React_Basic.createComponent("Home");

var homePage = function () {
  var render = function render(self) {
    return Optimus_Client_Component_Content.content({
      title: "Home",
      children: Data_Monoid.mempty(Data_Monoid.monoidArray)
    });
  };

  return React_Basic.make()(component)({
    initialState: Data_Unit.unit,
    render: render
  });
}();

module.exports = {
  homePage: homePage
};