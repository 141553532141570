// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Auth = require("../Optimus.Client.Auth/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");

var component = React_Basic.createComponent("Callback");

var callbackPage = function () {
  var render = function render(v) {
    return React_Basic_DOM_Generated.div_([React_Basic_DOM.text("loading...")]);
  };

  var didMount = function didMount(self) {
    return Optimus_Client_Auth.parseHash(function (res) {
      if (res instanceof Data_Either.Left) {
        return self.props.history.replace(Optimus_Client_Route.Login.value);
      }

      ;

      if (res instanceof Data_Either.Right) {
        return function __do() {
          var v = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage)();
          Web_Storage_Storage.setItem("accessToken")(res.value0.accessToken)(v)();
          var v1 = Optimus_Client_Auth.requireAuth();

          if (v1 instanceof Data_Maybe.Just) {
            return self.props.history.replace(new Optimus_Client_Route.Route(v1.value0, Optimus_Client_Route.SiloIndex.value))();
          }

          ;
          return self.props.history.replace(Optimus_Client_Route.Login.value)();
        };
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.Pages.Callback (line 30, column 7 - line 38, column 50): " + [res.constructor.name]);
    });
  };

  return React_Basic.make()(component)({
    initialState: Data_Unit.unit,
    didMount: didMount,
    render: render
  });
}();

module.exports = {
  component: component,
  callbackPage: callbackPage
};