// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_ST = require("../Control.Monad.ST/index.js");

var Control_Monad_ST_Internal = require("../Control.Monad.ST.Internal/index.js");

var Control_Monad_ST_Ref = require("../Control.Monad.ST.Ref/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Array_ST = require("../Data.Array.ST/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Prelude = require("../Prelude/index.js");

var Iterator = function () {
  function Iterator(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  Iterator.create = function (value0) {
    return function (value1) {
      return new Iterator(value0, value1);
    };
  };

  return Iterator;
}();

var peek = function peek(v) {
  return function __do() {
    var v1 = Control_Monad_ST_Internal.read(v.value1)();
    return v.value0(v1);
  };
};

var next = function next(v) {
  return function __do() {
    var v1 = Control_Monad_ST_Internal.read(v.value1)();
    var v2 = Control_Monad_ST_Internal.modify(function (v2) {
      return v2 + 1 | 0;
    })(v.value1)();
    return v.value0(v1);
  };
};

var pushWhile = function pushWhile(p) {
  return function (iter) {
    return function (array) {
      return function __do() {
        var v = Control_Monad_ST_Internal["new"](false)();

        while (Data_Functor.map(Control_Monad_ST_Internal.functorST)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Control_Monad_ST_Internal.read(v))()) {
          (function __do() {
            var v1 = peek(iter)();

            if (v1 instanceof Data_Maybe.Just && p(v1.value0)) {
              var v2 = Data_Array_ST.push(v1.value0)(array)();
              return Data_Functor["void"](Control_Monad_ST_Internal.functorST)(next(iter))();
            }

            ;
            return Data_Functor["void"](Control_Monad_ST_Internal.functorST)(Control_Monad_ST_Internal.write(true)(v))();
          })();
        }

        ;
        return {};
      };
    };
  };
};

var pushAll = pushWhile(Data_Function["const"](true));

var iterator = function iterator(f) {
  return Data_Functor.map(Control_Monad_ST_Internal.functorST)(Iterator.create(f))(Control_Monad_ST_Internal["new"](0));
};

var iterate = function iterate(iter) {
  return function (f) {
    return function __do() {
      var v = Control_Monad_ST_Internal["new"](false)();

      while (Data_Functor.map(Control_Monad_ST_Internal.functorST)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(Control_Monad_ST_Internal.read(v))()) {
        (function __do() {
          var v1 = next(iter)();

          if (v1 instanceof Data_Maybe.Just) {
            return f(v1.value0)();
          }

          ;

          if (v1 instanceof Data_Maybe.Nothing) {
            return Data_Functor["void"](Control_Monad_ST_Internal.functorST)(Control_Monad_ST_Internal.write(true)(v))();
          }

          ;
          throw new Error("Failed pattern match at Data.Array.ST.Iterator (line 42, column 5 - line 44, column 47): " + [v1.constructor.name]);
        })();
      }

      ;
      return {};
    };
  };
};

var exhausted = function exhausted($27) {
  return Data_Functor.map(Control_Monad_ST_Internal.functorST)(Data_Maybe.isNothing)(peek($27));
};

module.exports = {
  iterator: iterator,
  iterate: iterate,
  next: next,
  peek: peek,
  exhausted: exhausted,
  pushWhile: pushWhile,
  pushAll: pushAll
};