// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Date = require("../Data.Date/index.js");

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Formatter_DateTime = require("../Data.Formatter.DateTime/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HugeNum = require("../Data.HugeNum/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Optimus_Client_Utils = require("../Optimus.Client.Utils/index.js");

var Prelude = require("../Prelude/index.js");

var ZRPC_Clients_Zapper = require("../ZRPC.Clients.Zapper/index.js");

var InfoShow = function InfoShow(showInfo) {
  this.showInfo = showInfo;
};

var showInfo = function showInfo(dict) {
  return dict.showInfo;
};

var infoShowString = new InfoShow(function (a) {
  return a;
});

var infoShowMaybe = function infoShowMaybe(dictInfoShow) {
  return new InfoShow(function (v) {
    if (v instanceof Data_Maybe.Just) {
      return showInfo(dictInfoShow)(v.value0);
    }

    ;

    if (v instanceof Data_Maybe.Nothing) {
      return "-";
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.InfoShow (line 42, column 1 - line 42, column 59): " + [v.constructor.name]);
  });
};

var infoShowInt = new InfoShow(Data_Show.show(Data_Show.showInt));
var infoShowHugeNum = new InfoShow(Data_HugeNum.toString);
var infoShowGender = new InfoShow(function (v) {
  if (v instanceof ZRPC_Clients_Zapper.GenderFemale) {
    return "Female";
  }

  ;

  if (v instanceof ZRPC_Clients_Zapper.GenderMale) {
    return "Male";
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.InfoShow (line 35, column 1 - line 35, column 43): " + [v.constructor.name]);
});
var infoShowDateTiem = new InfoShow(function (dt) {
  return Data_Either.either(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn))(Data_Formatter_DateTime.formatDateTime("MMM DD, YYYY")(dt));
});
var infoShowDate = new InfoShow(function (a) {
  return Optimus_Client_Utils.formatDate(a);
});
var infoShowBoolean = new InfoShow(function (v) {
  if (v) {
    return "Yes";
  }

  ;

  if (!v) {
    return "No";
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.InfoShow (line 28, column 1 - line 28, column 45): " + [v.constructor.name]);
});

var infoShowArray = function infoShowArray(dictInfoShow) {
  return new InfoShow(function (arr) {
    var v = Data_Array.head(arr);

    if (v instanceof Data_Maybe.Just) {
      return Data_String_Common.joinWith(", ")(Data_Functor.mapFlipped(Data_Functor.functorArray)(arr)(showInfo(dictInfoShow)));
    }

    ;
    return "-";
  });
};

module.exports = {
  InfoShow: InfoShow,
  showInfo: showInfo,
  infoShowString: infoShowString,
  infoShowInt: infoShowInt,
  infoShowBoolean: infoShowBoolean,
  infoShowDate: infoShowDate,
  infoShowGender: infoShowGender,
  infoShowHugeNum: infoShowHugeNum,
  infoShowMaybe: infoShowMaybe,
  infoShowArray: infoShowArray,
  infoShowDateTiem: infoShowDateTiem
};