// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

module.exports = {
  notification: $foreign.notification
};