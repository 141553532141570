// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_String_Unsafe = require("../Data.String.Unsafe/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable = require("../Data.Unfoldable/index.js");

var Prelude = require("../Prelude/index.js");

var CodePoint = function CodePoint(x) {
  return x;
};

var unsurrogate = function unsurrogate(lead) {
  return function (trail) {
    return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
  };
};

var showCodePoint = new Data_Show.Show(function (v) {
  return "(CodePoint 0x" + (Data_String_Common.toUpper(Data_Int.toStringAs(Data_Int.hexadecimal)(v)) + ")");
});

var isTrail = function isTrail(cu) {
  return 56320 <= cu && cu <= 57343;
};

var isLead = function isLead(cu) {
  return 55296 <= cu && cu <= 56319;
};

var uncons = function uncons(s) {
  var v = Data_String_CodeUnits.length(s);

  if (v === 0) {
    return Data_Maybe.Nothing.value;
  }

  ;

  if (v === 1) {
    return new Data_Maybe.Just({
      head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
      tail: ""
    });
  }

  ;
  var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
  var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
  var $21 = isLead(cu0) && isTrail(cu1);

  if ($21) {
    return new Data_Maybe.Just({
      head: unsurrogate(cu0)(cu1),
      tail: Data_String_CodeUnits.drop(2)(s)
    });
  }

  ;
  return new Data_Maybe.Just({
    head: cu0,
    tail: Data_String_CodeUnits.drop(1)(s)
  });
};

var unconsButWithTuple = function unconsButWithTuple(s) {
  return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
    return new Data_Tuple.Tuple(v.head, v.tail);
  })(uncons(s));
};

var toCodePointArrayFallback = function toCodePointArrayFallback(s) {
  return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
};

var unsafeCodePointAt0Fallback = function unsafeCodePointAt0Fallback(s) {
  var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
  var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;

  if ($25) {
    var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
    var $26 = isTrail(cu1);

    if ($26) {
      return unsurrogate(cu0)(cu1);
    }

    ;
    return cu0;
  }

  ;
  return cu0;
};

var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);

var length = function length($52) {
  return Data_Array.length(toCodePointArray($52));
};

var lastIndexOf = function lastIndexOf(p) {
  return function (s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (i) {
      return length(Data_String_CodeUnits.take(i)(s));
    })(Data_String_CodeUnits.lastIndexOf(p)(s));
  };
};

var indexOf = function indexOf(p) {
  return function (s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (i) {
      return length(Data_String_CodeUnits.take(i)(s));
    })(Data_String_CodeUnits.indexOf(p)(s));
  };
};

var fromCharCode = function fromCharCode($53) {
  return Data_String_CodeUnits.singleton(Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar))($53));
};

var singletonFallback = function singletonFallback(v) {
  if (v <= 65535) {
    return fromCharCode(v);
  }

  ;
  var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
  var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
  return fromCharCode(lead) + fromCharCode(trail);
};

var fromCodePointArray = $foreign["_fromCodePointArray"](singletonFallback);
var singleton = $foreign["_singleton"](singletonFallback);

var takeFallback = function takeFallback(n) {
  return function (v) {
    if (n < 1) {
      return "";
    }

    ;
    var v1 = uncons(v);

    if (v1 instanceof Data_Maybe.Just) {
      return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
    }

    ;
    return v;
  };
};

var take = $foreign["_take"](takeFallback);

var lastIndexOf$prime = function lastIndexOf$prime(p) {
  return function (i) {
    return function (s) {
      var i$prime = Data_String_CodeUnits.length(take(i)(s));
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (k) {
        return length(Data_String_CodeUnits.take(k)(s));
      })(Data_String_CodeUnits["lastIndexOf'"](p)(i$prime)(s));
    };
  };
};

var splitAt = function splitAt(i) {
  return function (s) {
    var before = take(i)(s);
    return {
      before: before,
      after: Data_String_CodeUnits.drop(Data_String_CodeUnits.length(before))(s)
    };
  };
};

var eqCodePoint = new Data_Eq.Eq(function (x) {
  return function (y) {
    return x === y;
  };
});
var ordCodePoint = new Data_Ord.Ord(function () {
  return eqCodePoint;
}, function (x) {
  return function (y) {
    return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
  };
});

var drop = function drop(n) {
  return function (s) {
    return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
  };
};

var indexOf$prime = function indexOf$prime(p) {
  return function (i) {
    return function (s) {
      var s$prime = drop(i)(s);
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (k) {
        return i + length(Data_String_CodeUnits.take(k)(s$prime)) | 0;
      })(Data_String_CodeUnits.indexOf(p)(s$prime));
    };
  };
};

var countTail = function countTail($copy_p) {
  return function ($copy_s) {
    return function ($copy_accum) {
      var $tco_var_p = $copy_p;
      var $tco_var_s = $copy_s;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(p, s, accum) {
        var v = uncons(s);

        if (v instanceof Data_Maybe.Just) {
          var $39 = p(v.value0.head);

          if ($39) {
            $tco_var_p = p;
            $tco_var_s = v.value0.tail;
            $copy_accum = accum + 1 | 0;
            return;
          }

          ;
          $tco_done = true;
          return accum;
        }

        ;
        $tco_done = true;
        return accum;
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_p, $tco_var_s, $copy_accum);
      }

      ;
      return $tco_result;
    };
  };
};

var countFallback = function countFallback(p) {
  return function (s) {
    return countTail(p)(s)(0);
  };
};

var countPrefix = $foreign["_countPrefix"](countFallback)(unsafeCodePointAt0);

var dropWhile = function dropWhile(p) {
  return function (s) {
    return drop(countPrefix(p)(s))(s);
  };
};

var takeWhile = function takeWhile(p) {
  return function (s) {
    return take(countPrefix(p)(s))(s);
  };
};

var codePointFromChar = function codePointFromChar($54) {
  return CodePoint(Data_Enum.fromEnum(Data_Enum.boundedEnumChar)($54));
};

var codePointAtFallback = function codePointAtFallback($copy_n) {
  return function ($copy_s) {
    var $tco_var_n = $copy_n;
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(n, s) {
      var v = uncons(s);

      if (v instanceof Data_Maybe.Just) {
        var $44 = n === 0;

        if ($44) {
          $tco_done = true;
          return new Data_Maybe.Just(v.value0.head);
        }

        ;
        $tco_var_n = n - 1 | 0;
        $copy_s = v.value0.tail;
        return;
      }

      ;
      $tco_done = true;
      return Data_Maybe.Nothing.value;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($tco_var_n, $copy_s);
    }

    ;
    return $tco_result;
  };
};

var codePointAt = function codePointAt(v) {
  return function (v1) {
    if (v < 0) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v === 0 && v1 === "") {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v === 0) {
      return new Data_Maybe.Just(unsafeCodePointAt0(v1));
    }

    ;
    return $foreign["_codePointAt"](codePointAtFallback)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value)(unsafeCodePointAt0)(v)(v1);
  };
};

var boundedCodePoint = new Data_Bounded.Bounded(function () {
  return ordCodePoint;
}, 0, 1114111);
var boundedEnumCodePoint = new Data_Enum.BoundedEnum(function () {
  return boundedCodePoint;
}, function () {
  return enumCodePoint;
}, 1114111 + 1 | 0, function (v) {
  return v;
}, function (n) {
  if (n >= 0 && n <= 1114111) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.String.CodePoints (line 63, column 1 - line 63, column 55): " + [n.constructor.name]);
});
var enumCodePoint = new Data_Enum.Enum(function () {
  return ordCodePoint;
}, Data_Enum.defaultPred(Data_Enum.toEnum(boundedEnumCodePoint))(Data_Enum.fromEnum(boundedEnumCodePoint)), Data_Enum.defaultSucc(Data_Enum.toEnum(boundedEnumCodePoint))(Data_Enum.fromEnum(boundedEnumCodePoint)));
module.exports = {
  codePointFromChar: codePointFromChar,
  singleton: singleton,
  fromCodePointArray: fromCodePointArray,
  toCodePointArray: toCodePointArray,
  codePointAt: codePointAt,
  uncons: uncons,
  length: length,
  countPrefix: countPrefix,
  indexOf: indexOf,
  "indexOf'": indexOf$prime,
  lastIndexOf: lastIndexOf,
  "lastIndexOf'": lastIndexOf$prime,
  take: take,
  takeWhile: takeWhile,
  drop: drop,
  dropWhile: dropWhile,
  splitAt: splitAt,
  eqCodePoint: eqCodePoint,
  ordCodePoint: ordCodePoint,
  showCodePoint: showCodePoint,
  boundedCodePoint: boundedCodePoint,
  enumCodePoint: enumCodePoint,
  boundedEnumCodePoint: boundedEnumCodePoint
};