// Generated by purs version 0.12.3
"use strict";

var Data_Unit = require("../Data.Unit/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Layout = require("../Optimus.Client.Antd.Layout/index.js");

var Optimus_Client_Components_Breadcrumb = require("../Optimus.Client.Components.Breadcrumb/index.js");

var Optimus_Client_Components_Header = require("../Optimus.Client.Components.Header/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var component = React_Basic.createComponent("Layout");

var layout = function () {
  var footer = Optimus_Client_Antd_Layout.footer()({
    className: "center",
    children: [React_Basic_DOM.text("Optimus \xa9 2019. Created by ZAP")]
  });

  var content = function content(v) {
    return Optimus_Client_Antd_Layout.content()({
      className: "p-3",
      children: [Optimus_Client_Components_Breadcrumb.breadcrumbs({
        route: v.route,
        history: v.history
      }), v.content]
    });
  };

  var render = function render(v) {
    return Optimus_Client_Antd_Layout.layout()({
      className: "vh-100",
      children: [Optimus_Client_Components_Header.header, content(v.props), footer]
    });
  };

  return React_Basic.make()(component)({
    initialState: Data_Unit.unit,
    render: render
  });
}();

module.exports = {
  layout: layout
};