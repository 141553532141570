var notification = require('antd').notification;

function getNotif(key) {
  return function (unionDict) {
    return function (c) {
      return function () {
        notification[key](c);
      };
    };
  };
}

exports.notification = {
  success: getNotif('success'),
  error: getNotif('error'),
  info: getNotif('info'),
  warning: getNotif('warning'),
  warn: getNotif('warn'),
  open: getNotif('open'),
  close: notification.close,
  destroy: notification.destroy
};