// Generated by purs version 0.12.3
"use strict";

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var GenericBottom = function GenericBottom(genericBottom$prime) {
  this["genericBottom'"] = genericBottom$prime;
};

var GenericTop = function GenericTop(genericTop$prime) {
  this["genericTop'"] = genericTop$prime;
};

var genericTopNoArguments = new GenericTop(Data_Generic_Rep.NoArguments.value);

var genericTopArgument = function genericTopArgument(dictBounded) {
  return new GenericTop(Data_Bounded.top(dictBounded));
};

var genericTop$prime = function genericTop$prime(dict) {
  return dict["genericTop'"];
};

var genericTopConstructor = function genericTopConstructor(dictGenericTop) {
  return new GenericTop(genericTop$prime(dictGenericTop));
};

var genericTopProduct = function genericTopProduct(dictGenericTop) {
  return function (dictGenericTop1) {
    return new GenericTop(new Data_Generic_Rep.Product(genericTop$prime(dictGenericTop), genericTop$prime(dictGenericTop1)));
  };
};

var genericTopSum = function genericTopSum(dictGenericTop) {
  return new GenericTop(new Data_Generic_Rep.Inr(genericTop$prime(dictGenericTop)));
};

var genericTop = function genericTop(dictGeneric) {
  return function (dictGenericTop) {
    return Data_Generic_Rep.to(dictGeneric)(genericTop$prime(dictGenericTop));
  };
};

var genericBottomNoArguments = new GenericBottom(Data_Generic_Rep.NoArguments.value);

var genericBottomArgument = function genericBottomArgument(dictBounded) {
  return new GenericBottom(Data_Bounded.bottom(dictBounded));
};

var genericBottom$prime = function genericBottom$prime(dict) {
  return dict["genericBottom'"];
};

var genericBottomConstructor = function genericBottomConstructor(dictGenericBottom) {
  return new GenericBottom(genericBottom$prime(dictGenericBottom));
};

var genericBottomProduct = function genericBottomProduct(dictGenericBottom) {
  return function (dictGenericBottom1) {
    return new GenericBottom(new Data_Generic_Rep.Product(genericBottom$prime(dictGenericBottom), genericBottom$prime(dictGenericBottom1)));
  };
};

var genericBottomSum = function genericBottomSum(dictGenericBottom) {
  return new GenericBottom(new Data_Generic_Rep.Inl(genericBottom$prime(dictGenericBottom)));
};

var genericBottom = function genericBottom(dictGeneric) {
  return function (dictGenericBottom) {
    return Data_Generic_Rep.to(dictGeneric)(genericBottom$prime(dictGenericBottom));
  };
};

module.exports = {
  GenericBottom: GenericBottom,
  "genericBottom'": genericBottom$prime,
  genericBottom: genericBottom,
  GenericTop: GenericTop,
  "genericTop'": genericTop$prime,
  genericTop: genericTop,
  genericBottomNoArguments: genericBottomNoArguments,
  genericBottomArgument: genericBottomArgument,
  genericBottomSum: genericBottomSum,
  genericBottomProduct: genericBottomProduct,
  genericBottomConstructor: genericBottomConstructor,
  genericTopNoArguments: genericTopNoArguments,
  genericTopArgument: genericTopArgument,
  genericTopSum: genericTopSum,
  genericTopProduct: genericTopProduct,
  genericTopConstructor: genericTopConstructor
};