// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var table = function table(dictUnion) {
  return React_Basic.element($foreign["_table"]);
};

module.exports = {
  table: table
};