// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Effect = require("../Effect/index.js");

var MonadEffect = function MonadEffect(Monad0, liftEffect) {
  this.Monad0 = Monad0;
  this.liftEffect = liftEffect;
};

var monadEffectEffect = new MonadEffect(function () {
  return Effect.monadEffect;
}, Control_Category.identity(Control_Category.categoryFn));

var liftEffect = function liftEffect(dict) {
  return dict.liftEffect;
};

module.exports = {
  liftEffect: liftEffect,
  MonadEffect: MonadEffect,
  monadEffectEffect: monadEffectEffect
};