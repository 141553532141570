// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Map = require("../Data.Map/index.js");

var Data_Map_Internal = require("../Data.Map.Internal/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect = require("../Effect/index.js");

var Effect_Ref = require("../Effect.Ref/index.js");

var Foreign = require("../Foreign/index.js");

var Prelude = require("../Prelude/index.js");

var Routing = require("../Routing/index.js");

var Routing_Match = require("../Routing.Match/index.js");

var Web_DOM_Document = require("../Web.DOM.Document/index.js");

var Web_DOM_MutationObserver = require("../Web.DOM.MutationObserver/index.js");

var Web_DOM_Node = require("../Web.DOM.Node/index.js");

var Web_DOM_Text = require("../Web.DOM.Text/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Event_PopStateEvent_EventTypes = require("../Web.HTML.Event.PopStateEvent.EventTypes/index.js");

var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");

var Web_HTML_History = require("../Web.HTML.History/index.js");

var Web_HTML_Location = require("../Web.HTML.Location/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var makeImmediate = function makeImmediate(run) {
  return function __do() {
    var v = function __do() {
      var $37 = Web_HTML.window();
      return Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Web_HTML_Window.document($37))();
    }();

    var v1 = Effect_Ref["new"](new Data_Either.Right(0))();
    var v2 = Data_Functor.map(Effect.functorEffect)(Web_DOM_Text.toNode)(Web_DOM_Document.createTextNode("")(v))();
    var v3 = Web_DOM_MutationObserver.mutationObserver(function (v3) {
      return function (v4) {
        return function __do() {
          Effect_Ref.modify_(Data_Either.either(function ($38) {
            return Data_Either.Right.create(1 + $38 | 0);
          })(Data_Either.Right.create))(v1)();
          return run();
        };
      };
    })();
    Web_DOM_MutationObserver.observe()(v2)({
      characterData: true
    })(v3)();
    return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(v1))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Either.foldableEither)(function (tick) {
      return function __do() {
        Effect_Ref.write(new Data_Either.Left(tick + 1 | 0))(v1)();
        return Web_DOM_Node.setNodeValue(Data_Show.show(Data_Show.showInt)(tick))(v2)();
      };
    }));
  };
};

var makeInterface = function __do() {
  var v = Effect_Ref["new"](0)();
  var v1 = Effect_Ref["new"](Data_Map_Internal.empty)();

  var notify = function notify(ev) {
    return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(function (v2) {
      return v2(ev);
    }))(Effect_Ref.read(v1));
  };

  var locationState = function __do() {
    var v2 = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location)();
    var v3 = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(Web_HTML_History.state)();
    var v4 = Web_HTML_Location.pathname(v2)();
    var v5 = Web_HTML_Location.search(v2)();
    var v6 = Web_HTML_Location.hash(v2)();
    var path = v4 + (v5 + v6);
    return {
      state: v3,
      pathname: v4,
      search: v5,
      hash: v6,
      path: path
    };
  };

  var listen = function listen(k) {
    return function __do() {
      var v2 = Effect_Ref.read(v)();
      Effect_Ref.write(v2 + 1 | 0)(v)();
      Effect_Ref.modify_(Data_Map_Internal.insert(Data_Ord.ordInt)(v2)(k))(v1)();
      return Effect_Ref.modify_(Data_Map_Internal["delete"](Data_Ord.ordInt)(v2))(v1);
    };
  };

  var v2 = makeImmediate(Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState))();

  var stateFn = function stateFn(op) {
    return function (state) {
      return function (path) {
        return function __do() {
          Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(op(state)("")(path))();
          return v2();
        };
      };
    };
  };

  var v3 = Web_Event_EventTarget.eventListener(function (v3) {
    return Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState);
  })();

  (function __do() {
    var $39 = Web_HTML.window();
    return Web_Event_EventTarget.addEventListener(Web_HTML_Event_PopStateEvent_EventTypes.popstate)(v3)(false)(Web_HTML_Window.toEventTarget($39))();
  })();

  return {
    pushState: stateFn(Web_HTML_History.pushState),
    replaceState: stateFn(Web_HTML_History.replaceState),
    locationState: locationState,
    listen: listen
  };
};

var foldLocations = function foldLocations(cb) {
  return function (init) {
    return function (psi) {
      return function __do() {
        var v = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(psi.locationState))();
        return psi.listen(function (loc) {
          return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(v))(Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(cb)(loc))(Effect_Ref.read(v)));
        })();
      };
    };
  };
};

var foldPaths = function foldPaths(cb) {
  return function (init) {
    return foldLocations(function (a) {
      return function ($40) {
        return cb(a)(function (v) {
          return v.path;
        }($40));
      };
    })(function ($41) {
      return init(function (v) {
        return v.path;
      }($41));
    });
  };
};

var matchesWith = function matchesWith(dictFoldable) {
  return function (parser) {
    return function (cb) {
      var go = function go(a) {
        return function ($42) {
          return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
            return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
          })(Data_Foldable.indexl(dictFoldable)(0)(parser($42)));
        };
      };

      return foldPaths(go)(go(Data_Maybe.Nothing.value));
    };
  };
};

var matches = function matches($43) {
  return matchesWith(Data_Either.foldableEither)(Routing.match($43));
};

var paths = matchesWith(Data_Foldable.foldableMaybe)(Data_Maybe.Just.create);

var locations = function locations(cb) {
  var go = function go(a) {
    return function (b) {
      return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
    };
  };

  return foldLocations(go)(go(Data_Maybe.Nothing.value));
};

module.exports = {
  makeInterface: makeInterface,
  foldLocations: foldLocations,
  locations: locations,
  foldPaths: foldPaths,
  paths: paths,
  matches: matches,
  matchesWith: matchesWith
};