// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Maybe_First = require("../Data.Maybe.First/index.js");

var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Tuple = function () {
  function Tuple(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  Tuple.create = function (value0) {
    return function (value1) {
      return new Tuple(value0, value1);
    };
  };

  return Tuple;
}();

var FoldableWithIndex = function FoldableWithIndex(Foldable0, foldMapWithIndex, foldlWithIndex, foldrWithIndex) {
  this.Foldable0 = Foldable0;
  this.foldMapWithIndex = foldMapWithIndex;
  this.foldlWithIndex = foldlWithIndex;
  this.foldrWithIndex = foldrWithIndex;
};

var foldrWithIndex = function foldrWithIndex(dict) {
  return dict.foldrWithIndex;
};

var traverseWithIndex_ = function traverseWithIndex_(dictApplicative) {
  return function (dictFoldableWithIndex) {
    return function (f) {
      return foldrWithIndex(dictFoldableWithIndex)(function (i) {
        return function ($46) {
          return Control_Apply.applySecond(dictApplicative.Apply0())(f(i)($46));
        };
      })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
    };
  };
};

var forWithIndex_ = function forWithIndex_(dictApplicative) {
  return function (dictFoldableWithIndex) {
    return Data_Function.flip(traverseWithIndex_(dictApplicative)(dictFoldableWithIndex));
  };
};

var foldrDefault = function foldrDefault(dictFoldableWithIndex) {
  return function (f) {
    return foldrWithIndex(dictFoldableWithIndex)(Data_Function["const"](f));
  };
};

var foldlWithIndex = function foldlWithIndex(dict) {
  return dict.foldlWithIndex;
};

var foldlDefault = function foldlDefault(dictFoldableWithIndex) {
  return function (f) {
    return foldlWithIndex(dictFoldableWithIndex)(Data_Function["const"](f));
  };
};

var foldableWithIndexMultiplicative = new FoldableWithIndex(function () {
  return Data_Foldable.foldableMultiplicative;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableMultiplicative)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableMultiplicative)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableMultiplicative)(f(Data_Unit.unit));
});
var foldableWithIndexMaybe = new FoldableWithIndex(function () {
  return Data_Foldable.foldableMaybe;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableMaybe)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableMaybe)(f(Data_Unit.unit));
});
var foldableWithIndexLast = new FoldableWithIndex(function () {
  return Data_Foldable.foldableLast;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableLast)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableLast)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableLast)(f(Data_Unit.unit));
});
var foldableWithIndexFirst = new FoldableWithIndex(function () {
  return Data_Foldable.foldableFirst;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableFirst)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableFirst)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableFirst)(f(Data_Unit.unit));
});
var foldableWithIndexDual = new FoldableWithIndex(function () {
  return Data_Foldable.foldableDual;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableDual)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableDual)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableDual)(f(Data_Unit.unit));
});
var foldableWithIndexDisj = new FoldableWithIndex(function () {
  return Data_Foldable.foldableDisj;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableDisj)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableDisj)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableDisj)(f(Data_Unit.unit));
});
var foldableWithIndexConj = new FoldableWithIndex(function () {
  return Data_Foldable.foldableConj;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableConj)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableConj)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableConj)(f(Data_Unit.unit));
});
var foldableWithIndexAdditive = new FoldableWithIndex(function () {
  return Data_Foldable.foldableAdditive;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableAdditive)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableAdditive)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableAdditive)(f(Data_Unit.unit));
});

var foldWithIndexM = function foldWithIndexM(dictFoldableWithIndex) {
  return function (dictMonad) {
    return function (f) {
      return function (a0) {
        return foldlWithIndex(dictFoldableWithIndex)(function (i) {
          return function (ma) {
            return function (b) {
              return Control_Bind.bind(dictMonad.Bind1())(ma)(Data_Function.flip(f(i))(b));
            };
          };
        })(Control_Applicative.pure(dictMonad.Applicative0())(a0));
      };
    };
  };
};

var foldMapWithIndexDefaultR = function foldMapWithIndexDefaultR(dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldrWithIndex(dictFoldableWithIndex)(function (i) {
        return function (x) {
          return function (acc) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(f(i)(x))(acc);
          };
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldableWithIndexArray = new FoldableWithIndex(function () {
  return Data_Foldable.foldableArray;
}, function (dictMonoid) {
  return foldMapWithIndexDefaultR(foldableWithIndexArray)(dictMonoid);
}, function (f) {
  return function (z) {
    return function ($47) {
      return Data_Foldable.foldl(Data_Foldable.foldableArray)(function (y) {
        return function (v) {
          return f(v.value0)(y)(v.value1);
        };
      })(z)(Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create)($47));
    };
  };
}, function (f) {
  return function (z) {
    return function ($48) {
      return Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
        return function (y) {
          return f(v.value0)(v.value1)(y);
        };
      })(z)(Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create)($48));
    };
  };
});

var foldMapWithIndexDefaultL = function foldMapWithIndexDefaultL(dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldlWithIndex(dictFoldableWithIndex)(function (i) {
        return function (acc) {
          return function (x) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f(i)(x));
          };
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldMapWithIndex = function foldMapWithIndex(dict) {
  return dict.foldMapWithIndex;
};

var foldlWithIndexDefault = function foldlWithIndexDefault(dictFoldableWithIndex) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))(function (i) {
          return function ($49) {
            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo(Data_Function.flip(c(i))($49)));
          };
        })(xs)))(u);
      };
    };
  };
};

var foldrWithIndexDefault = function foldrWithIndexDefault(dictFoldableWithIndex) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function (i) {
          return function ($50) {
            return Data_Monoid_Endo.Endo(c(i)($50));
          };
        })(xs))(u);
      };
    };
  };
};

var surroundMapWithIndex = function surroundMapWithIndex(dictFoldableWithIndex) {
  return function (dictSemigroup) {
    return function (d) {
      return function (t) {
        return function (f) {
          var joined = function joined(i) {
            return function (a) {
              return function (m) {
                return Data_Semigroup.append(dictSemigroup)(d)(Data_Semigroup.append(dictSemigroup)(t(i)(a))(m));
              };
            };
          };

          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(joined)(f))(d);
        };
      };
    };
  };
};

var foldMapDefault = function foldMapDefault(dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldMapWithIndex(dictFoldableWithIndex)(dictMonoid)(Data_Function["const"](f));
    };
  };
};

var findWithIndex = function findWithIndex(dictFoldableWithIndex) {
  return function (p) {
    var go = function go(v) {
      return function (v1) {
        return function (v2) {
          if (v1 instanceof Data_Maybe.Nothing && p(v)(v2)) {
            return new Data_Maybe.Just({
              index: v,
              value: v2
            });
          }

          ;
          return v1;
        };
      };
    };

    return foldlWithIndex(dictFoldableWithIndex)(go)(Data_Maybe.Nothing.value);
  };
};

var anyWithIndex = function anyWithIndex(dictFoldableWithIndex) {
  return function (dictHeytingAlgebra) {
    return function (t) {
      return function ($51) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeDisj)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra))(function (i) {
          return function ($52) {
            return Data_Monoid_Disj.Disj(t(i)($52));
          };
        })($51));
      };
    };
  };
};

var allWithIndex = function allWithIndex(dictFoldableWithIndex) {
  return function (dictHeytingAlgebra) {
    return function (t) {
      return function ($53) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeConj)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Conj.monoidConj(dictHeytingAlgebra))(function (i) {
          return function ($54) {
            return Data_Monoid_Conj.Conj(t(i)($54));
          };
        })($53));
      };
    };
  };
};

module.exports = {
  FoldableWithIndex: FoldableWithIndex,
  foldrWithIndex: foldrWithIndex,
  foldlWithIndex: foldlWithIndex,
  foldMapWithIndex: foldMapWithIndex,
  foldrWithIndexDefault: foldrWithIndexDefault,
  foldlWithIndexDefault: foldlWithIndexDefault,
  foldMapWithIndexDefaultR: foldMapWithIndexDefaultR,
  foldMapWithIndexDefaultL: foldMapWithIndexDefaultL,
  foldWithIndexM: foldWithIndexM,
  traverseWithIndex_: traverseWithIndex_,
  forWithIndex_: forWithIndex_,
  surroundMapWithIndex: surroundMapWithIndex,
  allWithIndex: allWithIndex,
  anyWithIndex: anyWithIndex,
  findWithIndex: findWithIndex,
  foldrDefault: foldrDefault,
  foldlDefault: foldlDefault,
  foldMapDefault: foldMapDefault,
  foldableWithIndexArray: foldableWithIndexArray,
  foldableWithIndexMaybe: foldableWithIndexMaybe,
  foldableWithIndexFirst: foldableWithIndexFirst,
  foldableWithIndexLast: foldableWithIndexLast,
  foldableWithIndexAdditive: foldableWithIndexAdditive,
  foldableWithIndexDual: foldableWithIndexDual,
  foldableWithIndexDisj: foldableWithIndexDisj,
  foldableWithIndexConj: foldableWithIndexConj,
  foldableWithIndexMultiplicative: foldableWithIndexMultiplicative
};