// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var buttonGroup = function buttonGroup(dictUnion) {
  return React_Basic.element($foreign["_buttonGroup"]);
};

var buttonGroup_ = function buttonGroup_(children) {
  return buttonGroup()({
    children: children
  });
};

var button = function button(dictUnion) {
  return React_Basic.element($foreign["_button"]);
};

var button_ = function button_(children) {
  return button()({
    children: children
  });
};

module.exports = {
  button: button,
  button_: button_,
  buttonGroup: buttonGroup,
  buttonGroup_: buttonGroup_
};