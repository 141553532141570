// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Profunctor = require("../Data.Profunctor/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Record = require("../Record/index.js");

var Routing_Duplex_Parser = require("../Routing.Duplex.Parser/index.js");

var Routing_Duplex_Printer = require("../Routing.Duplex.Printer/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var RouteDuplex = function () {
  function RouteDuplex(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  RouteDuplex.create = function (value0) {
    return function (value1) {
      return new RouteDuplex(value0, value1);
    };
  };

  return RouteDuplex;
}();

var RouteDuplexParams = function RouteDuplexParams(params) {
  this.params = params;
};

var RouteDuplexBuildParams = function RouteDuplexBuildParams(buildParams) {
  this.buildParams = buildParams;
};

var suffix = function suffix(v) {
  return function (s) {
    return new RouteDuplex(function (a) {
      return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(v.value0(a))(Routing_Duplex_Printer.put(s));
    }, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.prefix(s)(Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)(Data_Unit.unit))));
  };
};

var string = Control_Category.identity(Control_Category.categoryFn);
var segment = new RouteDuplex(Routing_Duplex_Printer.put, Routing_Duplex_Parser.take);
var rest = new RouteDuplex(Data_Foldable.foldMap(Data_Foldable.foldableArray)(Routing_Duplex_Printer.monoidRoutePRinter)(Routing_Duplex_Printer.put), Routing_Duplex_Parser.rest);
var record = new RouteDuplex(Data_Monoid.mempty(Data_Monoid.monoidFn(Routing_Duplex_Printer.monoidRoutePRinter)), Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)({}));

var prop = function prop(dictIsSymbol) {
  return function (dictCons) {
    return function (dictCons1) {
      return function (dictLacks) {
        return function (sym) {
          return function (v) {
            return function (v1) {
              return new RouteDuplex(function (r) {
                return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(v1.value0(r))(v.value0(Record.get(dictIsSymbol)(dictCons)(sym)(r)));
              }, Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Function.flip(Record.insert(dictIsSymbol)(dictLacks)(dictCons1)(sym)))(v1.value1))(v.value1));
            };
          };
        };
      };
    };
  };
};

var profunctorRouteDuplex = new Data_Profunctor.Profunctor(function (f) {
  return function (g) {
    return function (v) {
      return new RouteDuplex(function ($100) {
        return v.value0(f($100));
      }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(g)(v.value1));
    };
  };
});

var print = function print(v) {
  return function ($101) {
    return Routing_Duplex_Printer.run(v.value0($101));
  };
};

var prefix = function prefix(s) {
  return function (v) {
    return new RouteDuplex(function (a) {
      return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(Routing_Duplex_Printer.put(s))(v.value0(a));
    }, Routing_Duplex_Parser.prefix(s)(v.value1));
  };
};

var path = function path($102) {
  return Data_Function.flip(Data_Foldable.foldr(Data_Foldable.foldableArray)(prefix))(Data_String_Common.split("/")($102));
};

var root = path("");

var parse = function parse(v) {
  return Routing_Duplex_Parser.run(v.value1);
};

var params = function params(dict) {
  return dict.params;
};

var param = function param(p) {
  return new RouteDuplex(Routing_Duplex_Printer.param(p), Routing_Duplex_Parser.param(p));
};

var optional = function optional(v) {
  return new RouteDuplex(Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Routing_Duplex_Printer.monoidRoutePRinter)(v.value0), Routing_Duplex_Parser.optional(v.value1));
};

var many1 = function many1(dictFoldable) {
  return function (dictAlt) {
    return function (dictApplicative) {
      return function (v) {
        return new RouteDuplex(Data_Foldable.foldMap(dictFoldable)(Routing_Duplex_Printer.monoidRoutePRinter)(v.value0), Routing_Duplex_Parser.many1(dictAlt)(dictApplicative)(v.value1));
      };
    };
  };
};

var many = function many(dictFoldable) {
  return function (dictAlternative) {
    return function (v) {
      return new RouteDuplex(Data_Foldable.foldMap(dictFoldable)(Routing_Duplex_Printer.monoidRoutePRinter)(v.value0), Routing_Duplex_Parser.many(dictAlternative)(v.value1));
    };
  };
};

var functorRouteDuplex = new Data_Functor.Functor(function (f) {
  return function (m) {
    return new RouteDuplex(m.value0, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(f)(m.value1));
  };
});

var flag = function flag(v) {
  var enc$prime = function enc$prime(v1) {
    if (v1) {
      return v.value0("");
    }

    ;
    return Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter);
  };

  var dec$prime = Routing_Duplex_Parser["default"](false)(Data_Functor.voidLeft(Routing_Duplex_Parser.functorRouteParser)(v.value1)(true));
  return new RouteDuplex(enc$prime, dec$prime);
};

var end = function end(v) {
  return new RouteDuplex(v.value0, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.end));
};

var $$default = function $$default(d) {
  return function (v) {
    return new RouteDuplex(v.value0, Routing_Duplex_Parser["default"](d)(v.value1));
  };
};

var buildParamsNil = new RouteDuplexBuildParams(function (v) {
  return function (r) {
    return Control_Category.identity(Control_Category.categoryFn);
  };
});

var buildParams = function buildParams(dict) {
  return dict.buildParams;
};

var buildParamsCons = function buildParamsCons(dictIsSymbol) {
  return function (dictCons) {
    return function (dictCons1) {
      return function (dictCons2) {
        return function (dictLacks) {
          return function (dictRouteDuplexBuildParams) {
            return new RouteDuplexBuildParams(function (v) {
              return function (r) {
                return function (prev) {
                  return buildParams(dictRouteDuplexBuildParams)(Type_Data_RowList.RLProxy.value)(r)(prop(dictIsSymbol)(dictCons1)(dictCons2)(dictLacks)(Data_Symbol.SProxy.value)(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r)(param(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))))(prev));
                };
              };
            });
          };
        };
      };
    };
  };
};

var routeDuplexParams = function routeDuplexParams(dictRowToList) {
  return function (dictRouteDuplexBuildParams) {
    return new RouteDuplexParams(function (r) {
      return buildParams(dictRouteDuplexBuildParams)(Type_Data_RowList.RLProxy.value)(r)(record);
    });
  };
};

var as = function as(f) {
  return function (g) {
    return function (v) {
      return new RouteDuplex(function ($103) {
        return v.value0(f($103));
      }, Routing_Duplex_Parser.as(Control_Category.identity(Control_Category.categoryFn))(g)(v.value1));
    };
  };
};

var $$boolean = as(Data_Show.show(Data_Show.showBoolean))(Routing_Duplex_Parser["boolean"]);
var $$int = as(Data_Show.show(Data_Show.showInt))(Routing_Duplex_Parser["int"]);
var applyRouteDuplex = new Control_Apply.Apply(function () {
  return functorRouteDuplex;
}, function (v) {
  return function (v1) {
    return new RouteDuplex(Control_Apply.apply(Control_Apply.applyFn)(Data_Functor.map(Data_Functor.functorFn)(Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter))(v.value0))(v1.value0), Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(v.value1)(v1.value1));
  };
});
var applicativeRouteDuplex = new Control_Applicative.Applicative(function () {
  return applyRouteDuplex;
}, function ($104) {
  return RouteDuplex.create(Data_Function["const"](Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter)))(Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser)($104));
});
module.exports = {
  RouteDuplex: RouteDuplex,
  parse: parse,
  print: print,
  prefix: prefix,
  suffix: suffix,
  path: path,
  root: root,
  end: end,
  segment: segment,
  param: param,
  flag: flag,
  many1: many1,
  many: many,
  rest: rest,
  "default": $$default,
  optional: optional,
  as: as,
  "int": $$int,
  "boolean": $$boolean,
  string: string,
  record: record,
  prop: prop,
  params: params,
  buildParams: buildParams,
  RouteDuplexParams: RouteDuplexParams,
  RouteDuplexBuildParams: RouteDuplexBuildParams,
  functorRouteDuplex: functorRouteDuplex,
  applyRouteDuplex: applyRouteDuplex,
  applicativeRouteDuplex: applicativeRouteDuplex,
  profunctorRouteDuplex: profunctorRouteDuplex,
  routeDuplexParams: routeDuplexParams,
  buildParamsCons: buildParamsCons,
  buildParamsNil: buildParamsNil
};