// Generated by purs version 0.12.3
"use strict";

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Prelude = require("../Prelude/index.js");

var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);

var runExcept = function runExcept($0) {
  return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(Control_Monad_Except_Trans.runExceptT($0));
};

var mapExcept = function mapExcept(f) {
  return Control_Monad_Except_Trans.mapExceptT(function ($1) {
    return Data_Identity.Identity(f(Data_Newtype.unwrap(Data_Identity.newtypeIdentity)($1)));
  });
};

module.exports = {
  runExcept: runExcept,
  mapExcept: mapExcept,
  withExcept: withExcept
};