// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  unsafeHas: $foreign.unsafeHas,
  unsafeGet: $foreign.unsafeGet,
  unsafeSet: $foreign.unsafeSet,
  unsafeDelete: $foreign.unsafeDelete
};