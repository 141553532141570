// Generated by purs version 0.12.3
"use strict";

var Control_Extend = require("../Control.Extend/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Comonad = function Comonad(Extend0, extract) {
  this.Extend0 = Extend0;
  this.extract = extract;
};

var extract = function extract(dict) {
  return dict.extract;
};

module.exports = {
  Comonad: Comonad,
  extract: extract
};