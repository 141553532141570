// Generated by purs version 0.12.3
"use strict";

var Control_Plus = require("../Control.Plus/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Optimus_Client_Components_Settings_Utilities = require("../Optimus.Client.Components.Settings.Utilities/index.js");

var Prelude = require("../Prelude/index.js");

var Record_Builder = require("../Record.Builder/index.js");

var Type_Data_Row = require("../Type.Data.Row/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var ZRPC_Types_Millis = require("../ZRPC.Types.Millis/index.js");

var Default = function Default($$default) {
  this["default"] = $$default;
};

var Defaults = function Defaults(defaults) {
  this.defaults = defaults;
};

var DefaultsRL = function DefaultsRL(defaultsRL) {
  this.defaultsRL = defaultsRL;
};

var defaultsRLNil = new DefaultsRL(function (v) {
  return Data_Monoid.mempty(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordNil));
});

var defaultsRL = function defaultsRL(dict) {
  return dict.defaultsRL;
};

var defaultsRecord = function defaultsRecord(dictRowToList) {
  return function (dictDefaultsRL) {
    return new Defaults(function (v) {
      return defaultsRL(dictDefaultsRL)(Type_Data_RowList.RLProxy.value);
    });
  };
};

var defaults = function defaults(dict) {
  return dict.defaults;
};

var defaultTransactionAmountComputationMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedTransactionAmountComputationMode));
var defaultString = new Default(Data_Monoid.mempty(Data_Monoid.monoidString));
var defaultRoundingMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedRoundingMode));

var defaultRecord = function defaultRecord(dictDefaults) {
  return new Default(defaults(dictDefaults)(Type_Data_Row.RProxy.value));
};

var defaultMillis = new Default(Data_Monoid.mempty(ZRPC_Types_Millis.monoidMillis));
var defaultMaybe = new Default(Control_Plus.empty(Data_Maybe.plusMaybe));
var defaultLinkTagVM = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedLinkTagVerificationMode));
var defaultInt = new Default(0);
var defaultBoolean = new Default(false);
var defaultAuthMode = new Default(Data_Bounded.top(ZRPC_Clients_Mercanti.boundedAuthorizationMode));

var $$default = function $$default(dict) {
  return dict["default"];
};

var defaultSFS = function defaultSFS(dictDefault) {
  return new Default({
    selected: Optimus_Client_Components_Settings_Utilities.Default.value,
    "default": {
      value: $$default(dictDefault),
      provider: ZRPC_Clients_Mercanti.ProviderZap.value
    },
    fixed: Data_Maybe.Nothing.value
  });
};

var defaultsRLCons = function defaultsRLCons(dictIsSymbol) {
  return function (dictDefault) {
    return function (dictDefaultsRL) {
      return function (dictLacks) {
        return function (dictCons) {
          return new DefaultsRL(function (v) {
            var value = $$default(dictDefault);
            var rest = defaultsRL(dictDefaultsRL)(Type_Data_RowList.RLProxy.value);
            return Record_Builder.build(Record_Builder.insert(dictCons)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(value))(rest);
          });
        };
      };
    };
  };
};

module.exports = {
  Default: Default,
  "default": $$default,
  Defaults: Defaults,
  defaults: defaults,
  DefaultsRL: DefaultsRL,
  defaultsRL: defaultsRL,
  defaultString: defaultString,
  defaultBoolean: defaultBoolean,
  defaultInt: defaultInt,
  defaultSFS: defaultSFS,
  defaultMaybe: defaultMaybe,
  defaultRoundingMode: defaultRoundingMode,
  defaultRecord: defaultRecord,
  defaultMillis: defaultMillis,
  defaultLinkTagVM: defaultLinkTagVM,
  defaultAuthMode: defaultAuthMode,
  defaultTransactionAmountComputationMode: defaultTransactionAmountComputationMode,
  defaultsRecord: defaultsRecord,
  defaultsRLNil: defaultsRLNil,
  defaultsRLCons: defaultsRLCons
};