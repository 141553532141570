// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM = require("../Web.DOM/index.js");

var Web_DOM_DOMTokenList = require("../Web.DOM.DOMTokenList/index.js");

var Web_DOM_Element = require("../Web.DOM.Element/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NonDocumentTypeChildNode = require("../Web.DOM.NonDocumentTypeChildNode/index.js");

var Web_DOM_ParentNode = require("../Web.DOM.ParentNode/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toElement = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;

var offsetParent = function offsetParent($0) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_offsetParent"]($0));
};

var fromParentNode = function fromParentNode(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

var fromNonDocumentTypeChildNode = function fromNonDocumentTypeChildNode(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

var fromNode = function fromNode(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

var fromEventTarget = function fromEventTarget(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

var fromElement = function fromElement(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

var fromChildNode = function fromChildNode(x) {
  return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
};

module.exports = {
  fromElement: fromElement,
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toElement: toElement,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  offsetParent: offsetParent,
  title: $foreign.title,
  setTitle: $foreign.setTitle,
  lang: $foreign.lang,
  setLang: $foreign.setLang,
  dir: $foreign.dir,
  setDir: $foreign.setDir,
  className: $foreign.className,
  setClassName: $foreign.setClassName,
  classList: $foreign.classList,
  hidden: $foreign.hidden,
  setHidden: $foreign.setHidden,
  tabIndex: $foreign.tabIndex,
  setTabIndex: $foreign.setTabIndex,
  draggable: $foreign.draggable,
  setDraggable: $foreign.setDraggable,
  contentEditable: $foreign.contentEditable,
  setContentEditable: $foreign.setContentEditable,
  isContentEditable: $foreign.isContentEditable,
  spellcheck: $foreign.spellcheck,
  setSpellcheck: $foreign.setSpellcheck,
  click: $foreign.click,
  focus: $foreign.focus,
  blur: $foreign.blur,
  getBoundingClientRect: $foreign.getBoundingClientRect,
  offsetTop: $foreign.offsetTop,
  offsetLeft: $foreign.offsetLeft,
  offsetWidth: $foreign.offsetWidth,
  offsetHeight: $foreign.offsetHeight
};