// Generated by purs version 0.12.3
"use strict";

var Data_Array = require("../Data.Array/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Card = require("../Optimus.Client.Antd.Card/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var Optimus_Client_AsyncPage = require("../Optimus.Client.AsyncPage/index.js");

var Optimus_Client_Component_Content = require("../Optimus.Client.Component.Content/index.js");

var Optimus_Client_Component_Grid = require("../Optimus.Client.Component.Grid/index.js");

var Optimus_Client_Components_InfoPanel = require("../Optimus.Client.Components.InfoPanel/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Optimus_Shared_Users = require("../Optimus.Shared.Users/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var component = React_Basic.createComponent("UserTags");

var tagsPage = function () {
  var tagInfoPair = function tagInfoPair(key) {
    return function (value) {
      return Optimus_Client_Antd_Grid.row()({
        type: "flex",
        className: "justify-center items-center mb-1",
        children: [Optimus_Client_Antd_Grid.col()({
          xs: 10,
          className: "right-align nowrap line-height-5 pr-2",
          children: Data_Array.singleton(React_Basic_DOM_Generated.label_(Data_Array.singleton(Optimus_Client_Antd_Typography.text()({
            strong: true,
            children: [React_Basic_DOM.text(key + ":")]
          }))))
        }), Optimus_Client_Antd_Grid.col()({
          xs: 14,
          children: Data_Array.singleton(value)
        })]
      });
    };
  };

  var renderCard = function renderCard(v) {
    return Optimus_Client_Antd_Card.card()({
      title: Data_Maybe.fromMaybe("(No Code)")(v.code),
      children: [tagInfoPair("Date Linked")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowDateTiem)(v.linkedAt)), tagInfoPair("UUID")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.uuid)), tagInfoPair("Barcode")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.barcode)), tagInfoPair("Magstripe")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.magstripe)), tagInfoPair("Holding")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.holding)(function (v1) {
        return v1.name;
      }))), tagInfoPair("Label")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.label))]
    });
  };

  var render = function render(props) {
    return Optimus_Client_Component_Content.content({
      title: "Tags",
      children: Data_Array.singleton(Optimus_Client_Component_Grid.grid({
        gutter: 2,
        className: "mw-100",
        rowItemCount: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4
        },
        items: Data_Functor.mapFlipped(Data_Functor.functorArray)(props.pgData)(renderCard)
      }))
    });
  };

  return React_Basic.makeStateless(component)(render);
}();

module.exports = {
  tagsPage: tagsPage
};