// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var inputNumber = function inputNumber(dictUnion) {
  return React_Basic.element($foreign["_inputNumber"]);
};

module.exports = {
  inputNumber: inputNumber
};