// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Select = require("../Optimus.Client.Antd.Select/index.js");

var Optimus_Utilities_Default = require("../Optimus.Utilities.Default/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Type_Proxy = require("../Type.Proxy/index.js");

var SingleSelection = function SingleSelection(toSingleSelectionSpec) {
  this.toSingleSelectionSpec = toSingleSelectionSpec;
};

var toSingleSelectionSpec = function toSingleSelectionSpec(dict) {
  return dict.toSingleSelectionSpec;
};

var singleSelectionSum = function singleSelectionSum(dictSingleSelection) {
  return function (dictSingleSelection1) {
    return new SingleSelection(function (v) {
      var ro = toSingleSelectionSpec(dictSingleSelection1)(Type_Proxy["Proxy"].value);
      var lo = toSingleSelectionSpec(dictSingleSelection)(Type_Proxy["Proxy"].value);
      return {
        options: Data_Semigroup.append(Data_Semigroup.semigroupArray)(lo.options)(ro.options),
        reader: function reader(s) {
          return Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Generic_Rep.Inl.create)(lo.reader(s)))(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Generic_Rep.Inr.create)(ro.reader(s)));
        },
        writer: function writer(v1) {
          if (v1 instanceof Data_Generic_Rep.Inl) {
            return lo.writer(v1.value0);
          }

          ;

          if (v1 instanceof Data_Generic_Rep.Inr) {
            return ro.writer(v1.value0);
          }

          ;
          throw new Error("Failed pattern match at Optimus.Client.Components.Settings.SingleSelection (line 44, column 13 - line 46, column 27): " + [v1.constructor.name]);
        }
      };
    });
  };
};

var singleSelectionConsArg = function singleSelectionConsArg(dictIsSymbol) {
  return function (dictDefault) {
    return new SingleSelection(function (v) {
      var option = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
      return {
        options: [option],
        reader: function reader(s) {
          var $16 = option === s;

          if ($16) {
            return Data_Maybe.Just.create(Optimus_Utilities_Default["default"](dictDefault));
          }

          ;
          return Data_Maybe.Nothing.value;
        },
        writer: function writer(rep) {
          return option;
        }
      };
    });
  };
};

var singleSelectionCons = function singleSelectionCons(dictIsSymbol) {
  return new SingleSelection(function (v) {
    var option = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
    return {
      options: [option],
      reader: function reader(s) {
        var $17 = option === s;

        if ($17) {
          return Data_Maybe.Just.create(Data_Generic_Rep.NoArguments.value);
        }

        ;
        return Data_Maybe.Nothing.value;
      },
      writer: function writer(rep) {
        return option;
      }
    };
  });
};

var genToSingleSelectionSpec = function genToSingleSelectionSpec(dictGeneric) {
  return function (dictSingleSelection) {
    return function (p) {
      return toSingleSelectionSpec(dictSingleSelection)(Type_Proxy["Proxy"].value);
    };
  };
};

var createSelect = function createSelect(dictGeneric) {
  return function (v) {
    return function (label) {
      return function (handler) {
        return function (spec) {
          return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Client_Antd_Select.select()({
            children: Data_Functor.mapFlipped(Data_Functor.functorArray)(spec.options)(function (l) {
              return Optimus_Client_Antd_Select.option()({
                value: l,
                children: [React_Basic_DOM.text(l)]
              });
            }),
            defaultValue: spec.writer(Data_Generic_Rep.from(dictGeneric)(v)),
            onChange: function onChange(newVal) {
              return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(spec.reader(newVal))(Data_Generic_Rep.to(dictGeneric)))(handler)();
            }
          })]);
        };
      };
    };
  };
};

var createRadio = function createRadio(dictGeneric) {
  return function (v) {
    return function (label) {
      return function (handler) {
        return function (spec) {
          return React_Basic_DOM_Generated.div_(Data_Functor.mapFlipped(Data_Functor.functorArray)(spec.options)(function (option) {
            return React_Basic_DOM_Generated.div_([React_Basic_DOM.text(label), React_Basic_DOM_Generated.input()({
              type: "radio",
              value: spec.writer(Data_Generic_Rep.from(dictGeneric)(v)),
              onChange: React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(function (newVal) {
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(newVal)(spec.reader))(function ($18) {
                  return handler(Data_Generic_Rep.to(dictGeneric)($18));
                });
              })
            })]);
          }));
        };
      };
    };
  };
};

module.exports = {
  SingleSelection: SingleSelection,
  toSingleSelectionSpec: toSingleSelectionSpec,
  genToSingleSelectionSpec: genToSingleSelectionSpec,
  createSelect: createSelect,
  createRadio: createRadio,
  singleSelectionCons: singleSelectionCons,
  singleSelectionSum: singleSelectionSum,
  singleSelectionConsArg: singleSelectionConsArg
};