// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Prelude = require("../Prelude/index.js");

var Web_File_File = require("../Web.File.File/index.js");

var item = function item(i) {
  return function ($0) {
    return Data_Nullable.toMaybe($foreign["_item"](i)($0));
  };
};

module.exports = {
  item: item,
  length: $foreign.length
};