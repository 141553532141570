// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  abs: $foreign.abs,
  acos: $foreign.acos,
  asin: $foreign.asin,
  atan: $foreign.atan,
  atan2: $foreign.atan2,
  ceil: $foreign.ceil,
  cos: $foreign.cos,
  exp: $foreign.exp,
  floor: $foreign.floor,
  log: $foreign.log,
  max: $foreign.max,
  min: $foreign.min,
  pow: $foreign.pow,
  round: $foreign.round,
  sin: $foreign.sin,
  sqrt: $foreign.sqrt,
  tan: $foreign.tan,
  trunc: $foreign.trunc,
  remainder: $foreign.remainder,
  e: $foreign.e,
  ln2: $foreign.ln2,
  ln10: $foreign.ln10,
  log2e: $foreign.log2e,
  log10e: $foreign.log10e,
  pi: $foreign.pi,
  tau: $foreign.tau,
  sqrt1_2: $foreign.sqrt1_2,
  sqrt2: $foreign.sqrt2
};