// Generated by purs version 0.12.3
"use strict";

var Control_Biapplicative = require("../Control.Biapplicative/index.js");

var Control_Biapply = require("../Control.Biapply/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Wrap = function Wrap(x) {
  return x;
};

var showWrap = function showWrap(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Wrap " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var ordWrap = function ordWrap(dictOrd) {
  return dictOrd;
};

var newtypeWrap = new Data_Newtype.Newtype(function (n) {
  return n;
}, Wrap);

var functorWrap = function functorWrap(dictBifunctor) {
  return new Data_Functor.Functor(function (f) {
    return function (v) {
      return Data_Bifunctor.rmap(dictBifunctor)(f)(v);
    };
  });
};

var eqWrap = function eqWrap(dictEq) {
  return dictEq;
};

var bifunctorWrap = function bifunctorWrap(dictBifunctor) {
  return new Data_Bifunctor.Bifunctor(function (f) {
    return function (g) {
      return function (v) {
        return Data_Bifunctor.bimap(dictBifunctor)(f)(g)(v);
      };
    };
  });
};

var biapplyWrap = function biapplyWrap(dictBiapply) {
  return new Control_Biapply.Biapply(function () {
    return bifunctorWrap(dictBiapply.Bifunctor0());
  }, function (v) {
    return function (v1) {
      return Control_Biapply.biapply(dictBiapply)(v)(v1);
    };
  });
};

var biapplicativeWrap = function biapplicativeWrap(dictBiapplicative) {
  return new Control_Biapplicative.Biapplicative(function () {
    return biapplyWrap(dictBiapplicative.Biapply0());
  }, function (a) {
    return function (b) {
      return Control_Biapplicative.bipure(dictBiapplicative)(a)(b);
    };
  });
};

module.exports = {
  Wrap: Wrap,
  newtypeWrap: newtypeWrap,
  eqWrap: eqWrap,
  ordWrap: ordWrap,
  showWrap: showWrap,
  functorWrap: functorWrap,
  bifunctorWrap: bifunctorWrap,
  biapplyWrap: biapplyWrap,
  biapplicativeWrap: biapplicativeWrap
};