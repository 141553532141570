// Generated by purs version 0.12.3
"use strict";

var Data_Function = require("../Data.Function/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Optimus_Client_ApiClient_Utilities = require("../Optimus.Client.ApiClient.Utilities/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Optimus_Shared_Bridge = require("../Optimus.Shared.Bridge/index.js");

var Optimus_Shared_Settings = require("../Optimus.Shared.Settings/index.js");

var Prelude = require("../Prelude/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var settingsUrl = function settingsUrl(entity) {
  return function (id) {
    return function (siloId) {
      return "/" + (siloId + ("/tmp/settings/" + (Data_Show.show(Optimus_Shared_Settings.showEntity)(entity) + ("/" + id))));
    };
  };
};

var updateSettings = function updateSettings(entity) {
  return function (id) {
    return function (siloId) {
      return Optimus_Client_ApiClient_Utilities.postAuthorizedApi(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "settings";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "checkBalanceAuthenticationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteAuthorizationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "clearingPeriod";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "countryAlpha2";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "defaultLocation";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "hasCashbackOnRedemptionOverflow";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isClearedVoidable";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnEmailReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnSmsReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isIdMethodByUnverifiedTagAllowed";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isLinkCardOnRegistrationRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isPayInCashEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnEarnRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnRedeemRequired";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isQuickRedeemEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemEmailReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemSmsReceiptEnabled";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteBoolean)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "linkTagVerificationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteLinkTagVerificationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "phoneNumberPrefix";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "posSetUp";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())()))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCooldown";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCountLimit";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteInt)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrValidityDuration";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteMillis)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scalingMethod";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_Clients_Mercanti.zrpcWriteRoundingMode))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "senderEmail";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "timeZone";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteString)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionAmountComputationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteTransactionAmountComputationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionCurrency";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_ZRPCWrite.zrpcWriteInt)))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "voidAuthenticationMode";
      }))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_Clients_Mercanti.zrpcWriteSetMode(ZRPC_Clients_Mercanti.zrpcWriteAuthorizationMode)))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())())())())())())())())())())())())())())())())())())())())())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Shared_Bridge.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Shared_Settings.zrpcReadSettingsErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadUnit)))))(settingsUrl(entity)(id)(siloId));
    };
  };
};

var updateBranchSettings = updateSettings(Optimus_Shared_Settings.Branch.value);
var updateHoldingSettings = updateSettings(Optimus_Shared_Settings.Holding.value);
var updateMerchantSettings = updateSettings(Optimus_Shared_Settings.Merchant.value);

var getSettings = function getSettings(entity) {
  return function (id) {
    return function (siloId) {
      return Optimus_Client_ApiClient_Utilities.getAuthorizedApi(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "settings";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "checkBalanceAuthenticationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "clearingPeriod";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "countryAlpha2";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "defaultLocation";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "hasCashbackOnRedemptionOverflow";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isClearedVoidable";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnEmailReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isEarnSmsReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isIdMethodByUnverifiedTagAllowed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isLinkCardOnRegistrationRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isPayInCashEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnEarnRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnRedeemRequired";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isQuickRedeemEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemEmailReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "isRedeemSmsReceiptEnabled";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "linkTagVerificationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadLinkTagVerificationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadLinkTagVerificationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "phoneNumberPrefix";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "posSetUp";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCooldown";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrScanCountLimit";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "qrValidityDuration";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadMillis))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scalingMethod";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadRoundingMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadRoundingMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "senderEmail";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "timeZone";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionAmountComputationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadTransactionAmountComputationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadTransactionAmountComputationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionCurrency";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "voidAuthenticationMode";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "default";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "provider";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadProvider))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
      }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fixed";
      }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_Clients_Mercanti.zrpcReadAuthorizationMode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()()))(settingsUrl(entity)(id)(siloId));
    };
  };
};

var getMerchantSettings = getSettings(Optimus_Shared_Settings.Merchant.value);
var getHoldingSettings = getSettings(Optimus_Shared_Settings.Holding.value);
var getBranchSettings = getSettings(Optimus_Shared_Settings.Branch.value);
module.exports = {
  settingsUrl: settingsUrl,
  getSettings: getSettings,
  getHoldingSettings: getHoldingSettings,
  getMerchantSettings: getMerchantSettings,
  getBranchSettings: getBranchSettings,
  updateSettings: updateSettings,
  updateHoldingSettings: updateHoldingSettings,
  updateMerchantSettings: updateMerchantSettings,
  updateBranchSettings: updateBranchSettings
};