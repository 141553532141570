// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var warnShow = function warnShow(dictShow) {
  return function (a) {
    return $foreign.warn(Data_Show.show(dictShow)(a));
  };
};

var logShow = function logShow(dictShow) {
  return function (a) {
    return $foreign.log(Data_Show.show(dictShow)(a));
  };
};

var infoShow = function infoShow(dictShow) {
  return function (a) {
    return $foreign.info(Data_Show.show(dictShow)(a));
  };
};

var errorShow = function errorShow(dictShow) {
  return function (a) {
    return $foreign.error(Data_Show.show(dictShow)(a));
  };
};

module.exports = {
  logShow: logShow,
  warnShow: warnShow,
  errorShow: errorShow,
  infoShow: infoShow,
  log: $foreign.log,
  warn: $foreign.warn,
  error: $foreign.error,
  info: $foreign.info,
  time: $foreign.time,
  timeEnd: $foreign.timeEnd
};