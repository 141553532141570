import 'antd/dist/antd.css';
import './css/index.css';

import { main } from '../../output/Optimus.Client.Main';

if (module.hot) {
  module.hot.accept(function() {
    console.log('running main again');
    main();
  });
}
main();
