// Generated by purs version 0.12.3
"use strict";

var Prelude = require("../Prelude/index.js");

var MonadGen = function MonadGen(Monad0, chooseBool, chooseFloat, chooseInt, resize, sized) {
  this.Monad0 = Monad0;
  this.chooseBool = chooseBool;
  this.chooseFloat = chooseFloat;
  this.chooseInt = chooseInt;
  this.resize = resize;
  this.sized = sized;
};

var sized = function sized(dict) {
  return dict.sized;
};

var resize = function resize(dict) {
  return dict.resize;
};

var chooseInt = function chooseInt(dict) {
  return dict.chooseInt;
};

var chooseFloat = function chooseFloat(dict) {
  return dict.chooseFloat;
};

var chooseBool = function chooseBool(dict) {
  return dict.chooseBool;
};

module.exports = {
  chooseBool: chooseBool,
  chooseFloat: chooseFloat,
  chooseInt: chooseInt,
  resize: resize,
  sized: sized,
  MonadGen: MonadGen
};