// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Prelude = require("../Prelude/index.js");

var GenericEq = function GenericEq(genericEq$prime) {
  this["genericEq'"] = genericEq$prime;
};

var genericEqNoConstructors = new GenericEq(function (v) {
  return function (v1) {
    return true;
  };
});
var genericEqNoArguments = new GenericEq(function (v) {
  return function (v1) {
    return true;
  };
});

var genericEqArgument = function genericEqArgument(dictEq) {
  return new GenericEq(function (v) {
    return function (v1) {
      return Data_Eq.eq(dictEq)(v)(v1);
    };
  });
};

var genericEq$prime = function genericEq$prime(dict) {
  return dict["genericEq'"];
};

var genericEqConstructor = function genericEqConstructor(dictGenericEq) {
  return new GenericEq(function (v) {
    return function (v1) {
      return genericEq$prime(dictGenericEq)(v)(v1);
    };
  });
};

var genericEqProduct = function genericEqProduct(dictGenericEq) {
  return function (dictGenericEq1) {
    return new GenericEq(function (v) {
      return function (v1) {
        return genericEq$prime(dictGenericEq)(v.value0)(v1.value0) && genericEq$prime(dictGenericEq1)(v.value1)(v1.value1);
      };
    });
  };
};

var genericEqSum = function genericEqSum(dictGenericEq) {
  return function (dictGenericEq1) {
    return new GenericEq(function (v) {
      return function (v1) {
        if (v instanceof Data_Generic_Rep.Inl && v1 instanceof Data_Generic_Rep.Inl) {
          return genericEq$prime(dictGenericEq)(v.value0)(v1.value0);
        }

        ;

        if (v instanceof Data_Generic_Rep.Inr && v1 instanceof Data_Generic_Rep.Inr) {
          return genericEq$prime(dictGenericEq1)(v.value0)(v1.value0);
        }

        ;
        return false;
      };
    });
  };
};

var genericEq = function genericEq(dictGeneric) {
  return function (dictGenericEq) {
    return function (x) {
      return function (y) {
        return genericEq$prime(dictGenericEq)(Data_Generic_Rep.from(dictGeneric)(x))(Data_Generic_Rep.from(dictGeneric)(y));
      };
    };
  };
};

module.exports = {
  GenericEq: GenericEq,
  "genericEq'": genericEq$prime,
  genericEq: genericEq,
  genericEqNoConstructors: genericEqNoConstructors,
  genericEqNoArguments: genericEqNoArguments,
  genericEqSum: genericEqSum,
  genericEqProduct: genericEqProduct,
  genericEqConstructor: genericEqConstructor,
  genericEqArgument: genericEqArgument
};