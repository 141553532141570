// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Comonad = require("../Control.Comonad/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_MonadPlus = require("../Control.MonadPlus/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var App = function App(x) {
  return x;
};

var traversableWithIndexApp = function traversableWithIndexApp(dictTraversableWithIndex) {
  return dictTraversableWithIndex;
};

var traversableApp = function traversableApp(dictTraversable) {
  return dictTraversable;
};

var showApp = function showApp(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(App " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semigroupApp = function semigroupApp(dictApply) {
  return function (dictSemigroup) {
    return new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
        return Control_Apply.lift2(dictApply)(Data_Semigroup.append(dictSemigroup))(v)(v1);
      };
    });
  };
};

var plusApp = function plusApp(dictPlus) {
  return dictPlus;
};

var newtypeApp = new Data_Newtype.Newtype(function (n) {
  return n;
}, App);

var monoidApp = function monoidApp(dictApplicative) {
  return function (dictMonoid) {
    return new Data_Monoid.Monoid(function () {
      return semigroupApp(dictApplicative.Apply0())(dictMonoid.Semigroup0());
    }, Control_Applicative.pure(dictApplicative)(Data_Monoid.mempty(dictMonoid)));
  };
};

var monadZeroApp = function monadZeroApp(dictMonadZero) {
  return dictMonadZero;
};

var monadPlusApp = function monadPlusApp(dictMonadPlus) {
  return dictMonadPlus;
};

var monadApp = function monadApp(dictMonad) {
  return dictMonad;
};

var lazyApp = function lazyApp(dictLazy) {
  return dictLazy;
};

var hoistLowerApp = Unsafe_Coerce.unsafeCoerce;
var hoistLiftApp = Unsafe_Coerce.unsafeCoerce;

var hoistApp = function hoistApp(f) {
  return function (v) {
    return f(v);
  };
};

var functorWithIndexApp = function functorWithIndexApp(dictFunctorWithIndex) {
  return dictFunctorWithIndex;
};

var functorApp = function functorApp(dictFunctor) {
  return dictFunctor;
};

var foldableWithIndexApp = function foldableWithIndexApp(dictFoldableWithIndex) {
  return dictFoldableWithIndex;
};

var foldableApp = function foldableApp(dictFoldable) {
  return dictFoldable;
};

var extendApp = function extendApp(dictExtend) {
  return dictExtend;
};

var eqApp = function eqApp(dictEq1) {
  return function (dictEq) {
    return new Data_Eq.Eq(function (x) {
      return function (y) {
        return Data_Eq.eq1(dictEq1)(dictEq)(x)(y);
      };
    });
  };
};

var ordApp = function ordApp(dictOrd1) {
  return function (dictOrd) {
    return new Data_Ord.Ord(function () {
      return eqApp(dictOrd1.Eq10())(dictOrd.Eq0());
    }, function (x) {
      return function (y) {
        return Data_Ord.compare1(dictOrd1)(dictOrd)(x)(y);
      };
    });
  };
};

var eq1App = function eq1App(dictEq1) {
  return new Data_Eq.Eq1(function (dictEq) {
    return Data_Eq.eq(eqApp(dictEq1)(dictEq));
  });
};

var ord1App = function ord1App(dictOrd1) {
  return new Data_Ord.Ord1(function () {
    return eq1App(dictOrd1.Eq10());
  }, function (dictOrd) {
    return Data_Ord.compare(ordApp(dictOrd1)(dictOrd));
  });
};

var comonadApp = function comonadApp(dictComonad) {
  return dictComonad;
};

var bindApp = function bindApp(dictBind) {
  return dictBind;
};

var applyApp = function applyApp(dictApply) {
  return dictApply;
};

var applicativeApp = function applicativeApp(dictApplicative) {
  return dictApplicative;
};

var alternativeApp = function alternativeApp(dictAlternative) {
  return dictAlternative;
};

var altApp = function altApp(dictAlt) {
  return dictAlt;
};

module.exports = {
  App: App,
  hoistApp: hoistApp,
  hoistLiftApp: hoistLiftApp,
  hoistLowerApp: hoistLowerApp,
  newtypeApp: newtypeApp,
  eqApp: eqApp,
  eq1App: eq1App,
  ordApp: ordApp,
  ord1App: ord1App,
  showApp: showApp,
  semigroupApp: semigroupApp,
  monoidApp: monoidApp,
  functorApp: functorApp,
  functorWithIndexApp: functorWithIndexApp,
  applyApp: applyApp,
  applicativeApp: applicativeApp,
  bindApp: bindApp,
  monadApp: monadApp,
  altApp: altApp,
  plusApp: plusApp,
  alternativeApp: alternativeApp,
  monadZeroApp: monadZeroApp,
  monadPlusApp: monadPlusApp,
  lazyApp: lazyApp,
  foldableApp: foldableApp,
  traversableApp: traversableApp,
  foldableWithIndexApp: foldableWithIndexApp,
  traversableWithIndexApp: traversableWithIndexApp,
  extendApp: extendApp,
  comonadApp: comonadApp
};