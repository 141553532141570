// Generated by purs version 0.12.3
"use strict";

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var MonadState = function MonadState(Monad0, state) {
  this.Monad0 = Monad0;
  this.state = state;
};

var state = function state(dict) {
  return dict.state;
};

var put = function put(dictMonadState) {
  return function (s) {
    return state(dictMonadState)(function (v) {
      return new Data_Tuple.Tuple(Data_Unit.unit, s);
    });
  };
};

var modify_ = function modify_(dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
    });
  };
};

var modify = function modify(dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      var s$prime = f(s);
      return new Data_Tuple.Tuple(s$prime, s$prime);
    });
  };
};

var gets = function gets(dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      return new Data_Tuple.Tuple(f(s), s);
    });
  };
};

var get = function get(dictMonadState) {
  return state(dictMonadState)(function (s) {
    return new Data_Tuple.Tuple(s, s);
  });
};

module.exports = {
  state: state,
  MonadState: MonadState,
  get: get,
  gets: gets,
  put: put,
  modify: modify,
  modify_: modify_
};