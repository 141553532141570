// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Button = require("../Optimus.Client.Antd.Button/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Select = require("../Optimus.Client.Antd.Select/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

var Optimus_Client_Auth = require("../Optimus.Client.Auth/index.js");

var Optimus_Client_Constants = require("../Optimus.Client.Constants/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");

var component = React_Basic.createComponent("Login");

var loginPage = function () {
  var initialState = {
    silo: Data_Maybe.fromMaybe("ZAP")(Data_Array.head(Optimus_Client_Constants.silos))
  };

  var handleSiloChange = function handleSiloChange(v) {
    return function (silo) {
      return v.setState(function (v1) {
        return {
          silo: silo
        };
      });
    };
  };

  var handleLogin = function handleLogin(siloId) {
    return React_Basic_DOM_Events.capture_(function __do() {
      var v = Web_HTML.window();
      var v1 = Web_HTML_Window.localStorage(v)();
      Web_Storage_Storage.setItem("siloId")(siloId)(v1)();
      return Optimus_Client_Auth.authorize();
    });
  };

  var render = function render(self) {
    return Optimus_Client_Antd_Grid.row()({
      type: "flex",
      justify: "center",
      children: [Optimus_Client_Antd_Grid.col()({
        className: "center",
        style: React_Basic_DOM.css({
          marginTop: "100px"
        }),
        children: [Optimus_Client_Antd_Typography.title_([React_Basic_DOM.text("Optimus")]), React_Basic_DOM_Generated.div_([Optimus_Client_Antd_Select.select()({
          className: "my-6",
          style: React_Basic_DOM.css({
            width: "200px"
          }),
          defaultValue: self.state.silo,
          onChange: Effect_Uncurried.mkEffectFn1(handleSiloChange(self)),
          children: Data_Functor.mapFlipped(Data_Functor.functorArray)(Optimus_Client_Constants.silos)(function (s) {
            return Optimus_Client_Antd_Select.option()({
              value: s,
              children: [React_Basic_DOM.text(s)]
            });
          })
        })]), Optimus_Client_Antd_Button.button()({
          type: "primary",
          block: true,
          onClick: handleLogin(self.state.silo),
          children: [React_Basic_DOM.text("Login")]
        })]
      })]
    });
  };

  return React_Basic.make()(component)({
    initialState: initialState,
    render: render
  });
}();

module.exports = {
  component: component,
  loginPage: loginPage
};