// Generated by purs version 0.12.3
"use strict";

var Data_Enum = require("../Data.Enum/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var toCharCode = Data_Enum.fromEnum(Data_Enum.boundedEnumChar);
var fromCharCode = Data_Enum.toEnum(Data_Enum.boundedEnumChar);
module.exports = {
  toCharCode: toCharCode,
  fromCharCode: fromCharCode
};