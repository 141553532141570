// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Effect_Class = require("../Effect.Class/index.js");

var Effect_Class_Console = require("../Effect.Class.Console/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Optimus_Client_ApiClient_Settings = require("../Optimus.Client.ApiClient.Settings/index.js");

var Optimus_Client_AsyncPage = require("../Optimus.Client.AsyncPage/index.js");

var Optimus_Client_Component_Content = require("../Optimus.Client.Component.Content/index.js");

var Optimus_Client_Components_Settings_Form = require("../Optimus.Client.Components.Settings.Form/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Optimus_Shared_Bridge = require("../Optimus.Shared.Bridge/index.js");

var Optimus_Shared_Settings = require("../Optimus.Shared.Settings/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var settingsComp = React_Basic.createComponent("SettingsPage");

var settingsPage = function settingsPage(updateSettings) {
  var render = function render(v) {
    var _onSubmit = function onSubmit(payload) {
      var handleRes = function handleRes(v1) {
        if (v1 instanceof Data_Either.Right && v1.value0 instanceof Data_Either.Right) {
          return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Update Settings Success"));
        }

        ;
        return function __do() {
          Effect_Class_Console.logShow(Effect_Class.monadEffectEffect)(Data_Either.showEither(Effect_Exception.showError)(Data_Either.showEither(Optimus_Shared_Bridge.showResErr)(Data_Either.showEither(Optimus_Shared_Settings.showSettingsErr)(Data_Unit.showUnit))))(v1)();
          return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Update Settings Failed"))();
        };
      };

      return Effect_Aff.runAff_(handleRes)(updateSettings(payload));
    };

    return Optimus_Client_Component_Content.content({
      title: "Settings: " + Data_Maybe.fromMaybe("(No Name)")(v.pgData.name),
      children: [Optimus_Client_Components_Settings_Form.settingsForm({
        settingsLoad: v.pgData.settings,
        onSubmit: function onSubmit(settingsUpdate) {
          return _onSubmit({
            settings: settingsUpdate
          });
        }
      })]
    });
  };

  return React_Basic.makeStateless(settingsComp)(render);
};

var mkSettingsPage = function mkSettingsPage(getSettings) {
  return function (updateSettings) {
    return function (settingsId) {
      return function (siloId) {
        return function (history) {
          return Optimus_Client_AsyncPage.asyncPage({
            fetchData: getSettings(settingsId)(siloId),
            pageView: settingsPage(updateSettings(settingsId)(siloId)),
            history: history,
            siloId: siloId
          });
        };
      };
    };
  };
};

var merchantSettingsPage = mkSettingsPage(Optimus_Client_ApiClient_Settings.getMerchantSettings)(Optimus_Client_ApiClient_Settings.updateMerchantSettings);
var holdingSettingsPage = mkSettingsPage(Optimus_Client_ApiClient_Settings.getHoldingSettings)(Optimus_Client_ApiClient_Settings.updateHoldingSettings);
var branchSettingsPage = mkSettingsPage(Optimus_Client_ApiClient_Settings.getBranchSettings)(Optimus_Client_ApiClient_Settings.updateBranchSettings);
module.exports = {
  holdingSettingsPage: holdingSettingsPage,
  merchantSettingsPage: merchantSettingsPage,
  branchSettingsPage: branchSettingsPage
};