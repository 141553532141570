// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var MediaType = function MediaType(x) {
  return x;
};

var showMediaType = new Data_Show.Show(function (v) {
  return "(MediaType " + (Data_Show.show(Data_Show.showString)(v) + ")");
});
var newtypeMediaType = new Data_Newtype.Newtype(function (n) {
  return n;
}, MediaType);
var eqMediaType = new Data_Eq.Eq(function (x) {
  return function (y) {
    return x === y;
  };
});
var ordMediaType = new Data_Ord.Ord(function () {
  return eqMediaType;
}, function (x) {
  return function (y) {
    return Data_Ord.compare(Data_Ord.ordString)(x)(y);
  };
});
module.exports = {
  MediaType: MediaType,
  newtypeMediaType: newtypeMediaType,
  eqMediaType: eqMediaType,
  ordMediaType: ordMediaType,
  showMediaType: showMediaType
};