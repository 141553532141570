// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var copy = function copy(text) {
  return function () {
    return $foreign["_copy"](text);
  };
};

module.exports = {
  copy: copy,
  "_copy": $foreign["_copy"]
};