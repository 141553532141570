// Generated by purs version 0.12.3
"use strict";

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_MonadPlus = require("../Control.MonadPlus/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_List = require("../Data.List/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Map = require("../Data.Map/index.js");

var Data_Map_Internal = require("../Data.Map.Internal/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Prelude = require("../Prelude/index.js");

var Routing_Types = require("../Routing.Types/index.js");

var parseQueryPart = function parseQueryPart(decoder) {
  var part2tuple = function part2tuple(input) {
    var keyVal = Data_Functor.map(Data_Functor.functorArray)(decoder)(Data_String_Common.split("=")(input));
    return Control_Bind.discard(Control_Bind.discardUnit)(Data_Maybe.bindMaybe)(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe)(Data_Array.length(keyVal) <= 2))(function () {
      return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(Data_Array.head(keyVal)))(Data_Array.index(keyVal)(1));
    });
  };

  return function ($11) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)(part2tuple)(Data_String_Common.split("&")($11)));
  };
};

var parse = function parse(decoder) {
  return function (hash) {
    var pathParts = function pathParts(str) {
      var parts = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(function ($12) {
        return Routing_Types.Path.create(decoder($12));
      })(Data_String_Common.split("/")(str)));
      var v = Data_List.unsnoc(parts);

      if (v instanceof Data_Maybe.Just && v.value0.last instanceof Routing_Types.Path && v.value0.last.value0 === "") {
        return v.value0.init;
      }

      ;
      return parts;
    };

    var v = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Function.flip(Data_String_CodePoints.splitAt)(hash))(Data_String_CodePoints.indexOf("?")(hash));

    if (v instanceof Data_Maybe.Just) {
      return Data_Semigroup.append(Data_List_Types.semigroupList)(pathParts(v.value0.before))(Data_Functor.map(Data_List_Types.functorList)(Routing_Types.Query.create)(Data_List.fromFoldable(Data_Foldable.foldableMaybe)(parseQueryPart(decoder)(Data_String_CodePoints.drop(1)(v.value0.after)))));
    }

    ;

    if (v instanceof Data_Maybe.Nothing) {
      return pathParts(hash);
    }

    ;
    throw new Error("Failed pattern match at Routing.Parser (line 32, column 3 - line 37, column 21): " + [v.constructor.name]);
  };
};

module.exports = {
  parse: parse
};