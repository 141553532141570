// Generated by purs version 0.12.3
"use strict";

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Prelude = require("../Prelude/index.js");

var UnexpectedPath = function () {
  function UnexpectedPath(value0) {
    this.value0 = value0;
  }

  ;

  UnexpectedPath.create = function (value0) {
    return new UnexpectedPath(value0);
  };

  return UnexpectedPath;
}();

var ExpectedBoolean = function () {
  function ExpectedBoolean() {}

  ;
  ExpectedBoolean.value = new ExpectedBoolean();
  return ExpectedBoolean;
}();

var ExpectedEnd = function () {
  function ExpectedEnd() {}

  ;
  ExpectedEnd.value = new ExpectedEnd();
  return ExpectedEnd;
}();

var ExpectedNumber = function () {
  function ExpectedNumber() {}

  ;
  ExpectedNumber.value = new ExpectedNumber();
  return ExpectedNumber;
}();

var ExpectedInt = function () {
  function ExpectedInt() {}

  ;
  ExpectedInt.value = new ExpectedInt();
  return ExpectedInt;
}();

var ExpectedString = function () {
  function ExpectedString() {}

  ;
  ExpectedString.value = new ExpectedString();
  return ExpectedString;
}();

var ExpectedQuery = function () {
  function ExpectedQuery() {}

  ;
  ExpectedQuery.value = new ExpectedQuery();
  return ExpectedQuery;
}();

var ExpectedPathPart = function () {
  function ExpectedPathPart() {}

  ;
  ExpectedPathPart.value = new ExpectedPathPart();
  return ExpectedPathPart;
}();

var KeyNotFound = function () {
  function KeyNotFound(value0) {
    this.value0 = value0;
  }

  ;

  KeyNotFound.create = function (value0) {
    return new KeyNotFound(value0);
  };

  return KeyNotFound;
}();

var Fail = function () {
  function Fail(value0) {
    this.value0 = value0;
  }

  ;

  Fail.create = function (value0) {
    return new Fail(value0);
  };

  return Fail;
}();

var showMatchError = function showMatchError(err) {
  if (err instanceof UnexpectedPath) {
    return "expected path part: " + err.value0;
  }

  ;

  if (err instanceof KeyNotFound) {
    return "key: " + (err.value0 + " has not found in query part");
  }

  ;

  if (err instanceof ExpectedQuery) {
    return "expected query - found path";
  }

  ;

  if (err instanceof ExpectedNumber) {
    return "expected number";
  }

  ;

  if (err instanceof ExpectedInt) {
    return "expected int";
  }

  ;

  if (err instanceof ExpectedBoolean) {
    return "expected boolean";
  }

  ;

  if (err instanceof ExpectedEnd) {
    return "expected end";
  }

  ;

  if (err instanceof ExpectedString) {
    return "expected string var";
  }

  ;

  if (err instanceof ExpectedPathPart) {
    return "expected path part, found query";
  }

  ;

  if (err instanceof Fail) {
    return "match error: " + err.value0;
  }

  ;
  throw new Error("Failed pattern match at Routing.Match.Error (line 29, column 3 - line 39, column 36): " + [err.constructor.name]);
};

module.exports = {
  UnexpectedPath: UnexpectedPath,
  ExpectedBoolean: ExpectedBoolean,
  ExpectedEnd: ExpectedEnd,
  ExpectedNumber: ExpectedNumber,
  ExpectedInt: ExpectedInt,
  ExpectedString: ExpectedString,
  ExpectedQuery: ExpectedQuery,
  ExpectedPathPart: ExpectedPathPart,
  KeyNotFound: KeyNotFound,
  Fail: Fail,
  showMatchError: showMatchError
};