// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Bifunctor_Clown = require("../Data.Bifunctor.Clown/index.js");

var Data_Bifunctor_Flip = require("../Data.Bifunctor.Flip/index.js");

var Data_Bifunctor_Joker = require("../Data.Bifunctor.Joker/index.js");

var Data_Bifunctor_Product = require("../Data.Bifunctor.Product/index.js");

var Data_Bifunctor_Wrap = require("../Data.Bifunctor.Wrap/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Bifoldable = function Bifoldable(bifoldMap, bifoldl, bifoldr) {
  this.bifoldMap = bifoldMap;
  this.bifoldl = bifoldl;
  this.bifoldr = bifoldr;
};

var bifoldr = function bifoldr(dict) {
  return dict.bifoldr;
};

var bitraverse_ = function bitraverse_(dictBifoldable) {
  return function (dictApplicative) {
    return function (f) {
      return function (g) {
        return bifoldr(dictBifoldable)(function ($97) {
          return Control_Apply.applySecond(dictApplicative.Apply0())(f($97));
        })(function ($98) {
          return Control_Apply.applySecond(dictApplicative.Apply0())(g($98));
        })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
      };
    };
  };
};

var bifor_ = function bifor_(dictBifoldable) {
  return function (dictApplicative) {
    return function (t) {
      return function (f) {
        return function (g) {
          return bitraverse_(dictBifoldable)(dictApplicative)(f)(g)(t);
        };
      };
    };
  };
};

var bisequence_ = function bisequence_(dictBifoldable) {
  return function (dictApplicative) {
    return bitraverse_(dictBifoldable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var bifoldl = function bifoldl(dict) {
  return dict.bifoldl;
};

var bifoldableJoker = function bifoldableJoker(dictFoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (v) {
      return function (r) {
        return function (v1) {
          return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(r)(v1);
        };
      };
    };
  }, function (v) {
    return function (r) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldl(dictFoldable)(r)(u)(v1);
        };
      };
    };
  }, function (v) {
    return function (r) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldr(dictFoldable)(r)(u)(v1);
        };
      };
    };
  });
};

var bifoldableClown = function bifoldableClown(dictFoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (l) {
      return function (v) {
        return function (v1) {
          return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(l)(v1);
        };
      };
    };
  }, function (l) {
    return function (v) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldl(dictFoldable)(l)(u)(v1);
        };
      };
    };
  }, function (l) {
    return function (v) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldr(dictFoldable)(l)(u)(v1);
        };
      };
    };
  });
};

var bifoldMapDefaultR = function bifoldMapDefaultR(dictBifoldable) {
  return function (dictMonoid) {
    return function (f) {
      return function (g) {
        return bifoldr(dictBifoldable)(function ($99) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(f($99));
        })(function ($100) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(g($100));
        })(Data_Monoid.mempty(dictMonoid));
      };
    };
  };
};

var bifoldMapDefaultL = function bifoldMapDefaultL(dictBifoldable) {
  return function (dictMonoid) {
    return function (f) {
      return function (g) {
        return bifoldl(dictBifoldable)(function (m) {
          return function (a) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(f(a));
          };
        })(function (m) {
          return function (b) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(g(b));
          };
        })(Data_Monoid.mempty(dictMonoid));
      };
    };
  };
};

var bifoldMap = function bifoldMap(dict) {
  return dict.bifoldMap;
};

var bifoldableFlip = function bifoldableFlip(dictBifoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (r) {
      return function (l) {
        return function (v) {
          return bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v);
        };
      };
    };
  }, function (r) {
    return function (l) {
      return function (u) {
        return function (v) {
          return bifoldl(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  }, function (r) {
    return function (l) {
      return function (u) {
        return function (v) {
          return bifoldr(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  });
};

var bifoldableWrap = function bifoldableWrap(dictBifoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (l) {
      return function (r) {
        return function (v) {
          return bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v);
        };
      };
    };
  }, function (l) {
    return function (r) {
      return function (u) {
        return function (v) {
          return bifoldl(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  }, function (l) {
    return function (r) {
      return function (u) {
        return function (v) {
          return bifoldr(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  });
};

var bifoldlDefault = function bifoldlDefault(dictBifoldable) {
  return function (f) {
    return function (g) {
      return function (z) {
        return function (p) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(bifoldMap(dictBifoldable)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))(function ($101) {
            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo(Data_Function.flip(f)($101)));
          })(function ($102) {
            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo(Data_Function.flip(g)($102)));
          })(p)))(z);
        };
      };
    };
  };
};

var bifoldrDefault = function bifoldrDefault(dictBifoldable) {
  return function (f) {
    return function (g) {
      return function (z) {
        return function (p) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(bifoldMap(dictBifoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function ($103) {
            return Data_Monoid_Endo.Endo(f($103));
          })(function ($104) {
            return Data_Monoid_Endo.Endo(g($104));
          })(p))(z);
        };
      };
    };
  };
};

var bifoldableProduct = function bifoldableProduct(dictBifoldable) {
  return function (dictBifoldable1) {
    return new Bifoldable(function (dictMonoid) {
      return function (l) {
        return function (r) {
          return function (v) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v.value0))(bifoldMap(dictBifoldable1)(dictMonoid)(l)(r)(v.value1));
          };
        };
      };
    }, function (l) {
      return function (r) {
        return function (u) {
          return function (m) {
            return bifoldlDefault(bifoldableProduct(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
          };
        };
      };
    }, function (l) {
      return function (r) {
        return function (u) {
          return function (m) {
            return bifoldrDefault(bifoldableProduct(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
          };
        };
      };
    });
  };
};

var bifold = function bifold(dictBifoldable) {
  return function (dictMonoid) {
    return bifoldMap(dictBifoldable)(dictMonoid)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var biany = function biany(dictBifoldable) {
  return function (dictBooleanAlgebra) {
    return function (p) {
      return function (q) {
        return function ($105) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeDisj)(bifoldMap(dictBifoldable)(Data_Monoid_Disj.monoidDisj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($106) {
            return Data_Monoid_Disj.Disj(p($106));
          })(function ($107) {
            return Data_Monoid_Disj.Disj(q($107));
          })($105));
        };
      };
    };
  };
};

var biall = function biall(dictBifoldable) {
  return function (dictBooleanAlgebra) {
    return function (p) {
      return function (q) {
        return function ($108) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeConj)(bifoldMap(dictBifoldable)(Data_Monoid_Conj.monoidConj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($109) {
            return Data_Monoid_Conj.Conj(p($109));
          })(function ($110) {
            return Data_Monoid_Conj.Conj(q($110));
          })($108));
        };
      };
    };
  };
};

module.exports = {
  bifoldMap: bifoldMap,
  bifoldl: bifoldl,
  bifoldr: bifoldr,
  Bifoldable: Bifoldable,
  bifoldrDefault: bifoldrDefault,
  bifoldlDefault: bifoldlDefault,
  bifoldMapDefaultR: bifoldMapDefaultR,
  bifoldMapDefaultL: bifoldMapDefaultL,
  bifold: bifold,
  bitraverse_: bitraverse_,
  bifor_: bifor_,
  bisequence_: bisequence_,
  biany: biany,
  biall: biall,
  bifoldableClown: bifoldableClown,
  bifoldableJoker: bifoldableJoker,
  bifoldableFlip: bifoldableFlip,
  bifoldableProduct: bifoldableProduct,
  bifoldableWrap: bifoldableWrap
};