// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Button = require("../Optimus.Client.Antd.Button/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Components_Settings_SettingInput = require("../Optimus.Client.Components.Settings.SettingInput/index.js");

var Optimus_Client_Components_Settings_ShowDefaultVal = require("../Optimus.Client.Components.Settings.ShowDefaultVal/index.js");

var Optimus_Client_Components_Settings_Utilities = require("../Optimus.Client.Components.Settings.Utilities/index.js");

var Optimus_Utilities_Default = require("../Optimus.Utilities.Default/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Type_Data_Boolean = require("../Type.Data.Boolean/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var component = React_Basic.createComponent("SettingsForm");

var settingsForm = function settingsForm(props) {
  var render = function render(self) {
    return React_Basic_DOM_Generated.form()({
      children: [Optimus_Client_Antd_Grid.row_(Optimus_Client_Components_Settings_SettingInput.toSettingInputs(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "checkBalanceAuthenticationMode";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputAuthorizationMode)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValAM))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "clearingPeriod";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "countryAlpha2";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "defaultLocation";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "hasCashbackOnRedemptionOverflow";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isClearedVoidable";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isEarnEmailReceiptEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isEarnSmsReceiptEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isIdMethodByUnverifiedTagAllowed";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isLinkCardOnRegistrationRequired";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isPayInCashEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnEarnRequired";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isProfileOnRedeemRequired";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isQuickRedeemEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isRedeemEmailReceiptEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "isRedeemSmsReceiptEnabled";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputBool)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultBoolean))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "linkTagVerificationMode";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputLinkTagVM)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValLTVM))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "phoneNumberPrefix";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "posSetUp";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputRecord(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputString)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsNil))))))(Optimus_Utilities_Default.defaultsRecord()(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(Optimus_Utilities_Default.defaultString)(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(Optimus_Utilities_Default.defaultString)(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(Optimus_Utilities_Default.defaultString)(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(Optimus_Utilities_Default.defaultString)(Optimus_Utilities_Default.defaultsRLNil)()())()())()())()())))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultRecord(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRecord()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundColor";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "backgroundUrl";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "foregroundColor";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "logoUrl";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLNil)()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString)))))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "qrScanCooldown";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "qrScanCountLimit";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputInt)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultInt))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "qrValidityDuration";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputMillis)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValMillis))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "scalingMethod";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputRecord(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRecord()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputInt)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(Optimus_Client_Components_Settings_SettingInput.toSettingInputRoundingMode)()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsNil))))(Optimus_Utilities_Default.defaultsRecord()(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(Optimus_Utilities_Default.defaultInt)(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(Optimus_Utilities_Default.defaultRoundingMode)(Optimus_Utilities_Default.defaultsRLNil)()())()())))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultRecord(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRecord()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "cashbackDecimal";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLCons(new Data_Symbol.IsSymbol(function () {
        return "roundingMode";
      }))(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValsRLNil)()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultRoundingMode))()(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultInt)))))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "senderEmail";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "timeZone";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputString)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValString))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "transactionAmountComputationMode";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputTransactionAmountComputationMode)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValTransactionAmountComputationMode))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "transactionCurrency";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputInt)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultInt))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
        return "voidAuthenticationMode";
      }))(Optimus_Client_Components_Settings_SettingInput["toSettingInputSettingFormState'"](Optimus_Client_Components_Settings_SettingInput.toSettingInputAuthorizationMode)(Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultValAM))()(Optimus_Client_Components_Settings_SettingInput.toSettingInputsNil))))))))))))))))))))))))))))))(function (v) {
        return self.setState(function (s) {
          return {
            formData: v
          };
        });
      })(self.state.formData)), React_Basic_DOM_Generated.div()({
        className: "p-3",
        children: [Optimus_Client_Antd_Button.button()({
          type: "primary",
          block: true,
          children: [React_Basic_DOM.text("Submit")],
          onClick: React_Basic_DOM_Events.capture_(self.props.onSubmit(Optimus_Client_Components_Settings_Utilities.settingSFSToSU(self.state.formData)))
        })]
      })]
    });
  };

  return React_Basic.make()(component)({
    initialState: {
      formData: Optimus_Client_Components_Settings_Utilities.settingSLToSFS(props.settingsLoad)
    },
    render: render
  })(props);
};

module.exports = {
  settingsForm: settingsForm
};