// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Bounded = function Bounded(Ord0, bottom, top) {
  this.Ord0 = Ord0;
  this.bottom = bottom;
  this.top = top;
};

var top = function top(dict) {
  return dict.top;
};

var boundedUnit = new Bounded(function () {
  return Data_Ord.ordUnit;
}, Data_Unit.unit, Data_Unit.unit);
var boundedOrdering = new Bounded(function () {
  return Data_Ord.ordOrdering;
}, Data_Ordering.LT.value, Data_Ordering.GT.value);
var boundedNumber = new Bounded(function () {
  return Data_Ord.ordNumber;
}, $foreign.bottomNumber, $foreign.topNumber);
var boundedInt = new Bounded(function () {
  return Data_Ord.ordInt;
}, $foreign.bottomInt, $foreign.topInt);
var boundedChar = new Bounded(function () {
  return Data_Ord.ordChar;
}, $foreign.bottomChar, $foreign.topChar);
var boundedBoolean = new Bounded(function () {
  return Data_Ord.ordBoolean;
}, false, true);

var bottom = function bottom(dict) {
  return dict.bottom;
};

module.exports = {
  Bounded: Bounded,
  bottom: bottom,
  top: top,
  boundedBoolean: boundedBoolean,
  boundedInt: boundedInt,
  boundedChar: boundedChar,
  boundedOrdering: boundedOrdering,
  boundedUnit: boundedUnit,
  boundedNumber: boundedNumber
};