// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Constants = require("../Optimus.Client.Constants/index.js");

var Prelude = require("../Prelude/index.js");

var Routing_Duplex = require("../Routing.Duplex/index.js");

var Routing_Duplex_Generic = require("../Routing.Duplex.Generic/index.js");

var Routing_Duplex_Generic_Syntax = require("../Routing.Duplex.Generic.Syntax/index.js");

var Routing_PushState = require("../Routing.PushState/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var UsersSearch = function () {
  function UsersSearch(value0) {
    this.value0 = value0;
  }

  ;

  UsersSearch.create = function (value0) {
    return new UsersSearch(value0);
  };

  return UsersSearch;
}();

var UsersOverview = function () {
  function UsersOverview(value0) {
    this.value0 = value0;
  }

  ;

  UsersOverview.create = function (value0) {
    return new UsersOverview(value0);
  };

  return UsersOverview;
}();

var UsersChangeEmail = function () {
  function UsersChangeEmail(value0) {
    this.value0 = value0;
  }

  ;

  UsersChangeEmail.create = function (value0) {
    return new UsersChangeEmail(value0);
  };

  return UsersChangeEmail;
}();

var UsersTags = function () {
  function UsersTags(value0) {
    this.value0 = value0;
  }

  ;

  UsersTags.create = function (value0) {
    return new UsersTags(value0);
  };

  return UsersTags;
}();

var HoldingSettings = function () {
  function HoldingSettings(value0) {
    this.value0 = value0;
  }

  ;

  HoldingSettings.create = function (value0) {
    return new HoldingSettings(value0);
  };

  return HoldingSettings;
}();

var MerchantSettings = function () {
  function MerchantSettings(value0) {
    this.value0 = value0;
  }

  ;

  MerchantSettings.create = function (value0) {
    return new MerchantSettings(value0);
  };

  return MerchantSettings;
}();

var BranchSettings = function () {
  function BranchSettings(value0) {
    this.value0 = value0;
  }

  ;

  BranchSettings.create = function (value0) {
    return new BranchSettings(value0);
  };

  return BranchSettings;
}();

var Users = function () {
  function Users(value0) {
    this.value0 = value0;
  }

  ;

  Users.create = function (value0) {
    return new Users(value0);
  };

  return Users;
}();

var Settings = function () {
  function Settings(value0) {
    this.value0 = value0;
  }

  ;

  Settings.create = function (value0) {
    return new Settings(value0);
  };

  return Settings;
}();

var SiloIndex = function () {
  function SiloIndex() {}

  ;
  SiloIndex.value = new SiloIndex();
  return SiloIndex;
}();

var SiloRoute = function () {
  function SiloRoute(value0) {
    this.value0 = value0;
  }

  ;

  SiloRoute.create = function (value0) {
    return new SiloRoute(value0);
  };

  return SiloRoute;
}();

var Index = function () {
  function Index() {}

  ;
  Index.value = new Index();
  return Index;
}();

var Login = function () {
  function Login() {}

  ;
  Login.value = new Login();
  return Login;
}();

var Callback = function () {
  function Callback() {}

  ;
  Callback.value = new Callback();
  return Callback;
}();

var Route = function () {
  function Route(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  Route.create = function (value0) {
    return function (value1) {
      return new Route(value0, value1);
    };
  };

  return Route;
}();

var silo = function () {
  var siloFromString = function siloFromString(s) {
    var mSilo = Data_Foldable.find(Data_Foldable.foldableArray)(function (v) {
      return v === s;
    })(Optimus_Client_Constants.silos);

    if (mSilo instanceof Data_Maybe.Just) {
      return new Data_Either.Right(mSilo.value0);
    }

    ;
    return new Data_Either.Left("Not a silo");
  };

  return Routing_Duplex.as(Control_Category.identity(Control_Category.categoryFn))(siloFromString)(Routing_Duplex.segment);
}();

var genericUsersRoute = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof UsersSearch) {
    return new Data_Generic_Rep.Inl(x.value0);
  }

  ;

  if (x instanceof UsersOverview) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
  }

  ;

  if (x instanceof UsersChangeEmail) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
  }

  ;

  if (x instanceof UsersTags) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 59, column 8 - line 59, column 58): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return new UsersSearch(x.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return new UsersOverview(x.value0.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return new UsersChangeEmail(x.value0.value0.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return new UsersTags(x.value0.value0.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 59, column 8 - line 59, column 58): " + [x.constructor.name]);
});
var usersRoute = Routing_Duplex.path("users")(Routing_Duplex_Generic.sum(genericUsersRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "UsersSearch";
}))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "UsersOverview";
}))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "UsersChangeEmail";
}))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "UsersTags";
}))()(Routing_Duplex_Generic.gRouteArgument)))))({
  UsersSearch: Routing_Duplex.prop(new Data_Symbol.IsSymbol(function () {
    return "query";
  }))()()()(Data_Symbol.SProxy.value)(Routing_Duplex.optional(Routing_Duplex.param("query")))(Routing_Duplex.record),
  UsersOverview: Routing_Duplex.segment,
  UsersChangeEmail: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepRouteString(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex.segment)("change-email"),
  UsersTags: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepRouteString(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex.segment)("tags")
}));
var genericSiloRoute = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof SiloIndex) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof SiloRoute) {
    return new Data_Generic_Rep.Inr(x.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 57, column 8 - line 57, column 56): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return SiloIndex.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return new SiloRoute(x.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 57, column 8 - line 57, column 56): " + [x.constructor.name]);
});
var genericSettingsRoute = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof HoldingSettings) {
    return new Data_Generic_Rep.Inl(x.value0);
  }

  ;

  if (x instanceof MerchantSettings) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
  }

  ;

  if (x instanceof BranchSettings) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 60, column 8 - line 60, column 64): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return new HoldingSettings(x.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return new MerchantSettings(x.value0.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return new BranchSettings(x.value0.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 60, column 8 - line 60, column 64): " + [x.constructor.name]);
});
var settingsRoute = Routing_Duplex.path("settings")(Routing_Duplex_Generic.sum(genericSettingsRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "HoldingSettings";
}))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "MerchantSettings";
}))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "BranchSettings";
}))()(Routing_Duplex_Generic.gRouteArgument))))({
  HoldingSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("holding")(Routing_Duplex.segment),
  MerchantSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("merchant")(Routing_Duplex.segment),
  BranchSettings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("branch")(Routing_Duplex.segment)
}));
var genericRoute = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Index) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Login) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof Callback) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof Route) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1))));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 56, column 8 - line 56, column 48): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Index.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return Login.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return Callback.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return new Route(x.value0.value0.value0.value0, x.value0.value0.value0.value1);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 56, column 8 - line 56, column 48): " + [x.constructor.name]);
});
var genericModuleRoute = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Users) {
    return new Data_Generic_Rep.Inl(x.value0);
  }

  ;

  if (x instanceof Settings) {
    return new Data_Generic_Rep.Inr(x.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 58, column 8 - line 58, column 60): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return new Users(x.value0);
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return new Settings(x.value0);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Route (line 58, column 8 - line 58, column 60): " + [x.constructor.name]);
});
var moduleRoute = Routing_Duplex_Generic.sum(genericModuleRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Users";
}))()(Routing_Duplex_Generic.gRouteAll))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Settings";
}))()(Routing_Duplex_Generic.gRouteAll)))({
  Users: usersRoute,
  Settings: settingsRoute
});
var siloRoute = Routing_Duplex_Generic.sum(genericSiloRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "SiloIndex";
}))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "SiloRoute";
}))()(Routing_Duplex_Generic.gRouteAll)))({
  SiloIndex: Routing_Duplex_Generic.noArgs,
  SiloRoute: moduleRoute
});
var route = Routing_Duplex.root(Routing_Duplex_Generic.sum(genericRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Index";
}))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Login";
}))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Callback";
}))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
  return "Route";
}))()(Routing_Duplex_Generic.gRouteProduct)))))({
  Index: Routing_Duplex_Generic.noArgs,
  Login: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("login")(Routing_Duplex_Generic.noArgs),
  Callback: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("callback")(Routing_Duplex_Generic.noArgs),
  Route: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepProduct(Routing_Duplex_Generic.gRouteAll))(silo)(siloRoute)
}));

var navToHistory = function navToHistory(nav) {
  var toHistory = function toHistory(f) {
    return function ($71) {
      return f(ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteUnit)(Data_Unit.unit))(Routing_Duplex.print(route)($71));
    };
  };

  return {
    push: toHistory(nav.pushState),
    replace: toHistory(nav.replaceState),
    location: nav.locationState,
    listener: function listener(cb) {
      return Routing_PushState.matchesWith(Data_Either.foldableEither)(Routing_Duplex.parse(route))(cb)(nav);
    }
  };
};

module.exports = {
  Users: Users,
  Settings: Settings,
  Index: Index,
  Login: Login,
  Callback: Callback,
  Route: Route,
  SiloIndex: SiloIndex,
  SiloRoute: SiloRoute,
  UsersSearch: UsersSearch,
  UsersOverview: UsersOverview,
  UsersChangeEmail: UsersChangeEmail,
  UsersTags: UsersTags,
  HoldingSettings: HoldingSettings,
  MerchantSettings: MerchantSettings,
  BranchSettings: BranchSettings,
  route: route,
  navToHistory: navToHistory,
  genericRoute: genericRoute,
  genericSiloRoute: genericSiloRoute,
  genericModuleRoute: genericModuleRoute,
  genericUsersRoute: genericUsersRoute,
  genericSettingsRoute: genericSettingsRoute
};