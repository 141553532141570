// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  baseUrl: $foreign.baseUrl,
  auth0ClientId: $foreign.auth0ClientId,
  auth0Domain: $foreign.auth0Domain,
  auth0Audience: $foreign.auth0Audience
};