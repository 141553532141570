// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_BooleanAlgebra = require("../Data.BooleanAlgebra/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");

var Data_DivisionRing = require("../Data.DivisionRing/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Field = require("../Data.Field/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_NaturalTransformation = require("../Data.NaturalTransformation/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Data_Void = require("../Data.Void/index.js");

module.exports = {};