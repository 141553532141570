// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Effect = require("../Effect/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Optimus_Client_Env = require("../Optimus.Client.Env/index.js");

var Prelude = require("../Prelude/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Location = require("../Web.HTML.Location/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");

var requireAuth = function __do() {
  var v = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage)();
  var v1 = Web_Storage_Storage.getItem("siloId")(v)();
  var v2 = Web_Storage_Storage.getItem("accessToken")(v)();

  if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
    return new Data_Maybe.Just(v1.value0);
  }

  ;
  return Data_Maybe.Nothing.value;
};

var locOrigin = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.origin);
var logoutConfig = Data_Functor.mapFlipped(Effect.functorEffect)(locOrigin)(function (o) {
  return {
    returnTo: o + "/login",
    clientID: Optimus_Client_Env.auth0ClientId
  };
});
var webConfig = Data_Functor.mapFlipped(Effect.functorEffect)(locOrigin)(function (o) {
  return {
    clientID: Optimus_Client_Env.auth0ClientId,
    redirectUri: o + "/callback",
    responseType: "token id_token",
    scope: "openid profile",
    domain: Optimus_Client_Env.auth0Domain,
    audience: Optimus_Client_Env.auth0Audience
  };
});
var webAuth = Control_Bind.bind(Effect.bindEffect)(webConfig)($foreign.mkWebAuth);

var logout = function __do() {
  var v = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage)();
  var v1 = webAuth();
  var v2 = logoutConfig();
  Web_Storage_Storage.removeItem("siloId")(v)();
  Web_Storage_Storage.removeItem("accessToken")(v)();
  return $foreign["_logout"](v1)(v2)();
};

var parseHash = function parseHash(cb) {
  return Control_Bind.bind(Effect.bindEffect)(webAuth)($foreign["_parseHash"](Data_Either.Left.create)(Data_Either.Right.create)(cb));
};

var authorize = Control_Bind.bind(Effect.bindEffect)(webAuth)($foreign["_authorize"]);
module.exports = {
  authorize: authorize,
  parseHash: parseHash,
  logout: logout,
  requireAuth: requireAuth
};