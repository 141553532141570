// Generated by purs version 0.12.3
"use strict";

var Data_Either = require("../Data.Either/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var Unauthorized = function () {
  function Unauthorized() {}

  ;
  Unauthorized.value = new Unauthorized();
  return Unauthorized;
}();

var UnknownError = function () {
  function UnknownError() {}

  ;
  UnknownError.value = new UnknownError();
  return UnknownError;
}();

var BadRequest = function () {
  function BadRequest() {}

  ;
  BadRequest.value = new BadRequest();
  return BadRequest;
}();

var NotFound = function () {
  function NotFound() {}

  ;
  NotFound.value = new NotFound();
  return NotFound;
}();

var InternalError = function () {
  function InternalError() {}

  ;
  InternalError.value = new InternalError();
  return InternalError;
}();

var genericResErr = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Unauthorized) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof UnknownError) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof BadRequest) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof NotFound) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
  }

  ;

  if (x instanceof InternalError) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Bridge (line 15, column 8 - line 15, column 52): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Unauthorized.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return UnknownError.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return BadRequest.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return NotFound.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return InternalError.value;
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Bridge (line 15, column 8 - line 15, column 52): " + [x.constructor.name]);
});
var showResErr = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericResErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UnknownError";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "InternalError";
  })))))))(x);
});
var consPrefixResErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "";
});
var zrpcReadResErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResErr)(genericResErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Unauthorized";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "UnknownError";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "BadRequest";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "NotFound";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "InternalError";
}))))))));
var zrpcWriteResErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixResErr)(genericResErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Unauthorized";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "UnknownError";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "BadRequest";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "NotFound";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "InternalError";
}))))))));
module.exports = {
  Unauthorized: Unauthorized,
  UnknownError: UnknownError,
  BadRequest: BadRequest,
  NotFound: NotFound,
  InternalError: InternalError,
  genericResErr: genericResErr,
  consPrefixResErr: consPrefixResErr,
  zrpcWriteResErr: zrpcWriteResErr,
  zrpcReadResErr: zrpcReadResErr,
  showResErr: showResErr
};