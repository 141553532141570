// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var crash = function crash(dictPartial) {
  return $foreign.crashWith(dictPartial)("Partial.crash: partial function");
};

module.exports = {
  crash: crash,
  crashWith: $foreign.crashWith
};