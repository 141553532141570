// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

module.exports = {
  remove: $foreign.remove
};