// Generated by purs version 0.12.3
"use strict";

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Prelude = require("../Prelude/index.js");

var GenericShow = function GenericShow(genericShow$prime) {
  this["genericShow'"] = genericShow$prime;
};

var GenericShowArgs = function GenericShowArgs(genericShowArgs) {
  this.genericShowArgs = genericShowArgs;
};

var genericShowArgsNoArguments = new GenericShowArgs(function (v) {
  return [];
});

var genericShowArgsArgument = function genericShowArgsArgument(dictShow) {
  return new GenericShowArgs(function (v) {
    return [Data_Show.show(dictShow)(v)];
  });
};

var genericShowArgs = function genericShowArgs(dict) {
  return dict.genericShowArgs;
};

var genericShowArgsProduct = function genericShowArgsProduct(dictGenericShowArgs) {
  return function (dictGenericShowArgs1) {
    return new GenericShowArgs(function (v) {
      return Data_Semigroup.append(Data_Semigroup.semigroupArray)(genericShowArgs(dictGenericShowArgs)(v.value0))(genericShowArgs(dictGenericShowArgs1)(v.value1));
    });
  };
};

var genericShowConstructor = function genericShowConstructor(dictGenericShowArgs) {
  return function (dictIsSymbol) {
    return new GenericShow(function (v) {
      var ctor = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
      var v1 = genericShowArgs(dictGenericShowArgs)(v);

      if (v1.length === 0) {
        return ctor;
      }

      ;
      return "(" + (Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ctor])(v1)) + ")");
    });
  };
};

var genericShow$prime = function genericShow$prime(dict) {
  return dict["genericShow'"];
};

var genericShowNoConstructors = new GenericShow(function (a) {
  return genericShow$prime(genericShowNoConstructors)(a);
});

var genericShowSum = function genericShowSum(dictGenericShow) {
  return function (dictGenericShow1) {
    return new GenericShow(function (v) {
      if (v instanceof Data_Generic_Rep.Inl) {
        return genericShow$prime(dictGenericShow)(v.value0);
      }

      ;

      if (v instanceof Data_Generic_Rep.Inr) {
        return genericShow$prime(dictGenericShow1)(v.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Generic.Rep.Show (line 26, column 1 - line 26, column 83): " + [v.constructor.name]);
    });
  };
};

var genericShow = function genericShow(dictGeneric) {
  return function (dictGenericShow) {
    return function (x) {
      return genericShow$prime(dictGenericShow)(Data_Generic_Rep.from(dictGeneric)(x));
    };
  };
};

module.exports = {
  GenericShow: GenericShow,
  "genericShow'": genericShow$prime,
  genericShow: genericShow,
  GenericShowArgs: GenericShowArgs,
  genericShowArgs: genericShowArgs,
  genericShowNoConstructors: genericShowNoConstructors,
  genericShowArgsNoArguments: genericShowArgsNoArguments,
  genericShowSum: genericShowSum,
  genericShowArgsProduct: genericShowArgsProduct,
  genericShowConstructor: genericShowConstructor,
  genericShowArgsArgument: genericShowArgsArgument
};