// Generated by purs version 0.12.3
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Endo = function Endo(x) {
  return x;
};

var showEndo = function showEndo(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Endo " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semigroupEndo = function semigroupEndo(dictSemigroupoid) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Control_Semigroupoid.compose(dictSemigroupoid)(v)(v1);
    };
  });
};

var ordEndo = function ordEndo(dictOrd) {
  return dictOrd;
};

var monoidEndo = function monoidEndo(dictCategory) {
  return new Data_Monoid.Monoid(function () {
    return semigroupEndo(dictCategory.Semigroupoid0());
  }, Control_Category.identity(dictCategory));
};

var eqEndo = function eqEndo(dictEq) {
  return dictEq;
};

var boundedEndo = function boundedEndo(dictBounded) {
  return dictBounded;
};

module.exports = {
  Endo: Endo,
  eqEndo: eqEndo,
  ordEndo: ordEndo,
  boundedEndo: boundedEndo,
  showEndo: showEndo,
  semigroupEndo: semigroupEndo,
  monoidEndo: monoidEndo
};