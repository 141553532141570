// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

module.exports = {
  mkEffectFn1: $foreign.mkEffectFn1,
  mkEffectFn2: $foreign.mkEffectFn2,
  mkEffectFn3: $foreign.mkEffectFn3,
  mkEffectFn4: $foreign.mkEffectFn4,
  mkEffectFn5: $foreign.mkEffectFn5,
  mkEffectFn6: $foreign.mkEffectFn6,
  mkEffectFn7: $foreign.mkEffectFn7,
  mkEffectFn8: $foreign.mkEffectFn8,
  mkEffectFn9: $foreign.mkEffectFn9,
  mkEffectFn10: $foreign.mkEffectFn10,
  runEffectFn1: $foreign.runEffectFn1,
  runEffectFn2: $foreign.runEffectFn2,
  runEffectFn3: $foreign.runEffectFn3,
  runEffectFn4: $foreign.runEffectFn4,
  runEffectFn5: $foreign.runEffectFn5,
  runEffectFn6: $foreign.runEffectFn6,
  runEffectFn7: $foreign.runEffectFn7,
  runEffectFn8: $foreign.runEffectFn8,
  runEffectFn9: $foreign.runEffectFn9,
  runEffectFn10: $foreign.runEffectFn10
};