// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Breadcrumb = require("../Optimus.Client.Antd.Breadcrumb/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var Routing_Duplex = require("../Routing.Duplex/index.js");

var pathToCrumbs = function pathToCrumbs(path) {
  var removeQueryPath = function removeQueryPath($8) {
    return Data_Maybe.fromMaybe("")(Data_Array.head(Data_String_Common.split("?")($8)));
  };

  var pathsToCrumb = function pathsToCrumb(ps) {
    var routeFromPaths = function routeFromPaths($9) {
      return function ($10) {
        return function (v) {
          if (v instanceof Data_Either.Right) {
            return new Data_Maybe.Just(v.value0);
          }

          ;
          return Data_Maybe.Nothing.value;
        }(Routing_Duplex.parse(Optimus_Client_Route.route)($10));
      }(function (v) {
        return "/" + v;
      }(Data_String_Common.joinWith("/")($9)));
    };

    return {
      name: Data_Maybe.fromMaybe("")(Data_Array.last(ps)),
      route: routeFromPaths(ps)
    };
  };

  var paths = Data_Maybe.fromMaybe([])(Data_Array.tail(Data_Functor.map(Data_Functor.functorArray)(removeQueryPath)(Data_String_Common.split("/")(path))));

  var go = function go($copy_i) {
    return function ($copy_ps) {
      return function ($copy_crumbs) {
        var $tco_var_i = $copy_i;
        var $tco_var_ps = $copy_ps;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(i, ps, crumbs) {
          var v = Data_Array.length(ps);

          if (v >= i) {
            $tco_var_i = i + 1 | 0;
            $tco_var_ps = ps;
            $copy_crumbs = Data_Semigroup.append(Data_Semigroup.semigroupArray)(crumbs)([pathsToCrumb(Data_Array.take(i)(ps))]);
            return;
          }

          ;
          $tco_done = true;
          return crumbs;
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_i, $tco_var_ps, $copy_crumbs);
        }

        ;
        return $tco_result;
      };
    };
  };

  return go(1)(paths)([]);
};

var breadcrumbs = function breadcrumbs(props) {
  var mkBCrumbs = function mkBCrumbs(crumb) {
    if (crumb.route instanceof Data_Maybe.Just) {
      return Optimus_Client_Antd_Breadcrumb.breadcrumbItem([React_Basic_DOM_Generated.a()({
        onClick: React_Basic_DOM_Events.capture_(props.history.push(crumb.route.value0)),
        children: [React_Basic_DOM.text(crumb.name)]
      })]);
    }

    ;
    return Optimus_Client_Antd_Breadcrumb.breadcrumbItem([React_Basic_DOM.text(crumb.name)]);
  };

  var crumbs = pathToCrumbs(Routing_Duplex.print(Optimus_Client_Route.route)(props.route));
  return React_Basic_DOM_Generated.div()({
    className: "px-3 pt-3",
    children: [Optimus_Client_Antd_Breadcrumb.breadcrumb()({
      separator: "/",
      children: Data_Functor.map(Data_Functor.functorArray)(mkBCrumbs)(crumbs)
    })]
  });
};

module.exports = {
  breadcrumbs: breadcrumbs,
  pathToCrumbs: pathToCrumbs
};