// Generated by purs version 0.12.3
"use strict";

var Type_Data_Boolean = require("../Type.Data.Boolean/index.js");

var Type_Data_Symbol = require("../Type.Data.Symbol/index.js");

var Type_Equality = require("../Type.Equality/index.js");

var RProxy = function () {
  function RProxy() {}

  ;
  RProxy.value = new RProxy();
  return RProxy;
}();

var RLProxy = function () {
  function RLProxy() {}

  ;
  RLProxy.value = new RLProxy();
  return RLProxy;
}();

var ListToRow = {};
var RowListRemove = {};
var RowListSet = {};
var RowListNub = {};
var RowListAppend = {};

var rowListSetImpl = function rowListSetImpl(dictTypeEquals) {
  return function (dictTypeEquals1) {
    return function (dictRowListRemove) {
      return RowListSet;
    };
  };
};

var rowListRemoveNil = RowListRemove;

var rowListRemoveCons = function rowListRemoveCons(dictRowListRemove) {
  return function (dictEquals) {
    return function (dictIf) {
      return RowListRemove;
    };
  };
};

var rowListNubNil = RowListNub;

var rowListNubCons = function rowListNubCons(dictTypeEquals) {
  return function (dictTypeEquals1) {
    return function (dictTypeEquals2) {
      return function (dictRowListRemove) {
        return function (dictRowListNub) {
          return RowListNub;
        };
      };
    };
  };
};

var rowListAppendNil = function rowListAppendNil(dictTypeEquals) {
  return RowListAppend;
};

var rowListAppendCons = function rowListAppendCons(dictRowListAppend) {
  return function (dictTypeEquals) {
    return RowListAppend;
  };
};

var listToRowNil = ListToRow;

var listToCons = function listToCons(dictListToRow) {
  return function (dictCons) {
    return ListToRow;
  };
};

module.exports = {
  RProxy: RProxy,
  RLProxy: RLProxy,
  ListToRow: ListToRow,
  RowListRemove: RowListRemove,
  RowListSet: RowListSet,
  RowListNub: RowListNub,
  RowListAppend: RowListAppend,
  listToRowNil: listToRowNil,
  listToCons: listToCons,
  rowListRemoveNil: rowListRemoveNil,
  rowListRemoveCons: rowListRemoveCons,
  rowListSetImpl: rowListSetImpl,
  rowListNubNil: rowListNubNil,
  rowListNubCons: rowListNubCons,
  rowListAppendNil: rowListAppendNil,
  rowListAppendCons: rowListAppendCons
};