// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

module.exports = {
  unsafeStringify: $foreign.unsafeStringify,
  unsafeToFixed: $foreign.unsafeToFixed,
  unsafeToExponential: $foreign.unsafeToExponential,
  unsafeToPrecision: $foreign.unsafeToPrecision,
  unsafeDecodeURI: $foreign.unsafeDecodeURI,
  unsafeEncodeURI: $foreign.unsafeEncodeURI,
  unsafeDecodeURIComponent: $foreign.unsafeDecodeURIComponent,
  unsafeEncodeURIComponent: $foreign.unsafeEncodeURIComponent
};