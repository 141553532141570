// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");

var Invariant = function Invariant(imap) {
  this.imap = imap;
};

var invariantMultiplicative = new Invariant(function (f) {
  return function (v) {
    return function (v1) {
      return f(v1);
    };
  };
});
var invariantEndo = new Invariant(function (ab) {
  return function (ba) {
    return function (v) {
      return function ($31) {
        return ab(v(ba($31)));
      };
    };
  };
});
var invariantDual = new Invariant(function (f) {
  return function (v) {
    return function (v1) {
      return f(v1);
    };
  };
});
var invariantDisj = new Invariant(function (f) {
  return function (v) {
    return function (v1) {
      return f(v1);
    };
  };
});
var invariantConj = new Invariant(function (f) {
  return function (v) {
    return function (v1) {
      return f(v1);
    };
  };
});
var invariantAdditive = new Invariant(function (f) {
  return function (v) {
    return function (v1) {
      return f(v1);
    };
  };
});

var imapF = function imapF(dictFunctor) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictFunctor)(f);
    };
  };
};

var invariantArray = new Invariant(imapF(Data_Functor.functorArray));
var invariantFn = new Invariant(imapF(Data_Functor.functorFn));

var imap = function imap(dict) {
  return dict.imap;
};

module.exports = {
  imap: imap,
  Invariant: Invariant,
  imapF: imapF,
  invariantFn: invariantFn,
  invariantArray: invariantArray,
  invariantAdditive: invariantAdditive,
  invariantConj: invariantConj,
  invariantDisj: invariantDisj,
  invariantDual: invariantDual,
  invariantEndo: invariantEndo,
  invariantMultiplicative: invariantMultiplicative
};