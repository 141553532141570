// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var row = function row(dictUnion) {
  return React_Basic.element($foreign["_row"]);
};

var row_ = function row_(children) {
  return row()({
    children: children
  });
};

var col = function col(dictUnion) {
  return React_Basic.element($foreign["_col"]);
};

var col_ = function col_(children) {
  return col()({
    children: children
  });
};

module.exports = {
  row: row,
  row_: row_,
  col: col,
  col_: col_
};