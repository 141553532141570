// Generated by purs version 0.12.3
"use strict";

var Control_Biapplicative = require("../Control.Biapplicative/index.js");

var Control_Biapply = require("../Control.Biapply/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Product = function () {
  function Product(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  Product.create = function (value0) {
    return function (value1) {
      return new Product(value0, value1);
    };
  };

  return Product;
}();

var showProduct = function showProduct(dictShow) {
  return function (dictShow1) {
    return new Data_Show.Show(function (v) {
      return "(Product " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
    });
  };
};

var eqProduct = function eqProduct(dictEq) {
  return function (dictEq1) {
    return new Data_Eq.Eq(function (x) {
      return function (y) {
        return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
      };
    });
  };
};

var ordProduct = function ordProduct(dictOrd) {
  return function (dictOrd1) {
    return new Data_Ord.Ord(function () {
      return eqProduct(dictOrd.Eq0())(dictOrd1.Eq0());
    }, function (x) {
      return function (y) {
        var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);

        if (v instanceof Data_Ordering.LT) {
          return Data_Ordering.LT.value;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return Data_Ordering.GT.value;
        }

        ;
        return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
      };
    });
  };
};

var bifunctorProduct = function bifunctorProduct(dictBifunctor) {
  return function (dictBifunctor1) {
    return new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
        return function (v) {
          return new Product(Data_Bifunctor.bimap(dictBifunctor)(f)(g)(v.value0), Data_Bifunctor.bimap(dictBifunctor1)(f)(g)(v.value1));
        };
      };
    });
  };
};

var biapplyProduct = function biapplyProduct(dictBiapply) {
  return function (dictBiapply1) {
    return new Control_Biapply.Biapply(function () {
      return bifunctorProduct(dictBiapply.Bifunctor0())(dictBiapply1.Bifunctor0());
    }, function (v) {
      return function (v1) {
        return new Product(Control_Biapply.biapply(dictBiapply)(v.value0)(v1.value0), Control_Biapply.biapply(dictBiapply1)(v.value1)(v1.value1));
      };
    });
  };
};

var biapplicativeProduct = function biapplicativeProduct(dictBiapplicative) {
  return function (dictBiapplicative1) {
    return new Control_Biapplicative.Biapplicative(function () {
      return biapplyProduct(dictBiapplicative.Biapply0())(dictBiapplicative1.Biapply0());
    }, function (a) {
      return function (b) {
        return new Product(Control_Biapplicative.bipure(dictBiapplicative)(a)(b), Control_Biapplicative.bipure(dictBiapplicative1)(a)(b));
      };
    });
  };
};

module.exports = {
  Product: Product,
  eqProduct: eqProduct,
  ordProduct: ordProduct,
  showProduct: showProduct,
  bifunctorProduct: bifunctorProduct,
  biapplyProduct: biapplyProduct,
  biapplicativeProduct: biapplicativeProduct
};