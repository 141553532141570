// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Lazy = require("../Data.Lazy/index.js");

var Data_List_Lazy_Types = require("../Data.List.Lazy.Types/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_NonEmpty = require("../Data.NonEmpty/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable = require("../Data.Unfoldable/index.js");

var Prelude = require("../Prelude/index.js");

var Pattern = function Pattern(x) {
  return x;
};

var zipWith = function zipWith(f) {
  return function (xs) {
    return function (ys) {
      var go = function go(v) {
        return function (v1) {
          if (v instanceof Data_List_Lazy_Types.Nil) {
            return Data_List_Lazy_Types.Nil.value;
          }

          ;

          if (v1 instanceof Data_List_Lazy_Types.Nil) {
            return Data_List_Lazy_Types.Nil.value;
          }

          ;

          if (v instanceof Data_List_Lazy_Types.Cons && v1 instanceof Data_List_Lazy_Types.Cons) {
            return new Data_List_Lazy_Types.Cons(f(v.value0)(v1.value0), zipWith(f)(v.value1)(v1.value1));
          }

          ;
          throw new Error("Failed pattern match at Data.List.Lazy (line 693, column 3 - line 693, column 35): " + [v.constructor.name, v1.constructor.name]);
        };
      };

      return Control_Apply.apply(Data_Lazy.applyLazy)(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs)))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(ys));
    };
  };
};

var zipWithA = function zipWithA(dictApplicative) {
  return function (f) {
    return function (xs) {
      return function (ys) {
        return Data_Traversable.sequence(Data_List_Lazy_Types.traversableList)(dictApplicative)(zipWith(f)(xs)(ys));
      };
    };
  };
};

var zip = zipWith(Data_Tuple.Tuple.create);

var updateAt = function updateAt(n) {
  return function (x) {
    return function (xs) {
      var go = function go(v) {
        return function (v1) {
          if (v1 instanceof Data_List_Lazy_Types.Nil) {
            return Data_List_Lazy_Types.Nil.value;
          }

          ;

          if (v === 0 && v1 instanceof Data_List_Lazy_Types.Cons) {
            return new Data_List_Lazy_Types.Cons(x, v1.value1);
          }

          ;

          if (v1 instanceof Data_List_Lazy_Types.Cons) {
            return new Data_List_Lazy_Types.Cons(v1.value0, updateAt(v - 1 | 0)(x)(v1.value1));
          }

          ;
          throw new Error("Failed pattern match at Data.List.Lazy (line 367, column 3 - line 367, column 17): " + [v.constructor.name, v1.constructor.name]);
        };
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go(n))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var unzip = Data_Foldable.foldr(Data_List_Lazy_Types.foldableList)(function (v) {
  return function (v1) {
    return new Data_Tuple.Tuple(Data_List_Lazy_Types.cons(v.value0)(v1.value0), Data_List_Lazy_Types.cons(v.value1)(v1.value1));
  };
})(new Data_Tuple.Tuple(Data_List_Lazy_Types.nil, Data_List_Lazy_Types.nil));

var uncons = function uncons(xs) {
  var v = Data_List_Lazy_Types.step(xs);

  if (v instanceof Data_List_Lazy_Types.Nil) {
    return Data_Maybe.Nothing.value;
  }

  ;

  if (v instanceof Data_List_Lazy_Types.Cons) {
    return new Data_Maybe.Just({
      head: v.value0,
      tail: v.value1
    });
  }

  ;
  throw new Error("Failed pattern match at Data.List.Lazy (line 285, column 13 - line 287, column 44): " + [v.constructor.name]);
};

var toUnfoldable = function toUnfoldable(dictUnfoldable) {
  return Data_Unfoldable.unfoldr(dictUnfoldable)(function (xs) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (rec) {
      return new Data_Tuple.Tuple(rec.head, rec.tail);
    })(uncons(xs));
  });
};

var takeWhile = function takeWhile(p) {
  var go = function go(v) {
    if (v instanceof Data_List_Lazy_Types.Cons && p(v.value0)) {
      return new Data_List_Lazy_Types.Cons(v.value0, takeWhile(p)(v.value1));
    }

    ;
    return Data_List_Lazy_Types.Nil.value;
  };

  return function ($249) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($249)));
  };
};

var take = function take(n) {
  var go = function go(v) {
    return function (v1) {
      if (v1 instanceof Data_List_Lazy_Types.Nil) {
        return Data_List_Lazy_Types.Nil.value;
      }

      ;

      if (v1 instanceof Data_List_Lazy_Types.Cons) {
        return new Data_List_Lazy_Types.Cons(v1.value0, take(v - 1 | 0)(v1.value1));
      }

      ;
      throw new Error("Failed pattern match at Data.List.Lazy (line 517, column 3 - line 517, column 32): " + [v.constructor.name, v1.constructor.name]);
    };
  };

  var $124 = n <= 0;

  if ($124) {
    return Data_Function["const"](Data_List_Lazy_Types.nil);
  }

  ;
  return function ($250) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go(n))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($250)));
  };
};

var tail = function tail(xs) {
  return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
    return v.tail;
  })(uncons(xs));
};

var stripPrefix = function stripPrefix(dictEq) {
  return function (v) {
    return function (s) {
      var go = function go(prefix) {
        return function (input) {
          var v1 = Data_List_Lazy_Types.step(prefix);

          if (v1 instanceof Data_List_Lazy_Types.Nil) {
            return Data_Maybe.Just.create(new Control_Monad_Rec_Class.Done(input));
          }

          ;

          if (v1 instanceof Data_List_Lazy_Types.Cons) {
            var v2 = Data_List_Lazy_Types.step(input);

            if (v2 instanceof Data_List_Lazy_Types.Cons && Data_Eq.eq(dictEq)(v1.value0)(v2.value0)) {
              return Data_Maybe.Just.create(new Control_Monad_Rec_Class.Loop({
                a: v1.value1,
                b: v2.value1
              }));
            }

            ;
            return Data_Maybe.Nothing.value;
          }

          ;
          throw new Error("Failed pattern match at Data.List.Lazy (line 499, column 21 - line 503, column 19): " + [v1.constructor.name]);
        };
      };

      return Control_Monad_Rec_Class.tailRecM2(Control_Monad_Rec_Class.monadRecMaybe)(go)(v)(s);
    };
  };
};

var span = function span(p) {
  return function (xs) {
    var v = uncons(xs);

    if (v instanceof Data_Maybe.Just && p(v.value0.head)) {
      var v1 = span(p)(v.value0.tail);
      return {
        init: Data_List_Lazy_Types.cons(v.value0.head)(v1.init),
        rest: v1.rest
      };
    }

    ;
    return {
      init: Data_List_Lazy_Types.nil,
      rest: xs
    };
  };
};

var snoc = function snoc(xs) {
  return function (x) {
    return Data_Foldable.foldr(Data_List_Lazy_Types.foldableList)(Data_List_Lazy_Types.cons)(Data_List_Lazy_Types.cons(x)(Data_List_Lazy_Types.nil))(xs);
  };
};

var singleton = function singleton(a) {
  return Data_List_Lazy_Types.cons(a)(Data_List_Lazy_Types.nil);
};

var showPattern = function showPattern(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Pattern " + (Data_Show.show(Data_List_Lazy_Types.showList(dictShow))(v) + ")");
  });
};

var scanrLazy = function scanrLazy(f) {
  return function (acc) {
    return function (xs) {
      var go = function go(v) {
        if (v instanceof Data_List_Lazy_Types.Nil) {
          return Data_List_Lazy_Types.Nil.value;
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Cons) {
          var acc$prime = f(v.value0)(acc);
          return Data_List_Lazy_Types.Cons.create(acc$prime)(scanrLazy(f)(acc$prime)(v.value1));
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 764, column 5 - line 764, column 27): " + [v.constructor.name]);
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var reverse = function reverse(xs) {
  return Control_Lazy.defer(Data_List_Lazy_Types.lazyList)(function (v) {
    return Data_Foldable.foldl(Data_List_Lazy_Types.foldableList)(Data_Function.flip(Data_List_Lazy_Types.cons))(Data_List_Lazy_Types.nil)(xs);
  });
};

var replicateM = function replicateM(dictMonad) {
  return function (n) {
    return function (m) {
      if (n < 1) {
        return Control_Applicative.pure(dictMonad.Applicative0())(Data_List_Lazy_Types.nil);
      }

      ;

      if (Data_Boolean.otherwise) {
        return Control_Bind.bind(dictMonad.Bind1())(m)(function (v) {
          return Control_Bind.bind(dictMonad.Bind1())(replicateM(dictMonad)(n - 1 | 0)(m))(function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(Data_List_Lazy_Types.cons(v)(v1));
          });
        });
      }

      ;
      throw new Error("Failed pattern match at Data.List.Lazy (line 161, column 1 - line 161, column 62): " + [n.constructor.name, m.constructor.name]);
    };
  };
};

var repeat = function repeat(x) {
  return Control_Lazy.fix(Data_List_Lazy_Types.lazyList)(function (xs) {
    return Data_List_Lazy_Types.cons(x)(xs);
  });
};

var replicate = function replicate(i) {
  return function (xs) {
    return take(i)(repeat(xs));
  };
};

var range = function range(start) {
  return function (end) {
    if (start > end) {
      var g = function g(x) {
        if (x >= end) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(x, x - 1 | 0));
        }

        ;

        if (Data_Boolean.otherwise) {
          return Data_Maybe.Nothing.value;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 148, column 13 - line 149, column 38): " + [x.constructor.name]);
      };

      return Data_Unfoldable.unfoldr(Data_List_Lazy_Types.unfoldableList)(g)(start);
    }

    ;

    if (Data_Boolean.otherwise) {
      var f = function f(x) {
        if (x <= end) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(x, x + 1 | 0));
        }

        ;

        if (Data_Boolean.otherwise) {
          return Data_Maybe.Nothing.value;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 153, column 5 - line 154, column 30): " + [x.constructor.name]);
      };

      return Data_Unfoldable.unfoldr(Data_List_Lazy_Types.unfoldableList)(f)(start);
    }

    ;
    throw new Error("Failed pattern match at Data.List.Lazy (line 145, column 1 - line 145, column 32): " + [start.constructor.name, end.constructor.name]);
  };
};

var partition = function partition(f) {
  var go = function go(x) {
    return function (v) {
      var $154 = f(x);

      if ($154) {
        return {
          yes: Data_List_Lazy_Types.cons(x)(v.yes),
          no: v.no
        };
      }

      ;
      return {
        yes: v.yes,
        no: Data_List_Lazy_Types.cons(x)(v.no)
      };
    };
  };

  return Data_Foldable.foldr(Data_List_Lazy_Types.foldableList)(go)({
    yes: Data_List_Lazy_Types.nil,
    no: Data_List_Lazy_Types.nil
  });
};

var $$null = function $$null($251) {
  return Data_Maybe.isNothing(uncons($251));
};

var newtypePattern = new Data_Newtype.Newtype(function (n) {
  return n;
}, Pattern);

var mapMaybe = function mapMaybe(f) {
  var go = function go($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Lazy_Types.Nil) {
        $tco_done = true;
        return Data_List_Lazy_Types.Nil.value;
      }

      ;

      if (v instanceof Data_List_Lazy_Types.Cons) {
        var v1 = f(v.value0);

        if (v1 instanceof Data_Maybe.Nothing) {
          $copy_v = Data_List_Lazy_Types.step(v.value1);
          return;
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          $tco_done = true;
          return new Data_List_Lazy_Types.Cons(v1.value0, mapMaybe(f)(v.value1));
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 460, column 5 - line 462, column 39): " + [v1.constructor.name]);
      }

      ;
      throw new Error("Failed pattern match at Data.List.Lazy (line 458, column 3 - line 458, column 15): " + [v.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  return function ($252) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($252)));
  };
};

var some = function some(dictAlternative) {
  return function (dictLazy) {
    return function (v) {
      return Control_Apply.apply(dictAlternative.Applicative0().Apply0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Data_List_Lazy_Types.cons)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
        return many(dictAlternative)(dictLazy)(v);
      }));
    };
  };
};

var many = function many(dictAlternative) {
  return function (dictLazy) {
    return function (v) {
      return Control_Alt.alt(dictAlternative.Plus1().Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())(Data_List_Lazy_Types.nil));
    };
  };
};

var length = Data_Foldable.foldl(Data_List_Lazy_Types.foldableList)(function (l) {
  return function (v) {
    return l + 1 | 0;
  };
})(0);

var last = function () {
  var go = function go($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Lazy_Types.Cons) {
        if ($$null(v.value1)) {
          $tco_done = true;
          return new Data_Maybe.Just(v.value0);
        }

        ;

        if (Data_Boolean.otherwise) {
          $copy_v = Data_List_Lazy_Types.step(v.value1);
          return;
        }

        ;
      }

      ;
      $tco_done = true;
      return Data_Maybe.Nothing.value;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  return function ($253) {
    return go(Data_List_Lazy_Types.step($253));
  };
}();

var iterate = function iterate(f) {
  return function (x) {
    return Control_Lazy.fix(Data_List_Lazy_Types.lazyList)(function (xs) {
      return Data_List_Lazy_Types.cons(x)(Data_Functor.map(Data_List_Lazy_Types.functorList)(f)(xs));
    });
  };
};

var insertAt = function insertAt(v) {
  return function (x) {
    return function (xs) {
      if (v === 0) {
        return Data_List_Lazy_Types.cons(x)(xs);
      }

      ;

      var go = function go(v1) {
        if (v1 instanceof Data_List_Lazy_Types.Nil) {
          return new Data_List_Lazy_Types.Cons(x, Data_List_Lazy_Types.nil);
        }

        ;

        if (v1 instanceof Data_List_Lazy_Types.Cons) {
          return new Data_List_Lazy_Types.Cons(v1.value0, insertAt(v - 1 | 0)(x)(v1.value1));
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 340, column 3 - line 340, column 22): " + [v1.constructor.name]);
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var init = function () {
  var go = function go(v) {
    if (v instanceof Data_List_Lazy_Types.Cons) {
      if ($$null(v.value1)) {
        return new Data_Maybe.Just(Data_List_Lazy_Types.nil);
      }

      ;

      if (Data_Boolean.otherwise) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_List_Lazy_Types.cons(v.value0))(go(Data_List_Lazy_Types.step(v.value1)));
      }

      ;
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  return function ($254) {
    return go(Data_List_Lazy_Types.step($254));
  };
}();

var index = function index(xs) {
  var go = function go($copy_v) {
    return function ($copy_v1) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, v1) {
        if (v instanceof Data_List_Lazy_Types.Nil) {
          $tco_done = true;
          return Data_Maybe.Nothing.value;
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Cons && v1 === 0) {
          $tco_done = true;
          return new Data_Maybe.Just(v.value0);
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Cons) {
          $tco_var_v = Data_List_Lazy_Types.step(v.value1);
          $copy_v1 = v1 - 1 | 0;
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 299, column 3 - line 299, column 21): " + [v.constructor.name, v1.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_v1);
      }

      ;
      return $tco_result;
    };
  };

  return go(Data_List_Lazy_Types.step(xs));
};

var head = function head(xs) {
  return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
    return v.head;
  })(uncons(xs));
};

var transpose = function transpose(xs) {
  var v = uncons(xs);

  if (v instanceof Data_Maybe.Nothing) {
    return xs;
  }

  ;

  if (v instanceof Data_Maybe.Just) {
    var v1 = uncons(v.value0.head);

    if (v1 instanceof Data_Maybe.Nothing) {
      return transpose(v.value0.tail);
    }

    ;

    if (v1 instanceof Data_Maybe.Just) {
      return Data_List_Lazy_Types.cons(Data_List_Lazy_Types.cons(v1.value0.head)(mapMaybe(head)(v.value0.tail)))(transpose(Data_List_Lazy_Types.cons(v1.value0.tail)(mapMaybe(tail)(v.value0.tail))));
    }

    ;
    throw new Error("Failed pattern match at Data.List.Lazy (line 734, column 7 - line 738, column 72): " + [v1.constructor.name]);
  }

  ;
  throw new Error("Failed pattern match at Data.List.Lazy (line 730, column 3 - line 738, column 72): " + [v.constructor.name]);
};

var groupBy = function groupBy(eq) {
  var go = function go(v) {
    if (v instanceof Data_List_Lazy_Types.Nil) {
      return Data_List_Lazy_Types.Nil.value;
    }

    ;

    if (v instanceof Data_List_Lazy_Types.Cons) {
      var v1 = span(eq(v.value0))(v.value1);
      return new Data_List_Lazy_Types.Cons(Data_Lazy.defer(function (v2) {
        return new Data_NonEmpty.NonEmpty(v.value0, v1.init);
      }), groupBy(eq)(v1.rest));
    }

    ;
    throw new Error("Failed pattern match at Data.List.Lazy (line 588, column 3 - line 588, column 15): " + [v.constructor.name]);
  };

  return function ($255) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($255)));
  };
};

var group = function group(dictEq) {
  return groupBy(Data_Eq.eq(dictEq));
};

var fromStep = function fromStep($256) {
  return Data_List_Lazy_Types.List(Control_Applicative.pure(Data_Lazy.applicativeLazy)($256));
};

var insertBy = function insertBy(cmp) {
  return function (x) {
    return function (xs) {
      var go = function go(v) {
        if (v instanceof Data_List_Lazy_Types.Nil) {
          return new Data_List_Lazy_Types.Cons(x, Data_List_Lazy_Types.nil);
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Cons) {
          var v1 = cmp(x)(v.value0);

          if (v1 instanceof Data_Ordering.GT) {
            return new Data_List_Lazy_Types.Cons(v.value0, insertBy(cmp)(x)(v.value1));
          }

          ;
          return new Data_List_Lazy_Types.Cons(x, fromStep(v));
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 235, column 3 - line 235, column 22): " + [v.constructor.name]);
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var insert = function insert(dictOrd) {
  return insertBy(Data_Ord.compare(dictOrd));
};

var fromFoldable = function fromFoldable(dictFoldable) {
  return Data_Foldable.foldr(dictFoldable)(Data_List_Lazy_Types.cons)(Data_List_Lazy_Types.nil);
};

var foldrLazy = function foldrLazy(dictLazy) {
  return function (op) {
    return function (z) {
      var go = function go(xs) {
        var v = Data_List_Lazy_Types.step(xs);

        if (v instanceof Data_List_Lazy_Types.Cons) {
          return Control_Lazy.defer(dictLazy)(function (v1) {
            return op(v.value0)(go(v.value1));
          });
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Nil) {
          return z;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 756, column 13 - line 758, column 15): " + [v.constructor.name]);
      };

      return go;
    };
  };
};

var foldM = function foldM(dictMonad) {
  return function (f) {
    return function (a) {
      return function (xs) {
        var v = uncons(xs);

        if (v instanceof Data_Maybe.Nothing) {
          return Control_Applicative.pure(dictMonad.Applicative0())(a);
        }

        ;

        if (v instanceof Data_Maybe.Just) {
          return Control_Bind.bind(dictMonad.Bind1())(f(a)(v.value0.head))(function (a$prime) {
            return foldM(dictMonad)(f)(a$prime)(v.value0.tail);
          });
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 747, column 5 - line 750, column 54): " + [v.constructor.name]);
      };
    };
  };
};

var findIndex = function findIndex(fn) {
  var go = function go(n) {
    return function (list) {
      return Control_Bind.bind(Data_Maybe.bindMaybe)(uncons(list))(function (v) {
        var $207 = fn(v.head);

        if ($207) {
          return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(n);
        }

        ;
        return go(n + 1 | 0)(v.tail);
      });
    };
  };

  return go(0);
};

var findLastIndex = function findLastIndex(fn) {
  return function (xs) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return (length(xs) - 1 | 0) - v | 0;
    })(findIndex(fn)(reverse(xs)));
  };
};

var filterM = function filterM(dictMonad) {
  return function (p) {
    return function (list) {
      var v = uncons(list);

      if (v instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(dictMonad.Applicative0())(Data_List_Lazy_Types.nil);
      }

      ;

      if (v instanceof Data_Maybe.Just) {
        return Control_Bind.bind(dictMonad.Bind1())(p(v.value0.head))(function (v1) {
          return Control_Bind.bind(dictMonad.Bind1())(filterM(dictMonad)(p)(v.value0.tail))(function (v2) {
            return Control_Applicative.pure(dictMonad.Applicative0())(function () {
              if (v1) {
                return Data_List_Lazy_Types.cons(v.value0.head)(v2);
              }

              ;
              return v2;
            }());
          });
        });
      }

      ;
      throw new Error("Failed pattern match at Data.List.Lazy (line 443, column 5 - line 448, column 48): " + [v.constructor.name]);
    };
  };
};

var filter = function filter(p) {
  var go = function go($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Lazy_Types.Nil) {
        $tco_done = true;
        return Data_List_Lazy_Types.Nil.value;
      }

      ;

      if (v instanceof Data_List_Lazy_Types.Cons) {
        if (p(v.value0)) {
          $tco_done = true;
          return new Data_List_Lazy_Types.Cons(v.value0, filter(p)(v.value1));
        }

        ;

        if (Data_Boolean.otherwise) {
          $copy_v = Data_List_Lazy_Types.step(v.value1);
          return;
        }

        ;
      }

      ;
      throw new Error("Failed pattern match at Data.List.Lazy (line 428, column 3 - line 428, column 15): " + [v.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  return function ($257) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($257)));
  };
};

var intersectBy = function intersectBy(eq) {
  return function (xs) {
    return function (ys) {
      return filter(function (x) {
        return Data_Foldable.any(Data_List_Lazy_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(eq(x))(ys);
      })(xs);
    };
  };
};

var intersect = function intersect(dictEq) {
  return intersectBy(Data_Eq.eq(dictEq));
};

var nubBy = function nubBy(eq) {
  var go = function go(v) {
    if (v instanceof Data_List_Lazy_Types.Nil) {
      return Data_List_Lazy_Types.Nil.value;
    }

    ;

    if (v instanceof Data_List_Lazy_Types.Cons) {
      return new Data_List_Lazy_Types.Cons(v.value0, nubBy(eq)(filter(function (y) {
        return !eq(v.value0)(y);
      })(v.value1)));
    }

    ;
    throw new Error("Failed pattern match at Data.List.Lazy (line 621, column 3 - line 621, column 15): " + [v.constructor.name]);
  };

  return function ($258) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($258)));
  };
};

var nub = function nub(dictEq) {
  return nubBy(Data_Eq.eq(dictEq));
};

var eqPattern = function eqPattern(dictEq) {
  return new Data_Eq.Eq(function (x) {
    return function (y) {
      return Data_Eq.eq(Data_List_Lazy_Types.eqList(dictEq))(x)(y);
    };
  });
};

var ordPattern = function ordPattern(dictOrd) {
  return new Data_Ord.Ord(function () {
    return eqPattern(dictOrd.Eq0());
  }, function (x) {
    return function (y) {
      return Data_Ord.compare(Data_List_Lazy_Types.ordList(dictOrd))(x)(y);
    };
  });
};

var elemLastIndex = function elemLastIndex(dictEq) {
  return function (x) {
    return findLastIndex(function (v) {
      return Data_Eq.eq(dictEq)(v)(x);
    });
  };
};

var elemIndex = function elemIndex(dictEq) {
  return function (x) {
    return findIndex(function (v) {
      return Data_Eq.eq(dictEq)(v)(x);
    });
  };
};

var dropWhile = function dropWhile(p) {
  var go = function go($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Lazy_Types.Cons && p(v.value0)) {
        $copy_v = Data_List_Lazy_Types.step(v.value1);
        return;
      }

      ;
      $tco_done = true;
      return fromStep(v);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  return function ($259) {
    return go(Data_List_Lazy_Types.step($259));
  };
};

var drop = function drop(n) {
  var go = function go($copy_v) {
    return function ($copy_v1) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, v1) {
        if (v === 0) {
          $tco_done = true;
          return v1;
        }

        ;

        if (v1 instanceof Data_List_Lazy_Types.Nil) {
          $tco_done = true;
          return Data_List_Lazy_Types.Nil.value;
        }

        ;

        if (v1 instanceof Data_List_Lazy_Types.Cons) {
          $tco_var_v = v - 1 | 0;
          $copy_v1 = Data_List_Lazy_Types.step(v1.value1);
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 536, column 3 - line 536, column 15): " + [v.constructor.name, v1.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_v1);
      }

      ;
      return $tco_result;
    };
  };

  return function ($260) {
    return Data_List_Lazy_Types.List(Data_Functor.map(Data_Lazy.functorLazy)(go(n))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)($260)));
  };
};

var slice = function slice(start) {
  return function (end) {
    return function (xs) {
      return take(end - start | 0)(drop(start)(xs));
    };
  };
};

var deleteBy = function deleteBy(eq) {
  return function (x) {
    return function (xs) {
      var go = function go(v) {
        if (v instanceof Data_List_Lazy_Types.Nil) {
          return Data_List_Lazy_Types.Nil.value;
        }

        ;

        if (v instanceof Data_List_Lazy_Types.Cons) {
          if (eq(x)(v.value0)) {
            return Data_List_Lazy_Types.step(v.value1);
          }

          ;

          if (Data_Boolean.otherwise) {
            return new Data_List_Lazy_Types.Cons(v.value0, deleteBy(eq)(x)(v.value1));
          }

          ;
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 650, column 3 - line 650, column 15): " + [v.constructor.name]);
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go)(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var unionBy = function unionBy(eq) {
  return function (xs) {
    return function (ys) {
      return Data_Semigroup.append(Data_List_Lazy_Types.semigroupList)(xs)(Data_Foldable.foldl(Data_List_Lazy_Types.foldableList)(Data_Function.flip(deleteBy(eq)))(nubBy(eq)(ys))(xs));
    };
  };
};

var union = function union(dictEq) {
  return unionBy(Data_Eq.eq(dictEq));
};

var deleteAt = function deleteAt(n) {
  return function (xs) {
    var go = function go(v) {
      return function (v1) {
        if (v1 instanceof Data_List_Lazy_Types.Nil) {
          return Data_List_Lazy_Types.Nil.value;
        }

        ;

        if (v === 0 && v1 instanceof Data_List_Lazy_Types.Cons) {
          return Data_List_Lazy_Types.step(v1.value1);
        }

        ;

        if (v1 instanceof Data_List_Lazy_Types.Cons) {
          return new Data_List_Lazy_Types.Cons(v1.value0, deleteAt(v - 1 | 0)(v1.value1));
        }

        ;
        throw new Error("Failed pattern match at Data.List.Lazy (line 353, column 3 - line 353, column 17): " + [v.constructor.name, v1.constructor.name]);
      };
    };

    return Data_Functor.map(Data_Lazy.functorLazy)(go(n))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
  };
};

var $$delete = function $$delete(dictEq) {
  return deleteBy(Data_Eq.eq(dictEq));
};

var difference = function difference(dictEq) {
  return Data_Foldable.foldl(Data_List_Lazy_Types.foldableList)(Data_Function.flip($$delete(dictEq)));
};

var cycle = function cycle(xs) {
  return Control_Lazy.fix(Data_List_Lazy_Types.lazyList)(function (ys) {
    return Data_Semigroup.append(Data_List_Lazy_Types.semigroupList)(xs)(ys);
  });
};

var concatMap = Data_Function.flip(Control_Bind.bind(Data_List_Lazy_Types.bindList));

var concat = function concat(v) {
  return Control_Bind.bind(Data_List_Lazy_Types.bindList)(v)(Control_Category.identity(Control_Category.categoryFn));
};

var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));

var alterAt = function alterAt(n) {
  return function (f) {
    return function (xs) {
      var go = function go(v) {
        return function (v1) {
          if (v1 instanceof Data_List_Lazy_Types.Nil) {
            return Data_List_Lazy_Types.Nil.value;
          }

          ;

          if (v === 0 && v1 instanceof Data_List_Lazy_Types.Cons) {
            var v2 = f(v1.value0);

            if (v2 instanceof Data_Maybe.Nothing) {
              return Data_List_Lazy_Types.step(v1.value1);
            }

            ;

            if (v2 instanceof Data_Maybe.Just) {
              return new Data_List_Lazy_Types.Cons(v2.value0, v1.value1);
            }

            ;
            throw new Error("Failed pattern match at Data.List.Lazy (line 394, column 22 - line 396, column 26): " + [v2.constructor.name]);
          }

          ;

          if (v1 instanceof Data_List_Lazy_Types.Cons) {
            return new Data_List_Lazy_Types.Cons(v1.value0, alterAt(v - 1 | 0)(f)(v1.value1));
          }

          ;
          throw new Error("Failed pattern match at Data.List.Lazy (line 393, column 3 - line 393, column 17): " + [v.constructor.name, v1.constructor.name]);
        };
      };

      return Data_Functor.map(Data_Lazy.functorLazy)(go(n))(Data_Newtype.unwrap(Data_List_Lazy_Types.newtypeList)(xs));
    };
  };
};

var modifyAt = function modifyAt(n) {
  return function (f) {
    return alterAt(n)(function ($261) {
      return Data_Maybe.Just.create(f($261));
    });
  };
};

module.exports = {
  toUnfoldable: toUnfoldable,
  fromFoldable: fromFoldable,
  singleton: singleton,
  range: range,
  replicate: replicate,
  replicateM: replicateM,
  some: some,
  many: many,
  repeat: repeat,
  iterate: iterate,
  cycle: cycle,
  "null": $$null,
  length: length,
  snoc: snoc,
  insert: insert,
  insertBy: insertBy,
  head: head,
  last: last,
  tail: tail,
  init: init,
  uncons: uncons,
  index: index,
  elemIndex: elemIndex,
  elemLastIndex: elemLastIndex,
  findIndex: findIndex,
  findLastIndex: findLastIndex,
  insertAt: insertAt,
  deleteAt: deleteAt,
  updateAt: updateAt,
  modifyAt: modifyAt,
  alterAt: alterAt,
  reverse: reverse,
  concat: concat,
  concatMap: concatMap,
  filter: filter,
  filterM: filterM,
  mapMaybe: mapMaybe,
  catMaybes: catMaybes,
  Pattern: Pattern,
  stripPrefix: stripPrefix,
  slice: slice,
  take: take,
  takeWhile: takeWhile,
  drop: drop,
  dropWhile: dropWhile,
  span: span,
  group: group,
  groupBy: groupBy,
  partition: partition,
  nub: nub,
  nubBy: nubBy,
  union: union,
  unionBy: unionBy,
  "delete": $$delete,
  deleteBy: deleteBy,
  difference: difference,
  intersect: intersect,
  intersectBy: intersectBy,
  zipWith: zipWith,
  zipWithA: zipWithA,
  zip: zip,
  unzip: unzip,
  transpose: transpose,
  foldM: foldM,
  foldrLazy: foldrLazy,
  scanrLazy: scanrLazy,
  eqPattern: eqPattern,
  ordPattern: ordPattern,
  newtypePattern: newtypePattern,
  showPattern: showPattern
};