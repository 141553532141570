// Generated by purs version 0.12.3
"use strict";

var Prelude = require("../Prelude/index.js");

var MonadTrans = function MonadTrans(lift) {
  this.lift = lift;
};

var lift = function lift(dict) {
  return dict.lift;
};

module.exports = {
  lift: lift,
  MonadTrans: MonadTrans
};