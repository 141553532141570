// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Effect_Class = require("../Effect.Class/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Foreign = require("../Foreign/index.js");

var Milkis = require("../Milkis/index.js");

var Milkis_Impl_Window = require("../Milkis.Impl.Window/index.js");

var Optimus_Client_Constants = require("../Optimus.Client.Constants/index.js");

var Optimus_Shared_Bridge = require("../Optimus.Shared.Bridge/index.js");

var Prelude = require("../Prelude/index.js");

var Type_Equality = require("../Type.Equality/index.js");

var Type_Row_Homogeneous = require("../Type.Row.Homogeneous/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var zjson = Milkis.json;

var requireToken = function () {
  var getToken = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.localStorage))(Web_Storage_Storage.getItem("accessToken")));
  return Control_Bind.bind(Effect_Aff.bindAff)(getToken)(function (v) {
    if (v instanceof Data_Maybe.Just) {
      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
    }

    ;

    if (v instanceof Data_Maybe.Nothing) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("No access token"));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.ApiClient.Utilities (line 55, column 29 - line 58, column 3): " + [v.constructor.name]);
  });
}();

var readJson = function readJson(dictZRPCRead) {
  return function (json) {
    var v = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(dictZRPCRead)(json));

    if (v instanceof Data_Either.Right) {
      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
    }

    ;

    if (v instanceof Data_Either.Left) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Unable to read: " + Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0)));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.ApiClient.Utilities (line 67, column 17 - line 69, column 64): " + [v.constructor.name]);
  };
};

var handleRes = function handleRes(v) {
  if (v instanceof Data_Either.Right) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
  }

  ;

  if (v instanceof Data_Either.Left) {
    if (v.value0 instanceof Optimus_Shared_Bridge.Unauthorized) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Unauthorized."));
    }

    ;

    if (v.value0 instanceof Optimus_Shared_Bridge.UnknownError) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("An unknown error has occured."));
    }

    ;

    if (v.value0 instanceof Optimus_Shared_Bridge.NotFound) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Not Found."));
    }

    ;

    if (v.value0 instanceof Optimus_Shared_Bridge.InternalError) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Server error."));
    }

    ;

    if (v.value0 instanceof Optimus_Shared_Bridge.BadRequest) {
      return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("BadRequest"));
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.ApiClient.Utilities (line 46, column 24 - line 54, column 1): " + [v.value0.constructor.name]);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.ApiClient.Utilities (line 44, column 1 - line 44, column 69): " + [v.constructor.name]);
};

var fetch = function fetch(dictUnion) {
  return Milkis.fetch(Milkis_Impl_Window.windowFetch)(dictUnion);
};

var apiUrl = function apiUrl(path) {
  return Milkis.URL(Optimus_Client_Constants.baseUrl + path);
};

var getApi = function getApi(path) {
  return function (token) {
    return fetch()(apiUrl(path))({
      method: Milkis.getMethod,
      headers: Milkis.makeHeaders(Type_Row_Homogeneous.homogeneous()(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListNil)(Type_Equality.refl)))({
        authorization: "Bearer " + token
      })
    });
  };
};

var getAuthorizedApi = function getAuthorizedApi(dictZRPCRead) {
  return function (path) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(requireToken)(getApi(path)))(zjson))(readJson(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(Optimus_Shared_Bridge.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead)))))(handleRes);
  };
};

var postApi = function postApi(path) {
  return function (payloadJson) {
    return function (token) {
      return fetch()(apiUrl(path))({
        method: Milkis.postMethod,
        headers: Milkis.makeHeaders(Type_Row_Homogeneous.homogeneous()(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListNil)(Type_Equality.refl))(Type_Equality.refl)))({
          authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }),
        body: Data_Argonaut_Core.stringify(payloadJson)
      });
    };
  };
};

var postAuthorizedApi = function postAuthorizedApi(dictZRPCWrite) {
  return function (dictZRPCRead) {
    return function (path) {
      return function (payload) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(requireToken)(postApi(path)(ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(payload))))(zjson))(readJson(dictZRPCRead));
      };
    };
  };
};

module.exports = {
  getAuthorizedApi: getAuthorizedApi,
  postAuthorizedApi: postAuthorizedApi,
  handleRes: handleRes,
  requireToken: requireToken,
  zjson: zjson,
  readJson: readJson,
  getApi: getApi,
  postApi: postApi,
  fetch: fetch,
  apiUrl: apiUrl
};