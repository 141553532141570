// Generated by purs version 0.12.3
"use strict";

var React_Basic = require("../React.Basic/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var unsafeCreateDOMComponent = Unsafe_Coerce.unsafeCoerce;
module.exports = {
  unsafeCreateDOMComponent: unsafeCreateDOMComponent
};