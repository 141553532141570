// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Control_Monad_State = require("../Control.Monad.State/index.js");

var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_List = require("../Data.List/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");

var withErrorMessage = function withErrorMessage(dictMonad) {
  return function (p) {
    return function (msg) {
      return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(p)(Text_Parsing_Parser.fail(dictMonad)("Expected " + msg));
    };
  };
};

var tryRethrow = function tryRethrow(dictMonad) {
  return function (p) {
    return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
      return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
        if (v1.value0 instanceof Data_Either.Left) {
          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(new Data_Either.Left(new Text_Parsing_Parser.ParseError(v1.value0.value0.value0, v.value1)), new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
        }

        ;
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
      });
    })));
  };
};

var $$try = function $$try(dictMonad) {
  return function (p) {
    return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
      return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
        if (v1.value0 instanceof Data_Either.Left) {
          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
        }

        ;
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
      });
    })));
  };
};

var skipMany1 = function skipMany1(dictMonad) {
  return function (p) {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(skipMany(dictMonad)(p))(function (v1) {
        return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit);
      });
    });
  };
};

var skipMany = function skipMany(dictMonad) {
  return function (p) {
    return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(skipMany1(dictMonad)(p))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit));
  };
};

var sepEndBy1 = function sepEndBy1(dictMonad) {
  return function (p) {
    return function (sep) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(sep)(function (v1) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(sepEndBy(dictMonad)(p)(sep))(function (v2) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_List_Types.Cons(v, v2));
          });
        }))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_List.singleton(v)));
      });
    };
  };
};

var sepEndBy = function sepEndBy(dictMonad) {
  return function (p) {
    return function (sep) {
      return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(sepEndBy1(dictMonad)(p)(sep))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_List_Types.Nil.value));
    };
  };
};

var sepBy1 = function sepBy1(dictMonad) {
  return function (p) {
    return function (sep) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_List.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(sep)(p)))(function (v1) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_List_Types.Cons(v, v1));
        });
      });
    };
  };
};

var sepBy = function sepBy(dictMonad) {
  return function (p) {
    return function (sep) {
      return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(sepBy1(dictMonad)(p)(sep))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_List_Types.Nil.value));
    };
  };
};

var optional = function optional(dictMonad) {
  return function (p) {
    return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(dictMonad.Bind1().Apply0().Functor0()))(p))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit));
  };
};

var option = function option(dictMonad) {
  return function (a) {
    return function (p) {
      return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(p)(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
    };
  };
};

var optionMaybe = function optionMaybe(dictMonad) {
  return function (p) {
    return option(dictMonad)(Data_Maybe.Nothing.value)(Data_Functor.map(Text_Parsing_Parser.functorParserT(dictMonad.Bind1().Apply0().Functor0()))(Data_Maybe.Just.create)(p));
  };
};

var notFollowedBy = function notFollowedBy(dictMonad) {
  return function (p) {
    return $$try(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))($$try(dictMonad)(p))(Text_Parsing_Parser.fail(dictMonad)("Negated parser succeeded")))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit)));
  };
};

var manyTill = function manyTill(dictMonad) {
  return function (p) {
    return function (end) {
      var scan = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(dictMonad.Bind1().Apply0().Functor0()))(end)(Data_List_Types.Nil.value))(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(scan)(function (v1) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_List_Types.Cons(v, v1));
        });
      }));
      return scan;
    };
  };
};

var many1Till = function many1Till(dictMonad) {
  return function (p) {
    return function (end) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(manyTill(dictMonad)(p)(end))(function (v1) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_List_Types.Cons(v, v1));
        });
      });
    };
  };
};

var lookAhead = function lookAhead(dictMonad) {
  return function (p) {
    return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (s) {
      return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(s))(function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v.value0, s));
      });
    })));
  };
};

var endBy1 = function endBy1(dictMonad) {
  return function (p) {
    return function (sep) {
      return Data_List.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(p)(sep));
    };
  };
};

var endBy = function endBy(dictMonad) {
  return function (p) {
    return function (sep) {
      return Data_List.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(p)(sep));
    };
  };
};

var choice = function choice(dictFoldable) {
  return function (dictMonad) {
    return Data_Foldable.foldl(dictFoldable)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad)))(Control_Plus.empty(Text_Parsing_Parser.plusParserT(dictMonad)));
  };
};

var chainr1$prime = function chainr1$prime(dictMonad) {
  return function (p) {
    return function (f) {
      return function (a) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(f)(function (v) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(chainr1(dictMonad)(p)(f))(function (v1) {
            return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v(a)(v1));
          });
        }))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
      };
    };
  };
};

var chainr1 = function chainr1(dictMonad) {
  return function (p) {
    return function (f) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return chainr1$prime(dictMonad)(p)(f)(v);
      });
    };
  };
};

var chainr = function chainr(dictMonad) {
  return function (p) {
    return function (f) {
      return function (a) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(chainr1(dictMonad)(p)(f))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
      };
    };
  };
};

var chainl1$prime = function chainl1$prime(dictMonad) {
  return function (p) {
    return function (f) {
      return function (a) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(f)(function (v) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v1) {
            return chainl1$prime(dictMonad)(p)(f)(v(a)(v1));
          });
        }))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
      };
    };
  };
};

var chainl1 = function chainl1(dictMonad) {
  return function (p) {
    return function (f) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
        return chainl1$prime(dictMonad)(p)(f)(v);
      });
    };
  };
};

var chainl = function chainl(dictMonad) {
  return function (p) {
    return function (f) {
      return function (a) {
        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(chainl1(dictMonad)(p)(f))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
      };
    };
  };
};

var between = function between(dictMonad) {
  return function (open) {
    return function (close) {
      return function (p) {
        return Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(open)(p))(close);
      };
    };
  };
};

var asErrorMessage = function asErrorMessage(dictMonad) {
  return Data_Function.flip(withErrorMessage(dictMonad));
};

module.exports = {
  withErrorMessage: withErrorMessage,
  asErrorMessage: asErrorMessage,
  between: between,
  option: option,
  optional: optional,
  optionMaybe: optionMaybe,
  "try": $$try,
  tryRethrow: tryRethrow,
  lookAhead: lookAhead,
  sepBy: sepBy,
  sepBy1: sepBy1,
  sepEndBy: sepEndBy,
  sepEndBy1: sepEndBy1,
  endBy1: endBy1,
  endBy: endBy,
  chainr: chainr,
  chainl: chainl,
  chainl1: chainl1,
  "chainl1'": chainl1$prime,
  chainr1: chainr1,
  "chainr1'": chainr1$prime,
  choice: choice,
  skipMany: skipMany,
  skipMany1: skipMany1,
  notFollowedBy: notFollowedBy,
  manyTill: manyTill,
  many1Till: many1Till
};