// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var MonadZero = function MonadZero(Alternative1, Monad0) {
  this.Alternative1 = Alternative1;
  this.Monad0 = Monad0;
};

var monadZeroArray = new MonadZero(function () {
  return Control_Alternative.alternativeArray;
}, function () {
  return Control_Monad.monadArray;
});

var guard = function guard(dictMonadZero) {
  return function (v) {
    if (v) {
      return Control_Applicative.pure(dictMonadZero.Alternative1().Applicative0())(Data_Unit.unit);
    }

    ;

    if (!v) {
      return Control_Plus.empty(dictMonadZero.Alternative1().Plus1());
    }

    ;
    throw new Error("Failed pattern match at Control.MonadZero (line 54, column 1 - line 54, column 52): " + [v.constructor.name]);
  };
};

module.exports = {
  MonadZero: MonadZero,
  guard: guard,
  monadZeroArray: monadZeroArray
};