// Generated by purs version 0.12.3
"use strict";

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Year = function Year(x) {
  return x;
};

var Monday = function () {
  function Monday() {}

  ;
  Monday.value = new Monday();
  return Monday;
}();

var Tuesday = function () {
  function Tuesday() {}

  ;
  Tuesday.value = new Tuesday();
  return Tuesday;
}();

var Wednesday = function () {
  function Wednesday() {}

  ;
  Wednesday.value = new Wednesday();
  return Wednesday;
}();

var Thursday = function () {
  function Thursday() {}

  ;
  Thursday.value = new Thursday();
  return Thursday;
}();

var Friday = function () {
  function Friday() {}

  ;
  Friday.value = new Friday();
  return Friday;
}();

var Saturday = function () {
  function Saturday() {}

  ;
  Saturday.value = new Saturday();
  return Saturday;
}();

var Sunday = function () {
  function Sunday() {}

  ;
  Sunday.value = new Sunday();
  return Sunday;
}();

var January = function () {
  function January() {}

  ;
  January.value = new January();
  return January;
}();

var February = function () {
  function February() {}

  ;
  February.value = new February();
  return February;
}();

var March = function () {
  function March() {}

  ;
  March.value = new March();
  return March;
}();

var April = function () {
  function April() {}

  ;
  April.value = new April();
  return April;
}();

var May = function () {
  function May() {}

  ;
  May.value = new May();
  return May;
}();

var June = function () {
  function June() {}

  ;
  June.value = new June();
  return June;
}();

var July = function () {
  function July() {}

  ;
  July.value = new July();
  return July;
}();

var August = function () {
  function August() {}

  ;
  August.value = new August();
  return August;
}();

var September = function () {
  function September() {}

  ;
  September.value = new September();
  return September;
}();

var October = function () {
  function October() {}

  ;
  October.value = new October();
  return October;
}();

var November = function () {
  function November() {}

  ;
  November.value = new November();
  return November;
}();

var December = function () {
  function December() {}

  ;
  December.value = new December();
  return December;
}();

var Day = function Day(x) {
  return x;
};

var showYear = new Data_Show.Show(function (v) {
  return "(Year " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var showWeekday = new Data_Show.Show(function (v) {
  if (v instanceof Monday) {
    return "Monday";
  }

  ;

  if (v instanceof Tuesday) {
    return "Tuesday";
  }

  ;

  if (v instanceof Wednesday) {
    return "Wednesday";
  }

  ;

  if (v instanceof Thursday) {
    return "Thursday";
  }

  ;

  if (v instanceof Friday) {
    return "Friday";
  }

  ;

  if (v instanceof Saturday) {
    return "Saturday";
  }

  ;

  if (v instanceof Sunday) {
    return "Sunday";
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 184, column 1 - line 184, column 37): " + [v.constructor.name]);
});
var showMonth = new Data_Show.Show(function (v) {
  if (v instanceof January) {
    return "January";
  }

  ;

  if (v instanceof February) {
    return "February";
  }

  ;

  if (v instanceof March) {
    return "March";
  }

  ;

  if (v instanceof April) {
    return "April";
  }

  ;

  if (v instanceof May) {
    return "May";
  }

  ;

  if (v instanceof June) {
    return "June";
  }

  ;

  if (v instanceof July) {
    return "July";
  }

  ;

  if (v instanceof August) {
    return "August";
  }

  ;

  if (v instanceof September) {
    return "September";
  }

  ;

  if (v instanceof October) {
    return "October";
  }

  ;

  if (v instanceof November) {
    return "November";
  }

  ;

  if (v instanceof December) {
    return "December";
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 101, column 1 - line 101, column 33): " + [v.constructor.name]);
});
var showDay = new Data_Show.Show(function (v) {
  return "(Day " + (Data_Show.show(Data_Show.showInt)(v) + ")");
});
var ordYear = Data_Ord.ordInt;
var ordDay = Data_Ord.ordInt;
var eqYear = Data_Eq.eqInt;
var eqWeekday = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof Monday && y instanceof Monday) {
      return true;
    }

    ;

    if (x instanceof Tuesday && y instanceof Tuesday) {
      return true;
    }

    ;

    if (x instanceof Wednesday && y instanceof Wednesday) {
      return true;
    }

    ;

    if (x instanceof Thursday && y instanceof Thursday) {
      return true;
    }

    ;

    if (x instanceof Friday && y instanceof Friday) {
      return true;
    }

    ;

    if (x instanceof Saturday && y instanceof Saturday) {
      return true;
    }

    ;

    if (x instanceof Sunday && y instanceof Sunday) {
      return true;
    }

    ;
    return false;
  };
});
var ordWeekday = new Data_Ord.Ord(function () {
  return eqWeekday;
}, function (x) {
  return function (y) {
    if (x instanceof Monday && y instanceof Monday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Monday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Monday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Tuesday && y instanceof Tuesday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Tuesday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Tuesday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Wednesday && y instanceof Wednesday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Wednesday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Wednesday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Thursday && y instanceof Thursday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Thursday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Thursday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Friday && y instanceof Friday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Friday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Friday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Saturday && y instanceof Saturday) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof Saturday) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof Saturday) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof Sunday && y instanceof Sunday) {
      return Data_Ordering.EQ.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 154, column 8 - line 154, column 42): " + [x.constructor.name, y.constructor.name]);
  };
});
var eqMonth = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof January && y instanceof January) {
      return true;
    }

    ;

    if (x instanceof February && y instanceof February) {
      return true;
    }

    ;

    if (x instanceof March && y instanceof March) {
      return true;
    }

    ;

    if (x instanceof April && y instanceof April) {
      return true;
    }

    ;

    if (x instanceof May && y instanceof May) {
      return true;
    }

    ;

    if (x instanceof June && y instanceof June) {
      return true;
    }

    ;

    if (x instanceof July && y instanceof July) {
      return true;
    }

    ;

    if (x instanceof August && y instanceof August) {
      return true;
    }

    ;

    if (x instanceof September && y instanceof September) {
      return true;
    }

    ;

    if (x instanceof October && y instanceof October) {
      return true;
    }

    ;

    if (x instanceof November && y instanceof November) {
      return true;
    }

    ;

    if (x instanceof December && y instanceof December) {
      return true;
    }

    ;
    return false;
  };
});
var ordMonth = new Data_Ord.Ord(function () {
  return eqMonth;
}, function (x) {
  return function (y) {
    if (x instanceof January && y instanceof January) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof January) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof January) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof February && y instanceof February) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof February) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof February) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof March && y instanceof March) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof March) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof March) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof April && y instanceof April) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof April) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof April) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof May && y instanceof May) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof May) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof May) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof June && y instanceof June) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof June) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof June) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof July && y instanceof July) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof July) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof July) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof August && y instanceof August) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof August) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof August) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof September && y instanceof September) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof September) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof September) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof October && y instanceof October) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof October) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof October) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof November && y instanceof November) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (x instanceof November) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof November) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof December && y instanceof December) {
      return Data_Ordering.EQ.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 61, column 8 - line 61, column 38): " + [x.constructor.name, y.constructor.name]);
  };
});
var eqDay = Data_Eq.eqInt;
var boundedYear = new Data_Bounded.Bounded(function () {
  return ordYear;
}, -271820 | 0, 275759);
var boundedWeekday = new Data_Bounded.Bounded(function () {
  return ordWeekday;
}, Monday.value, Sunday.value);
var boundedMonth = new Data_Bounded.Bounded(function () {
  return ordMonth;
}, January.value, December.value);
var boundedEnumYear = new Data_Enum.BoundedEnum(function () {
  return boundedYear;
}, function () {
  return enumYear;
}, 547580, function (v) {
  return v;
}, function (n) {
  if (n >= (-271820 | 0) && n <= 275759) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 35, column 1 - line 35, column 45): " + [n.constructor.name]);
});
var enumYear = new Data_Enum.Enum(function () {
  return ordYear;
}, function ($46) {
  return Data_Enum.toEnum(boundedEnumYear)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumYear)($46)));
}, function ($47) {
  return Data_Enum.toEnum(boundedEnumYear)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumYear)($47)));
});
var boundedEnumWeekday = new Data_Enum.BoundedEnum(function () {
  return boundedWeekday;
}, function () {
  return enumWeekday;
}, 7, function (v) {
  if (v instanceof Monday) {
    return 1;
  }

  ;

  if (v instanceof Tuesday) {
    return 2;
  }

  ;

  if (v instanceof Wednesday) {
    return 3;
  }

  ;

  if (v instanceof Thursday) {
    return 4;
  }

  ;

  if (v instanceof Friday) {
    return 5;
  }

  ;

  if (v instanceof Saturday) {
    return 6;
  }

  ;

  if (v instanceof Sunday) {
    return 7;
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 175, column 14 - line 184, column 1): " + [v.constructor.name]);
}, function (v) {
  if (v === 1) {
    return new Data_Maybe.Just(Monday.value);
  }

  ;

  if (v === 2) {
    return new Data_Maybe.Just(Tuesday.value);
  }

  ;

  if (v === 3) {
    return new Data_Maybe.Just(Wednesday.value);
  }

  ;

  if (v === 4) {
    return new Data_Maybe.Just(Thursday.value);
  }

  ;

  if (v === 5) {
    return new Data_Maybe.Just(Friday.value);
  }

  ;

  if (v === 6) {
    return new Data_Maybe.Just(Saturday.value);
  }

  ;

  if (v === 7) {
    return new Data_Maybe.Just(Sunday.value);
  }

  ;
  return Data_Maybe.Nothing.value;
});
var enumWeekday = new Data_Enum.Enum(function () {
  return ordWeekday;
}, function ($48) {
  return Data_Enum.toEnum(boundedEnumWeekday)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumWeekday)($48)));
}, function ($49) {
  return Data_Enum.toEnum(boundedEnumWeekday)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumWeekday)($49)));
});
var boundedEnumMonth = new Data_Enum.BoundedEnum(function () {
  return boundedMonth;
}, function () {
  return enumMonth;
}, 12, function (v) {
  if (v instanceof January) {
    return 1;
  }

  ;

  if (v instanceof February) {
    return 2;
  }

  ;

  if (v instanceof March) {
    return 3;
  }

  ;

  if (v instanceof April) {
    return 4;
  }

  ;

  if (v instanceof May) {
    return 5;
  }

  ;

  if (v instanceof June) {
    return 6;
  }

  ;

  if (v instanceof July) {
    return 7;
  }

  ;

  if (v instanceof August) {
    return 8;
  }

  ;

  if (v instanceof September) {
    return 9;
  }

  ;

  if (v instanceof October) {
    return 10;
  }

  ;

  if (v instanceof November) {
    return 11;
  }

  ;

  if (v instanceof December) {
    return 12;
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 87, column 14 - line 101, column 1): " + [v.constructor.name]);
}, function (v) {
  if (v === 1) {
    return new Data_Maybe.Just(January.value);
  }

  ;

  if (v === 2) {
    return new Data_Maybe.Just(February.value);
  }

  ;

  if (v === 3) {
    return new Data_Maybe.Just(March.value);
  }

  ;

  if (v === 4) {
    return new Data_Maybe.Just(April.value);
  }

  ;

  if (v === 5) {
    return new Data_Maybe.Just(May.value);
  }

  ;

  if (v === 6) {
    return new Data_Maybe.Just(June.value);
  }

  ;

  if (v === 7) {
    return new Data_Maybe.Just(July.value);
  }

  ;

  if (v === 8) {
    return new Data_Maybe.Just(August.value);
  }

  ;

  if (v === 9) {
    return new Data_Maybe.Just(September.value);
  }

  ;

  if (v === 10) {
    return new Data_Maybe.Just(October.value);
  }

  ;

  if (v === 11) {
    return new Data_Maybe.Just(November.value);
  }

  ;

  if (v === 12) {
    return new Data_Maybe.Just(December.value);
  }

  ;
  return Data_Maybe.Nothing.value;
});
var enumMonth = new Data_Enum.Enum(function () {
  return ordMonth;
}, function ($50) {
  return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMonth)($50)));
}, function ($51) {
  return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumMonth)($51)));
});
var boundedDay = new Data_Bounded.Bounded(function () {
  return ordDay;
}, 1, 31);
var boundedEnumDay = new Data_Enum.BoundedEnum(function () {
  return boundedDay;
}, function () {
  return enumDay;
}, 31, function (v) {
  return v;
}, function (n) {
  if (n >= 1 && n <= 31) {
    return new Data_Maybe.Just(n);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Date.Component (line 133, column 1 - line 133, column 43): " + [n.constructor.name]);
});
var enumDay = new Data_Enum.Enum(function () {
  return ordDay;
}, function ($52) {
  return Data_Enum.toEnum(boundedEnumDay)(function (v) {
    return v - 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumDay)($52)));
}, function ($53) {
  return Data_Enum.toEnum(boundedEnumDay)(function (v) {
    return v + 1 | 0;
  }(Data_Enum.fromEnum(boundedEnumDay)($53)));
});
module.exports = {
  January: January,
  February: February,
  March: March,
  April: April,
  May: May,
  June: June,
  July: July,
  August: August,
  September: September,
  October: October,
  November: November,
  December: December,
  Monday: Monday,
  Tuesday: Tuesday,
  Wednesday: Wednesday,
  Thursday: Thursday,
  Friday: Friday,
  Saturday: Saturday,
  Sunday: Sunday,
  eqYear: eqYear,
  ordYear: ordYear,
  boundedYear: boundedYear,
  enumYear: enumYear,
  boundedEnumYear: boundedEnumYear,
  showYear: showYear,
  eqMonth: eqMonth,
  ordMonth: ordMonth,
  boundedMonth: boundedMonth,
  enumMonth: enumMonth,
  boundedEnumMonth: boundedEnumMonth,
  showMonth: showMonth,
  eqDay: eqDay,
  ordDay: ordDay,
  boundedDay: boundedDay,
  enumDay: enumDay,
  boundedEnumDay: boundedEnumDay,
  showDay: showDay,
  eqWeekday: eqWeekday,
  ordWeekday: ordWeekday,
  boundedWeekday: boundedWeekday,
  enumWeekday: enumWeekday,
  boundedEnumWeekday: boundedEnumWeekday,
  showWeekday: showWeekday
};