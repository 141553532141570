// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var radioGroup = function radioGroup(dictUnion) {
  return React_Basic.element($foreign["_radioGroup"]);
};

var radio = function radio(dictUnion) {
  return React_Basic.element($foreign["_radio"]);
};

module.exports = {
  radio: radio,
  radioGroup: radioGroup
};