// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Biapplicative = require("../Control.Biapplicative/index.js");

var Control_Biapply = require("../Control.Biapply/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Prelude = require("../Prelude/index.js");

var Joker = function Joker(x) {
  return x;
};

var showJoker = function showJoker(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Joker " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var ordJoker = function ordJoker(dictOrd) {
  return dictOrd;
};

var newtypeJoker = new Data_Newtype.Newtype(function (n) {
  return n;
}, Joker);

var functorJoker = function functorJoker(dictFunctor) {
  return new Data_Functor.Functor(function (g) {
    return function (v) {
      return Data_Functor.map(dictFunctor)(g)(v);
    };
  });
};

var eqJoker = function eqJoker(dictEq) {
  return dictEq;
};

var bifunctorJoker = function bifunctorJoker(dictFunctor) {
  return new Data_Bifunctor.Bifunctor(function (v) {
    return function (g) {
      return function (v1) {
        return Data_Functor.map(dictFunctor)(g)(v1);
      };
    };
  });
};

var biapplyJoker = function biapplyJoker(dictApply) {
  return new Control_Biapply.Biapply(function () {
    return bifunctorJoker(dictApply.Functor0());
  }, function (v) {
    return function (v1) {
      return Control_Apply.apply(dictApply)(v)(v1);
    };
  });
};

var biapplicativeJoker = function biapplicativeJoker(dictApplicative) {
  return new Control_Biapplicative.Biapplicative(function () {
    return biapplyJoker(dictApplicative.Apply0());
  }, function (v) {
    return function (b) {
      return Control_Applicative.pure(dictApplicative)(b);
    };
  });
};

module.exports = {
  Joker: Joker,
  newtypeJoker: newtypeJoker,
  eqJoker: eqJoker,
  ordJoker: ordJoker,
  showJoker: showJoker,
  functorJoker: functorJoker,
  bifunctorJoker: bifunctorJoker,
  biapplyJoker: biapplyJoker,
  biapplicativeJoker: biapplicativeJoker
};