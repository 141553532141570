// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var Type_Row = require("../Type.Row/index.js");

var NoUpdate = function () {
  function NoUpdate() {}

  ;
  NoUpdate.value = new NoUpdate();
  return NoUpdate;
}();

var Update = function () {
  function Update(value0) {
    this.value0 = value0;
  }

  ;

  Update.create = function (value0) {
    return new Update(value0);
  };

  return Update;
}();

var SideEffects = function () {
  function SideEffects(value0) {
    this.value0 = value0;
  }

  ;

  SideEffects.create = function (value0) {
    return new SideEffects(value0);
  };

  return SideEffects;
}();

var UpdateAndSideEffects = function () {
  function UpdateAndSideEffects(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  UpdateAndSideEffects.create = function (value0) {
    return function (value1) {
      return new UpdateAndSideEffects(value0, value1);
    };
  };

  return UpdateAndSideEffects;
}();

var semigroupJSX = new Data_Semigroup.Semigroup(function (a) {
  return function (b) {
    return $foreign.fragment([a, b]);
  };
});
var monoidJSX = new Data_Monoid.Monoid(function () {
  return semigroupJSX;
}, $foreign.empty);

var makeStateless = function makeStateless(component) {
  return function (_render) {
    return $foreign.make()(component)({
      initialState: Data_Unit.unit,
      render: function render(self) {
        return _render(self.props);
      }
    });
  };
};

var keyed = Data_Function_Uncurried.runFn2($foreign.keyed_);
var elementKeyed = Data_Function_Uncurried.runFn2($foreign.elementKeyed_);
var element = Data_Function_Uncurried.runFn2($foreign.element_);

var buildStateUpdate = function buildStateUpdate(v) {
  if (v instanceof NoUpdate) {
    return {
      state: Data_Nullable["null"],
      effects: Data_Nullable["null"]
    };
  }

  ;

  if (v instanceof Update) {
    return {
      state: Data_Nullable.notNull(v.value0),
      effects: Data_Nullable["null"]
    };
  }

  ;

  if (v instanceof SideEffects) {
    return {
      state: Data_Nullable["null"],
      effects: Data_Nullable.notNull(v.value0)
    };
  }

  ;

  if (v instanceof UpdateAndSideEffects) {
    return {
      state: Data_Nullable.notNull(v.value0),
      effects: Data_Nullable.notNull(v.value1)
    };
  }

  ;
  throw new Error("Failed pattern match at React.Basic (line 382, column 20 - line 398, column 6): " + [v.constructor.name]);
};

var runUpdate = function runUpdate(update) {
  return Effect_Uncurried.runEffectFn3($foreign.runUpdate_)(function (self, action) {
    return buildStateUpdate(update(self)(action));
  });
};

module.exports = {
  NoUpdate: NoUpdate,
  Update: Update,
  SideEffects: SideEffects,
  UpdateAndSideEffects: UpdateAndSideEffects,
  runUpdate: runUpdate,
  makeStateless: makeStateless,
  keyed: keyed,
  element: element,
  elementKeyed: elementKeyed,
  semigroupJSX: semigroupJSX,
  monoidJSX: monoidJSX,
  createComponent: $foreign.createComponent,
  readProps: $foreign.readProps,
  readState: $foreign.readState,
  make: $foreign.make,
  empty: $foreign.empty,
  fragment: $foreign.fragment,
  displayNameFromComponent: $foreign.displayNameFromComponent,
  displayNameFromSelf: $foreign.displayNameFromSelf,
  toReactComponent: $foreign.toReactComponent
};