// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_Long = require("../Data.Long/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Input = require("../Optimus.Client.Antd.Input/index.js");

var Optimus_Client_Antd_InputNumber = require("../Optimus.Client.Antd.InputNumber/index.js");

var Optimus_Client_Antd_Radio = require("../Optimus.Client.Antd.Radio/index.js");

var Optimus_Client_Components_Card = require("../Optimus.Client.Components.Card/index.js");

var Optimus_Client_Components_InfoPanel = require("../Optimus.Client.Components.InfoPanel/index.js");

var Optimus_Client_Components_Section = require("../Optimus.Client.Components.Section/index.js");

var Optimus_Client_Components_Settings_ShowDefaultVal = require("../Optimus.Client.Components.Settings.ShowDefaultVal/index.js");

var Optimus_Client_Components_Settings_SingleSelection = require("../Optimus.Client.Components.Settings.SingleSelection/index.js");

var Optimus_Client_Components_Settings_Utilities = require("../Optimus.Client.Components.Settings.Utilities/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Optimus_Utilities_Default = require("../Optimus.Utilities.Default/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var Record = require("../Record/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var Type_Proxy = require("../Type.Proxy/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var ZRPC_Types_Millis = require("../ZRPC.Types.Millis/index.js");

var SettingInput = function SettingInput(Default0, toSettingInput) {
  this.Default0 = Default0;
  this.toSettingInput = toSettingInput;
};

var SettingInputs = function SettingInputs(toSettingInputs) {
  this.toSettingInputs = toSettingInputs;
};

var SettingInputsRL = function SettingInputsRL(toSettingInputsRL) {
  this.toSettingInputsRL = toSettingInputsRL;
};

var SingleSelectionFields = function SingleSelectionFields(toSingleSelectionFields) {
  this.toSingleSelectionFields = toSingleSelectionFields;
};

var toSingleSelectionFields = function toSingleSelectionFields(dict) {
  return dict.toSingleSelectionFields;
};

var toSettingInputsRL = function toSettingInputsRL(dict) {
  return dict.toSettingInputsRL;
};

var toSettingInputsRecord = function toSettingInputsRecord(dictRowToList) {
  return function (dictSettingInputsRL) {
    return new SettingInputs(function (r) {
      return toSettingInputsRL(dictSettingInputsRL)(Type_Data_RowList.RLProxy.value)(r);
    });
  };
};

var toSettingInputsNil = new SettingInputsRL(function (v) {
  return function (v1) {
    return function (r) {
      return [];
    };
  };
});

var toSettingInputs = function toSettingInputs(dict) {
  return dict.toSettingInputs;
};

var toSettingInputString = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultString;
}, function (v) {
  return function (label) {
    return function (handler) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Client_Antd_Input.input()({
        value: v,
        onChange: React_Basic_DOM_Events.capture(React_Basic_Events.merge()(React_Basic_Events.mergeCons(new Data_Symbol.IsSymbol(function () {
          return "targetValue";
        }))()()()()(React_Basic_Events.mergeNil))({
          targetValue: React_Basic_DOM_Events.targetValue
        }))(function (v1) {
          return handler(Data_Maybe.fromMaybe(Optimus_Utilities_Default["default"](Optimus_Utilities_Default.defaultString))(v1.targetValue));
        })
      })]);
    };
  };
});

var toSettingInputRecord = function toSettingInputRecord(dictSettingInputs) {
  return function (dictDefaults) {
    return new SettingInput(function () {
      return Optimus_Utilities_Default.defaultRecord(dictDefaults);
    }, function (v) {
      return function (label) {
        return function (handler) {
          return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), React_Basic_DOM_Generated.div_(toSettingInputs(dictSettingInputs)(handler)(v))]);
        };
      };
    });
  };
};

var toSettingInputMillis = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultMillis;
}, function (v) {
  return function (label) {
    return function (handler) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Client_Antd_InputNumber.inputNumber()({
        value: Data_Long.toString(v),
        onChange: function onChange($52) {
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handler)(Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_Millis.Millis)(Data_Long.fromString($52)))();
        }
      }), React_Basic_DOM.text("ms")]);
    };
  };
});
var toSettingInputInt = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultInt;
}, function (v) {
  return function (label) {
    return function (handler) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), Optimus_Client_Antd_InputNumber.inputNumber()({
        value: Data_Show.show(Data_Show.showInt)(v),
        onChange: function onChange($53) {
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(handler)(Data_Int.fromString($53))();
        }
      })]);
    };
  };
});
var toSettingInputBool = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultBoolean;
}, function (v) {
  return function (label) {
    return function (handler) {
      return React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.label_([React_Basic_DOM.text(label)]), React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.div_([Optimus_Client_Antd_Radio.radio()({
        checked: v,
        onChange: React_Basic_Events.handler_(handler(true)),
        children: [React_Basic_DOM.text("Yes")]
      })]), React_Basic_DOM_Generated.div_([Optimus_Client_Antd_Radio.radio()({
        checked: !v,
        onChange: React_Basic_Events.handler_(handler(false)),
        children: [React_Basic_DOM.text("No")]
      })])])]);
    };
  };
});

var toSettingInput = function toSettingInput(dict) {
  return dict.toSettingInput;
};

var toSettingInputMaybe = function toSettingInputMaybe(dictDefault) {
  return function (dictSettingInput) {
    return new SettingInput(function () {
      return Optimus_Utilities_Default.defaultMaybe;
    }, function (v) {
      return function (label) {
        return function (handler) {
          if (v instanceof Data_Maybe.Just) {
            return toSettingInput(dictSettingInput)(v.value0)(label)(function ($54) {
              return handler(Control_Applicative.pure(Data_Maybe.applicativeMaybe)($54));
            });
          }

          ;

          if (v instanceof Data_Maybe.Nothing) {
            return toSettingInput(dictSettingInput)(Optimus_Utilities_Default["default"](dictDefault))(label)(function ($55) {
              return handler(Control_Applicative.pure(Data_Maybe.applicativeMaybe)($55));
            });
          }

          ;
          throw new Error("Failed pattern match at Optimus.Client.Components.Settings.SettingInput (line 54, column 1 - line 54, column 87): " + [v.constructor.name, label.constructor.name, handler.constructor.name]);
        };
      };
    });
  };
};

var toSettingInputSettingFormState$prime = function toSettingInputSettingFormState$prime(dictSettingInput) {
  return function (dictShowDefaultVal) {
    return new SettingInput(function () {
      return Optimus_Utilities_Default.defaultSFS(dictSettingInput.Default0());
    }, function (v) {
      return function (label) {
        return function (handler) {
          var settingSelectionRadio = function settingSelectionRadio(ss) {
            return Optimus_Client_Antd_Radio.radio()({
              checked: Data_Eq.eq(Optimus_Client_Components_Settings_Utilities.settingSelectionEq)(v.selected)(ss),
              onChange: React_Basic_Events.handler_(handler({
                selected: ss,
                "default": v["default"],
                fixed: v.fixed
              })),
              value: Data_Show.show(Optimus_Client_Components_Settings_Utilities.settingSelectionShow)(ss),
              children: [React_Basic_DOM.text(Data_Show.show(Optimus_Client_Components_Settings_Utilities.settingSelectionShow)(ss))]
            });
          };

          return Optimus_Client_Components_Card.card([Optimus_Client_Components_Section.section(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)(label))(React_Basic_DOM_Generated.div_([Optimus_Client_Antd_Grid.row_([settingSelectionRadio(Optimus_Client_Components_Settings_Utilities.Default.value), React_Basic_DOM_Generated.div_([React_Basic_DOM_Generated.span_([React_Basic_DOM.text("Provider:" + Data_Show.show(ZRPC_Clients_Mercanti.showProvider)(v["default"].provider))]), React_Basic_DOM_Generated.br()({}), Data_Semigroup.append(React_Basic.semigroupJSX)(React_Basic_DOM_Generated.span_([React_Basic_DOM.text("Value:")]))(React_Basic.fragment([Optimus_Client_Components_Settings_ShowDefaultVal.showDefaultVal(dictShowDefaultVal)(v["default"].value)]))])]), Optimus_Client_Antd_Grid.row_([settingSelectionRadio(Optimus_Client_Components_Settings_Utilities.Fixed.value), Optimus_Client_Antd_Grid.row_([Optimus_Client_Antd_Grid.col()({
            offset: 1,
            children: [toSettingInput(toSettingInputMaybe(dictSettingInput.Default0())(dictSettingInput))(v.fixed)(label)(function (newFixed) {
              return handler({
                selected: v.selected,
                "default": v["default"],
                fixed: newFixed
              });
            })]
          })])])]))]);
        };
      };
    });
  };
};

var toSettingInputsRLConsSFS = function toSettingInputsRLConsSFS(dictIsSymbol) {
  return function (dictSettingInput) {
    return function (dictCons) {
      return function (dictSettingInputsRL) {
        return new SettingInputsRL(function (v) {
          return function (updater) {
            return function (r) {
              var tailInputCs = toSettingInputsRL(dictSettingInputsRL)(Type_Data_RowList.RLProxy.value)(updater)(r);
              var v1 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);

              var indivUpdater = function indivUpdater(newV) {
                return updater(Record.set(dictIsSymbol)(dictCons)(dictCons)(Data_Symbol.SProxy.value)(newV)(r));
              };

              return Data_Semigroup.append(Data_Semigroup.semigroupArray)(tailInputCs)([toSettingInput(dictSettingInput)(v1)(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))(indivUpdater)]);
            };
          };
        });
      };
    };
  };
};

var ssFieldsSum = function ssFieldsSum(dictSingleSelectionFields) {
  return function (dictSingleSelectionFields1) {
    return new SingleSelectionFields(function (v) {
      return function (handler) {
        if (v instanceof Data_Generic_Rep.Inl) {
          return toSingleSelectionFields(dictSingleSelectionFields)(v.value0)(function (newV) {
            return handler(new Data_Generic_Rep.Inl(newV));
          });
        }

        ;

        if (v instanceof Data_Generic_Rep.Inr) {
          return toSingleSelectionFields(dictSingleSelectionFields1)(v.value0)(function (newV) {
            return handler(new Data_Generic_Rep.Inr(newV));
          });
        }

        ;
        throw new Error("Failed pattern match at Optimus.Client.Components.Settings.SettingInput (line 224, column 1 - line 227, column 39): " + [v.constructor.name, handler.constructor.name]);
      };
    });
  };
};

var ssFieldsConsNoArg = function ssFieldsConsNoArg(dictIsSymbol) {
  return new SingleSelectionFields(function (v) {
    return function (v1) {
      return React_Basic.fragment([]);
    };
  });
};

var ssFieldsConsArgR = function ssFieldsConsArgR(dictIsSymbol) {
  return function (dictDefaults) {
    return function (dictSettingInputs) {
      return new SingleSelectionFields(function (v) {
        return function (handler) {
          var inputs = toSettingInputs(dictSettingInputs)(function (newV) {
            return handler(newV);
          })(v);
          return React_Basic_DOM_Generated.div_(inputs);
        };
      });
    };
  };
};

var genSingleSelectionFields = function genSingleSelectionFields(dictGeneric) {
  return function (dictSingleSelectionFields) {
    return function (v) {
      return function (handler) {
        return toSingleSelectionFields(dictSingleSelectionFields)(Data_Generic_Rep.from(dictGeneric)(v))(function ($56) {
          return handler(Data_Generic_Rep.to(dictGeneric)($56));
        });
      };
    };
  };
};

var genSingleSelectionSI = function genSingleSelectionSI(dictGeneric) {
  return function (dictSingleSelection) {
    return function (dictSingleSelectionFields) {
      return function (v) {
        return function (label) {
          return function (handler) {
            var spec = Optimus_Client_Components_Settings_SingleSelection.genToSingleSelectionSpec(dictGeneric)(dictSingleSelection)(Type_Proxy["Proxy"].value);
            var inputs = genSingleSelectionFields(dictGeneric)(dictSingleSelectionFields)(v)(handler);
            var dropdown = Optimus_Client_Components_Settings_SingleSelection.createSelect(dictGeneric)(v)(label)(handler)(spec);
            return React_Basic_DOM_Generated.div_([dropdown, inputs]);
          };
        };
      };
    };
  };
};

var toSettingInputAuthorizationMode = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultAuthMode;
}, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericAuthorizationMode)(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModePin";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModeOtp";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModePinOtp";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModeNoAuth";
}))))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModePin";
})))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModeOtp";
})))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModePinOtp";
})))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "AuthorizationModeNoAuth";
})))))));
var toSettingInputLinkTagVM = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultLinkTagVM;
}, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericLinkTagVerificationMode)(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeRequired";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeDisabled";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeOptional";
})))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeRequired";
})))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeDisabled";
})))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "LinkTagVerificationModeOptional";
}))))));
var toSettingInputRoundingMode = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultRoundingMode;
}, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericRoundingMode)(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeHalfUp";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeHalfDown";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeFloor";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeCeiling";
}))))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeHalfUp";
})))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeHalfDown";
})))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeFloor";
})))(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "RoundingModeCeiling";
})))))));
var toSettingInputTransactionAmountComputationMode = new SettingInput(function () {
  return Optimus_Utilities_Default.defaultTransactionAmountComputationMode;
}, genSingleSelectionSI(ZRPC_Clients_Mercanti.genericTransactionAmountComputationMode)(Optimus_Client_Components_Settings_SingleSelection.singleSelectionSum(Optimus_Client_Components_Settings_SingleSelection.singleSelectionCons(new Data_Symbol.IsSymbol(function () {
  return "TransactionAmountComputationModeUseSaleAmount";
})))(Optimus_Client_Components_Settings_SingleSelection.singleSelectionConsArg(new Data_Symbol.IsSymbol(function () {
  return "TransactionAmountComputationModeDeriveFromSku";
}))(Optimus_Utilities_Default.defaultRecord(Optimus_Utilities_Default.defaultsRecord()(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
  return "isDiscountedExcluded";
}))(Optimus_Utilities_Default.defaultBoolean)(Optimus_Utilities_Default.defaultsRLNil)()())))))(ssFieldsSum(ssFieldsConsNoArg(new Data_Symbol.IsSymbol(function () {
  return "TransactionAmountComputationModeUseSaleAmount";
})))(ssFieldsConsArgR(new Data_Symbol.IsSymbol(function () {
  return "TransactionAmountComputationModeDeriveFromSku";
}))(Optimus_Utilities_Default.defaultsRecord()(Optimus_Utilities_Default.defaultsRLCons(new Data_Symbol.IsSymbol(function () {
  return "isDiscountedExcluded";
}))(Optimus_Utilities_Default.defaultBoolean)(Optimus_Utilities_Default.defaultsRLNil)()()))(toSettingInputsRecord()(toSettingInputsRLConsSFS(new Data_Symbol.IsSymbol(function () {
  return "isDiscountedExcluded";
}))(toSettingInputBool)()(toSettingInputsNil))))));
module.exports = {
  SettingInput: SettingInput,
  toSettingInput: toSettingInput,
  SettingInputs: SettingInputs,
  toSettingInputs: toSettingInputs,
  SettingInputsRL: SettingInputsRL,
  toSettingInputsRL: toSettingInputsRL,
  toSettingInputMaybe: toSettingInputMaybe,
  toSettingInputString: toSettingInputString,
  toSettingInputInt: toSettingInputInt,
  toSettingInputMillis: toSettingInputMillis,
  toSettingInputBool: toSettingInputBool,
  toSettingInputRecord: toSettingInputRecord,
  toSettingInputRoundingMode: toSettingInputRoundingMode,
  toSettingInputLinkTagVM: toSettingInputLinkTagVM,
  toSettingInputAuthorizationMode: toSettingInputAuthorizationMode,
  toSettingInputTransactionAmountComputationMode: toSettingInputTransactionAmountComputationMode,
  toSettingInputsRecord: toSettingInputsRecord,
  toSettingInputsNil: toSettingInputsNil,
  toSettingInputsRLConsSFS: toSettingInputsRLConsSFS,
  "toSettingInputSettingFormState'": toSettingInputSettingFormState$prime
};