// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Foreign = require("../Foreign/index.js");

var Foreign_Index = require("../Foreign.Index/index.js");

var Foreign_Object = require("../Foreign.Object/index.js");

var Global_Unsafe = require("../Global.Unsafe/index.js");

var Milkis = require("../Milkis/index.js");

var Milkis_Impl = require("../Milkis.Impl/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var ZRPC_Internal = require("../ZRPC.Internal/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var Prod = function () {
  function Prod() {}

  ;
  Prod.value = new Prod();
  return Prod;
}();

var Preprod = function () {
  function Preprod() {}

  ;
  Preprod.value = new Preprod();
  return Preprod;
}();

var Staging = function () {
  function Staging() {}

  ;
  Staging.value = new Staging();
  return Staging;
}();

var Custom = function () {
  function Custom(value0) {
    this.value0 = value0;
  }

  ;

  Custom.create = function (value0) {
    return new Custom(value0);
  };

  return Custom;
}();

var ServiceName = function ServiceName(x) {
  return x;
};

var AppName = function AppName(x) {
  return x;
};

var urlForZenv = function urlForZenv(v) {
  if (v instanceof Prod) {
    return "https://public-rpc.zap.com.ph/rpc";
  }

  ;

  if (v instanceof Preprod) {
    return "https://public-rpc.preprod.zap.com.ph/rpc";
  }

  ;

  if (v instanceof Staging) {
    return "https://public-rpc.staging.zap.com.ph/rpc";
  }

  ;

  if (v instanceof Custom) {
    return v.value0;
  }

  ;
  throw new Error("Failed pattern match at ZRPC.Client (line 108, column 1 - line 108, column 26): " + [v.constructor.name]);
};

var unsafeForeignToJson = Unsafe_Coerce.unsafeCoerce;

var runFWithDefault = function runFWithDefault(a) {
  return function (fa) {
    var v = Control_Monad_Except.runExcept(fa);

    if (v instanceof Data_Either.Left) {
      return a;
    }

    ;

    if (v instanceof Data_Either.Right) {
      return v.value0;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Client (line 121, column 24 - line 123, column 15): " + [v.constructor.name]);
  };
};

var rpcFailureError = function rpcFailureError(msg) {
  return Effect_Exception.error("RPC Failure: " + msg);
};

var responseFormatError = function responseFormatError(msg) {
  return Effect_Exception.error("Invalid response: " + msg);
};

var readResp = function readResp(f) {
  return {
    success: runFWithDefault(true)(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign_Index.readProp("success")(f))(Foreign.readBoolean)),
    payload: runFWithDefault(ZRPC_Internal["undefined"])(Foreign_Index.readProp("payload")(f)),
    error: runFWithDefault("")(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign_Index.readProp("error")(f))(Foreign.readString))
  };
};

var rpcRequest = function rpcRequest(dictZRPCWrite) {
  return function (dictZRPCRead) {
    return function (cl) {
      return function (appName) {
        return function (serviceName) {
          return function (req) {
            var reqBody = Global_Unsafe.unsafeStringify({
              appName: appName,
              serviceName: serviceName,
              payload: ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(req)
            });
            var request = {
              method: Milkis.postMethod,
              headers: Foreign_Object.singleton("Content-Type")("application/json"),
              body: reqBody
            };

            var readRespFormat = function readRespFormat(f) {
              var v = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(dictZRPCRead)(unsafeForeignToJson(f)));

              if (v instanceof Data_Either.Right) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
              }

              ;

              if (v instanceof Data_Either.Left) {
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(responseFormatError(Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0)));
              }

              ;
              throw new Error("Failed pattern match at ZRPC.Client (line 94, column 7 - line 96, column 60): " + [v.constructor.name]);
            };

            var processResp = function processResp(rpcResp) {
              if (rpcResp.success) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(rpcResp.payload);
              }

              ;
              return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(rpcFailureError(rpcResp.error));
            };

            return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Bind.bind(Effect_Aff.bindAff)(Milkis.fetch(cl.fetchImpl)()(cl.rpcURL)(request))(Milkis.json))(readResp))(processResp))(readRespFormat);
          };
        };
      };
    };
  };
};

var mkRPCAppReqBuilder = function mkRPCAppReqBuilder(dictZRPCWrite) {
  return function (dictZRPCRead) {
    return function (appName) {
      return function (serviceNameS) {
        return function (rpcClient) {
          return function (req) {
            return rpcRequest(dictZRPCWrite)(dictZRPCRead)(rpcClient)(appName)(serviceNameS)(req);
          };
        };
      };
    };
  };
};

module.exports = {
  mkRPCAppReqBuilder: mkRPCAppReqBuilder,
  Prod: Prod,
  Preprod: Preprod,
  Staging: Staging,
  Custom: Custom,
  AppName: AppName,
  ServiceName: ServiceName,
  urlForZenv: urlForZenv
};