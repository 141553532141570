// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Dropdown = require("../Optimus.Client.Antd.Dropdown/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Icon = require("../Optimus.Client.Antd.Icon/index.js");

var Optimus_Client_Antd_Layout = require("../Optimus.Client.Antd.Layout/index.js");

var Optimus_Client_Antd_Menu = require("../Optimus.Client.Antd.Menu/index.js");

var Optimus_Client_Assets = require("../Optimus.Client.Assets/index.js");

var Optimus_Client_Auth = require("../Optimus.Client.Auth/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var header = function () {
  var leftHeader = Optimus_Client_Antd_Grid.col()({
    span: 10,
    children: [Data_Monoid.mempty(React_Basic.monoidJSX)]
  });

  var handleUserActionsClick = function handleUserActionsClick(v) {
    if (v.key === "logout") {
      return Optimus_Client_Auth.logout;
    }

    ;
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
  };

  var userActionsMenu = Optimus_Client_Antd_Menu.menu()({
    onClick: Effect_Uncurried.mkEffectFn1(handleUserActionsClick),
    children: [Optimus_Client_Antd_Menu.menuItem()({
      key: "logout",
      children: [React_Basic_DOM.text("Logout")]
    })]
  });
  var userActions = Optimus_Client_Antd_Dropdown.dropdown()({
    overlay: userActionsMenu,
    trigger: ["click"],
    children: [Optimus_Client_Antd_Icon.icon()({
      type: "user",
      style: React_Basic_DOM.css({
        fontSize: "20px"
      })
    })]
  });
  var rightHeader = Optimus_Client_Antd_Grid.col()({
    className: "flex justify-end items-center",
    span: 10,
    children: [userActions]
  });
  var centerHeader = Optimus_Client_Antd_Grid.col()({
    className: "flex justify-center items-center",
    span: 4,
    children: [React_Basic_DOM_Generated.img()({
      src: Optimus_Client_Assets.zapLogo
    })]
  });
  return Optimus_Client_Antd_Layout.header()({
    className: "header bg-white",
    children: [Optimus_Client_Antd_Grid.row()({
      className: "flex justify-between h-100",
      children: [leftHeader, centerHeader, rightHeader]
    })]
  });
}();

module.exports = {
  header: header
};