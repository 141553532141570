// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var MonadPlus = function MonadPlus(MonadZero0) {
  this.MonadZero0 = MonadZero0;
};

var monadPlusArray = new MonadPlus(function () {
  return Control_MonadZero.monadZeroArray;
});
module.exports = {
  MonadPlus: MonadPlus,
  monadPlusArray: monadPlusArray
};