// Generated by purs version 0.12.3
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Optimus_Client_Antd = require("../Optimus.Client.Antd/index.js");

var Optimus_Client_Antd_Button = require("../Optimus.Client.Antd.Button/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Icon = require("../Optimus.Client.Antd.Icon/index.js");

var Optimus_Client_AsyncPage = require("../Optimus.Client.AsyncPage/index.js");

var Optimus_Client_Component_Content = require("../Optimus.Client.Component.Content/index.js");

var Optimus_Client_Components_Card = require("../Optimus.Client.Components.Card/index.js");

var Optimus_Client_Components_InfoPanel = require("../Optimus.Client.Components.InfoPanel/index.js");

var Optimus_Client_Components_Section = require("../Optimus.Client.Components.Section/index.js");

var Optimus_Client_Components_Table = require("../Optimus.Client.Components.Table/index.js");

var Optimus_Client_InfoShow = require("../Optimus.Client.InfoShow/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Optimus_Shared_Users = require("../Optimus.Shared.Users/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var render = function render(v) {
  var tags = Optimus_Client_Components_Card.card([Optimus_Client_Components_Section.section(React_Basic.fragment([Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)("Tags"), React_Basic_DOM_Generated.a()({
    className: "fs-75 ml-2 underline",
    onClick: React_Basic_Events.handler_(v.history.push(Optimus_Client_Route.Route.create(v.siloId)(Optimus_Client_Route.SiloRoute.create(Optimus_Client_Route.Users.create(new Optimus_Client_Route.UsersTags(v.pgData.zapperId)))))),
    children: [React_Basic_DOM.text("View All")]
  })]))(Optimus_Client_Components_Table.table({
    onRowClick: function onRowClick(v1) {
      return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    },
    loading: false
  })([Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowDate)("Date Linked")(function ($14) {
    return Data_DateTime.date(function (v1) {
      return v1.linkedAt;
    }($14));
  }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("ZAP Code")(function (v1) {
    return v1.code;
  }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("Holding")(function (u) {
    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(u.holding)(function (v1) {
      return v1.name;
    });
  }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("Label")(function (v1) {
    return v1.label;
  })])(v.pgData.tags))]);
  var suspendButton = Optimus_Client_Antd_Button.button()({
    block: true,
    children: [React_Basic_DOM.text("Suspend")]
  });
  var profile = Optimus_Client_Components_Card.card([Optimus_Client_Components_Section.section(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)("Profile"))(Optimus_Client_Components_InfoPanel.infoPanel([Optimus_Client_Components_InfoPanel.ip("Mobile")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)(v.pgData.mobile)), Optimus_Client_Components_InfoPanel.ip("Email")(React_Basic_DOM_Generated.div_([Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.pgData.email)(function (v1) {
    return v1.value;
  })), React_Basic_DOM_Generated.a()({
    onClick: React_Basic_DOM_Events.capture_(v.history.push(Optimus_Client_Route.Route.create(v.siloId)(Optimus_Client_Route.SiloRoute.create(Optimus_Client_Route.Users.create(new Optimus_Client_Route.UsersChangeEmail(v.pgData.zapperId)))))),
    children: [Optimus_Client_Antd_Icon.icon()({
      className: "ml-1",
      type: "edit"
    })]
  })])), Optimus_Client_Components_InfoPanel.ip("Verified")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowBoolean))(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.pgData.email)(function (v1) {
    return v1.isVerified;
  }))), Optimus_Client_Components_InfoPanel.ip("Gender")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowGender))(v.pgData.gender)), Optimus_Client_Components_InfoPanel.ip("Birthday")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowDate))(v.pgData.birthday)), Optimus_Client_Components_InfoPanel.ip("Location")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.pgData.location))]))]);
  var deactivateButton = Optimus_Client_Antd_Button.button()({
    block: true,
    children: [React_Basic_DOM.text("Deactivate")]
  });
  var details = Optimus_Client_Components_Card.card([React_Basic_DOM_Generated.div()({
    className: "center mb-3",
    children: [React_Basic_DOM_Generated.img()({
      src: v.pgData.avatar,
      width: "100px"
    })]
  }), Optimus_Client_Components_InfoPanel.infoPanel([Optimus_Client_Components_InfoPanel.ip("First Name")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.pgData.firstName)), Optimus_Client_Components_InfoPanel.ip("Last Name")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))(v.pgData.lastName)), Optimus_Client_Components_InfoPanel.ip("ID")(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)(v.pgData.zapperId))]), Optimus_Client_Antd_Grid.row()({
    className: "mt-3",
    gutter: 8,
    type: "flex",
    justify: "center",
    children: [Optimus_Client_Antd_Grid.col()({
      className: "mb-3",
      xs: 24,
      sm: 8,
      md: 6,
      lg: 4,
      children: [suspendButton]
    }), Optimus_Client_Antd_Grid.col()({
      xs: 24,
      sm: 8,
      md: 6,
      lg: 4,
      children: [deactivateButton]
    })]
  })]);
  var currencyBalances = Optimus_Client_Components_Card.card([Optimus_Client_Components_Section.section(Optimus_Client_Components_InfoPanel.renderText(Optimus_Client_InfoShow.infoShowString)("Currency Balances"))(Optimus_Client_Components_Table.table({
    onRowClick: function onRowClick(v1) {
      return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    },
    loading: false
  })([Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowString))("Currency Name")(function (v1) {
    return v1.name;
  }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowHugeNum))("Redeemable")(function (v1) {
    return v1.redeemable;
  }), Optimus_Client_Components_Table.scspec(Optimus_Client_InfoShow.infoShowMaybe(Optimus_Client_InfoShow.infoShowHugeNum))("Pending")(function (v1) {
    return v1.pendingEarn;
  })])(Data_Maybe.fromMaybe([])(v.pgData.currencyBalances)))]);
  return Optimus_Client_Component_Content.content({
    title: "User Overview",
    children: [Optimus_Client_Antd_Grid.row_([details]), Optimus_Client_Antd_Grid.row_([profile]), Optimus_Client_Antd_Grid.row_([tags]), Optimus_Client_Antd_Grid.row_([currencyBalances])]
  });
};

var component = React_Basic.createComponent("User");
var overviewPage = React_Basic.makeStateless(component)(render);
module.exports = {
  overviewPage: overviewPage
};