// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_MediaType = require("../Data.MediaType/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_File_Blob = require("../Web.File.Blob/index.js");

var type_ = function type_($0) {
  return Web_File_Blob.type_($0);
};

var toBlob = Unsafe_Coerce.unsafeCoerce;

var size = function size($1) {
  return Web_File_Blob.size($1);
};

module.exports = {
  toBlob: toBlob,
  type_: type_,
  size: size,
  name: $foreign.name
};