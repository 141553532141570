// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_Events = require("../React.Basic.Events/index.js");

var inputSearch = function inputSearch(dictUnion) {
  return function (dictUnion1) {
    return React_Basic.element($foreign["_inputSearch"]);
  };
};

var input = function input(dictUnion) {
  return React_Basic.element($foreign["_input"]);
};

module.exports = {
  input: input,
  inputSearch: inputSearch
};