// Generated by purs version 0.12.3
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Eq = require("../Data.Generic.Rep.Eq/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Prelude = require("../Prelude/index.js");

var Record = require("../Record/index.js");

var Record_Builder = require("../Record.Builder/index.js");

var Type_Data_Boolean = require("../Type.Data.Boolean/index.js");

var Type_Row = require("../Type.Row/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var ZRPC_Clients_Mercanti = require("../ZRPC.Clients.Mercanti/index.js");

var Default = function () {
  function Default() {}

  ;
  Default.value = new Default();
  return Default;
}();

var Fixed = function () {
  function Fixed() {}

  ;
  Fixed.value = new Fixed();
  return Fixed;
}();

var SettingLoad$prime = function SettingLoad$prime(x) {
  return x;
};

var SettingFormState$prime = function SettingFormState$prime(x) {
  return x;
};

var RaToRb = function RaToRb(recordAToRecordB) {
  this.recordAToRecordB = recordAToRecordB;
};

var RaToRbRL = function RaToRbRL(recordAToRecordBRL) {
  this.recordAToRecordBRL = recordAToRecordBRL;
};

var recordAToRecordBRL = function recordAToRecordBRL(dict) {
  return dict.recordAToRecordBRL;
};

var recordAToRecordB = function recordAToRecordB(dict) {
  return dict.recordAToRecordB;
};

var raToRbRecord = function raToRbRecord(dictRowToList) {
  return function (dictRaToRbRL) {
    return new RaToRb(recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value));
  };
};

var raToRbRLNil = new RaToRbRL(function (v) {
  return function (v1) {
    return {};
  };
});

var raToRbRLConsSLtoSFS = function raToRbRLConsSLtoSFS(dictRaToRbRL) {
  return function (dictIsSymbol) {
    return function (dictCons) {
      return function (dictLacks) {
        return function (dictCons1) {
          return new RaToRbRL(function (v) {
            return function (p) {
              var rest = recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value)(p);

              var convert = function convert(v1) {
                return {
                  selected: function () {
                    if (v1.fixed instanceof Data_Maybe.Just) {
                      return Fixed.value;
                    }

                    ;

                    if (v1.fixed instanceof Data_Maybe.Nothing) {
                      return Default.value;
                    }

                    ;
                    throw new Error("Failed pattern match at Optimus.Client.Components.Settings.Utilities (line 86, column 17 - line 88, column 37): " + [v1.fixed.constructor.name]);
                  }(),
                  "default": v1["default"],
                  fixed: v1.fixed
                };
              };

              var sfs = convert(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(p));
              return Record_Builder.build(Record_Builder.insert(dictCons1)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(sfs))(rest);
            };
          });
        };
      };
    };
  };
};

var settingSLToSFS = function settingSLToSFS(sl) {
  return recordAToRecordB(raToRbRecord()(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLConsSLtoSFS(raToRbRLNil)(new Data_Symbol.IsSymbol(function () {
    return "voidAuthenticationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "transactionCurrency";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "transactionAmountComputationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "timeZone";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "senderEmail";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "scalingMethod";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrValidityDuration";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrScanCountLimit";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrScanCooldown";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "posSetUp";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "phoneNumberPrefix";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "linkTagVerificationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isRedeemSmsReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isRedeemEmailReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isQuickRedeemEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isProfileOnRedeemRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isProfileOnEarnRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isPayInCashEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isLinkCardOnRegistrationRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isIdMethodByUnverifiedTagAllowed";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isEarnSmsReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isEarnEmailReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isClearedVoidable";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "hasCashbackOnRedemptionOverflow";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "defaultLocation";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "countryAlpha2";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "clearingPeriod";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "checkBalanceAuthenticationMode";
  }))()()()))(sl);
};

var raToRbRLConsSFSToSU = function raToRbRLConsSFSToSU(dictRaToRbRL) {
  return function (dictIsSymbol) {
    return function (dictCons) {
      return function (dictLacks) {
        return function (dictCons1) {
          return new RaToRbRL(function (v) {
            return function (r) {
              var rest = recordAToRecordBRL(dictRaToRbRL)(Type_Row.RLProxy.value)(r);

              var convert = function convert(v1) {
                if (v1.selected instanceof Default) {
                  return new Data_Maybe.Just(ZRPC_Clients_Mercanti.SetModeDefault.value);
                }

                ;

                if (v1.selected instanceof Fixed) {
                  if (v1.fixed instanceof Data_Maybe.Just) {
                    return new Data_Maybe.Just(new ZRPC_Clients_Mercanti.SetModeFixed({
                      value: v1.fixed.value0
                    }));
                  }

                  ;

                  if (v1.fixed instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                  }

                  ;
                  throw new Error("Failed pattern match at Optimus.Client.Components.Settings.Utilities (line 111, column 20 - line 113, column 31): " + [v1.fixed.constructor.name]);
                }

                ;
                throw new Error("Failed pattern match at Optimus.Client.Components.Settings.Utilities (line 109, column 43 - line 113, column 31): " + [v1.selected.constructor.name]);
              };

              var um = convert(Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r));
              return Record_Builder.build(Record_Builder.insert(dictCons1)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(um))(rest);
            };
          });
        };
      };
    };
  };
};

var settingSFSToSU = function settingSFSToSU(sfs) {
  return recordAToRecordB(raToRbRecord()(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLConsSFSToSU(raToRbRLNil)(new Data_Symbol.IsSymbol(function () {
    return "voidAuthenticationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "transactionCurrency";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "transactionAmountComputationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "timeZone";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "senderEmail";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "scalingMethod";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrValidityDuration";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrScanCountLimit";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "qrScanCooldown";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "posSetUp";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "phoneNumberPrefix";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "linkTagVerificationMode";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isRedeemSmsReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isRedeemEmailReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isQuickRedeemEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isProfileOnRedeemRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isProfileOnEarnRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isPayInCashEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isLinkCardOnRegistrationRequired";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isIdMethodByUnverifiedTagAllowed";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isEarnSmsReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isEarnEmailReceiptEnabled";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "isClearedVoidable";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "hasCashbackOnRedemptionOverflow";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "defaultLocation";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "countryAlpha2";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "clearingPeriod";
  }))()()())(new Data_Symbol.IsSymbol(function () {
    return "checkBalanceAuthenticationMode";
  }))()()()))(sfs);
};

var genericSettingSelection = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Default) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Fixed) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Components.Settings.Utilities (line 37, column 8 - line 37, column 72): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Default.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return Fixed.value;
  }

  ;
  throw new Error("Failed pattern match at Optimus.Client.Components.Settings.Utilities (line 37, column 8 - line 37, column 72): " + [x.constructor.name]);
});
var settingSelectionEq = new Data_Eq.Eq(function (x) {
  return Data_Generic_Rep_Eq.genericEq(genericSettingSelection)(Data_Generic_Rep_Eq.genericEqSum(Data_Generic_Rep_Eq.genericEqConstructor(Data_Generic_Rep_Eq.genericEqNoArguments))(Data_Generic_Rep_Eq.genericEqConstructor(Data_Generic_Rep_Eq.genericEqNoArguments)))(x);
});
var settingSelectionShow = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericSettingSelection)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Default";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Fixed";
  }))))(x);
});
var genericSettingLoad$prime = new Data_Generic_Rep.Generic(function (x) {
  return x;
}, function (x) {
  return x;
});

var settingslShow = function settingslShow(dictShow) {
  return new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericSettingLoad$prime)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "default";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "fixed";
    }))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(dictShow)))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "provider";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(Data_Show.showRecordFieldsNil)(dictShow))(ZRPC_Clients_Mercanti.showProvider))))))(new Data_Symbol.IsSymbol(function () {
      return "SettingLoad'";
    })))(x);
  });
};

var genericSettingFormState$prime = new Data_Generic_Rep.Generic(function (x) {
  return x;
}, function (x) {
  return x;
});

var settingFSShow = function settingFSShow(dictShow) {
  return new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericSettingFormState$prime)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "default";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "fixed";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "selected";
    }))(Data_Show.showRecordFieldsNil)(settingSelectionShow))(Data_Maybe.showMaybe(dictShow)))(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "provider";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(Data_Show.showRecordFieldsNil)(dictShow))(ZRPC_Clients_Mercanti.showProvider))))))(new Data_Symbol.IsSymbol(function () {
      return "SettingFormState'";
    })))(x);
  });
};

module.exports = {
  RaToRbRL: RaToRbRL,
  RaToRb: RaToRb,
  recordAToRecordB: recordAToRecordB,
  recordAToRecordBRL: recordAToRecordBRL,
  settingSLToSFS: settingSLToSFS,
  settingSFSToSU: settingSFSToSU,
  "SettingFormState'": SettingFormState$prime,
  Default: Default,
  Fixed: Fixed,
  genericSettingSelection: genericSettingSelection,
  "genericSettingFormState'": genericSettingFormState$prime,
  settingSelectionShow: settingSelectionShow,
  settingFSShow: settingFSShow,
  settingSelectionEq: settingSelectionEq,
  raToRbRecord: raToRbRecord,
  raToRbRLNil: raToRbRLNil,
  raToRbRLConsSFSToSU: raToRbRLConsSFSToSU
};