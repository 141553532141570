// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM_ChildNode = require("../Web.DOM.ChildNode/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NonDocumentTypeChildNode = require("../Web.DOM.NonDocumentTypeChildNode/index.js");

var Web_DOM_ParentNode = require("../Web.DOM.ParentNode/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toParentNode = Unsafe_Coerce.unsafeCoerce;
var toNonDocumentTypeChildNode = Unsafe_Coerce.unsafeCoerce;
var toNode = Unsafe_Coerce.unsafeCoerce;
var toEventTarget = Unsafe_Coerce.unsafeCoerce;
var toChildNode = Unsafe_Coerce.unsafeCoerce;

var prefix = function prefix($0) {
  return Data_Nullable.toMaybe($foreign["_prefix"]($0));
};

var namespaceURI = function namespaceURI($1) {
  return Data_Nullable.toMaybe($foreign["_namespaceURI"]($1));
};

var getElementsByTagNameNS = function getElementsByTagNameNS($2) {
  return $foreign["_getElementsByTagNameNS"](Data_Nullable.toNullable($2));
};

var getAttribute = function getAttribute(attr) {
  return function ($3) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getAttribute"](attr)($3));
  };
};

var fromParentNode = Web_Internal_FFI.unsafeReadProtoTagged("Element");
var fromNonDocumentTypeChildNode = Web_Internal_FFI.unsafeReadProtoTagged("Element");
var fromNode = Web_Internal_FFI.unsafeReadProtoTagged("Element");
var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Element");
var fromChildNode = Web_Internal_FFI.unsafeReadProtoTagged("Element");
module.exports = {
  fromNode: fromNode,
  fromChildNode: fromChildNode,
  fromNonDocumentTypeChildNode: fromNonDocumentTypeChildNode,
  fromParentNode: fromParentNode,
  fromEventTarget: fromEventTarget,
  toNode: toNode,
  toChildNode: toChildNode,
  toNonDocumentTypeChildNode: toNonDocumentTypeChildNode,
  toParentNode: toParentNode,
  toEventTarget: toEventTarget,
  namespaceURI: namespaceURI,
  prefix: prefix,
  getElementsByTagNameNS: getElementsByTagNameNS,
  getAttribute: getAttribute,
  localName: $foreign.localName,
  tagName: $foreign.tagName,
  id: $foreign.id,
  setId: $foreign.setId,
  className: $foreign.className,
  setClassName: $foreign.setClassName,
  getElementsByTagName: $foreign.getElementsByTagName,
  getElementsByClassName: $foreign.getElementsByClassName,
  setAttribute: $foreign.setAttribute,
  hasAttribute: $foreign.hasAttribute,
  removeAttribute: $foreign.removeAttribute,
  scrollTop: $foreign.scrollTop,
  setScrollTop: $foreign.setScrollTop,
  scrollLeft: $foreign.scrollLeft,
  setScrollLeft: $foreign.setScrollLeft,
  scrollWidth: $foreign.scrollWidth,
  scrollHeight: $foreign.scrollHeight,
  clientTop: $foreign.clientTop,
  clientLeft: $foreign.clientLeft,
  clientWidth: $foreign.clientWidth,
  clientHeight: $foreign.clientHeight
};