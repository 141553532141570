// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Web_DOM_Document = require("../Web.DOM.Document/index.js");

var Web_DOM_Element = require("../Web.DOM.Element/index.js");

var Web_DOM_Internal_Types = require("../Web.DOM.Internal.Types/index.js");

var Web_DOM_NodeType = require("../Web.DOM.NodeType/index.js");

var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");

var Web_Internal_FFI = require("../Web.Internal.FFI/index.js");

var toEventTarget = Unsafe_Coerce.unsafeCoerce;

var previousSibling = function previousSibling($1) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_previousSibling"]($1));
};

var parentNode = function parentNode($2) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_parentNode"]($2));
};

var parentElement = function parentElement($3) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_parentElement"]($3));
};

var ownerDocument = function ownerDocument($4) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_ownerDocument"]($4));
};

var nodeType = function nodeType(dictPartial) {
  return function ($5) {
    return Data_Maybe.fromJust(dictPartial)(Data_Enum.toEnum(Web_DOM_NodeType.boundedEnumNodeType)($foreign.nodeTypeIndex($5)));
  };
};

var nextSibling = function nextSibling($6) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_nextSibling"]($6));
};

var lookupPrefix = function lookupPrefix(p) {
  return function ($7) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_lookupPrefix"](p)($7));
  };
};

var lookupNamespaceURI = function lookupNamespaceURI(ns) {
  return function ($8) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_lookupNamespaceURI"](ns)($8));
  };
};

var lastChild = function lastChild($9) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_lastChild"]($9));
};

var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Node");

var firstChild = function firstChild($10) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_firstChild"]($10));
};

module.exports = {
  fromEventTarget: fromEventTarget,
  toEventTarget: toEventTarget,
  nodeType: nodeType,
  ownerDocument: ownerDocument,
  parentNode: parentNode,
  parentElement: parentElement,
  firstChild: firstChild,
  lastChild: lastChild,
  previousSibling: previousSibling,
  nextSibling: nextSibling,
  lookupPrefix: lookupPrefix,
  lookupNamespaceURI: lookupNamespaceURI,
  nodeTypeIndex: $foreign.nodeTypeIndex,
  nodeName: $foreign.nodeName,
  baseURI: $foreign.baseURI,
  hasChildNodes: $foreign.hasChildNodes,
  childNodes: $foreign.childNodes,
  nodeValue: $foreign.nodeValue,
  setNodeValue: $foreign.setNodeValue,
  textContent: $foreign.textContent,
  setTextContent: $foreign.setTextContent,
  normalize: $foreign.normalize,
  clone: $foreign.clone,
  deepClone: $foreign.deepClone,
  isEqualNode: $foreign.isEqualNode,
  compareDocumentPositionBits: $foreign.compareDocumentPositionBits,
  contains: $foreign.contains,
  isDefaultNamespace: $foreign.isDefaultNamespace,
  insertBefore: $foreign.insertBefore,
  appendChild: $foreign.appendChild,
  replaceChild: $foreign.replaceChild,
  removeChild: $foreign.removeChild
};