// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Comonad = require("../Control.Comonad/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Data_BooleanAlgebra = require("../Data.BooleanAlgebra/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");

var Data_Semigroup_Traversable = require("../Data.Semigroup.Traversable/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Prelude = require("../Prelude/index.js");

var Identity = function Identity(x) {
  return x;
};

var showIdentity = function showIdentity(dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Identity " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semiringIdentity = function semiringIdentity(dictSemiring) {
  return dictSemiring;
};

var semigroupIdenity = function semigroupIdenity(dictSemigroup) {
  return dictSemigroup;
};

var ringIdentity = function ringIdentity(dictRing) {
  return dictRing;
};

var ordIdentity = function ordIdentity(dictOrd) {
  return dictOrd;
};

var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
  return n;
}, Identity);

var monoidIdentity = function monoidIdentity(dictMonoid) {
  return dictMonoid;
};

var lazyIdentity = function lazyIdentity(dictLazy) {
  return dictLazy;
};

var heytingAlgebraIdentity = function heytingAlgebraIdentity(dictHeytingAlgebra) {
  return dictHeytingAlgebra;
};

var functorIdentity = new Data_Functor.Functor(function (f) {
  return function (m) {
    return f(m);
  };
});
var functorWithIndexIdentity = new Data_FunctorWithIndex.FunctorWithIndex(function () {
  return functorIdentity;
}, function (f) {
  return function (v) {
    return f(Data_Unit.unit)(v);
  };
});
var invariantIdentity = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorIdentity));
var foldableIdentity = new Data_Foldable.Foldable(function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(v)(z);
    };
  };
});
var foldableWithIndexIdentity = new Data_FoldableWithIndex.FoldableWithIndex(function () {
  return foldableIdentity;
}, function (dictMonoid) {
  return function (f) {
    return function (v) {
      return f(Data_Unit.unit)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(Data_Unit.unit)(z)(v);
    };
  };
}, function (f) {
  return function (z) {
    return function (v) {
      return f(Data_Unit.unit)(v)(z);
    };
  };
});
var traversableIdentity = new Data_Traversable.Traversable(function () {
  return foldableIdentity;
}, function () {
  return functorIdentity;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Identity)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Identity)(f(v));
    };
  };
});
var traversableWithIndexIdentity = new Data_TraversableWithIndex.TraversableWithIndex(function () {
  return foldableWithIndexIdentity;
}, function () {
  return functorWithIndexIdentity;
}, function () {
  return traversableIdentity;
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Identity)(f(Data_Unit.unit)(v));
    };
  };
});
var foldable1Identity = new Data_Semigroup_Foldable.Foldable1(function () {
  return foldableIdentity;
}, function (dictSemigroup) {
  return function (v) {
    return v;
  };
}, function (dictSemigroup) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
});
var traversable1Identity = new Data_Semigroup_Traversable.Traversable1(function () {
  return foldable1Identity;
}, function () {
  return traversableIdentity;
}, function (dictApply) {
  return function (v) {
    return Data_Functor.map(dictApply.Functor0())(Identity)(v);
  };
}, function (dictApply) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApply.Functor0())(Identity)(f(v));
    };
  };
});
var extendIdentity = new Control_Extend.Extend(function () {
  return functorIdentity;
}, function (f) {
  return function (m) {
    return f(m);
  };
});

var euclideanRingIdentity = function euclideanRingIdentity(dictEuclideanRing) {
  return dictEuclideanRing;
};

var eqIdentity = function eqIdentity(dictEq) {
  return dictEq;
};

var eq1Identity = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqIdentity(dictEq));
});
var ord1Identity = new Data_Ord.Ord1(function () {
  return eq1Identity;
}, function (dictOrd) {
  return Data_Ord.compare(ordIdentity(dictOrd));
});
var comonadIdentity = new Control_Comonad.Comonad(function () {
  return extendIdentity;
}, function (v) {
  return v;
});

var commutativeRingIdentity = function commutativeRingIdentity(dictCommutativeRing) {
  return dictCommutativeRing;
};

var boundedIdentity = function boundedIdentity(dictBounded) {
  return dictBounded;
};

var booleanAlgebraIdentity = function booleanAlgebraIdentity(dictBooleanAlgebra) {
  return dictBooleanAlgebra;
};

var applyIdentity = new Control_Apply.Apply(function () {
  return functorIdentity;
}, function (v) {
  return function (v1) {
    return v(v1);
  };
});
var bindIdentity = new Control_Bind.Bind(function () {
  return applyIdentity;
}, function (v) {
  return function (f) {
    return f(v);
  };
});
var applicativeIdentity = new Control_Applicative.Applicative(function () {
  return applyIdentity;
}, Identity);
var monadIdentity = new Control_Monad.Monad(function () {
  return applicativeIdentity;
}, function () {
  return bindIdentity;
});
var altIdentity = new Control_Alt.Alt(function () {
  return functorIdentity;
}, function (x) {
  return function (v) {
    return x;
  };
});
module.exports = {
  Identity: Identity,
  newtypeIdentity: newtypeIdentity,
  eqIdentity: eqIdentity,
  ordIdentity: ordIdentity,
  boundedIdentity: boundedIdentity,
  heytingAlgebraIdentity: heytingAlgebraIdentity,
  booleanAlgebraIdentity: booleanAlgebraIdentity,
  semigroupIdenity: semigroupIdenity,
  monoidIdentity: monoidIdentity,
  semiringIdentity: semiringIdentity,
  euclideanRingIdentity: euclideanRingIdentity,
  ringIdentity: ringIdentity,
  commutativeRingIdentity: commutativeRingIdentity,
  lazyIdentity: lazyIdentity,
  showIdentity: showIdentity,
  eq1Identity: eq1Identity,
  ord1Identity: ord1Identity,
  functorIdentity: functorIdentity,
  functorWithIndexIdentity: functorWithIndexIdentity,
  invariantIdentity: invariantIdentity,
  altIdentity: altIdentity,
  applyIdentity: applyIdentity,
  applicativeIdentity: applicativeIdentity,
  bindIdentity: bindIdentity,
  monadIdentity: monadIdentity,
  extendIdentity: extendIdentity,
  comonadIdentity: comonadIdentity,
  foldableIdentity: foldableIdentity,
  foldable1Identity: foldable1Identity,
  foldableWithIndexIdentity: foldableWithIndexIdentity,
  traversableIdentity: traversableIdentity,
  traversable1Identity: traversable1Identity,
  traversableWithIndexIdentity: traversableWithIndexIdentity
};