// Generated by purs version 0.12.3
"use strict";

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Eq = require("../Data.Generic.Rep.Eq/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_HugeNum = require("../Data.HugeNum/index.js");

var Data_Long = require("../Data.Long/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Prelude = require("../Prelude/index.js");

var ZRPC_Client = require("../ZRPC.Client/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_Types_LocalDateTime = require("../ZRPC.Types.LocalDateTime/index.js");

var ZRPC_Types_Millis = require("../ZRPC.Types.Millis/index.js");

var ZRPC_Types_ZJson = require("../ZRPC.Types.ZJson/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

module.exports = {};