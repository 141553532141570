// Generated by purs version 0.12.3
"use strict";

var Type_Equality = require("../Type.Equality/index.js");

var Type_Row = require("../Type.Row/index.js");

var Homogeneous = {};
var HomogeneousRowList = {};
var homogeneousRowListNil = HomogeneousRowList;

var homogeneousRowListCons = function homogeneousRowListCons(dictHomogeneousRowList) {
  return function (dictTypeEquals) {
    return HomogeneousRowList;
  };
};

var homogeneous = function homogeneous(dictRowToList) {
  return function (dictHomogeneousRowList) {
    return Homogeneous;
  };
};

module.exports = {
  Homogeneous: Homogeneous,
  HomogeneousRowList: HomogeneousRowList,
  homogeneous: homogeneous,
  homogeneousRowListCons: homogeneousRowListCons,
  homogeneousRowListNil: homogeneousRowListNil
};