// Generated by purs version 0.12.3
"use strict";

var Optimus_Client_Antd_Alert = require("../Optimus.Client.Antd.Alert/index.js");

var Optimus_Client_Antd_Breadcrumb = require("../Optimus.Client.Antd.Breadcrumb/index.js");

var Optimus_Client_Antd_Button = require("../Optimus.Client.Antd.Button/index.js");

var Optimus_Client_Antd_Card = require("../Optimus.Client.Antd.Card/index.js");

var Optimus_Client_Antd_Dropdown = require("../Optimus.Client.Antd.Dropdown/index.js");

var Optimus_Client_Antd_Grid = require("../Optimus.Client.Antd.Grid/index.js");

var Optimus_Client_Antd_Icon = require("../Optimus.Client.Antd.Icon/index.js");

var Optimus_Client_Antd_Input = require("../Optimus.Client.Antd.Input/index.js");

var Optimus_Client_Antd_InputNumber = require("../Optimus.Client.Antd.InputNumber/index.js");

var Optimus_Client_Antd_Layout = require("../Optimus.Client.Antd.Layout/index.js");

var Optimus_Client_Antd_Menu = require("../Optimus.Client.Antd.Menu/index.js");

var Optimus_Client_Antd_Message = require("../Optimus.Client.Antd.Message/index.js");

var Optimus_Client_Antd_Notification = require("../Optimus.Client.Antd.Notification/index.js");

var Optimus_Client_Antd_Radio = require("../Optimus.Client.Antd.Radio/index.js");

var Optimus_Client_Antd_Select = require("../Optimus.Client.Antd.Select/index.js");

var Optimus_Client_Antd_Skeleton = require("../Optimus.Client.Antd.Skeleton/index.js");

var Optimus_Client_Antd_Table = require("../Optimus.Client.Antd.Table/index.js");

var Optimus_Client_Antd_Typography = require("../Optimus.Client.Antd.Typography/index.js");

module.exports = {};