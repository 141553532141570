// Generated by purs version 0.12.3
"use strict";

var Data_Date = require("../Data.Date/index.js");

var Data_DateTime = require("../Data.DateTime/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Optimus_Shared_Bridge = require("../Optimus.Shared.Bridge/index.js");

var Prelude = require("../Prelude/index.js");

var ZRPC = require("../ZRPC/index.js");

var ZRPC_Clients_Zapper = require("../ZRPC.Clients.Zapper/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var ExpiredVerification = function () {
  function ExpiredVerification() {}

  ;
  ExpiredVerification.value = new ExpiredVerification();
  return ExpiredVerification;
}();

var ExceededResendCount = function () {
  function ExceededResendCount() {}

  ;
  ExceededResendCount.value = new ExceededResendCount();
  return ExceededResendCount;
}();

var EmailVerficationNotFound = function () {
  function EmailVerficationNotFound() {}

  ;
  EmailVerficationNotFound.value = new EmailVerficationNotFound();
  return EmailVerficationNotFound;
}();

var ResendEmailVerificationFailed = function () {
  function ResendEmailVerificationFailed() {}

  ;
  ResendEmailVerificationFailed.value = new ResendEmailVerificationFailed();
  return ResendEmailVerificationFailed;
}();

var genericResendEmailVerificationError = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof ExpiredVerification) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof ExceededResendCount) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof EmailVerficationNotFound) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof ResendEmailVerificationFailed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Users (line 84, column 8 - line 84, column 96): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return ExpiredVerification.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return ExceededResendCount.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return EmailVerficationNotFound.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return ResendEmailVerificationFailed.value;
  }

  ;
  throw new Error("Failed pattern match at Optimus.Shared.Users (line 84, column 8 - line 84, column 96): " + [x.constructor.name]);
});
var showResendEmailVerificationError = new Data_Show.Show(function (x) {
  return Data_Generic_Rep_Show.genericShow(genericResendEmailVerificationError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ExpiredVerification";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ExceededResendCount";
  })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "EmailVerficationNotFound";
  })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendEmailVerificationFailed";
  }))))))(x);
});
var consPrefixResendEmailVerificationError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "";
});
var zrpcReadResendEmailVerificationError = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResendEmailVerificationError)(genericResendEmailVerificationError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExpiredVerification";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExceededResendCount";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "EmailVerficationNotFound";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ResendEmailVerificationFailed";
})))))));
var zrpcWriteResendEmailVerificationError = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixResendEmailVerificationError)(genericResendEmailVerificationError)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExpiredVerification";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExceededResendCount";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "EmailVerficationNotFound";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ResendEmailVerificationFailed";
})))))));
module.exports = {
  ExpiredVerification: ExpiredVerification,
  ExceededResendCount: ExceededResendCount,
  EmailVerficationNotFound: EmailVerficationNotFound,
  ResendEmailVerificationFailed: ResendEmailVerificationFailed,
  genericResendEmailVerificationError: genericResendEmailVerificationError,
  consPrefixResendEmailVerificationError: consPrefixResendEmailVerificationError,
  zrpcWriteResendEmailVerificationError: zrpcWriteResendEmailVerificationError,
  zrpcReadResendEmailVerificationError: zrpcReadResendEmailVerificationError,
  showResendEmailVerificationError: showResendEmailVerificationError
};