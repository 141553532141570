// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");

var Control_Parallel = require("../Control.Parallel/index.js");

var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Time_Duration = require("../Data.Time.Duration/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var Effect_Class = require("../Effect.Class/index.js");

var Effect_Exception = require("../Effect.Exception/index.js");

var Effect_Unsafe = require("../Effect.Unsafe/index.js");

var Partial_Unsafe = require("../Partial.Unsafe/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Fiber = function Fiber(x) {
  return x;
};

var FFIUtil = function FFIUtil(x) {
  return x;
};

var Canceler = function Canceler(x) {
  return x;
};

var suspendAff = $foreign["_fork"](false);
var newtypeCanceler = new Data_Newtype.Newtype(function (n) {
  return n;
}, Canceler);
var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
var functorAff = new Data_Functor.Functor($foreign["_map"]);
var forkAff = $foreign["_fork"](true);

var ffiUtil = function () {
  var unsafeFromRight = function unsafeFromRight(v) {
    if (v instanceof Data_Either.Right) {
      return v.value0;
    }

    ;

    if (v instanceof Data_Either.Left) {
      return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
    }

    ;
    throw new Error("Failed pattern match at Effect.Aff (line 400, column 21 - line 402, column 31): " + [v.constructor.name]);
  };

  var unsafeFromLeft = function unsafeFromLeft(v) {
    if (v instanceof Data_Either.Left) {
      return v.value0;
    }

    ;

    if (v instanceof Data_Either.Right) {
      return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
    }

    ;
    throw new Error("Failed pattern match at Effect.Aff (line 395, column 20 - line 399, column 3): " + [v.constructor.name]);
  };

  var isLeft = function isLeft(v) {
    if (v instanceof Data_Either.Left) {
      return true;
    }

    ;

    if (v instanceof Data_Either.Right) {
      return false;
    }

    ;
    throw new Error("Failed pattern match at Effect.Aff (line 390, column 12 - line 392, column 20): " + [v.constructor.name]);
  };

  return {
    isLeft: isLeft,
    fromLeft: unsafeFromLeft,
    fromRight: unsafeFromRight,
    left: Data_Either.Left.create,
    right: Data_Either.Right.create
  };
}();

var makeFiber = function makeFiber(aff) {
  return $foreign["_makeFiber"](ffiUtil, aff);
};

var launchAff = function launchAff(aff) {
  return function __do() {
    var v = makeFiber(aff)();
    v.run();
    return v;
  };
};

var launchAff_ = function launchAff_($49) {
  return Data_Functor["void"](Effect.functorEffect)(launchAff($49));
};

var launchSuspendedAff = makeFiber;

var delay = function delay(v) {
  return $foreign["_delay"](Data_Either.Right.create, v);
};

var bracket = function bracket(acquire) {
  return function (completed) {
    return $foreign.generalBracket(acquire)({
      killed: Data_Function["const"](completed),
      failed: Data_Function["const"](completed),
      completed: Data_Function["const"](completed)
    });
  };
};

var applyParAff = new Control_Apply.Apply(function () {
  return functorParAff;
}, $foreign["_parAffApply"]);

var semigroupParAff = function semigroupParAff(dictSemigroup) {
  return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyParAff)(Data_Semigroup.append(dictSemigroup)));
};

var monadAff = new Control_Monad.Monad(function () {
  return applicativeAff;
}, function () {
  return bindAff;
});
var bindAff = new Control_Bind.Bind(function () {
  return applyAff;
}, $foreign["_bind"]);
var applyAff = new Control_Apply.Apply(function () {
  return functorAff;
}, Control_Monad.ap(monadAff));
var applicativeAff = new Control_Applicative.Applicative(function () {
  return applyAff;
}, $foreign["_pure"]);

var cancelWith = function cancelWith(aff) {
  return function (v) {
    return $foreign.generalBracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))({
      killed: function killed(e) {
        return function (v1) {
          return v(e);
        };
      },
      failed: Data_Function["const"](Control_Applicative.pure(applicativeAff)),
      completed: Data_Function["const"](Control_Applicative.pure(applicativeAff))
    })(Data_Function["const"](aff));
  };
};

var $$finally = function $$finally(fin) {
  return function (a) {
    return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
  };
};

var invincible = function invincible(a) {
  return bracket(a)(Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit)))(Control_Applicative.pure(applicativeAff));
};

var lazyAff = new Control_Lazy.Lazy(function (f) {
  return Control_Bind.bind(bindAff)(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(f);
});

var semigroupAff = function semigroupAff(dictSemigroup) {
  return new Data_Semigroup.Semigroup(Control_Apply.lift2(applyAff)(Data_Semigroup.append(dictSemigroup)));
};

var monadEffectAff = new Effect_Class.MonadEffect(function () {
  return monadAff;
}, $foreign["_liftEffect"]);

var effectCanceler = function effectCanceler($50) {
  return Canceler(Data_Function["const"](Effect_Class.liftEffect(monadEffectAff)($50)));
};

var joinFiber = function joinFiber(v) {
  return $foreign.makeAff(function (k) {
    return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
  });
};

var functorFiber = new Data_Functor.Functor(function (f) {
  return function (t) {
    return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
  };
});
var applyFiber = new Control_Apply.Apply(function () {
  return functorFiber;
}, function (t1) {
  return function (t2) {
    return Effect_Unsafe.unsafePerformEffect(makeFiber(Control_Apply.apply(applyAff)(joinFiber(t1))(joinFiber(t2))));
  };
});
var applicativeFiber = new Control_Applicative.Applicative(function () {
  return applyFiber;
}, function (a) {
  return Effect_Unsafe.unsafePerformEffect(makeFiber(Control_Applicative.pure(applicativeAff)(a)));
});

var killFiber = function killFiber(e) {
  return function (v) {
    return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
      if (v1) {
        return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
      }

      ;
      return $foreign.makeAff(function (k) {
        return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
      });
    });
  };
};

var fiberCanceler = function fiberCanceler($51) {
  return Canceler(Data_Function.flip(killFiber)($51));
};

var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
  return monadAff;
}, $foreign["_throwError"]);
var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
  return monadThrowAff;
}, $foreign["_catchError"]);
var attempt = Control_Monad_Error_Class["try"](monadErrorAff);

var runAff = function runAff(k) {
  return function (aff) {
    return launchAff(Control_Bind.bindFlipped(bindAff)(function ($52) {
      return Effect_Class.liftEffect(monadEffectAff)(k($52));
    })(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
  };
};

var runAff_ = function runAff_(k) {
  return function (aff) {
    return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
  };
};

var runSuspendedAff = function runSuspendedAff(k) {
  return function (aff) {
    return launchSuspendedAff(Control_Bind.bindFlipped(bindAff)(function ($53) {
      return Effect_Class.liftEffect(monadEffectAff)(k($53));
    })(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
  };
};

var parallelAff = new Control_Parallel_Class.Parallel(function () {
  return applicativeParAff;
}, function () {
  return monadAff;
}, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
var applicativeParAff = new Control_Applicative.Applicative(function () {
  return applyParAff;
}, function ($54) {
  return Control_Parallel_Class.parallel(parallelAff)(Control_Applicative.pure(applicativeAff)($54));
});

var monoidParAff = function monoidParAff(dictMonoid) {
  return new Data_Monoid.Monoid(function () {
    return semigroupParAff(dictMonoid.Semigroup0());
  }, Control_Applicative.pure(applicativeParAff)(Data_Monoid.mempty(dictMonoid)));
};

var semigroupCanceler = new Data_Semigroup.Semigroup(function (v) {
  return function (v1) {
    return function (err) {
      return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([v(err), v1(err)]);
    };
  };
});

var supervise = function supervise(aff) {
  var killError = Effect_Exception.error("[Aff] Child fiber outlived parent");

  var killAll = function killAll(err) {
    return function (sup) {
      return $foreign.makeAff(function (k) {
        return $foreign["_killAll"](err, sup.supervisor, k(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Unit.unit)));
      });
    };
  };

  var acquire = function __do() {
    var v = $foreign["_makeSupervisedFiber"](ffiUtil, aff)();
    v.fiber.run();
    return v;
  };

  return $foreign.generalBracket(Effect_Class.liftEffect(monadEffectAff)(acquire))({
    killed: function killed(err) {
      return function (sup) {
        return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([killFiber(err)(sup.fiber), killAll(err)(sup)]);
      };
    },
    failed: Data_Function["const"](killAll(killError)),
    completed: Data_Function["const"](killAll(killError))
  })(function ($55) {
    return joinFiber(function (v) {
      return v.fiber;
    }($55));
  });
};

var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
  return monadAff;
}, function (k) {
  var go = function go(a) {
    return Control_Bind.bind(bindAff)(k(a))(function (v) {
      if (v instanceof Control_Monad_Rec_Class.Done) {
        return Control_Applicative.pure(applicativeAff)(v.value0);
      }

      ;

      if (v instanceof Control_Monad_Rec_Class.Loop) {
        return go(v.value0);
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 100, column 7 - line 102, column 22): " + [v.constructor.name]);
    });
  };

  return go;
});

var monoidAff = function monoidAff(dictMonoid) {
  return new Data_Monoid.Monoid(function () {
    return semigroupAff(dictMonoid.Semigroup0());
  }, Control_Applicative.pure(applicativeAff)(Data_Monoid.mempty(dictMonoid)));
};

var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
var monoidCanceler = new Data_Monoid.Monoid(function () {
  return semigroupCanceler;
}, nonCanceler);
var never = $foreign.makeAff(function (v) {
  return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(monoidCanceler));
});

var apathize = function apathize($56) {
  return Data_Functor.map(functorAff)(Data_Function["const"](Data_Unit.unit))(attempt($56));
};

var altParAff = new Control_Alt.Alt(function () {
  return functorParAff;
}, $foreign["_parAffAlt"]);
var altAff = new Control_Alt.Alt(function () {
  return functorAff;
}, function (a1) {
  return function (a2) {
    return Control_Monad_Error_Class.catchError(monadErrorAff)(a1)(Data_Function["const"](a2));
  };
});
var plusAff = new Control_Plus.Plus(function () {
  return altAff;
}, Control_Monad_Error_Class.throwError(monadThrowAff)(Effect_Exception.error("Always fails")));
var plusParAff = new Control_Plus.Plus(function () {
  return altParAff;
}, Control_Parallel_Class.parallel(parallelAff)(Control_Plus.empty(plusAff)));
var alternativeParAff = new Control_Alternative.Alternative(function () {
  return applicativeParAff;
}, function () {
  return plusParAff;
});
module.exports = {
  Canceler: Canceler,
  launchAff: launchAff,
  launchAff_: launchAff_,
  launchSuspendedAff: launchSuspendedAff,
  runAff: runAff,
  runAff_: runAff_,
  runSuspendedAff: runSuspendedAff,
  forkAff: forkAff,
  suspendAff: suspendAff,
  supervise: supervise,
  attempt: attempt,
  apathize: apathize,
  delay: delay,
  never: never,
  "finally": $$finally,
  invincible: invincible,
  killFiber: killFiber,
  joinFiber: joinFiber,
  cancelWith: cancelWith,
  bracket: bracket,
  nonCanceler: nonCanceler,
  effectCanceler: effectCanceler,
  fiberCanceler: fiberCanceler,
  functorAff: functorAff,
  applyAff: applyAff,
  applicativeAff: applicativeAff,
  bindAff: bindAff,
  monadAff: monadAff,
  semigroupAff: semigroupAff,
  monoidAff: monoidAff,
  altAff: altAff,
  plusAff: plusAff,
  monadRecAff: monadRecAff,
  monadThrowAff: monadThrowAff,
  monadErrorAff: monadErrorAff,
  monadEffectAff: monadEffectAff,
  lazyAff: lazyAff,
  functorParAff: functorParAff,
  applyParAff: applyParAff,
  applicativeParAff: applicativeParAff,
  semigroupParAff: semigroupParAff,
  monoidParAff: monoidParAff,
  altParAff: altParAff,
  plusParAff: plusParAff,
  alternativeParAff: alternativeParAff,
  parallelAff: parallelAff,
  functorFiber: functorFiber,
  applyFiber: applyFiber,
  applicativeFiber: applicativeFiber,
  newtypeCanceler: newtypeCanceler,
  semigroupCanceler: semigroupCanceler,
  monoidCanceler: monoidCanceler,
  makeAff: $foreign.makeAff,
  generalBracket: $foreign.generalBracket
};