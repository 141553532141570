// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM = require("../React.Basic.DOM/index.js");

var card = function card(dictUnion) {
  return React_Basic.element($foreign["_card"]);
};

var card_ = function card_(children) {
  return card()({
    children: children
  });
};

module.exports = {
  card: card,
  card_: card_,
  "_card": $foreign["_card"]
};