// Generated by purs version 0.12.3
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Alternative = function Alternative(Applicative0, Plus1) {
  this.Applicative0 = Applicative0;
  this.Plus1 = Plus1;
};

var alternativeArray = new Alternative(function () {
  return Control_Applicative.applicativeArray;
}, function () {
  return Control_Plus.plusArray;
});
module.exports = {
  Alternative: Alternative,
  alternativeArray: alternativeArray
};