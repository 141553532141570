// Generated by purs version 0.12.3
"use strict";

var $foreign = require("./foreign.js");

var Effect = require("../Effect/index.js");

var Prelude = require("../Prelude/index.js");

var Web_DOM_MutationRecord = require("../Web.DOM.MutationRecord/index.js");

var Web_DOM_Node = require("../Web.DOM.Node/index.js");

var observe = function observe(dictUnion) {
  return $foreign["_observe"];
};

module.exports = {
  observe: observe,
  mutationObserver: $foreign.mutationObserver,
  disconnect: $foreign.disconnect,
  takeRecords: $foreign.takeRecords
};