// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String = require("../Data.String/index.js");

var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_String_Pattern = require("../Data.String.Pattern/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Prelude = require("../Prelude/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var NonEmptyString = function NonEmptyString(x) {
  return x;
};

var NonEmptyReplacement = function NonEmptyReplacement(x) {
  return x;
};

var MakeNonEmpty = function MakeNonEmpty(nes) {
  this.nes = nes;
};

var toUpper = function toUpper(v) {
  return Data_String_Common.toUpper(v);
};

var toString = function toString(v) {
  return v;
};

var toLower = function toLower(v) {
  return Data_String_Common.toLower(v);
};

var showNonEmptyString = new Data_Show.Show(function (v) {
  return "(NonEmptyString.unsafeFromString " + (Data_Show.show(Data_Show.showString)(v) + ")");
});
var showNonEmptyReplacement = new Data_Show.Show(function (v) {
  return "(NonEmptyReplacement " + (Data_Show.show(showNonEmptyString)(v) + ")");
});
var semigroupNonEmptyString = Data_Semigroup.semigroupString;
var semigroupNonEmptyReplacement = semigroupNonEmptyString;

var replaceAll = function replaceAll(pat) {
  return function (v) {
    return function (v1) {
      return Data_String_Common.replaceAll(pat)(v)(v1);
    };
  };
};

var replace = function replace(pat) {
  return function (v) {
    return function (v1) {
      return Data_String_Common.replace(pat)(v)(v1);
    };
  };
};

var prependString = function prependString(s1) {
  return function (v) {
    return s1 + v;
  };
};

var ordNonEmptyString = Data_Ord.ordString;
var ordNonEmptyReplacement = ordNonEmptyString;

var nonEmptyNonEmpty = function nonEmptyNonEmpty(dictIsSymbol) {
  return new MakeNonEmpty(function (p) {
    return Data_Symbol.reflectSymbol(dictIsSymbol)(p);
  });
};

var nes = function nes(dict) {
  return dict.nes;
};

var makeNonEmptyBad = function makeNonEmptyBad(dictFail) {
  return new MakeNonEmpty(function (v) {
    return "";
  });
};

var localeCompare = function localeCompare(v) {
  return function (v1) {
    return Data_String_Common.localeCompare(v)(v1);
  };
};

var liftS = function liftS(f) {
  return function (v) {
    return f(v);
  };
};

var joinWith1 = function joinWith1(dictFoldable1) {
  return function (v) {
    return function ($46) {
      return NonEmptyString(Data_Foldable.intercalate(dictFoldable1.Foldable0())(Data_Monoid.monoidString)(v)($46));
    };
  };
};

var joinWith = function joinWith(dictFoldable) {
  return function (splice) {
    return function ($47) {
      return Data_Foldable.intercalate(dictFoldable)(Data_Monoid.monoidString)(splice)($47);
    };
  };
};

var join1With = function join1With(dictFoldable1) {
  return function (splice) {
    return function ($48) {
      return NonEmptyString(joinWith(dictFoldable1.Foldable0())(splice)($48));
    };
  };
};

var fromString = function fromString(v) {
  if (v === "") {
    return Data_Maybe.Nothing.value;
  }

  ;
  return new Data_Maybe.Just(v);
};

var stripPrefix = function stripPrefix(pat) {
  return Control_Bind.composeKleisliFlipped(Data_Maybe.bindMaybe)(fromString)(liftS(Data_String_CodeUnits.stripPrefix(pat)));
};

var stripSuffix = function stripSuffix(pat) {
  return Control_Bind.composeKleisliFlipped(Data_Maybe.bindMaybe)(fromString)(liftS(Data_String_CodeUnits.stripSuffix(pat)));
};

var trim = function trim(v) {
  return fromString(Data_String_Common.trim(v));
};

var unsafeFromString = function unsafeFromString(dictPartial) {
  return function ($49) {
    return Data_Maybe.fromJust(dictPartial)(fromString($49));
  };
};

var eqNonEmptyString = Data_Eq.eqString;
var eqNonEmptyReplacement = eqNonEmptyString;

var contains = function contains($50) {
  return liftS(Data_String_CodeUnits.contains($50));
};

var appendString = function appendString(v) {
  return function (s2) {
    return v + s2;
  };
};

module.exports = {
  nes: nes,
  NonEmptyString: NonEmptyString,
  MakeNonEmpty: MakeNonEmpty,
  NonEmptyReplacement: NonEmptyReplacement,
  fromString: fromString,
  unsafeFromString: unsafeFromString,
  toString: toString,
  appendString: appendString,
  prependString: prependString,
  stripPrefix: stripPrefix,
  stripSuffix: stripSuffix,
  contains: contains,
  localeCompare: localeCompare,
  replace: replace,
  replaceAll: replaceAll,
  toLower: toLower,
  toUpper: toUpper,
  trim: trim,
  joinWith: joinWith,
  join1With: join1With,
  joinWith1: joinWith1,
  liftS: liftS,
  eqNonEmptyString: eqNonEmptyString,
  ordNonEmptyString: ordNonEmptyString,
  semigroupNonEmptyString: semigroupNonEmptyString,
  showNonEmptyString: showNonEmptyString,
  makeNonEmptyBad: makeNonEmptyBad,
  nonEmptyNonEmpty: nonEmptyNonEmpty,
  eqNonEmptyReplacement: eqNonEmptyReplacement,
  ordNonEmptyReplacement: ordNonEmptyReplacement,
  semigroupNonEmptyReplacement: semigroupNonEmptyReplacement,
  showNonEmptyReplacement: showNonEmptyReplacement
};