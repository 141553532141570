// Generated by purs version 0.12.3
"use strict";

var Control_Bind = require("../Control.Bind/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Optimus_Client_Pages_Loading = require("../Optimus.Client.Pages.Loading/index.js");

var Optimus_Client_Route = require("../Optimus.Client.Route/index.js");

var Prelude = require("../Prelude/index.js");

var React_Basic = require("../React.Basic/index.js");

var Web_HTML = require("../Web.HTML/index.js");

var Web_HTML_Window = require("../Web.HTML.Window/index.js");

var component = React_Basic.createComponent("AsyncPage");

var asyncPage = function () {
  var render = function render(v) {
    if (v.state.data instanceof Data_Maybe.Just) {
      return v.props.pageView({
        pgData: v.state.data.value0,
        history: v.props.history,
        siloId: v.props.siloId
      });
    }

    ;

    if (v.state.data instanceof Data_Maybe.Nothing) {
      return Optimus_Client_Pages_Loading.loadingPage;
    }

    ;
    throw new Error("Failed pattern match at Optimus.Client.AsyncPage (line 54, column 7 - line 56, column 20): " + [v.state.data.constructor.name]);
  };

  var handleRes = function handleRes(v) {
    return function (e) {
      if (e instanceof Data_Either.Right) {
        return v.setState(function (v1) {
          var $11 = {};

          for (var $12 in v1) {
            if ({}.hasOwnProperty.call(v1, $12)) {
              $11[$12] = v1[$12];
            }

            ;
          }

          ;
          $11.data = new Data_Maybe.Just(e.value0);
          return $11;
        });
      }

      ;

      if (e instanceof Data_Either.Left) {
        return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("ERROR"));
      }

      ;
      throw new Error("Failed pattern match at Optimus.Client.AsyncPage (line 46, column 30 - line 51, column 5): " + [e.constructor.name]);
    };
  };

  var didMount = function didMount(self) {
    return Effect_Aff.runAff_(handleRes(self))(self.props.fetchData);
  };

  return React_Basic.make()(component)({
    initialState: {
      data: Data_Maybe.Nothing.value
    },
    didMount: didMount,
    render: render
  });
}();

module.exports = {
  component: component,
  asyncPage: asyncPage
};